import axios from 'axios';
import { MasterDataURL } from '../../../../config/UrlEndPoint';

export const URL = `${MasterDataURL}donor_category`;

// Get MasterCategory All For use Combobox data
export function getMasterCategoryAll() {
    return axios.get(URL, {
        params: {
            sort_by: '+name'
        }
    });
}

// Get MasterCategory All For use Combobox data
// export function getMasterCategoryAllCompany() {
//     return axios.get(`${URL}`);
// }

// //Get By ID
export function getMasterCategoryById(masterCategoryId) {
    return axios.get(`${URL}/${masterCategoryId}`);
}

// items => filtered/sorted result for table data
export function findMasterCategory(queryParams) {
    return axios.post(`${URL}/find`, queryParams);
}

// POST => POST: Create the masterCategory on the server
export function createMasterCategory(masterCategory) {
    return axios.post(`${URL}/create`, masterCategory);
}

// UPDATE => PUT: update the masterCategory on the server
export function updateMasterCategory(masterCategory) {
    return axios.put(`${URL}/${masterCategory.uuid}`, masterCategory);
}
// soft delete by setting is_deleted to true
export function deleteMasterCategory(masterCategory) {
    return axios.put(`${URL}/delete/${masterCategory.uuid}`, masterCategory);
}


