import React from "react";
import { Route } from "react-router-dom";
import { PartnershipCard } from "./PartnershipkCard";
import { PartnershipEditDialog } from "./partnership-edit-dialog/PartnershipEditDialog";
import { PartnershipUIProvider } from "./PartnershipUIContext";
import { PrivateRoute } from "../../../../PrivateRoute";
import { RoleChecker } from "../../../../RoleChecker";

export function PartnershipPage({ history }) {
  const partnershipUIEvents = {
    newPartnershipButtonClick: () => {
      history.push("/qurban/partnership/new");
    },
    openEditPartnershipPage: (id) => {
      history.push(`/qurban/partnership/${id}/edit`);
    },
    openManagePartnershipPage: (id) => {
      history.push(`/qurban/partnership/${id}/manage`);
    },
    EditPartnershipReportTemplatePage: (id) => {
      history.push(`/qurban/partnership/${id}/manage/edit/report_template`);
    },
    PartnershipCertificateTemplatePage: (id) => {
      history.push(
        `/qurban/partnership/${id}/manage/edit/certificate_template`
      );
    },
  };

  return (
    <PartnershipUIProvider partnershipUIEvents={partnershipUIEvents}>
      <PartnershipCard />
    </PartnershipUIProvider>
  );
}
