import React, { useEffect, useMemo } from "react";
import { Field, Formik } from "formik";
import ReactSelect from "react-select";
import { isEqual } from "lodash";
import { useDonorImportUIContext } from "../DonorImportUIContext";
import { ActionButton } from "../../../../_metronic/_partials/controls/ActionButton";
import { Input } from "../../../../_metronic/_partials/controls";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../MasterData/_redux/user/userAction";

function prepareFilter(queryParams, values) {
  const { user_uuid } = values;
  const newQueryParams = { ...queryParams };

  newQueryParams.user_uuid = user_uuid;

  return newQueryParams;
}

export function DonorImportFilter({ listLoading }) {
  // Branchs UI Context
  const donorImportUIContext = useDonorImportUIContext();
  const donorImportUIProps = useMemo(() => {
    return {
      setQueryParams: donorImportUIContext.setQueryParams,
      queryParams: donorImportUIContext.queryParams,
    };
  }, [donorImportUIContext]);

  function applyFilter(values) {
    const newQueryParams = prepareFilter(
      donorImportUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, donorImportUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      donorImportUIProps.setQueryParams(newQueryParams);
    }
  }

  const dispatch = useDispatch();

  const initUser = {
    filter: {
      // name: '',
      role: ["Fundraiser", "FundCollCheck", "KepalaCabang", "SuperAdmin"],
    },
    pageNumber: null,
    pageSize: null,
  };

  useEffect(() => {
    dispatch(fetchUsers(initUser));
    // eslint-disable-next-line
  }, []);

  const { userList } = useSelector(
    (state) => ({ userList: state.users }),
    shallowEqual
  );

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) => {
        applyFilter(values);
      }}
    >
      {({ values, handleSubmit, setFieldValue, resetForm }) => (
        <form onSubmit={handleSubmit} className="form form-label-right">
          <div className="form-group row">
            <div className="col-lg-4">
              <label>User</label>
              <Autocomplete
                name="user_uuid"
                options={userList.entities || []}
                getOptionLabel={(option) => `${option.name} - ${option.email}`}
                onChange={(e, value) => {
                  if (value) {
                    setFieldValue("user_uuid", value.uuid);
                  } else {
                    setFieldValue("user_uuid", "");
                  }
                }}
                value={
                  userList.entities &&
                  userList.entities.length > 0 &&
                  userList.entities.find(
                    (option) => option.uuid === values.user_uuid
                  )
                }
                // loading={channelName.length === 0 ? true : false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Cari User"
                    variant="outlined"
                    disableClearable
                    size="small"
                  />
                )}
              />
            </div>
          </div>
          <div className="mt-4 form-group row">
            <div className="col d-flex justify-content-end">
              {/* <ActionButton
                type="button"
                text="Hapus Pencarian"
                variant="warning"
                onClick={() => {
                  resetForm();
                  handleSubmit();
                }}
                className={"mr-3"}
              /> */}
              <ActionButton type="submit" text="Terapkan" variant="warning" />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
