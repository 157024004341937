import axios from 'axios';
import { MasterDataURL } from '../../../../config/UrlEndPoint';

export const URL = `${MasterDataURL}bank_pooling`;

// Get BankPooling All For use Combobox data
export function getBankPoolingAll() {
    return axios.get(URL);
}

// Get BankPooling All For use Combobox data
export function getBankPoolingAllCompany() {
    return axios.get(`${URL}/allcompany`);
}

// //Get By ID
export function getBankPoolingById(bankPoolingId) {
    return axios.get(`${URL}/${bankPoolingId}`);
}

// items => filtered/sorted result for table data
export function findBankPooling(queryParams) {
    return axios.post(`${URL}/find`, queryParams);
}

// POST => POST: Create the bankPooling on the server
export function createBankPooling(bankPooling) {
    return axios.post(URL, bankPooling);
}

// UPDATE => PUT: update the bankPooling on the server
export function updateBankPooling(bankPooling) {
    return axios.put(`${URL}/${bankPooling.uuid}`, bankPooling);
}

