import React, { useState } from "react";
import {
  Input,
  Pagination,
  Select,
} from "../../../../_metronic/_partials/controls";
import { sizePerPageList } from "../DonaturUIHelpers";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  toAbsoluteUrl,
} from "../../../../_metronic/_helpers";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Field, Formik } from "formik";

export const DonaturInteractions = ({
  listLoading = false,
  entities = [],
  totalCount = 0,
  queryParams = {},
  setQueryParams,
  editInteraction,
  downloadFile,
  deleteInteraction,
}) => {
  // * TABLE

  const columns = [
    {
      dataField: "source",
      text: "Sumber",
    },
    {
      dataField: "subject",
      text: "Perihal",
    },
    {
      dataField: "description",
      text: "Interaksi",
    },
    {
      dataField: "action",
      text: "Actions",
      classes: "text-right pr-0",
      formatter: (cell, row) => {
        return (
          <>
            {row.file && (
              <>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="products-edit-tooltip">Download File</Tooltip>
                  }
                >
                  <button
                    className="btn btn-icon btn-light btn-hover-dark btn-sm mx-3"
                    onClick={() => downloadFile(row)}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-dark">
                      <GetAppIcon />
                    </span>
                  </button>
                </OverlayTrigger>
              </>
            )}
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Edit Interaksi</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => editInteraction(row.uuid)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Hapus Interaksi</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
                onClick={() => deleteInteraction(row.uuid)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                  />
                </span>
              </a>
            </OverlayTrigger>
          </>
        );
      },
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={queryParams.filter}
        onSubmit={(values) => {
          setQueryParams((prevState) => ({
            ...prevState,
            filter: {
              ...prevState.filter,
              ...values,
            },
          }));
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <div className="form-group row">
              <div className="col-lg-4">
                <Select
                  name="source"
                  label="Sumber"
                  onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                >
                  <option value="">--PILIH--</option>
                  <option value="KONTER">Konter</option>
                  <option value="SMS">SMS</option>
                  <option value="EMAIL">Email</option>
                  <option value="WA">WhatsApp</option>
                  <option value="TELEPON">Telepon</option>
                  <option value="LIVECHAT">LiveChat</option>
                  <option value="FB">FaceBook</option>
                  <option value="IG">Instagram</option>
                  <option value="TWITTER">Twitter</option>
                  <option value="ECHANNEL">E-Channel</option>
                  <option value="KANTOR KAS">Kantor Kas</option>
                  <option value="PENJEMPUTAN">Penjemputan</option>
                  <option value="KUNJUNGAN DONATUR">Kunjungan Donatur</option>
                  <option value="EVENT">Event/Kegiatan Dompet Dhuafa</option>
                  <option value="FEEDBACK SURVEY KURBAN">Feedback Survey Kurban</option>
                  <option value="FEEDBACK SURVEY LAYANAN">Feedback Survey Layanan</option>
                  <option value="FEEDBACK SURVEY RAMADHAN">Feedback Survey Ramadhan</option>
                  <option value="FEEDBACK SURVEY">Feedback Survey</option>
                </Select>
              </div>
              <div className="col-lg-4">
                <label>Subject (Perihal)</label>
                <input
                  name="subject"
                  className="form-control"
                  placeholder="Subject"
                  onChange={(e) => setFieldValue(e.target.name, e.target.value)}
                />
              </div>
            </div>
            <button
              className="btn btn-primary float-right"
              onClick={handleSubmit}
            >
              Terapkan
            </button>
          </>
        )}
      </Formik>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                // defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(setQueryParams)}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};
