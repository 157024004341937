import React, { useMemo, useState } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { Modal } from 'react-bootstrap';
import { useCheckUIContext } from '../CheckUIContext';
import { CheckerHeaderUIProvider } from './CheckHeaderUIContext';
import { CheckFilter } from './CheckFilter';
import { CheckTable } from './CheckTable';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Form as Forms } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input-field';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import * as Yup from "yup";

export function CheckHeader({ setSubmitValue, amount }) {
    const checkUIContext = useCheckUIContext();
    const checkUIProps = useMemo(() => {
        return {
            bank: checkUIContext.bank,
            showBank: checkUIContext.showBank,
            openBank: checkUIContext.openBank,
            closeBank: checkUIContext.closeBank,
        };
    }, [checkUIContext]);

    const { bank_name, branch_name, account_number } = checkUIProps.bank;

    const initialValues = {
        diff_description: '',
        channel_charge: '',
        file: '',
        amount: amount,
        trx_date: '',
        bank_amount: Number('') - Number(''),
        diff_amount: 0
    }


    return (
        <>
            <Card border="primary">
                <CardHeader title="Pilih Bank Pengumpul">
                    <CardHeaderToolbar>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={checkUIProps.openBank}
                        >Select
                        </button>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>

                    <table>
                        <tbody>
                        <tr>
                            <td width="100px"><p><b>Bank</b></p></td>
                            <td width="10%"><p>:</p></td>
                            <td><p><b>{bank_name}</b></p></td>
                        </tr>
                        <tr>
                            <td><p><b>No Bank Pengumpul</b></p></td>
                            <td><p>:</p></td>
                            <td><p><b>{account_number}</b></p></td>
                        </tr>
                        <tr>
                            <td><p><b>Cabang</b></p></td>
                            <td><p>:</p></td>
                            <td><p><b>{branch_name}</b></p></td>
                        </tr>
                        </tbody>
                    </table>

                    <Modal
                        size="lg"
                        show={checkUIProps.showBank}
                        onHide={checkUIProps.closeBank}
                        aria-labelledby="modal-sizes-title-lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="modal-sizes-title-lg">Select Bank Pengumpul</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                            <CheckerHeaderUIProvider>

                                <CheckFilter/>
                                <CheckTable/>

                            </CheckerHeaderUIProvider>

                        </Modal.Body>
                    </Modal>
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        onSubmit={(values) => {
                            values.amount = amount
                            setSubmitValue(values);
                        }}>
                        {({ handleSubmit, setFieldValue, handleChange, values, setValues }) => (
                            <>
                                <Form className="form form-label-right">
                                    <div className="form-group row">
                                        <div className="col-lg mb-4 ">
                                            <label for="trx_date">Tanggal Transaksi</label>
                                            <TextField
                                                id="trx_date"
                                                type="date"
                                                name="trx_date"
                                                className="form-control"
                                                value={values.trx_date}
                                                onChange={(e) => {
                                                    setFieldValue('trx_date', moment(e.target.value).format('YYYY-MM-DD'));
                                                    handleSubmit();
                                                }}
                                            />
                                            <ErrorMessage name='trx_date'
                                                render={msg => <div style={{ color: 'red' }}>{msg}</div>}
                                            />
                                        </div>
                                
                                        <div className="col-lg mb-2">
                                            <label for="amount">Grand Total</label>
                                            <CurrencyInput
                                                name="amount"
                                                placeholder="Nominal"
                                                intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                                                className="form-control"
                                                decimalsLimit={2}
                                                label="Jumlah"
                                                value={amount}
                                                disabled={true}
                                            // change
                                            />
                                            <ErrorMessage name='amount'
                                                render={msg => <div style={{ color: 'red' }}>{msg}</div>}
                                            />
                                        </div>
                                    </div>

                                    

                                    <div className="form-group row">
                                        <div className="col-lg">
                                            <label for="bank_amount">Input Dana Masuk</label>
                                            <CurrencyInput
                                                name="bank_amount"
                                                placeholder="Nominal Pada Bank/EDC"
                                                intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                                                className="form-control"
                                                // defaultValue={Number(values.amount) + Number(values.channel_charge)}
                                                value={values.bank_amount}
                                                decimalsLimit={2}
                                                label="Jumlah"
                                                onValueChange={(value, name) => {
                                                    setFieldValue(name, value)
                                                    let difference = Number(amount) - (Number(values.channel_charge) + Number(value))
                                                    setFieldValue("diff_amount", difference)
                                                    handleSubmit();
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg">
                                            <label for="channel_charge">Input Biaya Channel</label>
                                            <CurrencyInput
                                                name="channel_charge"
                                                placeholder="Nominal Pada Bank/EDC"
                                                intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                                                className="form-control"
                                                defaultValue={0}
                                                value={values.channel_charge}
                                                decimalsLimit={2}
                                                label="Jumlah"
                                                onValueChange={(value, name) => {
                                                    setFieldValue(name, value)
                                                    let difference = Number(amount) - (Number(values.bank_amount) + Number(value))
                                                    setFieldValue("diff_amount", difference);
                                                    handleSubmit();
                                                }}
                                            />
                                            <ErrorMessage name='channel_charge'
                                                render={msg => <div style={{ color: 'red' }}>{msg}</div>}
                                            />
                                        </div>

                                        <div className="col-lg">
                                            <label for="diff_amount">Selisih Nominal</label>
                                            <CurrencyInput
                                                name="diff_amount"
                                                placeholder="Selisih Nominal"
                                                intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                                                className="form-control"
                                                // defaultValue={values.goods_qty_price ?? ""}
                                                value={values.diff_amount}
                                                decimalsLimit={2}
                                                label="Jumlah"
                                                disabled={true}
                                            />
                                            <ErrorMessage name='diff_amount'
                                                render={msg => <div style={{ color: 'red' }}>{msg}</div>}
                                            />
                                        </div>

                                        <div className="col-lg">
                                            <label for="diff_description">Deskripsi Selisih</label>
                                            <Field
                                                id="diff_description"

                                                name="diff_description"
                                                className="form-control"
                                                // disabled={status === "SETTLE"}
                                                value={values.diff_description}
                                                onChange={(e) => {
                                                    setFieldValue('diff_description', e.target.value);
                                                    handleSubmit();
                                                }}
                                            />
                                            <ErrorMessage name='diff_description'
                                                render={msg => <div style={{ color: 'red' }}>{msg}</div>}
                                            />
                                        </div>

                                    </div>
                                    <div className="form-group row">
                                        <div className="col-lg">
                                            <Forms.Group>
                                                <Forms.File
                                                    id="file"
                                                    label="Bukti Transfer"
                                                    // disabled={status === "SETTLE"}
                                                    onChange={(event) => {
                                                        setFieldValue('file', event.currentTarget.files[0]);
                                                        handleSubmit();
                                                    }} />
                                                <ErrorMessage name='file'
                                                    render={msg => <div style={{ color: 'red' }}>{msg}</div>} />
                                            </Forms.Group>
                                        </div>
                                    </div>
                                    {/* )} */}
                                </Form>
                            </>
                        )}

                    </Formik>
                </CardBody>
            </Card>
        </>
    );
}

