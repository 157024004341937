import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDispatch } from 'react-redux';
import * as actions from '../_redux/collectorAction';
import * as columnFormatters from './column-formatters/';
import { currencyFormatter } from '../../../../_metronic/_helpers/UtilityHelpers'

// let totalEdc = 0;
// let totalCash = 0;
// let totalPartner = 0;

export const CollectorDialogList = ({ id, status }) => {

	const [collectDetail, setCollectDetail] = useState([]);
	const [countRow, setCountRow] = useState(0);
	const [printUrl, setPrintUrl] = useState('');
	const [totalEdc, setTotalEdc] = useState(0)
	const [totalCash, setTotalCash] = useState(0)
	const [totalPartner, setTotalPartner] = useState(0)
	const [totalGoods, setTotalGoods] = useState(0)
	const [grandTotal, setGrandTotal] = useState(0)



	const total = (value) => {
		value.forEach(item => {
			setTotalEdc(oldTotalEdc => oldTotalEdc += Number(item.payment_edc))
			setTotalCash(oldTotalCash => oldTotalCash += Number(item.payment_cash))
			setTotalPartner(oldTotalPartner => oldTotalPartner += Number(item.payment_counter_partner))
			setTotalGoods(oldTotalGoods => oldTotalGoods += Number(item.payment_goods))
			setGrandTotal(oldGrandTotal => oldGrandTotal += Number(item.total_amount))
			// console.log(item.payment_cash)
			console.log('====================================');
			console.log(item.total_amount);
			console.log('====================================');
		})
		// console.log(totalEdc, totalCash, totalPartner);
	}
	const dispatch = useDispatch();
	useEffect(() => {
		//Requset ke Collector Action
		dispatch(actions.fetchCollectDetail(id, status)).then(response => {
			setCollectDetail(response.rows);
			setCountRow(response.count);
			setPrintUrl(response.url_print)
			// console.log(response);
			total(response.rows);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	const downloadReportClick = () => {
		window.open(printUrl)
	}

	// console.log(collectDetail);

	// Table columns
	const columns = [
		{
			dataField: 'donation_hid',
			text: 'Donation HID',
			footer: '',
		},
		{
			dataField: 'donor.name',
			text: 'Donatur',
			footer: '',
		},
		{
			dataField: 'payment_cash',
			text: 'CASH',
			classes: 'text-right',
			headerClasses: 'text-right',
			footerClasses: 'text-right',
			footer: `${currencyFormatter(totalCash)}`,
			formatter: columnFormatters.PriceColumnFormatter
		},
		{
			dataField: 'payment_edc',
			text: 'EDC',
			classes: 'text-right',
			headerClasses: 'text-right',
			footerClasses: 'text-right',
			footer: `${currencyFormatter(totalEdc)}`,
			formatter: columnFormatters.PriceColumnFormatter
		},
		{
			dataField: 'payment_counter_partner',
			text: 'MITRA KONTER',
			classes: 'text-right',
			headerClasses: 'text-right',
			footerClasses: 'text-right',
			footer: `${currencyFormatter(totalPartner)}`,
			formatter: columnFormatters.PriceColumnFormatter
		},
		{
			dataField: 'payment_goods',
			text: 'Barang',
			classes: 'text-right',
			headerClasses: 'text-right',
			footerClasses: 'text-right',
			footer: `${currencyFormatter(totalGoods)}`,
			formatter: columnFormatters.PriceColumnFormatter
		},
		{
			dataField: 'row',
			text: 'Total',
			classes: 'text-right',
			headerClasses: 'text-right',
			footer: `${currencyFormatter(grandTotal)}`,
			footerClasses: 'text-right',
			formatter: columnFormatters.PriceTotalCount,
		},
	];


	return (
		<>
			<h4>Counter : {collectDetail.length !== 0 ? collectDetail[0].channel.name : ""} </h4>
			<h6>Fundraiser : {collectDetail.length != 0 ? collectDetail[0].user_name : ""} </h6>
			<h6>Collect HID : {collectDetail.length != 0 ? collectDetail[0].collect_hid : ""} </h6>
			<BootstrapTable
				wrapperClasses="table-responsive"
				classes="table table-head-custom table-vertical-center overflow-hidden"
				bootstrap4
				bordered={false}
				remote
				keyField="uuid"
				data={collectDetail === null ? [] : collectDetail}
				columns={columns}
			// expandRow={expandRow}

			>
			</BootstrapTable>
			{/* <h6>Total Transaksi : {countRow} item</h6> */}
		</>
	);
};

