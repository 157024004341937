import React from "react";
import { DonorImportCard } from "./DonorImportCard";
import { DonorImportUIProvider } from "./DonorImportUIContext";

export function DonorImportPage({ history }) {
  const donorImportUIEvents = {
    newDonorImportButtonClick: () => {
      history.push(`/donatur-import/new`);
    },
    openEditDonorImportPage: (id) => {
      history.push(`/donatur-import/${id}/edit`);
    },
  };

  return (
    <DonorImportUIProvider donorImportUIEvents={donorImportUIEvents}>
      <DonorImportCard />
    </DonorImportUIProvider>
  );
}
