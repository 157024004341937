import axios from 'axios';
import { MasterDataURL } from '../../../../config/UrlEndPoint';

export const BRANCHS_URL = `${MasterDataURL}branch`;

// items => filtered/sorted result
export function findBranchs(queryParams) {
    return axios.post(`${BRANCHS_URL}/find`, queryParams);
}

//Get By ID
export function getBranchById(branchId) {
    return axios.get(`${BRANCHS_URL}/${branchId}`);
}

//Get By ID
export function getBranchAll() {
    return axios.get(`${BRANCHS_URL}`);
}

// POST => POST: Create the branch on the server
export function createBranch(branch) {
    return axios.post(BRANCHS_URL, branch);
}

// UPDATE => PUT: update the branch on the server
export function updateBranch(branch) {
    return axios.put(`${BRANCHS_URL}/${branch.uuid}`, branch);
}
