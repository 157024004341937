import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
    Input,
    Select
} from '../../../../../../../_metronic/_partials/controls';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../../../_metronic/_partials/controls';
import { RoleChecker, RoleCheckerNoRedux } from '../../../../../../RoleChecker';
import { copyValue } from "../../../../../../../_metronic/_helpers/UtilityHelpers";
import { categoryHewan, tipeHewan } from '../HewanKurbanCampaignUIHelpers';
import CurrencyInput from 'react-currency-input-field';
import { getHewanKurbanAll } from '../../../../_redux/hewan-kurban/hewanKurbanCrud';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { getBranchAll } from '../../../../../MasterData/_redux/branchs/branchCrud';
import { Editor } from "@tinymce/tinymce-react";






// Validation schema
const HewanKurbanCampaignEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Nama Hewan Kurban Campaign Wajib Diisi'),
    price: Yup.number().min(1, "Harga Tidak Boleh Kurang Dari Sama Dengan 0").required('Harga wajib diisi'),
    thk_livestock_uuid: Yup.string().required('Hewan Kurban Campaign Wajib Diisi'),
});

export function HewanKurbanCampaignEditForm({ saveHewanKurbanCampaign, actionsLoading, hewanKurbanCampaign, roleMaster, onHide, id }) {

    const [LivestockList, setLivestockList] = useState([])
    const [branch, setBranch] = useState([]);
    const [imgPreview, setImgPreview] = useState("")


    const getHewanKurbanList = async () => {
        const response = await getHewanKurbanAll()
        if (response) {
            setLivestockList(response.data.data)
        }
    }
    const fetchBranch = async () => {
        const result = await getBranchAll();
        setBranch(result.data.data);
    };

    useEffect(() => {

        fetchBranch();
        getHewanKurbanList()
    }, [])

    useEffect(() => {
        if (hewanKurbanCampaign.uuid && hewanKurbanCampaign.file) {
          setImgPreview(`${hewanKurbanCampaign.file.adapter}${hewanKurbanCampaign.file.path}`);
        }
    }, [hewanKurbanCampaign]);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={hewanKurbanCampaign}
                validationSchema={HewanKurbanCampaignEditSchema}
                onSubmit={(values) => {
                    saveHewanKurbanCampaign(values)

                }}
            >
                {({ handleSubmit, values, setFieldValue, handleChange }) => (
                    <>

                        <Modal.Body className="overlay overlay-block cursor-default">
                            {actionsLoading && (
                                <div className="overlay-layer bg-transparent">
                                    <div className="spinner spinner-lg spinner-success" />
                                </div>
                            )}
                            <Form className="form form-label-right">
                                <div className="form-group row">
                                    <div className="col-lg-12 mb-1">
                                        <Field
                                            name="name"
                                            component={Input}
                                            placeholder="Text"
                                            label="Nama Hewan Kurban Campaign"
                                        />
                                    </div>

                                    <div className="col-lg-12 mb-1">

                                        <label htmlFor="thk_livestock_uuid">Hewan Kurban</label>
                                        <Autocomplete
                                            name="thk_livestock_uuid"
                                            options={LivestockList}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    setFieldValue('thk_livestock_uuid', value.uuid)

                                                } else {
                                                    setFieldValue('thk_livestock_uuid', "")
                                                }
                                            }}
                                            value={LivestockList.length > 0 && LivestockList.find((item) => item.uuid === values.thk_livestock_uuid)}
                                            // loading={LivestockList.length === 0 ? true : false}

                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Pilih Hewan Kurban"
                                                    variant="outlined"
                                                    disableClearable
                                                    size="small"

                                                />
                                            )}
                                        />
                                        <label htmlFor="price">Harap Masukkan <strong>Hewan Kurban</strong></label>
                                        <ErrorMessage name='thk_livestock'
                                            render={msg => <div style={{ color: 'red', }}>{msg}</div>} />
                                    </div>
                                    <div className="col-lg-12 mb-1">

                                        <label>Harap Pilih <b>Branch</b></label>
                                        <Autocomplete
                                            name="branch_uuid"
                                            options={branch}
                                            getOptionLabel={(option) => option.name}
                                            onChange={(e, value) => {
                                                if (value) {
                                                    setFieldValue('branch_uuid', value.uuid)
                                                } else {
                                                    setFieldValue('branch_uuid', "")
                                                }
                                            }}
                                            value={branch.length > 0 && branch.find((item) => item.uuid === values.branch_uuid)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Pilih Branch"
                                                    variant="outlined"
                                                    disableClearable
                                                    size="small"

                                                />
                                            )}
                                        />
                                        <label>Harap Masukkan <b>Branch</b></label>
                                    </div>
                                    <div className="col-lg-12 mb-1">

                                        <label htmlFor="price">Harga Hewan Kurban Campaign</label>
                                        <CurrencyInput
                                            name="price"
                                            placeholder="Harga Hewan Kurban Campaign"
                                            intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                                            className="form-control"
                                            defaultValue={values.price}
                                            value={values.price}
                                            decimalsLimit={2}
                                            label="Jumlah"
                                            onValueChange={(value, name) => {
                                                if (value) {
                                                    setFieldValue(name, Number(value))
                                                } else {
                                                    setFieldValue(name, 0)
                                                }
                                            }}
                                        />
                                        <label htmlFor="price">Harap Masukkan <strong>Harga Hewan Kurban Campaign</strong></label>
                                        <ErrorMessage name='price'
                                            render={msg => <div style={{ color: 'red' }}>{msg}</div>} />
                                    </div>

                                </div>
                                {/* <div className="form-group row">
                                    <div className="col">
                                        <div className="custom-file">
                                            <input
                                                name="file"
                                                type="file"
                                                className="custom-file-input"
                                                accept=".jpeg, .jpg, .png, .webp"
                                                id="project-document"
                                                onChange={e => {
                                                    setFieldValue("file", e.target.files[0])
                                                    setImgPreview(URL.createObjectURL(e.target.files[0]))
                                                }}
                                            />
                                            <label
                                                className="custom-file-label"
                                                htmlFor="project-document"
                                            >
                                                {values.file instanceof File
                                                ? values.file.name
                                                : "Choose file"}
                                            </label>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <div className="form-group row">
                                    {imgPreview && (
                                        <div className='col'>
                                            <img src={imgPreview} className="img-thumbnail"/>
                                        </div>
                                    )}
                                    <div className="col">
                                        <label>
                                            <strong>Deskripsi</strong>
                                        </label>
                                        <textarea
                                            name="description"
                                            placeholder="Deskripsi"
                                            className="form-control"
                                            value={values.description}
                                            onChange={e => setFieldValue("description", e.target.value)}
                                        />
                                    </div>
                                </div> */}

                                <Editor
                                    apiKey="bqbatsgd5atufwa92dfyyrmk1jhkx9vts4tf9lc2cnt4le7v"
                                    value={values.description}
                                    onEditorChange={value => setFieldValue("description", value)}
                                    plugins="advlist autolink lists link image charmap print preview anchor
                                            searchreplace visualblocks code fullscreen
                                            insertdatetime media table paste code help wordcount"
                                    init={{
                                        menubar: "file | edit | view | tools | table | help",
                                        height: 500,
                                        toolbar:
                                        "undo redo | formatselect | bold italic backcolor | \
                                        alignleft aligncenter alignright alignjustify | \
                                        bullist numlist outdent indent | removeformat",
                                        // images_upload_handler: uploadDescImage,
                                    }}
                                />

                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-light btn-elevate"
                            >
                                Cancel
                            </button>
                            <> </>
                            <button
                                type="submit"
                                onClick={() => handleSubmit()}
                                className="btn btn-primary btn-elevate"
                            >
                                Save
                            </button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    );
}

