import { createSlice } from '@reduxjs/toolkit';

const initialPickupState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: null,
	pickupForEdit: undefined,
	lastError: null,
};

export const callTypes = {
	list: 'list',
	action: 'action',
};

export const pickupSlice = createSlice({
	name: 'pickup',
	initialState: initialPickupState,
	reducers: {
		catchError: (state, action) => {
			state.error = action.payload.error;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false;
			} else {
				state.actionsLoading = false;
			}
		},

		startCall: (state, action) => {
			state.error = null;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true;
			} else {
				state.actionsLoading = true;
			}
		},

		// getPickupById
		pickupFetched: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
			state.pickupData = action.payload.pickupData;
		},

		// findPickups
		pickupsFetched: (state, action) => {
			const { total_items, items } = action.payload;
			state.listLoading = false;
			state.error = null;
			state.entities = items;
			state.totalCount = total_items;
		},

		// Set to default state actionLoading & error
		pickupReload: (state, action) => {
			state.actionsLoading = false;
			state.listLoading = false;
			state.error = null;
		},

	},
});

