/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export const ActionsColumnFormatter = (
  _,
  row,
  __,
  { openEditDonorImportPage, openDeleteDonorImport, allowEdit, allowDelete }
) => (
  <>
    {allowEdit && (
      <OverlayTrigger
        overlay={<Tooltip id="donor-importedit-tooltip">Detail Import</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-info btn-sm mx-3"
          onClick={() => openEditDonorImportPage(row.uuid)}
        >
          <span className="svg-icon svg-icon-md svg-icon-info">
              <i class="fas fa-eye"></i>
            </span>
        </a>
      </OverlayTrigger>
    )}
  </>
);
