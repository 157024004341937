import React from 'react';
import { CollectorUIProvider } from './CollectorUIContext';
import { CollectorCard } from './CollectorCard';
import { Route } from 'react-router-dom';
import { CollectorDialog } from './dialog/CollectorDialog';

export function CollectorPage2({ history }) {
    const collectorUIEvents = {
        openDetailPage: (id, status) => {
            history.push(`/collector/${id}/${status}`);
        },
    };

    return (
        <CollectorUIProvider collectorUIEvents={collectorUIEvents}>

            <Route path="/collector/:id/:status">
                {({ history, match }) => (
                    <CollectorDialog
                        id={match && match.params.id}
                        show={match != null}
                        onHide={() => {
                            history.push('/collector');
                        }}
                        status={match && match.params.status}
                    />
                )}
            </Route>

            <CollectorCard/>
        </CollectorUIProvider>
    );
}
