import React, { useMemo, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { useHewanKurbanCampaignUIContext } from '../../HewanKurbanCampaignUIContext';
import { initialFilter, categoryHewan, tipeHewan } from '../../HewanKurbanCampaignUIHelpers';
import CurrencyInput from 'react-currency-input-field';
import { getHewanKurbanAll } from '../../../../../_redux/hewan-kurban/hewanKurbanCrud'
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getBranchAll } from '../../../../../../MasterData/_redux/branchs/branchCrud';
import {
	Select,
} from '../../../../../../../../_metronic/_partials/controls';

const prepareFilter = (queryParams, values) => {
	const { name, thk_livestock_uuid, priceGte, priceLte, branch_uuid, tipe_branch, periode } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};

	//add to Object filter
	filter.name = name ?? '';
	filter.thk_livestock_uuid = thk_livestock_uuid ?? '';
	filter.priceGte = priceGte ?? '';
	filter.priceLte = priceLte ?? '';
	filter.branch_uuid = branch_uuid ?? '';
	filter.tipe_branch = tipe_branch ?? '';
	filter.periode = periode ?? '';
	newQueryParams.filter = filter;
	return newQueryParams;
};

export const HewanKurbanCampaignFilter = ({ listLoading }) => {
	// HewanKurbanCampaign UI Context
	const hewanKurbanCampaignUIContext = useHewanKurbanCampaignUIContext();
	const hewanKurbanCampaignUIProps = useMemo(() => {
		return {
			setQueryParams: hewanKurbanCampaignUIContext.setQueryParams,
			queryParams: hewanKurbanCampaignUIContext.queryParams,
			filterType: hewanKurbanCampaignUIContext.filterType,
		};
	}, [hewanKurbanCampaignUIContext]);

	const applyFilter = (values) => {
		const newQueryParams = prepareFilter(hewanKurbanCampaignUIProps.queryParams, values);
		if (!isEqual(newQueryParams, hewanKurbanCampaignUIProps.queryParams)) {
			newQueryParams.pageNumber = 1;
			hewanKurbanCampaignUIProps.setQueryParams(newQueryParams);
		}
	};

	const [LivestockList, setLivestockList] = useState([])
	const [branch, setBranch] = useState([]);

	const getHewanKurbanList = async () => {
		const response = await getHewanKurbanAll()
		if (response) {
			setLivestockList(response.data.data)
		}
	}

	const fetchBranch = async () => {
		const result = await getBranchAll();
		setBranch(result.data.data);

	};

	useEffect(() => {
		getHewanKurbanList()
		fetchBranch()
	}, [])




	return (
		<Formik initialValues={initialFilter.filter} onSubmit={(values) => {
			applyFilter(values);
		}}
		>{({ values, handleSubmit, handleBlur, setFieldValue }) => (
			<form onSubmit={handleSubmit} className="form form-label-right">
				<div className="row">
					<div className="col-lg-4 mb-2">
						<input
							thk_livestock_uuid="text"
							className="form-control"
							name="name"
							placeholder="Search"
							onBlur={handleBlur}
							value={values.name}
							onChange={(e) => {
								setFieldValue('name', e.target.value);
							}}
						/>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Nama Hewan Kurban Campaign
						</small>

					</div>
					<div className="col-lg-4 mb-2">

						<Autocomplete
							name="thk_livestock_uuid"
							options={LivestockList}
							getOptionLabel={(option) => option.name}
							onChange={(e, value) => {
								if (value) {
									setFieldValue('thk_livestock_uuid', value.uuid)

								} else {
									setFieldValue('thk_livestock_uuid', "")
								}
							}}
							value={LivestockList.length > 0 && LivestockList.find((item) => item.uuid === values.thk_livestock_uuid)}
							// loading={LivestockList.length === 0 ? true : false}

							renderInput={(params) => (
								<TextField
									{...params}
									placeholder="Pilih Hewan Kurban"
									variant="outlined"
									disableClearable
									size="small"

								/>
							)}
						/>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Hewan Kurban
						</small>
					</div>


					<div className="col-lg-4 mb-2">

						<CurrencyInput
							name="priceGte"
							placeholder="Harga Hewan Kurban Campaign Lebih Dari Sama Dengan"
							intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
							className="form-control"
							value={values.priceGte}
							decimalsLimit={2}
							label="Jumlah"
							onValueChange={(value, name) => {
								if (value) {
									setFieldValue(name, Number(value))
								} else {
									setFieldValue(name, "")
								}
							}}
						/>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Harga Hewan Kurban Campaign Lebih Dari Sama Dengan
						</small>
					</div>
					<div className="col-lg-4 mb-2">

						<CurrencyInput
							name="priceLte"
							placeholder="Harga Hewan Kurban Campaign Kurang Dari Sama Dengan"
							intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
							className="form-control"
							value={values.priceLte}
							decimalsLimit={2}
							label="Jumlah"
							onValueChange={(value, name) => {
								if (value) {
									setFieldValue(name, Number(value))
								} else {
									setFieldValue(name, "")
								}
							}}
						/>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Harga Hewan Kurban Campaign Kurang Dari Sama Dengan
						</small>
					</div>

					<div className="col-lg-4 mb-2">

						<Autocomplete
							name="branch_uuid"
							options={branch}
							getOptionLabel={(option) => option.name}
							// defaultValue={branch.find((option) => option.uuid === values.branch_uuid)}
							onChange={(e, value) => {
								if (value) {
									setFieldValue('branch_uuid', value.uuid)

								} else {
									setFieldValue('branch_uuid', "")
								}
							}}
							value={branch.length > 0 && branch.find((option) => option.uuid === values.branch_uuid)}
							loading={branch.length === 0 ? true : false}

							renderInput={(params) => (
								<TextField
									{...params}
									placeholder="Cari Cabang"
									variant="outlined"
									disableClearable
									size="small"

								/>
							)}
						/>
						<small className="form-text text-muted">
							<b>Cari</b> Nama Branch
						</small>
					</div>

					<div className="col-lg-4 mb-2">
						<input
							thk_livestock_uuid="text"
							className="form-control"
							name="periode"
							placeholder="Search"
							onBlur={handleBlur}
							value={values.periode}
							onChange={(e) => {
								setFieldValue('periode', e.target.value);
							}}
						/>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Periode
						</small>

					</div>

				</div>
				<button
					thk_livestock_uuid="submit"
					onClick={() => handleSubmit()}
					className="btn btn-primary btn-elevate float-right mb-2"
				>
					Cari
				</button>
			</form>
		)}
		</Formik>

	);
};

