import axios from 'axios';
import { MasterDataURL } from '../../../../config/UrlEndPoint';

export const URL = `${MasterDataURL}department`;

// Get Department All For use Combobox data
export function getDepartmentAll() {
    return axios.get(URL);
}

export function getDepartmentNotDeleted() {
    return axios.get(`${URL}/notdeleted`);
}

// //Get By ID
export function getDepartmentById(departmentId) {
    return axios.get(`${URL}/${departmentId}`);
}

// items => filtered/sorted result for table data
export function findDepartment(queryParams) {
    return axios.post(`${URL}/find`, queryParams);
}

// POST => POST: Create the department on the server
export function createDepartment(department) {
    return axios.post(URL, department);
}

// UPDATE => PUT: update the department on the server
export function updateDepartment(department) {
    return axios.put(`${URL}/${department.uuid}`, department);
}

export function deleteDepartment(department) {
    return axios.delete(`${URL}/${department.uuid}`);
}

