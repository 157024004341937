import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory } from "react-router-dom";
import { StatusColumnFormatter } from "../../Donatur/donatur-table/column-formatters/StatusColumnFormatter";

export default function DonorDetailList({ data = [] }) {
  const history = useHistory();
  const handleClickDetail = (row) => {
    history.push(`/donatur/${row.uuid}/edit`);
  };
  const columns = [
    {
      dataField: "donor_id",
      text: "ID Donatur",
    },
    {
      dataField: "name",
      text: "Nama",
    },
    {
      dataField: "hp",
      text: "HP",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "type",
      text: "Tipe Donatur",
    },
    {
      dataField: "is_active",
      text: "Status",
      formatter: StatusColumnFormatter,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (_, row) => {
        return (
          <button
            className="btn btn-icon btn-light btn-hover-info btn-sm mx-2"
            onClick={() => handleClickDetail(row)}
          >
            <span className="svg-icon svg-icon-md svg-icon-info">
              <i class="fas fa-eye"></i>
            </span>
          </button>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        bordered={false}
        remote
        keyField="uuid"
        data={data}
        columns={columns}
      />
    </>
  );
}
