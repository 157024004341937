import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { useDonaturTrxUIContext } from '../donatur-trx/DonaturTrxUIContext';
import * as columnFormatters from './column-formatters'
import { sizePerPageList } from '../DonaturUIHelpers';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Pagination } from '../../../../_metronic/_partials/controls';
import { getHandlerTableChange, NoRecordsFoundMessage, PleaseWaitMessage } from '../../../../_metronic/_helpers';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchDonationHistory } from '../_redux/donatur/donaturAction'
import { currencyFormatter } from '../../../../_metronic/_helpers/UtilityHelpers';



const prepareFilter = (queryParams, donaturType) => {
    const type_donor = donaturType;
    const newQueryParams = { ...queryParams };
    const filter = {};

    newQueryParams.filter.type_donor = filter.type_donor = type_donor ?? '';
    return newQueryParams;
};


export const
    DonaturTrxTable = ({ donaturType, tab, openDetailDonationPage, setCurrentFilter, user_uuid }) => {

        const donaturTrxUIContext = useDonaturTrxUIContext();
        const donaturTrxUIProps = useMemo(() => {
            return {
                queryParams: donaturTrxUIContext.queryParams,
                setQueryParams: donaturTrxUIContext.setQueryParams,
            };
        }, [donaturTrxUIContext]);

        const { currentState } = useSelector(
            (state) => ({ currentState: state.donaturs }),
            shallowEqual,
        );

        const { totalDonationCount, dataDonation, listLoading } = currentState;
        const dispatch = useDispatch();

        // set query untuk tab donasi dan donasi child
        if (tab === "riwayat_donasi_child") {
            if (donaturType !== "INDIVIDU") {
                const newQueryParams = prepareFilter(donaturTrxUIProps.queryParams, donaturType);
                donaturTrxUIProps.setQueryParams(newQueryParams)
            }
        } else {
            const newQueryParams = prepareFilter(donaturTrxUIProps.queryParams, "");
            donaturTrxUIProps.setQueryParams(newQueryParams)
        }


        useEffect(() => {
            dispatch(fetchDonationHistory((donaturTrxUIProps.queryParams)))
            setCurrentFilter(prev => ({
                ...prev,
                pageNumber: donaturTrxUIProps.queryParams.pageNumber,
                pageSize: donaturTrxUIProps.queryParams.pageSize,
            }))

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [donaturTrxUIProps.queryParams, dispatch, user_uuid]);
        // console.log(dataDonation);

        let columns = []

        if (tab === "riwayat_donasi_child") {
            if (donaturType !== "INDIVIDU") {
                columns = [
                    {
                        dataField: 'donation_hid',
                        text: 'Donation HID',
                    },
                    {
                        dataField: "donor.name",
                        text: "Nama Anggota"
                    },
                    {
                        dataField: 'trx_date',
                        text: 'Tanggal Trx',
                        formatter: columnFormatters.DateColumnFormatter,
                    },
                    {
                        dataField: 'status',
                        text: 'Status',
                        formatter: columnFormatters.StatusColumnFormatter
                    },
                    {
                        dataField: 'channel.type',
                        text: 'Channel Type',
                    },
                    {
                        dataField: 'channel.name',
                        text: 'Channel Name',
                        // sort: true,
                        // sortCaret: sortCaret,
                    },
                    {
                        dataField: 'total_amount',
                        text: 'Amount',
                        headerClasses: 'text-right',
                        classes: 'text-right',
                        formatter: currencyFormatter
                    },
                    {
                        dataField: 'action',
                        text: 'Actions',
                        formatter: columnFormatters.ActionsColumnFormatter,
                        formatExtraData: {
                            openDetailDonationPage: openDetailDonationPage,
                        },
                        classes: 'text-right pr-0',
                        headerClasses: 'text-right pr-3',
                        style: {
                            minWidth: '100px',
                        },
                    }

                ];
            }
        } else {
            columns = [
                {
                    dataField: 'donation_hid',
                    text: 'Donation HID',
                },
                {
                    dataField: 'trx_date',
                    text: 'Tanggal Trx',
                    formatter: columnFormatters.DateColumnFormatter,
                },
                {
                    dataField: 'status',
                    text: 'Status',
                    formatter: columnFormatters.StatusColumnFormatter
                },
                {
                    dataField: 'channel.type',
                    text: 'TYPE',
                },
                {
                    dataField: 'channel.name',
                    text: 'Channel Name',
                    // sort: true,
                    // sortCaret: sortCaret,
                },
                {
                    dataField: 'total_amount',
                    text: 'Amount',
                    headerClasses: 'text-right',
                    classes: 'text-right',
                    formatter: currencyFormatter
                },
                {
                    dataField: 'action',
                    text: 'Actions',
                    formatter: columnFormatters.ActionsColumnFormatter,
                    formatExtraData: {
                        openDetailDonationPage: openDetailDonationPage,
                    },
                    classes: 'text-right pr-0',
                    headerClasses: 'text-right pr-3',
                    style: {
                        minWidth: '100px',
                    },
                }
            ];
        }

        // Table columns



        const columnChild = [
            {
                dataField: 'name',
                text: 'Nama',
                formatter: columnFormatters.NameFormatter
            },
            {
                dataField: 'type',
                text: 'Tipe',
            },
            {
                dataField: 'amount',
                text: 'Total',
                formatter: columnFormatters.PriceAmount,
            },
        ]


        const expandRow = {
            renderer: row => (
                <BootstrapTable
                    wrapperClasses="table-respontesive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    striped
                    bootstrap4
                    bordered
                    size="sm"
                    remote
                    keyField='uuid'
                    data={row.donation_items === null ? [] : row.donation_items}
                    columns={columnChild}
                />
            ),
            onlyOneExpanding: true,
            showExpandColumn: true
        };


        const paginationOptions = {
            custom: true,
            totalSize: totalDonationCount,
            sizePerPageList: sizePerPageList,
            sizePerPage: donaturTrxUIProps.queryParams.pageSize,
            page: donaturTrxUIProps.queryParams.pageNumber,
        };

        console.log(dataDonation);

        return (
            <>
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {({ paginationProps, paginationTableProps }) => {
                        return (
                            <Pagination
                                isLoading={listLoading}
                                paginationProps={paginationProps}
                            >
                                <BootstrapTable
                                    wrapperClasses="table-responsive"
                                    classes="table table-head-custom table-vertical-center overflow-hidden"
                                    bootstrap4
                                    bordered={false}
                                    remote
                                    keyField="uuid"
                                    data={dataDonation ? dataDonation : []}
                                    columns={columns}
                                    expandRow={expandRow}
                                    // defaultSorted={uiHelpers.defaultSorted}
                                    onTableChange={getHandlerTableChange(
                                        donaturTrxUIProps.setQueryParams,
                                    )}
                                    {...paginationTableProps}
                                >
                                    <PleaseWaitMessage entities={dataDonation} />
                                    <NoRecordsFoundMessage entities={dataDonation} />
                                </BootstrapTable>
                            </Pagination>
                        );
                    }}
                </PaginationProvider>
            </>
        );
    };
