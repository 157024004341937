import React, { useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useHewanKurbanCampaignUIContext } from '../HewanKurbanCampaignUIContext';
import { HewanKurbanCampaignEditDialogHeader } from './HewanKurbanCampaignEditDialogHeader';
import { HewanKurbanCampaignEditForm } from './HewanKurbanCampaignEditForm';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../_redux/hewan-kurban-campaign/hewanKurbanCampaignAction';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../../../_metronic/_helpers';
import { LoadingAnimation } from '../../../../../../config/LoadingAnimation';

export const HewanKurbanCampaignEditDialog = ({ id, show, onHide }) => {

    // HewanKurbanCampaign UI Context
    const hewanKurbanCampaignUIContext = useHewanKurbanCampaignUIContext();
    const hewanKurbanCampaignUIProps = useMemo(() => {
        return {
            initHewanKurbanCampaign: hewanKurbanCampaignUIContext.initHewanKurbanCampaign,
            queryParams: hewanKurbanCampaignUIContext.queryParams,
            setQueryParams: hewanKurbanCampaignUIContext.setQueryParams,
        };
    }, [hewanKurbanCampaignUIContext]);

    // HewanKurbanCampaign Redux state
    const dispatch = useDispatch();
    const { actionsLoading, hewanKurbanCampaignForEdit, error, roleMaster } = useSelector(
        (state) => ({
            actionsLoading: state.hewanKurbanCampaigns.actionsLoading,
            hewanKurbanCampaignForEdit: state.hewanKurbanCampaigns.hewanKurbanCampaignForEdit,
            roleMaster: state.hewanKurbanCampaigns.roleMaster,
            error: state.hewanKurbanCampaigns.error
        }),
        shallowEqual,
    );

    if (error) {
        toast.error(error, toastOption)
    }
    useEffect(() => {
        // server call for getting HewanKurbanCampaign by id
        dispatch(actions.fetchHewanKurbanCampaign(id));
    }, [id, dispatch]);

    const saveHewanKurbanCampaign = (hewanKurbanCampaign) => {
         const formData = new FormData();
         formData.append("thk_livestock_uuid", hewanKurbanCampaign.thk_livestock_uuid);
         formData.append("branch_uuid", hewanKurbanCampaign.branch_uuid);
         formData.append("name", hewanKurbanCampaign.name);
         formData.append("price", hewanKurbanCampaign.price);
         formData.append("file", hewanKurbanCampaign.file);
         formData.append("description", hewanKurbanCampaign.description ?? "");
        if (!id) {
            dispatch(actions.createHewanKurbanCampaign(formData)).then((response) => {
                if (response)
                    toast.success(response.message, toastOption);
                dispatch(actions.fetchHewanKurbanCampaigns(hewanKurbanCampaignUIProps.queryParams)).then(() => onHide());
            });

        } else {
            dispatch(actions.updateHewanKurbanCampaign(formData, id)).then((response) => {
                if (response)

                    toast.success(response.message, toastOption);
                dispatch(actions.fetchHewanKurbanCampaigns(hewanKurbanCampaignUIProps.queryParams)).then(() => onHide());
            });
        }
    };

    return (
        <>
        <LoadingAnimation open={actionsLoading}/>
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="modal-sizes-title-lg"
            size="xl"
            backdrop="static"
            fullscreen={true}
            animation={true}
        >
            <HewanKurbanCampaignEditDialogHeader id={id} />
            <HewanKurbanCampaignEditForm
                saveHewanKurbanCampaign={saveHewanKurbanCampaign}
                actionsLoading={actionsLoading}
                hewanKurbanCampaign={hewanKurbanCampaignForEdit || hewanKurbanCampaignUIProps.initHewanKurbanCampaign}
                onHide={onHide}
                roleMaster={roleMaster}
                id={id}
            />
        </Modal>
        </>
    );
};
