import axios from 'axios';
import { MasterDataURL } from '../../../../config/UrlEndPoint';

export const URL = `${MasterDataURL}program`;

// Get Program All
export function getProgramAll() {
    return axios.get(URL);
}

// //Get By ID
export function getProgramById(programId) {
    return axios.get(`${URL}/${programId}`);
}

// POST => POST: Create the program on the server
export function createProgram(program) {
    return axios.post(URL, program);
}

// items => filtered/sorted result for table data
export function findProgram(queryParams) {
    return axios.post(`${URL}/find`, queryParams);
}

export function getProgramFiltered(filter) {
    return axios.post(`${URL}/filtered`, filter);
}

// UPDATE => PUT: update the program on the server
export function updateProgram(program, uuid) {
    return axios.put(`${URL}/${uuid}`, program);
}

export function deleteProgram(program) {
    return axios.delete(`${URL}/delete/${program.uuid}`);
}

