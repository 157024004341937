import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../_redux/hewan-kurban/hewanKurbanAction';
import * as uiHelpers from '../HewanKurbanUIHelpers';
import { currencyFormatter } from '../../../../../../../_metronic/_helpers';


import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
} from '../../../../../../../_metronic/_helpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../../../../_metronic/_partials/controls';
import { useHewanKurbanUIContext } from '../HewanKurbanUIContext';
import { RoleChecker } from '../../../../../../RoleChecker';
// import { ToastContainer } from 'react-toastify';

const HewanKurbanTable = (props) => {
    // HewanKurban UI Context
    const hewanKurbanUIContext = useHewanKurbanUIContext();
    const hewanKurbanUIProps = useMemo(() => {
        return {
            queryParams: hewanKurbanUIContext.queryParams,
            setQueryParams: hewanKurbanUIContext.setQueryParams,
            openEditHewanKurbanPage: hewanKurbanUIContext.openEditHewanKurbanPage,
        };
    }, [hewanKurbanUIContext]);


    // Getting curret state of hewanKurban list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.hewanKurbans }),
        shallowEqual,
    );

    const { totalCount, entities, listLoading } = currentState;

    const dispatch = useDispatch();
    useEffect(() => {

        //Requset ke Branch Action
        dispatch(actions.fetchHewanKurbans(hewanKurbanUIProps.queryParams));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hewanKurbanUIProps.queryParams, dispatch]);

    const allowEdit = RoleChecker('Edit', 'hewan_kurban')

    // Table columns
    const columns = [
        {
            dataField: 'name',
            text: 'Nama',
            // sortCaret: sortCaret,
        },
        {
            dataField: 'thk_period.name',
            text: 'Periode',
            // sortCaret: sortCaret,
        },
        {
            dataField: 'price',
            text: 'Harga',
            formatter: (cell, row) => {
                return currencyFormatter(cell)
            }
            // sortCaret: sortCaret,
        },
        {
            dataField: 'type',
            text: 'Tipe',
            formatter: (cell, row) => {
                switch (cell) {
                    case "17_SAPI":
                        return "1/7 Sapi"
                    case "KAMBING":
                        return "Kambing"
                    case "SAPI":
                        return "Sapi"
                    default:
                        break;
                }
            }
            // sortCaret: sortCaret,
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                openEditHewanKurbanPage: hewanKurbanUIProps.openEditHewanKurbanPage,
                allowEdit
            },
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: '100px',
            },
        },
    ];

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: hewanKurbanUIProps.queryParams.pageSize,
        page: hewanKurbanUIProps.queryParams.pageNumber,
    };

    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                bordered={false}
                                remote
                                keyField="uuid"
                                data={entities === null ? [] : entities}
                                columns={columns}
                                // defaultSorted={uiHelpers.defaultSorted}
                                onTableChange={getHandlerTableChange(
                                    hewanKurbanUIProps.setQueryParams,
                                )}
                                // selectRow={getSelectRow({
                                //   entities,
                                //   ids: productsUIProps.ids,
                                //   setIds: productsUIProps.setIds,
                                // })}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
};

export default HewanKurbanTable;
