export const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '50', value: 30 },
];

export const initialFilter = {
    filter: {
        name_file: '',
        start_date: '',
        end_date: '',
        type: '',
        read_own: true,
    },
    pageNumber: 1,
    pageSize: 10
};

export const badge = (status) => {
    let data = {};
    switch (status) {
        case 'SANDRA_DONATION':
            data = { text: 'SANDRA_DONATION', type: 'info' };
            break;
        case 'SANDRA_DONOR':
            data = { text: 'SANDRA_DONOR', type: 'warning' };
            break;
        default:
            data = { text: status, type: 'primary' };
    }


    return data;
};
