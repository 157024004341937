import { createSlice } from '@reduxjs/toolkit';

const initialCounterRecapState = {
    listLoading: false,
    actionsLoading: false,
    entities: null,
    counterRecapForEdit: undefined,
    lastError: null,
};

export const callTypes = {
    list: 'list',
    action: 'action',
};

export const counterRecapSlice = createSlice({
    name: 'counterRecap',
    initialState: initialCounterRecapState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // getCounterRecapById
        counterRecapFetched: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.counterRecapForEdit = action.payload.counterRecapForEdit;
        },

        // findCounterRecaps
        counterCounterRecapFetched: (state, action) => {
            // const { total_items, items, counterRecap_item_array } = action.payload;
            const { items } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = items;
            // state.counterRecap_item_array = counterRecap_item_array
        },

        // Set to default state actionLoading & error
        counterRecapReload: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
        },

    },
});

