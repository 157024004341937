import React from "react";
import { shallowEqual, useSelector } from "react-redux";

export function FooterCompact({
	today,
	footerClasses,
	footerContainerClasses,
	latestVersion
}) {
	const { userAuth } = useSelector(
		(state) => ({ userAuth: state.auth.user }),
		shallowEqual,
	);
	return (
		<>
			{/* begin::Footer */}
			<div
				className={`footer bg-white py-4 d-flex flex-lg-column  ${footerClasses} d-print-none`}
				id="kt_footer"
			>
				{/* begin::Container */}
				<div
					className={`${footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
				>
					{/* begin::Copyright */}
					<div className="text-dark order-2 order-md-1">
						<span className="text-muted font-weight-bold mr-2">
							{today} &copy;
						</span>
						{` `}
						<span className="text-dark-75">
							Dompet Dhuafa
						</span>
						{` `}
						<span className="ml-5 text-dark-50">
							{latestVersion.version_number}
						</span>
					</div>
					{/* end::Copyright */}
					{` `}
					{/* begin::Nav */}
					{(userAuth.role !== 'MitraEchannel') &&
						<div className="nav nav-dark order-1 order-md-2">
							<a
								href="http://ozip.co.id/"
								rel="noopener noreferrer"
								target="_blank"
								className="text-dark-75 text-hover-primary"
							>
								Crafted with <i className="fa fa-heart text-danger"></i> by
								OZIP
							</a>
						</div>
					}
					{/* end::Nav */}
				</div>
				{/* end::Container */}
			</div>
			{/* end::Footer */}
		</>
	);
}
