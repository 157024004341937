import * as requestFromServer from './hewanKurbanCrud';
import { hewanKurbanSlice, callTypes } from './hewanKurbanSlice';
import { actions as auth } from '../../../Auth/_redux/authRedux'
import { toastOption } from '../../../../../_metronic/_helpers/ToastHelpers'
import { toast } from 'react-toastify'

const { actions } = hewanKurbanSlice;

export const fetchHewanKurbans = (queryParams) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer.findHewanKurban(queryParams)
        .then(response => {
            const { data: { total_items, items } } = response.data;
            dispatch(actions.hewanKurbansFetched({ total_items, items }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchHewanKurban = (id) => dispatch => {
    if (!id) {
        return dispatch(actions.hewanKurbanFetched({ hewanKurbanForEdit: undefined }));
    }

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.getHewanKurbanById(id)
        .then(response => {
            const { data: hewanKurban } = response.data; // alias data -> donatur
            dispatch(actions.hewanKurbanFetched({ hewanKurbanForEdit: hewanKurban }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const createHewanKurban = (hewanKurbanForCreation) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.createHewanKurban(hewanKurbanForCreation)
        .then(response => {
            dispatch(actions.hewanKurbanReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const updateHewanKurban = (hewanKurban) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.updateHewanKurban(hewanKurban)
        .then(response => {
            dispatch(actions.hewanKurbanReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};


export const deleteHewanKurban = (hewanKurban) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.deleteHewanKurban(hewanKurban)
        .then(response => {
            dispatch(actions.hewanKurbanReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};
