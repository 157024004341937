import React, { createContext, useCallback, useContext, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './CollectorUIHelpers';

const CollectorUIContext = createContext();

export function useCollectorUIContext() {
    return useContext(CollectorUIContext);
}

export const CollectorUIConsumer = CollectorUIContext.Consumer;

export function CollectorUIProvider({ collectorUIEvents, children }) {

    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const value = {
        queryParams,
        setQueryParams,
        openDetailPage: collectorUIEvents.openDetailPage,
    };

    return (
        <CollectorUIContext.Provider value={value}>
            {children}
        </CollectorUIContext.Provider>
    );
}
