/* eslint-disable no-restricted-imports */
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";

export function CheckerRequestDialogHeader( { onHide } ) {
	const {  actionsLoading } = useSelector(
		(state) => ({
			actionsLoading: state.channels.actionsLoading,
		}),
		shallowEqual
	);
	return (
		<>
			{actionsLoading && <ModalProgressBar variant="query" />}
			<Modal.Header closeButton>
				<Modal.Title id="modal-sizes-title-lg">Approval Checker</Modal.Title>
			</Modal.Header>
		</>
	)
}