import React from 'react';
import Moment from 'react-moment';

export const DateColumnFormatter = (
    cellContent,
    row,
    rowIndex,
) => (
    <>
        <p><Moment format="DD-MMM-YYYY">{cellContent}</Moment> <Moment format="HH:mm">{cellContent}</Moment></p>
    </>
);
