import React, { useEffect, useMemo } from 'react';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { Pagination } from '../../../../_metronic/_partials/controls';
import BootstrapTable from 'react-bootstrap-table-next';
import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
} from '../../../../_metronic/_helpers';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../_redux/collectorAction';
import * as columnFormatters from './column-formatters';
import { useCollectorUIContext } from '../CollectorUIContext';
import * as uiHelpers from '../CollectorUIHelpers';
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls';

export function CollectorList(props) {

    // Collector UI Context
    const collectorUIContext = useCollectorUIContext();
    const collectorUIProps = useMemo(() => {
        return {
            queryParams: collectorUIContext.queryParams,
            setQueryParams: collectorUIContext.setQueryParams,
            openDetailPage: collectorUIContext.openDetailPage,
        };
    }, [collectorUIContext]);

    // Getting curret state of collector list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.collects }),
        shallowEqual,
    );

    const { entities, totalEdcDonations, totalTunaiDonations, totalPartnerDonations, totalGoodsDonations } = currentState;
    console.log(currentState);

    const dispatch = useDispatch();
    useEffect(() => {
        //Requset ke Collector Action
        dispatch(actions.fetchCollects(collectorUIProps.queryParams));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectorUIProps.queryParams, dispatch]);

    // console.log(currentState);

    // Table columns
    const columns = [
        {
            dataField: 'collect_hid',
            text: 'Collect HID',
        },
        {
            dataField: 'name',
            text: 'Nama FR',
            // formatter: columnFormatters.PriceColumnFormatter,
        },
        {
            dataField: 'counter_name',
            text: 'Konter',
            // formatter: columnFormatters.PriceColumnFormatter,'
        },
        {
            dataField: 'collect_status',
            text: 'Status',
            formatter: columnFormatters.StatusColumnFormatter,
        },
        {
            dataField: 'trx_item',
            text: 'Banyaknya Donasi',
            // formatter: columnFormatters.PriceColumnFormatter,
        },
        {
            dataField: 'donation_cash',
            text: 'Tunai',
            classes: 'text-right',
            headerClasses: 'text-right',
            formatter: columnFormatters.PriceColumnFormatter,
        },
        {
            dataField: 'donation_edc',
            text: 'Edc',
            classes: 'text-right',
            headerClasses: 'text-right',
            formatter: columnFormatters.PriceColumnFormatter,
        },
        {
            dataField: 'donation_partner',
            text: 'Mitra Konter',
            classes: 'text-right',
            headerClasses: 'text-right',
            formatter: columnFormatters.PriceColumnFormatter,
        },
        {
            dataField: 'donation_goods',
            text: 'Barang',
            classes: 'text-right',
            headerClasses: 'text-right',
            formatter: columnFormatters.PriceColumnFormatter,
        },
        {
            dataField: 'trx_total',
            text: 'Jumlah Donasi',
            classes: 'text-right',
            headerClasses: 'text-right',
            formatter: columnFormatters.PriceColumnFormatter,
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                openDetailPage: collectorUIProps.openDetailPage,

            },
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: '100px',
            },
        },
    ];

    // Table pagination properties
    // const paginationOptions = {
    //     custom: true,
    //     totalSize: totalCount,
    //     sizePerPageList: uiHelpers.sizePerPageList,
    //     sizePerPage: collectorUIProps.queryParams.pageSize,
    //     page: collectorUIProps.queryParams.pageNumber,
    // };


    // s2-135 request Sandra 2 Mobile : Collector Approve : Perlu total rekap sesuai jenis pembayaran
    return (
        <>
            <Card>
                <CardHeader title="Tabel Donasi Tunai"></CardHeader>
                <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField="collect_batch_uuid"
                    data={entities === null ? [] : entities}
                    columns={columns}
                >
                    <PleaseWaitMessage entities={entities} />
                    <NoRecordsFoundMessage entities={entities} />
                </BootstrapTable>
                <hr></hr>
                <h5 className="text-right">Total Donasi Tunai {columnFormatters.PriceColumnFormatter(totalTunaiDonations)}</h5>
                <hr></hr>
                <h5 className="text-right">Total Donasi EDC {columnFormatters.PriceColumnFormatter(totalEdcDonations)}</h5>
                <hr></hr>
                <h5 className="text-right">Total Donasi Mitra Konter {columnFormatters.PriceColumnFormatter(totalPartnerDonations)}</h5>
                <hr></hr>
                <h5 className="text-right">Total Donasi Barang {columnFormatters.PriceColumnFormatter(totalGoodsDonations)}</h5>
            </Card>
            {/* <Card>
                <CardHeader title="Tabel Donasi EDC"></CardHeader>
                <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField="collect_batch_uuid"
                    data={edcItems === null ? [] : edcItems}
                    columns={columns}
                >
                    <PleaseWaitMessage entities={edcItems} />
                    <NoRecordsFoundMessage entities={edcItems} />
                </BootstrapTable>
                <hr></hr>
                <h5 className="text-right">Total Donasi EDC {columnFormatters.PriceColumnFormatter(totalEdcDonations)}</h5>
            </Card> */}
            {/* <Card>
                <CardHeader title="Tabel Donasi Tunai + EDC"></CardHeader>
                <BootstrapTable
                    wrapperClasses="table-responsive"
                    classes="table table-head-custom table-vertical-center overflow-hidden"
                    bootstrap4
                    bordered={false}
                    remote
                    keyField="collect_batch_uuid"
                    data={tunaiEdcItems === null ? [] : tunaiEdcItems}
                    columns={columns}
                >
                    <PleaseWaitMessage entities={tunaiEdcItems} />
                    <NoRecordsFoundMessage entities={tunaiEdcItems} />
                </BootstrapTable>
                <hr></hr>
                <h5 className="text-right">Total Donasi Tunai + EDC {columnFormatters.PriceColumnFormatter(totalTunaiEdcDonations)}</h5>
            </Card> */}
        </>
        // <>
        //     <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        //         {({ paginationProps, paginationTableProps }) => {
        //             return (
        //                 <Pagination
        //                     isLoading={listLoading}
        //                     paginationProps={paginationProps}
        //                 >
        // <BootstrapTable
        //     wrapperClasses="table-responsive"
        //     classes="table table-head-custom table-vertical-center overflow-hidden"
        //     bootstrap4
        //     bordered={false}
        //     remote
        //     keyField="collect_batch_uuid"
        //     data={tunaiItems === null ? [] : tunaiItems}
        //     columns={columns}
        // onTableChange={
        //     getHandlerTableChange(collectorUIProps.setQueryParams)
        // }
        // {...paginationTableProps}
        // >
        //     <PleaseWaitMessage entities={tunaiItems} />
        //     <NoRecordsFoundMessage entities={tunaiItems} />
        // </BootstrapTable>
        //                 </Pagination>
        //             );
        //         }}

        //     </PaginationProvider>
        // </>
    );
}

