import { createSlice } from "@reduxjs/toolkit";

const initialCollectRecapState = {
  listLoading: false,
  actionsLoading: false,
  entities: null,
  total_items: 0,
  collectRecapForEdit: {
    count: 0,
    rows: [],
  },
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const collectRecapSlice = createSlice({
  name: "collectRecap",
  initialState: initialCollectRecapState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getCollectRecapById
    collectRecapFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.collectRecapForEdit = action.payload.collectRecapForEdit;
    },

    // findCollectRecaps
    collectCollectRecapFetched: (state, action) => {
      // const { total_items, items, collectRecap_item_array } = action.payload;
      const { items, total_items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.total_items = total_items;
      // state.collectRecap_item_array = collectRecap_item_array
    },

    // Set to default state actionLoading & error
    collectRecapReload: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
  },
});
