import axios from "axios";
import { DonorURL } from "../../../config/UrlEndPoint";

export const URL = `${DonorURL}donor_import`;

export function findDonorImports(queryParams) {
  return axios.get(`${URL}/`, {
    params: { ...queryParams },
  });
}

export function getDonorImportById(donorImportId) {
  return axios.get(`${URL}/${donorImportId}`);
}

export function createImport() {
  return axios.post(`${URL}/create_import`);
}

export function createDonorImport(donorImport) {
  return axios.post(URL, donorImport);
}

export function validateDonatur(importData) {
  return axios.post(`${URL}/validation`, importData);
}
