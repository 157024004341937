import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash";

const AolBigQueryUIContext = createContext();

export function useAolBigQueryUIContext() {
  return useContext(AolBigQueryUIContext);
}

export const AolBigQueryUIConsumer = AolBigQueryUIContext.Consumer;

export function AolBigQueryUIProvider({ aolBigQueryUIEvents, children }) {
  const value = {};

  return (
    <AolBigQueryUIContext.Provider value={value}>
      {children}
    </AolBigQueryUIContext.Provider>
  );
}
