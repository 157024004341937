import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

export default function DonorInvalidTable({ errorRows, setOpenEditDonor }) {
  // data import old
  // const columns = [
  //   {
  //     text: "No",
  //     formatter: (_, __, indexRow) => {
  //       return <span>{indexRow + 1}</span>;
  //     },
  //   },
  //   {
  //     dataField: "data.type",
  //     text: "Tipe Donatur",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.name",
  //     text: "Nama",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.greeting",
  //     text: "Sapaan",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.hp",
  //     text: "HP",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.email",
  //     text: "Email",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.address",
  //     text: "Alamat",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.province",
  //     text: "Provinsi",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.city",
  //     text: "Kota",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.district",
  //     text: "Kecamatan",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.postal_code",
  //     text: "Kode Pos",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.nik",
  //     text: "NIK",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.npwp",
  //     text: "NPWP",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.npwz",
  //     text: "NPWZ",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.is_alive",
  //     text: "Status Hidup",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.is_active",
  //     text: "Status Aktif",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.kode_rekening",
  //     text: "Kode Rekening",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.tanggal_lahir",
  //     text: "Tanggal Lahir",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.jenis_kelamin",
  //     text: "Jenis Kelamin",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.pekerjaan",
  //     text: "Pekerjaan",
  //     style: { cursor: "pointer" },
  //   },
  //   {
  //     dataField: "data.pendidikan",
  //     text: "Pendidikan Terakhir",
  //     style: { cursor: "pointer" },
  //   },
  // ];

  // data import new
  const columns = [
    {
      text: "No",
      formatter: (_, __, indexRow) => {
        return (
          <>
            <span>{indexRow + 1}</span>
          </>
        );
      },
    },
    {
      dataField: "data.type",
      text: "Tipe Donatur",
    },
    {
      dataField: "data.name",
      text: "Nama Donatur",
    },
    {
      dataField: "data.greeting",
      text: "Sapaan",
    },
    {
      dataField: "data.hp",
      text: "HP",
    },
    {
      dataField: "data.email",
      text: "Email",
    },
    {
      dataField: "data.address",
      text: "Alamat",
    },
    {
      dataField: "data.npwp",
      text: "NPWP",
    },
    {
      dataField: "data.tanggal_lahir",
      text: "Tanggal Lahir",
    },
    {
      dataField: "data.jenis_kelamin",
      text: "Jenis Kelamin",
    },
    {
      dataField: "data.kat_donatur",
      text: "Kategori Donatur",
    },
    {
      dataField: "data.kat_prospek",
      text: "Kategori Prospek",
    },
    {
      dataField: "data.sumber_data",
      text: "Sumber Data Donatur",
    },
  ];

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      setOpenEditDonor((prev) => ({
        ...prev,
        show: true,
        initValues: row,
      }));
    },
  };

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        rowEvents={tableRowEvents}
        bootstrap4
        bordered={true}
        remote
        keyField="uuid"
        data={errorRows}
        columns={columns}
      />
    </>
  );
}
