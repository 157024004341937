import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
  Input,
} from "../../../../../../../_metronic/_partials/controls";
import {
  THK_IMPORT_FIELDS,
  readSpreadsheet,
  sizePerPageList,
} from "../../PartnershipUIHelpers";
import { Field, Formik } from "formik";
import CurrencyInput from "react-currency-input-field";
import AsyncSelect from "react-select/async";
import { genOptionLoader } from "../../../../../../../_metronic/_utils/option-loaders";
import { promiseTuplify } from "../../../../../../../_metronic/_utils/promise-utils";
import { findHewanKurbanCampaign } from "../../../../../Donation/_redux/hewan-kurban-campaign/hewanKurbanCampaignCrud";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { v4 as uuidv4 } from "uuid";
import ImportPreviewList from "./ImportPreviewList";
import { getSelectorStyles } from "../../../../../../../_metronic/_helpers/style-helpers";
import * as yup from "yup";
import {
  createDonationPartnership,
  createPartnershipBatch,
  validationImportData,
} from "../../../_redux/partnershipCrud";
import Loading from "../../../../../../../_metronic/_assets/js/components/loading";
import ErrorPreviewList from "./ErrorPreviewList";
import EditModal from "./EditModal";
import { ActionButton } from "../../../../../../../_metronic/_partials/controls/ActionButton";
import { toast } from "react-toastify";
import {
  currencyFormatter,
  toastOption,
} from "../../../../../../../_metronic/_helpers";
import moment from "moment";

const initialValues = {
  thk_livestock_campaign: {},
};

const ValidationSchema = yup.object().shape({
  thk_livestock_campaign: yup.string().required("Jenis Halaman wajb diisi"),
});

export default function ThkImportForm({
  match: {
    params: { id },
  },
  history,
}) {
  const [options, setOptions] = useState({
    livestockCampaign: [],
  });

  const [spreadSheet, setSpreadSheet] = useState(null);
  const [excelRows, setExcelRows] = useState([]);

  const [errorRows, setErrorRows] = useState([]);

  const [isValidation, setIsValidation] = useState(true);

  const [loading, setLoading] = useState({
    show: false,
    percentage: 0,
  });

  const [openEditDonation, setOpenEditDonation] = useState({
    show: false,
    initValues: {},
  });

  useEffect(() => {
    fetchPartnershipCampaign();
  }, [id]);

  // * Find Partnership Campaign
  async function fetchPartnershipCampaign() {
    const [res, error] = await promiseTuplify(
      findHewanKurbanCampaign({
        pageNumber: 1,
        pageSize: 20,
        filter: {
          thk_partnership_uuid: id,
        },
      })
    );

    if (error) {
      return;
    }

    if (res) {
      const { data: { data: { items = [] } } = {} } = res;

      const options = items.map(({ uuid, name, price }) => ({
        label: `${name} - ${currencyFormatter(price)}`,
        value: uuid,
        price,
      }));

      setOptions((prev) => ({
        ...prev,
        livestockCampaign: options,
      }));
    }
  }

  function handleBack() {
    history.goBack();
  }

  const onFileSelected = (event, setFieldValue) => {
    setFieldValue("file", event.target.files[0]);
    setSpreadSheet(event.target.files[0]);

    // Additional logic should be performed here

    // When we are done, clear it
    event.target.value = "";
  };

  // * Create Template Excel
  const createTemplate = async () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = "SANDRA_2";
    workbook.created = new Date();
    workbook.addWorksheet("import_donasi_kurban");

    const worksheet = workbook.getWorksheet("import_donasi_kurban");
    worksheet.columns = [
      { header: "No", key: "no", width: 5 },
      { header: "ID Transaksi", width: 20, key: "channel_trx_id" },
      {
        header: "Tanggal Transaksi",
        width: 40,
        key: "trx_date",
        style: { numFmt: "dd/mm/yyyy" },
      },
      { header: "Nama Donatur", width: 40, key: "donor" },
      { header: "Negara", width: 40, key: "country" },
      { header: "Provinsi", width: 40, key: "province" },
      { header: "Kota", width: 40, key: "city" },
      { header: "Kecamatan", width: 40, key: "district" },
      { header: "Alamat", width: 60, key: "address" },
      { header: "Jumlah", width: 40, key: "amount" },
      { header: "HP", width: 40, key: "hp" },
      { header: "Email", width: 40, key: "email" },
      { header: "Atas Nama 1", width: 20, key: "name_1" },
      { header: "Atas Nama 2", width: 20, key: "name_2" },
      { header: "Atas Nama 3", width: 20, key: "name_3" },
      { header: "Atas Nama 4", width: 20, key: "name_4" },
      { header: "Atas Nama 5", width: 20, key: "name_5" },
      { header: "Atas Nama 6", width: 20, key: "name_6" },
      { header: "Atas Nama 7", width: 20, key: "name_7" },
    ];

    const currencyFormat = {
      numFmt: '"Rp"#,##0.00;[Red]-"Rp"#,##0.00',
    };

    // * Validasi Format Tanggal
    worksheet.dataValidations.add("C2:C1001", {
      type: "date",
      operator: "greaterThan",
      showErrorMessage: true,
      formulae: [new Date(1950, 0, 1)],
      allowBlank: false,
      errorStyle: "error",
      error: "Harap input format tanggal DD/MM/YYYY",
    });

    // * Validasi Format Tanggal
    worksheet.dataValidations.add("J2:J1001", {
      type: "whole",
      operator: "between",
      formula1: "0",
      formula2: "999999999",
      showErrorMessage: true,
      errorTitle: "Invalid Value",
      error: "Please enter a valid currency value in IDR format.",
    });

    worksheet.columns.forEach(function(column, i) {
      var maxLength = 0;
      column["eachCell"]({ includeEmpty: true }, function(cell) {
        var columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength;
    });

    workbook.xlsx.writeBuffer().then(function(buffer) {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `template_import_donasi_kurban.xlsx`
      );
    });
  };

  // * Read Excel File
  useEffect(() => {
    if (spreadSheet) {
      parseDonorRows(spreadSheet);
    }
  }, [spreadSheet]);

  async function parseDonorRows() {
    const result = await readSpreadsheet(spreadSheet);

    if (!(result instanceof Array)) {
      return [];
    }

    const rows = result.map((row) => {
      const item = {
        uuid: uuidv4(),
      };

      Object.entries(THK_IMPORT_FIELDS).forEach(([label, field]) => {
        item[field] = row[label];

        // ? Format HP to string
        if (label === "HP" && row[label]) {
          item[field] = row[label].toString();
        }

        // ? Format trx_date
        if (label === "Tanggal Transaksi" && row[label]) {
          item[field] = moment(
            Math.floor(row[label] - 25569) * 86400 * 1000
          ).format("YYYY-MM-DD"); // to convert date from excel like 44239
        }
      });

      return item;
    });

    setExcelRows(rows);
    validateData(rows);
  }

  // * Validation Data
  async function validateData(importData) {
    setLoading((prev) => ({
      ...prev,
      show: true,
      percentage: 0,
    }));
    const tempData = [...importData];
    const percentage = (tempData.length * 100) / tempData.length;

    let currentPercentage = 0;
    const successProcessData = [];

    const hasErrorData = [];

    for (const item of importData) {
      // if (item.hp && item.hp.substring(0, 1) == "8") {
      //   item.hp = `0${item.hp}`;
      // }
      const { data } = await validationImportData(item);

      if (data.error.length !== 0) {
        hasErrorData.push(data);
        setExcelRows((prev) => {
          const temp = [...prev];
          const filteredNotError = temp.filter(
            (oldData) => oldData.uuid !== data.data.uuid
          );
          return filteredNotError;
        });
        // ? Set Error / Invalid Data
        setErrorRows((prev) => {
          const temp = [...prev];
          temp.push(data);
          return temp;
        });
      }
      // ? Loading State
      successProcessData.push(data);
      currentPercentage = Math.floor(
        (successProcessData.length * 100) / tempData.length
      );
      setLoading((prev) => ({
        ...prev,
        percentage: currentPercentage,
      }));
    }

    if (hasErrorData.length === 0) setIsValidation(false);

    // ? Hide Loading
    if (percentage === currentPercentage) {
      setLoading((prev) => ({
        ...prev,
        show: false,
        percentage: 0,
      }));
    }
  }

  // * Handle update data error
  const handleSaveEditDonor = (values) => {
    const currentItem = errorRows.find(
      (errItem) => errItem.data.uuid === values.uuid
    );
    setExcelRows((prev) => {
      const temp = [...prev];
      temp.push(values);
      return temp;
    });
    setErrorRows((prev) => {
      const temp = [...prev];
      const newArray = temp.filter(
        (item) => item.data.uuid !== currentItem?.data.uuid
      );
      return newArray;
    });
    handleHideEditModal();
  };

  function handleHideEditModal() {
    setOpenEditDonation({
      show: false,
      initValues: {},
    });
  }

  async function createBatch(params) {
    const createBatchData = { ...params, thk_partnership_uuid: id };
    const { data: partnershipBatch } = await createPartnershipBatch(
      createBatchData
    );
    const donationData = {
      thk_livestock_campaign: params.thk_livestock_campaign,
      thk_partnership_batch_uuid: partnershipBatch.data.uuid,
      thk_partnership_uuid: id,
    };

    await saveDonationImport(donationData, excelRows);
  }

  // * create donation import
  async function saveDonationImport(params, dataImport) {
    setLoading((prev) => ({
      ...prev,
      show: true,
      percentage: 0,
    }));

    const tempData = [...dataImport];
    const percentage = (tempData.length * 100) / tempData.length;

    let currentPercentage = 0;
    const successProcessData = [];

    for (const item of dataImport) {
      const data = { ...item, ...params };
      createDonationPartnership(data).then((response) => {
        // ? Loading State
        successProcessData.push(response);
        currentPercentage = Math.floor(
          (successProcessData.length * 100) / tempData.length
        );

        setLoading((prev) => ({
          ...prev,
          percentage: currentPercentage,
        }));
        // ? Hide Loading
        if (percentage === currentPercentage) {
          setLoading((prev) => ({
            ...prev,
            show: false,
            percentage: 0,
          }));
          toast.success("Created Successfully", toastOption);
          handleBack();
        }
      });
    }
  }

  function removeErrorData(data) {
    setErrorRows((prev) => {
      const temp = [...prev];
      const newArr = temp.filter((item) => item.data.uuid !== data.data.uuid);
      return newArr;
    });
  }

  return (
    <>
      <Loading {...loading} />
      <EditModal
        {...openEditDonation}
        onHide={handleHideEditModal}
        onSave={handleSaveEditDonor}
      />
      <Formik
        validationSchema={ValidationSchema}
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => createBatch(values)}
      >
        {({ values, setFieldValue, handleSubmit, touched, errors }) => (
          <>
            <Card>
              <CardHeader title="Import Donasi Kurban">
                <CardHeaderToolbar>
                  <button
                    type="button"
                    className="btn btn-outline-info mx-1"
                    onClick={createTemplate}
                  >
                    Download Template
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary mx-1"
                    onClick={handleBack}
                  >
                    Kembali
                  </button>
                </CardHeaderToolbar>
              </CardHeader>
              <CardBody>
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label>Hewan Campaign</label>
                    <AsyncSelect
                      name="thk_livestock_campaign"
                      defaultOptions={options.livestockCampaign}
                      placeholder={"Pilih hewan campaign"}
                      loadOptions={genOptionLoader(
                        (inputValue) =>
                          findHewanKurbanCampaign({
                            pageNumber: 1,
                            pageSize: 20,
                            filter: {
                              name: inputValue,
                              thk_partnership_uuid: id,
                            },
                          }),
                        "items",
                        ({ name, price, uuid: value }) => {
                          return {
                            label: `${name} - ${currencyFormatter(price)}`,
                            value,
                            price,
                          };
                        }
                      )}
                      value={
                        values.thk_livestock_campaign &&
                        values.thk_livestock_campaign.uuid
                          ? {
                              label: values.thk_livestock_campaign.name,
                              value: values.thk_livestock_campaign.uuid,
                            }
                          : null
                      }
                      onChange={({ label: name, value: uuid, price }) => {
                        setFieldValue("thk_livestock_campaign", {
                          uuid,
                          name,
                          price,
                        });
                        setErrorRows([]);
                        setExcelRows([]);
                        setSpreadSheet(null);
                        setFieldValue("file", null);
                        setIsValidation(true);
                      }}
                      styles={getSelectorStyles(
                        "thk_livestock_campaign",
                        errors,
                        touched
                      )}
                    />
                  </div>
                  {values.thk_livestock_campaign.uuid && (
                    <div className="col-lg-6">
                      <label>File Import (.xlsx)</label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="file"
                          onChange={(e) => {
                            onFileSelected(e, setFieldValue);
                          }}
                          accept=".xlsx, .xlsm, .xls, .xlr"
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="image"
                          aria-describedby="inputGroupFileAddon02"
                        >
                          {values.file instanceof File
                            ? values.file.name
                            : "Pilih File Import"}
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
            {errorRows.length !== 0 && (
              <Card>
                <CardHeader title="Data tidak valid" />
                <CardBody>
                  <div className="form-group">
                    <ErrorPreviewList
                      data={errorRows}
                      setOpenEditDonation={setOpenEditDonation}
                      removeErrorData={removeErrorData}
                    />
                  </div>
                </CardBody>
              </Card>
            )}
            <Card>
              <CardHeader title="Data valid" />
              <CardBody>
                <div className="form-group">
                  <ImportPreviewList
                    data={excelRows}
                    thkCampaign={values.thk_livestock_campaign}
                  />
                </div>
              </CardBody>
              <CardFooter>
                <div className="float-right">
                  {isValidation ? (
                    <>
                      {errorRows.length === 0 && (
                        <ActionButton
                          variant="warning"
                          className="mx-1"
                          onClick={() => validateData(excelRows)}
                          // loading={actionsLoading}
                          text="Validasi"
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <ActionButton
                        className="mx-1"
                        onClick={() => handleSubmit()}
                        // loading={actionsLoading}
                        text="Simpan"
                      />
                    </>
                  )}
                </div>
              </CardFooter>
            </Card>
          </>
        )}
      </Formik>
    </>
  );
}
