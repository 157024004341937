import * as requestFromServer from "./donor-import-api";
import { donorImportSlice, callTypes } from "./donor-import-slice";
import { actions as auth } from "../../Auth/_redux/authRedux";
import { toastOption } from "../../../../_metronic/_helpers/ToastHelpers";
import { toast } from "react-toastify";

const { actions } = donorImportSlice;

export const fetchDonorImports = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDonorImports(queryParams)
    .then((response) => {
      const {
        data: { total_items, items },
      } = response.data;
      dispatch(actions.donorImportsFetched({ total_items, items }));
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDonorImport = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.donorImportFetched({ donorImportForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDonorImportById(id)
    .then((response) => {
      const { data: donorImport } = response.data; // alias data -> donorImport
      dispatch(actions.donorImportFetched({ donorImportForEdit: donorImport }));
      return donorImport;
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createDonorImport = (donorImportData) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createDonorImport(donorImportData)
    .then((response) => {
      dispatch(actions.donorImportsReload);
      return response.data;
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const validateDonatur = (donorImportData) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .validateDonatur(donorImportData)
    .then((response) => {
      dispatch(actions.donorImportsReload);
      return response.data;
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createImport = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createImport()
    .then((response) => {
      dispatch(actions.donorImportsReload);
      return response.data;
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
