import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { useThkChannelReportUIContext } from "../ThkChannelReportUIContext";
import { ThkChannelReportEditForm } from "./ThkChannelReportEditForm";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/thkChannelReportAction";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { promiseTuplify } from "../../../../../../_metronic/_utils/promise-utils";
import { findAllThkPeriods } from "../../../../Donation/thk-period/thk-period-api";
import Loading from "../../../../../../_metronic/_assets/js/components/loading";
import { findDonaturs } from "../../../../Donatur/_redux/donatur/donaturCrud";
import { FILE_TYPE, FILE_TYPE_LABEL } from "../ThkChannelReportUIHelpers";
import { findChannels } from "../../../../MasterData/_redux/channels/channelsCrud";

export const ThkChannelReportEditDialog = ({
  match: {
    params: { id },
  },
  history,
}) => {
  const [options, setOptions] = useState({
    period: [],
  });
  // ThkChannelReport Redux state
  const dispatch = useDispatch();
  const { actionsLoading, thkChannelReportForEdit, error } = useSelector(
    (state) => ({
      actionsLoading: state.thkChannelReport.actionsLoading,
      thkChannelReportForEdit: state.thkChannelReport.thkChannelReportForEdit,
      error: state.thkChannelReport.error,
    }),
    shallowEqual
  );

  async function fetchThkPeriods() {
    const [res, error] = await promiseTuplify(findAllThkPeriods());

    if (error) {
      return;
    }

    if (res) {
      const { data: { data = [] } = {} } = res;

      const options = data.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        period: options,
      }));
    }
  }

  async function fetchChannel() {
    const [res, error] = await promiseTuplify(
      findChannels({
        pageNumber: 1,
        pageSize: 10,
        filter: {},
      })
    );

    if (error) {
      return;
    }

    if (res) {
      const { data: { data = [] } = {} } = res;

      const options = data.items.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        channel: options,
      }));
    }
  }

  useEffect(() => {
    // server call for getting ThkChannelReport by id
    dispatch(actions.fetchThkChannelReport(id));

    fetchThkPeriods();
    fetchChannel();
  }, [id, dispatch]);

  const saveThkChannelReport = (payload) => {
    const {
      uuid,
      channel,
      thk_period,
      report_template_type,
      report_file,
      kambing_file,
      sapi_file,
    } = payload;
    const formData = new FormData();
    if (report_template_type == "1") {
      formData.append("file", report_file);
    } else {
      formData.append("kambing_bg", kambing_file);
      formData.append("sapi_bg", sapi_file);
    }
    formData.append("channel_uuid", channel.uuid);
    formData.append("thk_period_uuid", thk_period.uuid);
    formData.append("report_template_type", report_template_type);

    if (uuid) {
      dispatch(actions.updateThkChannelReport(formData, uuid)).then(
        (response) => {
          if (response.message) {
            toast.success(response.message, toastOption);
            dispatch(actions.reloadThkChannelReportState());
            handleBack();
          } else {
            toast.error(response, toastOption);
            dispatch(actions.reloadThkChannelReportState());
          }
        }
      );
    } else {
      dispatch(actions.createThkChannelReport(formData)).then((response) => {
        if (response.message) {
          toast.success(response.message, toastOption);
          dispatch(actions.reloadThkChannelReportState());
          handleBack();
        } else {
          toast.error(response, toastOption);
          dispatch(actions.reloadThkChannelReportState());
        }
      });
    }
  };

  function handleBack() {
    history.goBack();
  }

  function getInitialValues() {
    const initValue = {
      uuid: thkChannelReportForEdit?.uuid,
      thk_period: {
        name: thkChannelReportForEdit?.thk_period.name,
        uuid: thkChannelReportForEdit?.thk_period.uuid,
      },
      channel: {
        name: thkChannelReportForEdit?.channel.name,
        uuid: thkChannelReportForEdit?.channel.uuid,
      },
      report_file: thkChannelReportForEdit
        ? thkChannelReportForEdit.report_file
        : {},
      kambing_file: thkChannelReportForEdit
        ? thkChannelReportForEdit.kambing_file
        : {},
      sapi_file: thkChannelReportForEdit
        ? thkChannelReportForEdit.sapi_file
        : {},
      report_template_type: thkChannelReportForEdit
        ? thkChannelReportForEdit.report_template_type
        : "1",
    };

    return initValue;
  }

  return (
    <>
      <Loading show={actionsLoading} />
      <Card>
        <CardHeader title={`${id ? "Edit" : "Tambah"} Channel Laporan`}>
          <CardHeaderToolbar>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleBack}
            >
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ThkChannelReportEditForm
            saveThkChannelReport={saveThkChannelReport}
            initialValues={getInitialValues()}
            options={options}
          />
        </CardBody>
      </Card>
    </>
  );
};
