import axios from "axios";
import { MasterDataURL } from "../../../../config/UrlEndPoint";

export const URL = `${MasterDataURL}donor_source`;

export function getDonorSourceAll() {
  return axios.get(URL);
}

export function findDonorSources(queryParams) {
  return axios.get(`${URL}/`, { params: queryParams });
}

export function getDonorSourceById(donorSourceId) {
  return axios.get(`${URL}/${donorSourceId}`);
}

export function createDonorSource(donorSource) {
  return axios.post(URL, donorSource);
}

export function updateDonorSource(donor_source_uuid, donorSource) {
  return axios.put(`${URL}/${donor_source_uuid}`, donorSource);
}

export function fetchDeleteDonorSource(donor_source_uuid) {
  return axios.delete(`${URL}/${donor_source_uuid}`);
}
