import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input, Select } from "../../../../../../_metronic/_partials/controls";
import AsyncSelect from "react-select/async";
import ReactSelect from "react-select";
import { findDonaturs } from "../../../../Donatur/_redux/donatur/donaturCrud";
import { genOptionLoader } from "../../../../../../_metronic/_utils/option-loaders";
import { Form as BootsrapForm } from "react-bootstrap";
import PreviewImage from "../../../../../../_metronic/_assets/js/components/preview-image";
import { getSelectorStyles } from "../../../../../../_metronic/_helpers/style-helpers";
import { findChannels } from "../../../../MasterData/_redux/channels/channelsCrud";

export function ThkChannelReportEditForm({
  saveThkChannelReport,
  actionsLoading,
  options = {},
  initialValues,
}) {
  const [imageModal, setImageModal] = useState({
    show: false,
    img: null,
  });

  function getImageUrl(image = {}) {
    if (!image) return;
    if (image instanceof File) {
      return URL.createObjectURL(image);
    }

    const { adapter, path } = image;

    if (adapter && path) {
      return adapter + path;
    }

    return null;
  }

  function handleClickImg(img) {
    setImageModal((prev) => ({
      ...prev,
      show: true,
      img,
    }));
  }

  function handleCloseImg() {
    setImageModal({
      show: false,
      img: null,
    });
  }

  const imgTemplateStyle = {
    width: "100%",
    height: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const imgFileStyle = {
    width: "100%",
    height: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <>
      <PreviewImage
        show={imageModal.show}
        images={imageModal.img}
        onHide={handleCloseImg}
        title={"Preview Template Laporan Channel"}
      />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => saveThkChannelReport(values)}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          handleBlur,
          errors,
          touched,
        }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Contoh Template</label>
                </div>
                <div className="col-lg-4 mb-5">
                  <div className="text-center">
                    <label>Tipe 1</label>
                  </div>
                  <div style={imgTemplateStyle}>
                    <img
                      src="/media/partnership-template/type1.png"
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleClickImg("/media/partnership-template/type1.png")
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-4 mb-5">
                  <div className="text-center">
                    <label>Tipe 2</label>
                  </div>
                  <div style={imgTemplateStyle}>
                    <img
                      src="/media/partnership-template/type2.png"
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleClickImg("/media/partnership-template/type2.png")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Periode</label>
                </div>
                <div className="col-lg-9 mb-5">
                  <ReactSelect
                    name="thk_period"
                    placeholder={"Periode Thk"}
                    options={options.period}
                    isDisabled={values.uuid}
                    value={
                      values.thk_period && values.thk_period.uuid
                        ? {
                            label: values.thk_period.name ?? "",
                            value: values.thk_period.uuid ?? "",
                          }
                        : null
                    }
                    onChange={({ label: name, value: uuid }) => {
                      setFieldValue("thk_period", {
                        uuid,
                        name,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Channel</label>
                </div>
                <div className="col-lg-9 mb-5">
                  <AsyncSelect
                    name="channel"
                    defaultOptions={options.channel}
                    onBlur={handleBlur}
                    placeholder="Pilih channel"
                    isDisabled={values.uuid}
                    loadOptions={genOptionLoader(
                      (inputValue) =>
                        findChannels({
                          filter: { name: inputValue },
                          pageNumber: 1,
                          pageSize: 50,
                        }),
                      "items",
                      ({ name: label, uuid }) => {
                        return {
                          label,
                          value: uuid,
                        };
                      }
                    )}
                    value={
                      values.channel && values.channel.uuid
                        ? {
                            label: values.channel.name,
                            value: values.channel.uuid,
                          }
                        : null
                    }
                    onChange={({ label: name, value: uuid }) => {
                      setFieldValue("channel", { uuid, name });
                    }}
                    styles={getSelectorStyles("channel", errors, touched)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Tipe Template</label>
                </div>
                <div className="col-lg-9 mb-5">
                  <Select
                    name="report_template_type"
                    value={values.report_template_type}
                    className="form-control"
                  >
                    <option value={"1"}>1</option>
                    <option value={"2"}>2</option>
                  </Select>
                </div>
              </div>
              {values.report_template_type == "1" && (
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="report_file">File</label>
                  </div>
                  <div className="col-md-9 mb-4 md-mb-0">
                    <div className="input-group mb-3">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="report_file"
                          onChange={(e) => {
                            setFieldValue("report_file", e.target.files[0]);
                          }}
                          accept="image/*"
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="image"
                          aria-describedby="inputGroupFileAddon02"
                          style={{ zIndex: 0 }}
                        >
                          Pilih Gambar
                        </label>
                      </div>
                    </div>
                    {getImageUrl(values.report_file) && (
                      <div className="mt-5" style={imgFileStyle}>
                        <img
                          src={getImageUrl(values.report_file)}
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                          }}
                          alt="report file"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {values.report_template_type == "2" && (
                <>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="kambing_file">Template Kambing</label>
                    </div>
                    <div className="col-md-9 mb-4 md-mb-0">
                      <div className="input-group mb-3">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="kambing_file"
                            onChange={(e) => {
                              setFieldValue("kambing_file", e.target.files[0]);
                            }}
                            accept="image/*"
                            style={{ zIndex: 0 }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="kambing_file"
                            aria-describedby="inputGroupFileAddon02"
                          >
                            Pilih Gambar
                          </label>
                        </div>
                      </div>
                      {getImageUrl(values.kambing_file) && (
                        <div className="mt-5" style={imgFileStyle}>
                          <img
                            src={getImageUrl(values.kambing_file)}
                            style={{
                              objectFit: "contain",
                              width: "100%",
                              height: "100%",
                            }}
                            alt="kambing file"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="sapi_file">Template Sapi</label>
                    </div>
                    <div className="col-md-9 mb-4 md-mb-0">
                      <div className="input-group mb-3">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="sapi_file"
                            onChange={(e) => {
                              setFieldValue("sapi_file", e.target.files[0]);
                            }}
                            accept="image/*"
                            style={{ zIndex: 0 }}
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="sapi_file"
                            aria-describedby="inputGroupFileAddon02"
                          >
                            Pilih Gambar
                          </label>
                        </div>
                      </div>
                      {getImageUrl(values.sapi_file) && (
                        <div className="mt-5" style={imgFileStyle}>
                          <img
                            src={getImageUrl(values.sapi_file)}
                            style={{
                              objectFit: "contain",
                              width: "100%",
                              height: "100%",
                            }}
                            alt="sapi file"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Form>
            <div className="float-right">
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
