import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./PartnershipUIHelpers";

const PartnershipUIContext = createContext();

export function usePartnershipUIContext() {
  return useContext(PartnershipUIContext);
}

export const PartnershipUIConsumer = PartnershipUIContext.Consumer;

export function PartnershipUIProvider({ partnershipUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initPartnership = {
    name: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initPartnership,
    newPartnershipButtonClick: partnershipUIEvents.newPartnershipButtonClick,
    openEditPartnershipPage: partnershipUIEvents.openEditPartnershipPage,
    openManagePartnershipPage: partnershipUIEvents.openManagePartnershipPage,
    EditPartnershipReportTemplatePage: partnershipUIEvents.EditPartnershipReportTemplatePage,
    EditPartnershipCertificateTemplatePage: partnershipUIEvents.EditPartnershipCertificateTemplatePage,
  };

  return (
    <PartnershipUIContext.Provider value={value}>
      {children}
    </PartnershipUIContext.Provider>
  );
}
