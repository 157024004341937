import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
    Input,
    Select
} from '../../../../../../../_metronic/_partials/controls';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../../../_metronic/_partials/controls';
import { RoleChecker, RoleCheckerNoRedux } from '../../../../../../RoleChecker';
import { copyValue } from "../../../../../../../_metronic/_helpers/UtilityHelpers";
import { categoryHewan, tipeHewan } from '../HewanKurbanUIHelpers';
import CurrencyInput from 'react-currency-input-field';




// Validation schema
const HewanKurbanEditSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Nama Hewan Kurban Wajib Diisi'),
    price: Yup.number().min(1, "Harga Tidak Boleh Kurang Dari Sama Dengan 0").required('Harga wajib diisi'),
    type: Yup.string()
        .required('Tipe Hewan Kurban Wajib Diisi'),
});

export function HewanKurbanEditForm({ saveHewanKurban, actionsLoading, hewanKurban, roleMaster, onHide, id }) {

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={hewanKurban}
                validationSchema={HewanKurbanEditSchema}
                onSubmit={(values) => {
                    // console.log(values);
                    saveHewanKurban(values)

                }}
            >
                {({ handleSubmit, values, setFieldValue, handleChange }) => (
                    <>

                        <Modal.Body className="overlay overlay-block cursor-default">
                            {actionsLoading && (
                                <div className="overlay-layer bg-transparent">
                                    <div className="spinner spinner-lg spinner-success" />
                                </div>
                            )}
                            <Form className="form form-label-right">
                                <div className="form-group row">
                                    <div className="col-lg-12 mb-1">
                                        <Field
                                            name="name"
                                            component={Input}
                                            placeholder="Text"
                                            label="Nama Hewan Kurban"
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-1">

                                        <Select
                                            name="type"
                                            placeholder="Text"
                                            label="Tipe"
                                        >
                                            <option value="">Pilih</option>
                                            {
                                                tipeHewan.map((item) => {
                                                    return <option key={item.value} value={item.value}>{item.text}</option>
                                                })
                                            }
                                        </Select>
                                        <ErrorMessage name='type'
                                            render={msg => <div style={{ color: 'red', }}>{msg}</div>} />
                                    </div>

                                    <div className="col-lg-12 mb-1">

                                        <label htmlFor="price">Harga Hewan Kurban</label>
                                        <CurrencyInput
                                            name="price"
                                            placeholder="Harga Hewan Kurban"
                                            intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
                                            className="form-control"
                                            defaultValue={values.price}
                                            value={values.price}
                                            decimalsLimit={2}
                                            label="Jumlah"
                                            onValueChange={(value, name) => {
                                                if (value) {
                                                    setFieldValue(name, Number(value))
                                                } else {
                                                    setFieldValue(name, 0)
                                                }
                                            }}
                                        />
                                        <label htmlFor="price">Harap Masukkan <strong>Harga Hewan Kurban</strong></label>
                                        <ErrorMessage name='price'
                                            render={msg => <div style={{ color: 'red' }}>{msg}</div>} />
                                    </div>

                                </div>

                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                type="button"
                                onClick={onHide}
                                className="btn btn-light btn-elevate"
                            >
                                Cancel
                            </button>
                            <> </>
                            <button
                                type="submit"
                                onClick={() => handleSubmit()}
                                className="btn btn-primary btn-elevate"
                            >
                                Save
                            </button>
                        </Modal.Footer>
                    </>
                )}
            </Formik>
        </>
    );
}

