/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { CircularProgress } from "@material-ui/core";

export function TilesDonationWidget({
  className,
  baseColor = "primary",
  widgetHeight = "150px",
  icon = "",
  title = "",
  value = "",
  subValue = "",
  loading = false,
}) {
  return (
    <>
      <div
        className={`card card-custom bg-${baseColor} ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className={`card-body ${loading && "d-flex justify-content-center"}`}>
          {loading ? (
            <div className="text-center align-self-center">
              <CircularProgress />
            </div>
          ) : (
            <div>
              <div className="d-flex justify-content-center align-items-stretch ">
                <span className="svg-icon svg-icon-3x svg-icon-white ">
                  <i className={icon} style={{ color: "white", fontSize: "36px" }}></i>
                </span>
                <div
                  className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-3 col text-nowrap align-self-center`}
                >
                  {subValue}
                </div>
              </div>
              <p className={`text-inverse-${baseColor} font-weight-bolder font-size-h2 mt-1`}>
                {value}
              </p>
              <a
                href="#"
                className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1`}
              >
                {title}
              </a>
              {/* <div className="row mt-3">
                
                
              </div> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
