import * as requestFromServer from "./thkChannelReportCrud";
import { thkChannelReportSlice, callTypes } from "./thkChannelReportSlice";
import { actions as auth } from "../../../Auth/_redux/authRedux";
import { toastOption } from "../../../../../_metronic/_helpers/ToastHelpers";
import { toast } from "react-toastify";

const { actions } = thkChannelReportSlice;

export const fetchThkChannelReports = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findThkChannelReport(queryParams)
    .then((response) => {
      const {
        data: { total_items, items },
      } = response.data;
      dispatch(actions.thkChannelReportsFetched({ total_items, items }));
    })
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error(error.response.data.message, toastOption);
        dispatch(auth.logout());
      }
      error = error.response.data.message;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchThkChannelReport = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.thkChannelReportFetched({ thkChannelReportForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getThkChannelReportById(id)
    .then((response) => {
      const { data: thkChannelReport } = response.data; // alias data -> donatur
      dispatch(
        actions.thkChannelReportFetched({
          thkChannelReportForEdit: thkChannelReport,
        })
      );
    })
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error(error.response.data.message, toastOption);
        dispatch(auth.logout());
      }
      error = error.response.data.message;
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createThkChannelReport = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createThkChannelReport(payload)
    .then((response) => {
      dispatch(actions.thkChannelReportReload());
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error(error.response.data.message, toastOption);
        dispatch(auth.logout());
      }
      error = error.response.data.message;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      return error;
    });
};

export const updateThkChannelReport = (thkChannelReport, uuid) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateThkChannelReport(thkChannelReport, uuid)
    .then((response) => {
      dispatch(actions.thkChannelReportReload());
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error(error.response.data.message, toastOption);
        dispatch(auth.logout());
      }
      error = error.response.data.message;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const findThkChannelReportDonation = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findThkChannelReportDonation(queryParams)
    .then((response) => {
      const {
        data: { total_items, items },
      } = response;
      dispatch(actions.thkChannelReportDonation({ total_items, items }));
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        toast.error(error.response.data.message, toastOption);
        dispatch(auth.logout());
      }
      error = error.response.data.message;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getChannelBatchReport = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getChannelBatchReport(queryParams)
    .then((response) => {
      const {
        data: { total_items, items },
      } = response.data;
      dispatch(actions.channelBatchReport({ total_items, items }));
    })
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error(error.response.data.message, toastOption);
        dispatch(auth.logout());
      }
      error = error.response.data.message;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deleteBatchChannelReport = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteChannelBatchReport(payload)
    .then((response) => {
      dispatch(actions.thkChannelReportReload());
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error(error.response.data.message, toastOption);
        dispatch(auth.logout());
      }
      error = error.response.data.message;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
      return error;
    });
};

export const reloadThkChannelReportState = () => (dispatch) => {
  dispatch(actions.thkChannelReportReload());
};
