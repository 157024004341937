import {createSlice} from '@reduxjs/toolkit';

const initialProjectState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    projectForEdit: undefined,
    lastError: null,
    projectLog: []
}

export const callTypes = {
    list: "list",
    action: "action"
};

export const projectsSlice = createSlice({
    name: 'project',
    initialState: initialProjectState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // getProjectById
        projectFetched : (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.projectForEdit = action.payload.projectForEdit;
        },
        projectLogFetched : (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.projectLog = action.payload.data;
        },
        // findProjects
        projectsFetched: (state, action) => {
            const { total_items, items } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = items;
            state.totalCount = total_items;
        },
        // Set to default state actionLoading & error
        projectsReload: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
        }

    }
});
