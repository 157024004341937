import React, { useEffect, useMemo, useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { currencyFormatter } from '../../../../../_metronic/_helpers';
import { useCheckUIContext } from '../CheckUIContext';
import Moment from 'react-moment';
import { CheckHeader } from '../header/CheckHeader';
import { RoleChecker } from '../../../../RoleChecker';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../_metronic/_helpers';
import { values } from 'lodash';

export function CheckList(props) {

	const checkUIContext = useCheckUIContext();
	const checkUIProps = useMemo(() => {
		return {
			item: checkUIContext.item,
			createRekonsiliasi: checkUIContext.createRekonsiliasi,
			isDisabled: checkUIContext.isDisabled,
		};
	}, [checkUIContext]);


	const paymentTypeDeterminer = (cash, bank) => {
		if (cash > 0 && bank == 0) {
			return (
				<span>
					<strong style={{ color: 'green' }}>Cash</strong>
				</span>
			)
		} else if (cash > 0 && bank > 0) {
			return (
				<span>
					<strong style={{ color: 'red' }}>Bank + Cash</strong>
				</span>
			)

		} else if (cash == 0 && bank > 0) {
			return (
				<span>
					<strong style={{ color: 'blue' }}>Bank</strong>
				</span>
			);
		} else if (cash == 0 && bank == 0) {
			return (
				<span>
					<strong style={{ color: 'purple' }}>Partner</strong>
				</span>
			);
		}
	}

	const [selectedRows, setSelectedRows] = useState([]);
	const [selectAll, setSelectAll] = useState(false);
	const [openModal, setOpenModal] = useState(false);

	const [amount, setAmount] = useState(0);

	const handleCheckboxChange = (event, id) => {
		if (event.target.checked) {
		  setSelectedRows([...selectedRows, id]);
		} else {
		  setSelectedRows(selectedRows.filter(rowId => rowId !== id));
		}
	  };

	const handleSelectAllChange = (event) => {
		setSelectAll(event.target.checked);
		setSelectedRows(event.target.checked ? checkUIProps.item.map(v => v.uuid) : []);
	};

	const handleValidation = (value) => {
		// validations
        if (!value.trx_date) {
            return toast.error('tanggal transaksi wajib diisi', toastOption);
        }

        if (!value.amount) {
            return toast.error('Nominal wajib diisi', toastOption);
        }

        if (!value.diff_description) {
            return toast.error('Deskripsi wajib diisi', toastOption);
        }

        if (!value.channel_charge) {
            return toast.error('Selisih wajib diisi', toastOption);
        }

        if (!value.bank_amount) {
            return toast.error('Nominal Bank wajib diisi', toastOption);
        }

        if (value.diff_amount !== 0) {
            return toast.error('Selisih Nominal Harus 0', toastOption);
        }

        if (!value.file) {
            return toast.error('Bukti transfer wajib diisi', toastOption);
        }

		// open modal
		setOpenModal(true);
	}

	const [submitValue, setSubmitValue] = useState({});

	useEffect(() => {
		if (selectedRows.length > 0) {
			let sumAmount = selectedRows.reduce((sum, selectData) => {
				let match = checkUIProps.item.find(v => v.uuid === selectData);

				return sum + (match ? parseInt(match.trx_total) : 0);
			}, 0);
			
			return setAmount(sumAmount);
		}

		setAmount(0);

	},[selectedRows]);

	return (
		<>
			<Card border="primary">
				<CardHeader title="Daftar Request Disetujui">

				</CardHeader>
				<CardBody>

					<CheckHeader setSubmitValue={setSubmitValue} amount={amount} />
					
					<Table striped hover responsive className="text-center">
						<thead>
							<tr>
								<th>
									<Form.Check
                						type="checkbox"
                						onChange={handleSelectAllChange}
                						checked={selectAll}
              						/>
								</th>
								<th>#</th>
								<th>Collect Name</th>
								<th>Donation Item</th>
								<th>Tipe Pembayaran</th>
								<th>Jumlah</th>
								<th>Tanggal Diperiksa</th>
							</tr>
						</thead>
						<tbody>
							{
								checkUIProps.item.map((row, index) =>
									<tr key={row.collect_batch_uuid}>
										<td>
											<Form.Check
              								  type="checkbox"
              								  onChange={(event) => handleCheckboxChange(event, row.uuid)}
              								  checked={selectedRows.includes(row.uuid)}
              								/>
										</td>
										<td>{index + 1}</td>
										<td>{row.collect_user_name}</td>
										<td>{row.trx_item}</td>
										<td>{paymentTypeDeterminer(row.payment_cash, row.payment_edc)}</td>
										<td>{currencyFormatter(row.trx_total)}</td>
										<td>
											<Moment format="DD-MMM-YYYY h:mm:ss">{row.checker_at}</Moment>
										</td>
									</tr>,
								)
							}
						</tbody>
					</Table><br />

				</CardBody>
				<CardBody>
					<CardHeaderToolbar>
						{RoleChecker('Collect', 'collector') && <button
							disabled={selectedRows.length <= 0}
							type="button"
							className="btn btn-danger float-right"
							onClick={() => handleValidation(submitValue)}
						>Buat Rekonsiliasi Baru
						</button>}
					</CardHeaderToolbar>
				</CardBody>
			</Card>
			<div>
				<Dialog
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">Settle Rekonsoliasi</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Anda Tidak Dapat Mengubah Data Rekonsiliasi Ini Lagi Setelah Statusnya SETTLE, Apakah anda yakin ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenModal(false)} color="danger">
                            Batal
                        </Button>
                        <Button
                            onClick={async () => {
								await checkUIProps.createRekonsiliasi(selectedRows, submitValue)
								setOpenModal(false);
							}}
                            color="primary"
                            autoFocus
                        >
                            Oke
                        </Button>
                    </DialogActions>
                </Dialog>
			</div>

		</>
	);
}

