/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import SVG from "react-inlinesvg";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import * as auth from "../Auth";
import { useHistory } from "react-router-dom";
import * as bcrypt from "bcryptjs";
import Axios from "axios";
import { toast } from 'react-toastify';
import { toastOption } from '../../../_metronic/_helpers';
import { MasterDataURL } from '../../config/UrlEndPoint';

function ChangePassword(props) {
  const history = useHistory();

  function updateUserPassword(password) {
    return Axios.put(`${MasterDataURL}user/change-password`, { password });
  }

  const onCancel = () => {
    history.goBack();
  }

  const validate = (values) => {
    let errors = {};
    if (values.currentPassword) {
      let passwordIsValid = bcrypt.compareSync(values.currentPassword, user.password);
      if(!passwordIsValid) errors.currentPassword = "Password lama salah";
    }
    if (values.currentPassword && values.password) {
      if(values.currentPassword == values.password) errors.password = "Password baru tidak boleh sama dengan password lama";
    }
    return errors;
  }

  // Fields
  const [loading, setloading] = useState(false);
  const [isError, setisError] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user, shallowEqual);

  // Methods
  const saveUser = async (values, setStatus, setSubmitting) => {
    setloading(true);
    setisError(false);

    const response = await updateUserPassword(values.password);
    if (response?.data?.data) {
      toast.success('Berhasil mengubah password', toastOption);
      const result = response?.data?.data;
      const { channel, access_role} = user;
      dispatch(props.setUser({ ...result, channel, access_role }));
			history.push('/');
    } else {
      toast.error('Gagal mengubah password', toastOption);
    }
    // history.go(0)
    setloading(false);
    setSubmitting(false);
    // setisError(true);
  };
  // UI Helpers
  const initialValues = {
    currentPassword: "",
    password: "",
    cPassword: "",
  };
  const Schema = Yup.object().shape({
    currentPassword: Yup.string().required("Password Lama harus diisi"),
    password: Yup.string().required("Password Baru harus diisi")
    .min(8, "Password minimal 8 karakter")
    .max(20, "Password tidak boleh melebihi 20 karakter")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/,
      "Password tidak valid, harap menggunakan huruf kecil, huruf besar, angka, dan simbol"
    ), // required pass huruf kecil, huruf besar, angka, dan simbol

    // .test("isValidPass", " tidak valid, harap menggunakan angka dan nomer", (value, context) => {
    //   var letter = /[a-zA-Z]/; 
    //   var number = /[0-9]/;
    //   var valid = number.test(value) && letter.test(value);
    //   return valid;
    // }),

    cPassword: Yup.string()
      .required("Konfirmasi Password harus diisi")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Password Baru dan Konfirmasi Password tidak sama!"
        ),
      }),
  });
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Schema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    },
    validate,
  });

  return (
    <form className="card card-custom" onSubmit={formik.handleSubmit}>
      {loading && <ModalProgressBar />}

      {/* begin::Header */}
      <div className="card-header py-3">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label font-weight-bolder text-dark">
            Ubah Password
          </h3>
          {/* <span className="text-muted font-weight-bold font-size-sm mt-1">
            Change your account password
          </span> */}
        </div>
        <div className="card-toolbar">
          <button
            type="submit"
            className="btn btn-success mr-2"
            disabled={
              formik.isSubmitting || (formik.touched && !formik.isValid)
            }
          >
            Save Changes
            {formik.isSubmitting}
          </button>
          <Link
            to="#"
            className="btn btn-secondary"
            onClick={onCancel}
          >
            Cancel
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Form */}
      <div className="form">
        <div className="card-body">
          {/* begin::Alert */}
          {isError && (
            <div
              className="alert alert-custom alert-light-danger fade show mb-10"
              role="alert"
            >
              <div className="alert-icon">
                <span className="svg-icon svg-icon-3x svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
                  ></SVG>{" "}
                </span>
              </div>
              <div className="alert-text font-weight-bold">
                Configure user passwords to expire periodically. Users will need
                warning that their passwords are going to expire,
                <br />
                or they might inadvertently get locked out of the system!
              </div>
              <div className="alert-close" onClick={() => setisError(false)}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i className="ki ki-close"></i>
                  </span>
                </button>
              </div>
            </div>
          )}
          {/* end::Alert */}
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-alert">
              Password Lama
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="password"
                placeholder="Password Lama"
                className={`form-control form-control-lg form-control-solid mb-2 ${getInputClasses(
                  "currentPassword"
                )}`}
                name="currentPassword"
                {...formik.getFieldProps("currentPassword")}
              />
              {formik.touched.currentPassword &&
              formik.errors.currentPassword ? (
                <div className="invalid-feedback">
                  {formik.errors.currentPassword}
                </div>
              ) : null}
              {/* <a href="#" className="text-sm font-weight-bold">
                Forgot password ?
              </a> */}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-alert">
              Password Baru
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="password"
                placeholder="Password Baru"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "password"
                )}`}
                name="password"
                {...formik.getFieldProps("password")}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="invalid-feedback">{formik.errors.password}</div>
              ) : null}
            </div>
          </div>
          <div className="form-group row">
            <label className="col-xl-3 col-lg-3 col-form-label text-alert">
              Konfirmasi Password
            </label>
            <div className="col-lg-9 col-xl-6">
              <input
                type="password"
                placeholder="Konfirmasi Password"
                className={`form-control form-control-lg form-control-solid ${getInputClasses(
                  "cPassword"
                )}`}
                name="cPassword"
                {...formik.getFieldProps("cPassword")}
              />
              {formik.touched.cPassword && formik.errors.cPassword ? (
                <div className="invalid-feedback">
                  {formik.errors.cPassword}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {/* end::Form */}
    </form>
  );
}

export default connect(null, auth.actions)(ChangePassword);
