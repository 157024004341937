import React from "react";
import { Route } from "react-router-dom";
import { ThkChannelReportCard } from "./ThkChannelReportCard";
import { ThkChannelReportUIProvider } from "./ThkChannelReportUIContext";
import { PrivateRoute } from "../../../../PrivateRoute";
import { RoleChecker } from "../../../../RoleChecker";

export function ThkChannelReportPage({ history }) {
  const thkChannelReportUIEvents = {
    openEditThkChannelReportList: (id) => {
      history.push(`/qurban/thk_channel_report/${id}/list`);
    },
    openEditThkChannelReportPage: (id) => {
      history.push(`/qurban/thk_channel_report/${id}/edit`);
    },
    newThkChannelReportButtonClick: () => {
      history.push(`/qurban/thk_channel_report/new`);
    },
  };

  return (
    <ThkChannelReportUIProvider
      thkChannelReportUIEvents={thkChannelReportUIEvents}
    >
      <ThkChannelReportCard />
    </ThkChannelReportUIProvider>
  );
}
