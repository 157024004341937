// Code helper pembantu diletakan pada halaman ini.
import Moment from 'react-moment';

// Formatter Currency
import React from "react";
import { useLocation } from "react-router-dom";

const defaultOptions = {
    significantDigits: 2,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbol: 'Rp. ',
};

export const currencyFormatter = (value, options) => {
    value = Number(value)
    if (isNaN(value)) value = 0.0;
    options = { ...defaultOptions, ...options };
    value = value.toFixed(options.significantDigits);

    const [currency, decimal] = value.split('.');
    return `${options.symbol} ${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        options.thousandsSeparator,
    )}${options.decimalSeparator}${decimal}`;
};

export const numberFormatter = (value, options) => {
    value = Number(value)
    if (isNaN(value)) value = 0.0;
    options = { ...defaultOptions, ...options };
    value = value.toFixed(options.significantDigits);

    const [currency, decimal] = value.split('.');
    return `${currency.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        options.thousandsSeparator,
    )}`;
};
// End Formatter Currency

export const copyValue = (x) => {
    return JSON.parse(JSON.stringify(x));
}


export function PrivateNumber(cell, row) {
    if (cell) {
        let number = []
        for (let i = 0; i <= cell.length; i++) {
            if (i > cell.length - 3) {
                number.push('*')
            } else {
                number.push(cell[i])
            }
        }
        return (
            <span>{number.join('')}</span>
        );
    } else {
        return ''
    }
}

export function PrivateEmail(cell, row) {
    if (cell) {
        var maskedEmail = cell.replace(/([^@.])/g, "*").split('');
        var previous = "";
        for (let i = 0; i < maskedEmail.length; i++) {
            if (i <= 2 || previous === "." || previous === "@") {
                maskedEmail[i] = cell[i];
            }
            previous = cell[i];
        }
        return (
            <span>{maskedEmail.join('')}</span>
        );
    } else {
        return ''
    }
}

export const bootstrapTableDateFormatter = (date) => {
    return <Moment format="DD-MMM-YYYY">{date}</Moment>
}

export function formatBytes(bytes, decimals = 2) {
    const result = {
        size: 0,
        indexSizes: 0,
        strSize: '0 Bytes',
    }

    if (bytes <= 0) return result;
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const size = Number((bytes / Math.pow(k, i)).toFixed(dm));
    const strSize = size + ' ' + sizes[i];

    result.indexSizes = i;
    result.size = size;
    result.strSize = strSize;
    return result;
}

export function genOptionLoaderDebounced(fetcher, mapper) {
  let timeout = null;

  return (inputValue, callback) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      fetcher(inputValue).then(({ data: { data: { items = [] } = {} } }) => {
        const options = items.map(mapper);
        callback(options);
      });
    }, 500);
  };
}

export function genOptionLoaderDebouncedProject(fetcher, mapper) {
    let timeout = null;
  
    return (inputValue, callback) => {
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        fetcher(inputValue).then(({ data: { data = [] } }) => {
          const options = data.map(mapper);
          callback(options);
        });
      }, 500);
    };
  }

export const generateYearsBetween = (startYear = 2010, endYear) => {
  const endDate = endYear || new Date().getFullYear();
  let years = [];

  for (let i = startYear; i <= endDate; i++) {
    years.push(startYear);
    startYear++;
  }
  return years;
};

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}