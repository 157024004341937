/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import SVG from "react-inlinesvg";
import { CircularProgress } from "@material-ui/core";
import ApexCharts from 'apexcharts'
import { currencyFormatter, toAbsoluteUrl } from "../../../../../_metronic/_helpers";

export function TileCustomPieWidget({
  className,
  iconColor = "success",
  widgetHeight = "450px",
  title = "",
  series = [],
  labels = [],
  loading = false,
  chartID = "",
  icon = toAbsoluteUrl("/media/svg/icons/Layout/Layout-Grid.svg")
}) {

  var options = {
    series,
    chart: {
      // width: 280,
      height: 350,
      type: 'pie',
    },
    labels,
    legend: false,
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: chartID !== "donor_gender" ? {
        formatter: function (val) {
          return currencyFormatter(val)
        },
      } : {
        formatter: function (val) {
          return (val)
        },
      },
    },
    legend: {
      position: 'bottom'
    }
    // responsive: [{
    //   breakpoint: 1400,
    //   options: {
    //     chart: {
    //       width: 250
    //     },
    //     legend: {
    //       position: 'bottom'
    //     }
    //   }
    // }]
  };

  // function renderChart() {

  // }

  // function cleanUp() {
  //   chart.destroy();
  // };

  useEffect(() => {
    var chart = new ApexCharts(document.querySelector(`#${chartID}`), options);
    chart.render();
    // renderChart()
    return function cleanUp() {
      chart.destroy();
    };
  }, [loading])


  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight }}
      >

        <div className={`card-body d-flex ${loading && " justify-content-center "} mx-auto no-wrap`}>



          <div className="no-wrap">
            <p
              className="text-hover-primary text-center font-weight-bold font-size-h4"
            >
              {title}
            </p>
            {
              !loading && series.length == 0 && <p
                className="text-hover-primary text-center font-weight-bold font-size-h4"
              >
                Data Kosong
              </p>
            }
            {
              loading && <div className="text-center align-self-center"><CircularProgress /></div>

            }
            <div id={`${chartID}`} className="text-center"></div>

          </div>




        </div>
      </div>
    </>
  );
}
