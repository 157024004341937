import { createSlice } from "@reduxjs/toolkit";

const initialThkChannelReportState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  thkChannelReportForEdit: undefined,
  lastError: null,
  thkChannelReportBatchList: [],
  thkChannelReportBatchEdit: {},
  thkChannelReportDonation: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
  },
  channelBatchReport: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
  },
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const thkChannelReportSlice = createSlice({
  name: "thkChannelReport",
  initialState: initialThkChannelReportState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getThkChannelReportById
    thkChannelReportFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.thkChannelReportForEdit = action.payload.thkChannelReportForEdit;
    },
    // findThkChannelReport
    thkChannelReportsFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // * findThkChannelReportDonation
    thkChannelReportDonation: (state, action) => {
      const { total_items, items } = action.payload;
      state.thkChannelReportDonation.listLoading = false;
      state.thkChannelReportDonation.error = null;
      state.thkChannelReportDonation.entities = items;
      state.thkChannelReportDonation.totalCount = total_items;
    },
    // Set to default state actionLoading & error
    thkChannelReportReload: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },

    // findThkChannelReportBatch
    thkChannelReportsBatchFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.thkChannelReportBatchList = items;
      state.totalCount = total_items;
    },

    // findThkChannelReportBatch
    thkChannelReportBatchDetail: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.thkChannelReportBatchEdit =
        action.payload.thkChannelReportBatchEdit;
    },

    // find channel batch report channel
    channelBatchReport: (state, action) => {
      const { total_items, items } = action.payload;
      state.channelBatchReport.listLoading = false;
      state.channelBatchReport.error = null;
      state.channelBatchReport.entities = items;
      state.channelBatchReport.totalCount = total_items;
    },
  },
});
