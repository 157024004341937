import { createSlice } from '@reduxjs/toolkit';

const initialFundTypeState = {

    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    fundtypeForEdit: undefined,
    lastError: null,
}

export const callTypes = {
    list: 'list',
    action: 'action'
}

export const fundTypeSlice = createSlice({
    name: 'fundtype',
    initialState: initialFundTypeState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },

        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // READ get single fundtype
        fundtypeFetched: (state, action) =>{
            state.actionsLoading = false;
            state.error = null;
            state.fundtypeForEdit = action.payload.fundtypeForEdit;
        },

        // READ find many
        fundtypesFetched: ( state, action ) =>{
            const { total_items, items } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = items;
            state.totalCount = total_items;
        },

        // set state actionloading and error to default
        fundtypeReload: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
        }
    }
});