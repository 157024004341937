import * as requestFromServer from "./footerCrud";
import { versionPlatformSlice, callTypes } from "./footerSlice";
import { toastOption } from "../../../../_helpers/ToastHelpers";
import { toast } from "react-toastify";

const { actions } = versionPlatformSlice;

export const findLatestVersion = (query) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findLatestVersion(query)
    .then((response) => {
      const { data } = response.data;
      dispatch(actions.versionPlatformFetched(data));
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
