import React, { useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import PartnershipTable from "../partnership-table/PartnershipTable";
import { PartnershipFilter } from "../partnership-table/partnership-filter/PartnershipFilter";
import { RoleChecker } from "../../../../../RoleChecker";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useQuery } from "../../../../../../_metronic/_helpers";
import QurbanTable from "./thk-qurban/QurbanTable";
import ThkCampaignTable from "./thk-campaign/ThkCampaignTable";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchPartnership } from "../../_redux/partnershipAction";
import ThkImportTable from "./thk-import/ThkImportTable";
import ThkReportTable from "./thk-report/ThkReportTable";
import { ThkTemplateEditForm } from "./thk-report/ThkReportEditForm";
import PartnershipDownloadBatch from "../partnership-download-batch/PartnershipDownloadBatch";

export const PartnershipManageCard = ({ match: { params }, history }) => {
  const dispatch = useDispatch();
  const { actionsLoading, partnershipForEdit = {}, error } = useSelector(
    (state) => ({
      actionsLoading: state.thkPartnership.actionsLoading,
      partnershipForEdit: state.thkPartnership.partnershipForEdit,
      error: state.thkPartnership.error,
    }),
    shallowEqual
  );
  
  useEffect(() => {
    if (params.id) {
      dispatch(fetchPartnership(params.id));
    }
  }, []);

  const {
    location: { pathname },
  } = history;

  let query = useQuery();
  const activeTab = query.get("tab");

  function handleBack() {
    history.goBack();
  }

  function handleClickTab(tab) {
    history.push({
      pathname,
      search: `?tab=${tab}`,
    });
  }

  const tabList = [
    {
      title: "Daftar Kurban",
      key: "qurban_list",
      component: (
        <QurbanTable thkPartnership={partnershipForEdit} history={history} />
      ),
    },
    {
      title: "Import",
      key: "import",
      component: (
        <ThkImportTable thkPartnership={partnershipForEdit} history={history} />
      ),
    },
    {
      title: "Hewan Campaign",
      key: "thk_campaign",
      component: <ThkCampaignTable thkPartnership={partnershipForEdit} />,
    },
    {
      title: "Laporan Kurban",
      key: "thk_report",
      component: <ThkTemplateEditForm thkPartnership={partnershipForEdit} />,
    },
    {
      title: "Download Batch",
      key: "download_batch",
      component: (
        <PartnershipDownloadBatch thkPartnership={partnershipForEdit} />
      ),
    },
  ];

  return (
    <Card>
      <CardHeader title={`${partnershipForEdit?.name}`}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleBack}
          >
            Kembali
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Tabs
          id="tab"
          activeKey={activeTab ?? "qurban_list"}
          onSelect={(tab) => {
            handleClickTab(tab);
          }}
          defaultActiveKey="qurban_list"
        >
          {tabList.map((list) => (
            <Tab eventKey={list.key} title={list.title} key={list.key}>
              <div className="mt-8">{list.component}</div>
            </Tab>
          ))}
        </Tabs>
      </CardBody>
    </Card>
  );
};
