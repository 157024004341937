import { createSlice } from '@reduxjs/toolkit';

const initialReconcileState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: null,
	reconcileForEdit: undefined,
	lastError: null,
	reconcileData: {
		donation: [],
		bank_pooling_uuid: "",
		diff_description: "",
		channel_charge: "",
		file: '',
		amount: ""
	}
};

export const callTypes = {
	list: 'list',
	action: 'action',
};

export const reconcileSlice = createSlice({
	name: 'reconcile',
	initialState: initialReconcileState,
	reducers: {
		catchError: (state, action) => {
			state.error = action.payload.error;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false;
			} else {
				state.actionsLoading = false;
			}
		},

		startCall: (state, action) => {
			state.error = null;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true;
			} else {
				state.actionsLoading = true;
			}
		},

		// getReconcileById
		reconcileFetched: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
			state.reconcileData = action.payload.reconcileData;
		},

		// findReconcile
		reconcilesFetched: (state, action) => {
			const { total_items, items } = action.payload;
			state.listLoading = false;
			state.error = null;
			state.entities = items;
			state.totalCount = total_items;
		},

		// Set to default state actionLoading & error
		reconcileReload: (state, action) => {
			state.actionsLoading = false;
			state.listLoading = false;
			state.error = null;
		},

	},
});

