import React, { useEffect, useState } from "react";

import { Formik, Form } from "formik";

import ReactSelect from "react-select";
import { Select } from "../../../../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  reloadPartnershipState,
  updateReportTemplate,
} from "../../../_redux/partnershipAction";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../../../_metronic/_helpers";
import Loading from "../../../../../../../_metronic/_assets/js/components/loading";
import PreviewImage from "../../../../../../../_metronic/_assets/js/components/preview-image";

export function ThkTemplateEditForm({ thkPartnership }) {
  const [imageModal, setImageModal] = useState({
    show: false,
    img: null,
  });

  const {
    uuid,
    report_file,
    report_template_type,
    kambing_file,
    sapi_file,
  } = thkPartnership;

  const { currentState } = useSelector(
    (state) => ({ currentState: state.thkPartnership }),
    shallowEqual
  );

  const { actionsLoading } = currentState;

  const dispatch = useDispatch();

  const savePhoto = (payload, uuid) => {
    const formData = new FormData();
    if (payload.report_template_type == "1") {
      formData.append("file", payload.report_file);
    } else {
      formData.append("kambing_bg", payload.kambing_file);
      formData.append("sapi_bg", payload.sapi_file);
    }
    formData.append("report_template_type", payload.report_template_type);

    dispatch(updateReportTemplate(formData, uuid)).then((response) => {
      toast.success(response.message, toastOption);
      dispatch(reloadPartnershipState());
    });
  };

  const [initialValues, setInitialValues] = useState({
    report_file: null,
    kambing_file: null,
    sapi_file: null,
    report_template_type: "1",
  });

  useEffect(() => {
    setInitialValues((prevValues) => ({
      ...prevValues,
      report_file,
      kambing_file,
      sapi_file,
      report_template_type,
    }));
  }, [thkPartnership]);

  function getImageUrl(image) {
    if (image instanceof File) {
      return URL.createObjectURL(image);
    }

    if (image instanceof Object) {
      const { adapter, path } = image;

      if (adapter && path) {
        return adapter + path;
      }
    }

    return null;
  }

  function handleClickImg(img) {
    setImageModal((prev) => ({
      ...prev,
      show: true,
      img,
    }));
  }

  function handleCloseImg() {
    setImageModal({
      show: false,
      img: null,
    });
  }

  const imgTemplateStyle = {
    width: "100%",
    height: "200px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const imgFileStyle = {
    width: "100%",
    height: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <>
      <Loading show={actionsLoading} />
      <PreviewImage
        show={imageModal.show}
        images={imageModal.img}
        onHide={handleCloseImg}
        title={"Preview Template Laporan Partnership"}
      />
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => savePhoto(values, uuid)}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Contoh Template</label>
                </div>
                <div className="col-lg-4 mb-5">
                  <div className="text-center">
                    <label>Tipe 1</label>
                  </div>
                  <div style={imgTemplateStyle}>
                    <img
                      src="/media/partnership-template/type1.png"
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleClickImg("/media/partnership-template/type1.png")
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-4 mb-5">
                  <div className="text-center">
                    <label>Tipe 2</label>
                  </div>
                  <div style={imgTemplateStyle}>
                    <img
                      src="/media/partnership-template/type2.png"
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleClickImg("/media/partnership-template/type2.png")
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Tipe Template</label>
                </div>
                <div className="col-lg-9 mb-5">
                  <Select
                    name="report_template_type"
                    value={values.report_template_type}
                    className="form-control"
                  >
                    <option value={"1"}>1</option>
                    <option value={"2"}>2</option>
                  </Select>
                </div>
              </div>
              {values.report_template_type == "1" && (
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label htmlFor="report_file">File</label>
                  </div>
                  <div className="col-md-9 mb-4 md-mb-0">
                    <div className="input-group mb-3">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="report_file"
                          onChange={(e) => {
                            setFieldValue("report_file", e.target.files[0]);
                          }}
                          accept="image/*"
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="image"
                          aria-describedby="inputGroupFileAddon02"
                        >
                          Pilih Gambar
                        </label>
                      </div>
                    </div>
                    {getImageUrl(values.report_file) && (
                      <div className="mt-5" style={imgFileStyle}>
                        <img
                          src={getImageUrl(values.report_file)}
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "100%",
                          }}
                          alt="report file"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {values.report_template_type == "2" && (
                <>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="kambing_file">Template Kambing</label>
                    </div>
                    <div className="col-md-9 mb-4 md-mb-0">
                      <div className="input-group mb-3">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="kambing_file"
                            onChange={(e) => {
                              setFieldValue("kambing_file", e.target.files[0]);
                            }}
                            accept="image/*"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="kambing_file"
                            aria-describedby="inputGroupFileAddon02"
                          >
                            Pilih Gambar
                          </label>
                        </div>
                      </div>
                      {getImageUrl(values.kambing_file) && (
                        <div className="mt-5" style={imgFileStyle}>
                          <img
                            src={getImageUrl(values.kambing_file)}
                            style={{
                              objectFit: "contain",
                              width: "100%",
                              height: "100%",
                            }}
                            alt="kambing file"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-3">
                      <label htmlFor="sapi_file">Template Sapi</label>
                    </div>
                    <div className="col-md-9 mb-4 md-mb-0">
                      <div className="input-group mb-3">
                        <div className="custom-file">
                          <input
                            type="file"
                            className="custom-file-input"
                            id="sapi_file"
                            onChange={(e) => {
                              setFieldValue("sapi_file", e.target.files[0]);
                            }}
                            accept="image/*"
                          />
                          <label
                            className="custom-file-label"
                            htmlFor="kambing_file"
                            aria-describedby="inputGroupFileAddon02"
                          >
                            Pilih Gambar
                          </label>
                        </div>
                      </div>
                      {getImageUrl(values.sapi_file) && (
                        <div className="mt-5" style={imgFileStyle}>
                          <img
                            src={getImageUrl(values.sapi_file)}
                            style={{
                              objectFit: "contain",
                              width: "100%",
                              height: "100%",
                            }}
                            alt="sapi file"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </Form>
            <div className="float-right">
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
