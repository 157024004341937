import React, {  useMemo, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { isEqual, startCase, toLower } from 'lodash';
import { useCheckerUIContext } from '../../CheckerUIContext';
import { initialFilter, statusList, paymentTypeList } from '../../CheckerUIHelper';
// filter berdasarkan tanggal dan pembayaran commented untuk menunggu info berikutnya
import {DateRangePicker} from 'react-dates';
// import { Card, CardBody, CardHeader } from '../../../../../_metronic/_partials/controls'



const prepareFilter = (queryParams, values) => {
	const { searchName, collectStatus, start_date, end_date, paymentType } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};

	//add to Object filter
	filter.name = searchName ?? '';
	filter.status = collectStatus ?? '';
	filter.start_date = start_date ? start_date : ''
    filter.end_date = end_date ? end_date : ''
    // filter.paymentType = paymentType ? paymentType : ''

	newQueryParams.filter = filter;
	return newQueryParams;
};

export const CheckerFilter = ({ listLoading }) => {
	// Checker UI Context
	const checkerUIContext = useCheckerUIContext();
	const checkerUIProps = useMemo(() => {
		return {
			setQueryParams: checkerUIContext.setQueryParams,
			queryParams: checkerUIContext.queryParams,
			filterType: checkerUIContext.filterType,
		};
	}, [checkerUIContext]);

	const applyFilter = (values) => {
		const newQueryParams = prepareFilter(checkerUIProps.queryParams, values);
		if (!isEqual(newQueryParams, checkerUIProps.queryParams)) {
			newQueryParams.pageNumber = 1;
			checkerUIProps.setQueryParams(newQueryParams);
		}
	};
	//UNTUK DATERANGE
		const [onFocused, setOnFocused] = useState(null)
		const [tanggal, setTanggal] = useState(
			{
				start_date: null,
				end_date: null,
			}
		)
	// UNTUK MENAMPIKAN TITLE JENIS PEMBAYARAN YANG DITAMPIKAN 
		// const [paymentTitle, setPaymentTitle] = useState('')

		// useEffect(()=>{
		// 	setPaymentTitle(checkerUIProps.queryParams.filter.paymentType)
		// }, [checkerUIProps.queryParams.filter.paymentType])
		
	return (
		<> 
			<Formik initialValues={initialFilter.filter} onSubmit={(values) => {
				applyFilter(values);
			}}
			>{({ values, handleSubmit, handleBlur, setFieldValue }) => (
				<form onSubmit={handleSubmit} className="form form-label-right">
					<div className="form-group row">
						<div className="col-lg-3 mt-md-0 mt-3">
							<input
								type="text"
								className="form-control"
								name="searchName"
								placeholder="Search"
								onBlur={handleBlur}
								value={values.searchName}
								onChange={(e) => {
									setFieldValue('searchName', e.target.value);
									handleSubmit();
								}}
							/>
							<small className="form-text text-muted">
								<b>Cari</b> Berdasarkan Name Kolektor
							</small>
						</div>
						<div className="col-lg-3 mt-md-0 mt-3">
							<select
								className="form-control"
								placeholder="Filter Type"
								name="collectStatus"
								onBlur={handleBlur}
								onChange={(e) => {
									setFieldValue('collectStatus', e.target.value);
									handleSubmit();
								}}
								value={values.collectStatus}
							>
								{statusList.map(list =>
									<option key={list.value} value={list.value}>{list.name}</option>,
								)}
							</select>
							<small className="form-text text-muted">
								<b>Filter</b> Collect Status
							</small>
						</div>
						{/* date and payment filter disabled */}
						 {/* <div className="col-lg-3">
							<select
								className="form-control"
								placeholder="Filter Type"
								name="paymentType"
								onBlur={handleBlur}
								onChange={(e) => {
									setFieldValue('paymentType', e.target.value);
									handleSubmit();
								}}
								value={values.pay}
							>
								{paymentTypeList.map(list =>
									<option key={list.value} value={list.value}>{list.value}</option>,
								)}
							</select>
							<small className="form-text text-muted">
								<b>Filter</b> Payment Type
							</small>
						</div> */}
						<div className="col-lg-3 mt-md-0 mt-3">
								<DateRangePicker
									small={true}
									startDate={tanggal.start_date}
									endDate={tanggal.end_date}
									onDatesChange={({startDate, endDate}) => {
										setTanggal({
											start_date: startDate,
											end_date: endDate
										})
										setFieldValue('start_date', startDate)
										setFieldValue('end_date', endDate)
										handleSubmit()
									}}
									focusedInput={onFocused}
									onFocusChange={focusedInput => setOnFocused(focusedInput)}
									numberOfMonths={1}
									isOutsideRange={() => false}
									showClearDates={true}
									startDateId="start_date"
									endDateId="end_date"
									hideKeyboardShortcutsPanel={true}
								/>
								<small className="form-text text-muted">
									Cari berdasarkan <b>Jarak Waktu</b>
								</small>
							</div>
					</div>
				</form>
			)}
			</Formik>
			{/* donation componenet disabled */}
			{/* <Card>
				<CardHeader title={`MENAMPILKAN SELURUH HASIL DENGAN PEMBAYARAN ${paymentTitle}`}></CardHeader>
			</Card> */}
		</>
		

	);
};

