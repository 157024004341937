import axios from 'axios';
import { DonationURL } from '../../../../config/UrlEndPoint';

export const URL = `${DonationURL}livestock`;


// Get HewanKurban All For use Combobox data
export function getHewanKurbanAll() {
    return axios.get(URL);
}

export function getThkPeriodAll() {
    return axios.get(`${URL}/thk/periode`);
}


// //Get By ID
export function getHewanKurbanById(hewanKurbanId) {
    return axios.get(`${URL}/${hewanKurbanId}`);
}

// items => filtered/sorted result for table data
export function findHewanKurban(queryParams) {
    return axios.post(`${URL}/find`, queryParams);
}

// POST => POST: Create the hewanKurban on the server
export function createHewanKurban(hewanKurban) {
    return axios.post(URL, hewanKurban);
}

// UPDATE => PUT: update the hewanKurban on the server
export function updateHewanKurban(hewanKurban) {
    return axios.put(`${URL}/${hewanKurban.uuid}`, hewanKurban);
}

// UPDATE => Delete: Delete the hewanKurban on the server
export function deleteHewanKurban(hewanKurban) {
    return axios.delete(`${URL}/${hewanKurban.uuid}`, hewanKurban);
}


