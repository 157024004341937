import axios from 'axios';
import { DonationURL } from '../../../config/UrlEndPoint';

export const DONATION_URL = `${ DonationURL }donation`;

// request data list checker yang akan di checker
export function findChecker( queryParams ) {
	return axios.post( `${ DONATION_URL }/checker/request/find`, queryParams );
}

//Get By ID
export function getCheckerById( settle_batch_uuid, status ) {
	return axios.get( `${ DONATION_URL }/checker/request/${ settle_batch_uuid }/${ status }` );
}

// UPDATE => PUT: update the checker status on the server
export function updateCheckerStatus( data_update ) {
	return axios.put( `${ DONATION_URL }/checker/process`, data_update );
}

export function getDonationApprovedCheck(data) {
    return axios.get(`${DONATION_URL}/approved_check`);
}


// // POST => POST: Create the donatur on the server
// export function createDonatur(donatur) {
//     return axios.post(DONATION_URL, donatur);
// }


