import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import {
  currencyFormatter,
  toAbsoluteUrl,
} from "../../../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import moment from "moment";

export default function ErrorPreviewList({
  data = [],
  setOpenEditDonation,
  removeErrorData,
}) {
  const columns = [
    {
      dataField: "data.channel_trx_id",
      text: "ID Transaksi",
    },
    {
      dataField: "data.trx_date",
      text: "Tanggal Transaksi",
      // formatter: (cell) => {
      //   return moment(Math.floor(cell - 25569) * 86400 * 1000).format(
      //     "YYYY-MM-DD"
      //   ); // to convert date from excel like 44239
      // },
    },
    {
      dataField: "data.donor",
      text: "Nama Donatur",
    },
    {
      dataField: "data.hp",
      text: "HP",
    },
    {
      dataField: "data.email",
      text: "Email",
    },
    {
      dataField: "data.country",
      text: "Negara",
    },
    {
      dataField: "data.province",
      text: "Provinsi",
    },
    {
      dataField: "data.city",
      text: "Kota",
    },
    {
      dataField: "data.district",
      text: "Kecamatan",
    },
    {
      dataField: "data.amount",
      text: "Jumlah",
      classes: "text-nowrap",
      formatter: (cell) => {
        return currencyFormatter(cell);
      },
    },
    {
      dataField: "data.name_1",
      text: "Atas Nama 1",
    },
    {
      text: "Action",
      formatter: (cell, row) => {
        return (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Edit Data Donasi</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() =>
                  setOpenEditDonation({
                    show: true,
                    initValues: row,
                  })
                }
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Hapus Data Donasi</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
                onClick={() => removeErrorData(row)}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}
                  />
                </span>
              </a>
            </OverlayTrigger>
          </>
        );
      },
    },
  ];
  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="uuid"
        data={data}
        columns={columns}
      />
    </>
  );
}
