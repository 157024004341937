/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

export const ActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex,
    { openDetailKurbanListPage }
) => (
    <>

        <Link
            to={`/donasi/${row.donation_uuid}/detail`}
            type="button"
            className="btn btn-info mb-2"
            // onClick={() => openDetailKurbanListPage(row.donation_uuid)}
        >Detail
        </Link>
        {/* <OverlayTrigger
                    overlay={<Tooltip id="detail-trx-tooltip">Edit Konfirmasi</Tooltip>}
                >
                    <a
                        className="btn btn-icon btn-light btn-hover-info btn-sm mx-2"
                        onClick={() => openEditKurbanListPage(row.uuid)}
                    >
                        <span className="svg-icon svg-icon-md svg-icon-warning">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}

                            />
                        </span>
                    </a>
                </OverlayTrigger> */}

    </>
);
