import React from 'react';
import { KurbanListUIProvider } from './KurbanUIContext';
import { KurbanListCard } from './KurbanCard'

export function KurbanPage({ history }) {

	const kurbanListUIEvents = {
		// newKurbanListClick: () => {
		// 	history.push('/donasi/kurbanList/new');
		// },
		// openChooseKurbanListPage: (id) => {
		// 	history.push(`/donasi/kurbanList/${id}/choose`);
		// },
		// openEditKurbanListPage: (id) => {
		// 	history.push(`/donasi/kurbanList/${id}/edit`);
		// }
		openDetailKurbanListPage: (id) => {
			history.push(`/donasi/${id}/detail`);
		}
	}

	return (
		<KurbanListUIProvider kurbanListUIEvents={kurbanListUIEvents} history={history} >
			<KurbanListCard />
		</KurbanListUIProvider>
	);
}

