import React, { useMemo } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../../_metronic/_partials/controls';
import { useHewanKurbanCampaignUIContext } from './HewanKurbanCampaignUIContext';
import HewanKurbanCampaignTable from './hewan-kurban-campaign-table/HewanKurbanCampaignTable';
import { HewanKurbanCampaignFilter } from './hewan-kurban-campaign-table/hewan-kurban-campaign-filter/HewanKurbanCampaignFilter'
import { RoleChecker } from '../../../../../RoleChecker';

export const HewanKurbanCampaignCard = () => {

	const hewanKurbanCampaignUIContext = useHewanKurbanCampaignUIContext();
	const hewanKurbanCampaignUIProps = useMemo(() => {
		return {
			newHewanKurbanCampaignButtonClick: hewanKurbanCampaignUIContext.newHewanKurbanCampaignButtonClick,
		};
	}, [hewanKurbanCampaignUIContext]);

	return (
		<Card>
			<CardHeader title="Hewan Kurban Campaign List">
				<CardHeaderToolbar>
					{RoleChecker('Create', 'access_role') && <button
						type="button"
						className="btn btn-primary"
						onClick={hewanKurbanCampaignUIProps.newHewanKurbanCampaignButtonClick}
					>Hewan Kurban Campaign Baru
					</button>}
				</CardHeaderToolbar>
			</CardHeader>
			<CardBody>
				<HewanKurbanCampaignFilter />
				<HewanKurbanCampaignTable />
			</CardBody>
		</Card>
	);
};
