import React from 'react';
import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls'
import {CheckerFilter} from './checker-table/checker-filter/CheckerFilter'
import {CheckerTable} from './checker-table/CheckerTable'
// CheckerDonationTotal adalah fitur untuk menampilkan total donasi pada page, 
// nonaktif untuk menunggu perkembangan selanjutnya
import {CheckerDonationTotal} from './checker-donation-total/CheckerDonationTotal'

export function CheckerCard(props) {
	return(
		<>
			<Card>
				<CardHeader title="Approval Checker"></CardHeader>
				<CardBody>
					<CheckerFilter/>
					<CheckerTable/>
					{/* s2-251 tambah tabel by cash dan tunai */}
					<CheckerDonationTotal/>
				</CardBody>
			</Card>
		</>
	)
}