import React from 'react';
import { badge } from '../../CheckerUIHelper';

export function StatusColumnFormatter(cellContent, row) {

    let data = badge(cellContent);
    return (
        <span className={`label label-lg label-light-${data.type} label-inline`}>
            {cellContent}
        </span>
    );
}
