import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { usePeriodTemplateUIContext } from "../PeriodTemplateUIContext";
import { PeriodTemplateEditForm } from "./PeriodTemplateEditForm";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/periodTemplateAction";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { promiseTuplify } from "../../../../../../_metronic/_utils/promise-utils";
import { findAllThkPeriods } from "../../../../Donation/thk-period/thk-period-api";
import Loading from "../../../../../../_metronic/_assets/js/components/loading";
import { findDonaturs } from "../../../../Donatur/_redux/donatur/donaturCrud";
import { FILE_TYPE, FILE_TYPE_LABEL } from "../PeriodTemplateUIHelpers";

export const PeriodTemplateEditDialog = ({
  match: {
    params: { id, file_type },
  },
  history,
}) => {
  const [options, setOptions] = useState({
    period: [],
  });
  // PeriodTemplate Redux state
  const dispatch = useDispatch();
  const { actionsLoading, periodTemplateForEdit, error } = useSelector(
    (state) => ({
      actionsLoading: state.thkPeriodTemplate.actionsLoading,
      periodTemplateForEdit: state.thkPeriodTemplate.periodTemplateForEdit,
      error: state.thkPeriodTemplate.error,
    }),
    shallowEqual
  );

  async function fetchThkPeriods() {
    const [res, error] = await promiseTuplify(findAllThkPeriods());

    if (error) {
      return;
    }

    if (res) {
      const { data: { data = [] } = {} } = res;

      const options = data.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        period: options,
      }));
    }
  }

  useEffect(() => {
    // server call for getting PeriodTemplate by id
    dispatch(actions.fetchPeriodTemplate(id));

    fetchThkPeriods();
  }, [id, dispatch]);

  const savePeriodTemplate = (payload) => {
    const formData = new FormData();
    formData.append("type", file_type);
    formData.append("file", payload.image);

    dispatch(actions.updatePeriodTemplate(formData, payload.uuid)).then(
      (response) => {
        console.log(`response`, response);
        toast.success(response.message, toastOption);
        dispatch(actions.reloadPeriodTemplateState());
      }
    );
  };

  function handleBack() {
    history.goBack();
  }

  function getInitialValues() {
    const initValue = {
      uuid: periodTemplateForEdit?.uuid,
      thk_period: {
        name: periodTemplateForEdit?.name,
        uuid: periodTemplateForEdit?.uuid,
      },
      image: periodTemplateForEdit
        ? periodTemplateForEdit[file_type] || {}
        : {},
    };

    return initValue;
  }

  return (
    <>
      <Loading show={actionsLoading} />
      <Card>
        <CardHeader
          title={`${id ? "Edit" : "Tambah"} Template ${
            FILE_TYPE_LABEL[file_type]
          }`}
        >
          <CardHeaderToolbar>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleBack}
            >
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <PeriodTemplateEditForm
            savePeriodTemplate={savePeriodTemplate}
            actionsLoading={actionsLoading}
            periodTemplate={getInitialValues()}
            options={options}
          />
        </CardBody>
      </Card>
    </>
  );
};
