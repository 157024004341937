import React, {useEffect, useMemo} from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {useCheckerHeaderUIContext} from './CheckHeaderUIContext';
import * as actions from '../../../MasterData/_redux/bank-pooling/bankPoolingAction';
import {ActionsColumnFormatter} from './ActionsColumnsFormatter';

export function CheckTable() {

    // DonationHeader UI Context
    const checkerHeaderUIContext = useCheckerHeaderUIContext();
    const checkerHeaderUIProps = useMemo(() => {
        return {
            queryParams: checkerHeaderUIContext.queryParams,
            setQueryParams: checkerHeaderUIContext.setQueryParams,
            selectedBank: checkerHeaderUIContext.selectedBank,
        };
    }, [checkerHeaderUIContext]);

    // Getting curret state of user checker list from store (Redux)
    const {currentState} = useSelector(
        (state) => ({currentState: state.bankPoolings}),
        shallowEqual,
    );

    const {entities} = currentState;

    const dispatch = useDispatch();
    useEffect(() => {
       // Add role in query params
        dispatch(actions.fetchBankPoolings(checkerHeaderUIProps.queryParams));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkerHeaderUIProps.queryParams, dispatch]);

    const columns = [
        {
            dataField: 'bank.name',
            text: 'Bank',
        },
        {
            dataField: 'account_number',
            text: 'No Bank Pengumpul',
        },
        {
            dataField: 'branch.name',
            text: 'Cabang',
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: ActionsColumnFormatter,
            formatExtraData: {
                selectedBank: checkerHeaderUIProps.selectedBank,
            },
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: '100px',
            },
        },
    ];
    return (
        <>
            <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
            >
            </BootstrapTable>
        </>
    );
}

