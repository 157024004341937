import { createSlice } from "@reduxjs/toolkit";

const initialOAuthAppState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  oAuthAppForEdit: undefined,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const oAuthAppSlice = createSlice({
  name: "oAuthApp",
  initialState: initialOAuthAppState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getOAuthAppById
    oAuthAppFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.oAuthAppForEdit = action.payload.oAuthAppForEdit;
    },
    // findOAuthApp
    oAuthAppsFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // Set to default state actionLoading & error
    oAuthAppReload: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
  },
});
