export const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '50', value: 50 },
];

// default value untuk payment disabled sampai info berikutnya
export const initialFilter = {
    filter: {
        name: '',
        status: 'REQUEST',
        start_date: '',
        end_date: '',
        paymentType: 'EDC, TUNAI dan EDC + TUNAI'
    },
    pageNumber: 1,
    pageSize: 10,
};

export const collectStatusList = [
    { name: 'REQUEST COLLECT', value: 'REQUEST' },
    { name: 'APPROVED COLLECTED', value: 'APPROVED' },
];
// default value untuk payment disabled sampai info berikutnya
export const paymentTypeList = [
    { value: 'EDC, TUNAI dan EDC + TUNAI' },
    { value: 'TUNAI' },
    { value: 'EDC' },
    { value: 'EDC + TUNAI' },
]

export const badge = (status) => {
    let data = {};
    switch (status) {
        case 'VOID':
            data = { type: 'danger' };
            break;
        case 'REQUEST_VOID':
            data = { type: 'warning' };
            break;
        case 'SETTLE':
            data = { type: 'success' };
            break;
        default:
            data = { type: 'info' };

    }

    return data;
};
