import axios from "axios";
import { DonationURL } from "../../../../config/UrlEndPoint";

export const ThkChannelReportUrl = `${DonationURL}thk_channel_report`;

//Get By ID
export function getThkChannelReportById(thkChannelReportId) {
  return axios.get(`${ThkChannelReportUrl}/${thkChannelReportId}`);
}

// items => filtered/sorted result for table data
export function findThkChannelReport(queryParams) {
  return axios.post(`${ThkChannelReportUrl}/find`, queryParams);
}

// UPDATE => PUT: update the thkChannelReport on the server
export function updateThkChannelReport(thkChannelReport, uuid) {
  return axios.put(
    `${ThkChannelReportUrl}/update_template/${uuid}`,
    thkChannelReport
  );
}

export function createThkChannelReport(payload) {
  return axios.post(`${ThkChannelReportUrl}/`, payload);
}

export function findThkChannelReportDonation(payload) {
  return axios.post(
    `${ThkChannelReportUrl}/find_channel_report_donation`,
    payload
  );
}

export function getChannelBatchReport(payload) {
  return axios.post(`${ThkChannelReportUrl}/channel_batch_report`, payload);
}

export function deleteChannelBatchReport(payload) {
  const { channel_uuid, file_uuid } = payload;
  return axios.delete(
    `${ThkChannelReportUrl}/delete_channel_batch_report/${channel_uuid}/${file_uuid}`
  );
}
