import React, { useMemo } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import { useDoubleNameUIContext } from './DoubleNameUIContext';
import DoubleNameTable from './double-name-table/DoubleNameTable';
import { DoubleNameFilter } from './double-name-table/double-name-filter/DoubleNameFilter'
import { RoleChecker } from '../../../RoleChecker';
import { Divider } from '@material-ui/core';
import DoubleNameTableDetail from './double-name-table-detail/DoubleNameTableDetail';
import { DoubleNameFilterDetail } from './double-name-table-detail/double-name-filter-detail/DoubleNameFilterDetail';
import DividerWithText from '../../../../_metronic/layout/components/extras/DividerWithText';

export const DoubleNameCard = () => {

	const doubleNameUIContext = useDoubleNameUIContext();
	const doubleNameUIProps = useMemo(() => {
		return {
			newDoubleNameButtonClick: doubleNameUIContext.newDoubleNameButtonClick,
			selectedDonor: doubleNameUIContext.selectedDonor
		};
	}, [doubleNameUIContext]);

	return (
		<>
			<Card>
				<CardHeader title="Daftar Double Donatur Bedasarkan Nama">
					<CardHeaderToolbar>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<div className='row'>
						<div className='col-lg-12 mb-md-10'>
							<div className="mb-5">
								<DividerWithText>
									Daftar Donatur Double
								</DividerWithText>

							</div>
							<DoubleNameFilter />
							<DoubleNameTable />

						</div>
						<div className='col-lg-12 d-flex  mb-10 justify-content-center align-item-center'>
							<Divider orientation="horizontal" flexItem />
						</div>
						<div className='col-lg-12'>
							<div className="mb-5">

								<DividerWithText>
									Daftar Detail Donatur
								</DividerWithText>
							</div>
							{doubleNameUIProps.selectedDonor !== null ? <div>
								{/* <DoubleNameFilterDetail /> */}
								<DoubleNameTableDetail />

							</div> :
								<h1 className='text-center'>Harap Pilih Donatur Terlebih Dahulu</h1>}

						</div>
					</div>

				</CardBody>
			</Card>
			{/* <div className='row' >
				<div className='col-lg-6'>
					<Card>
						<CardHeader title="Daftar Double Donatur Bedasarkan Nama">
							<CardHeaderToolbar>
							</CardHeaderToolbar>
						</CardHeader>
						<CardBody>
							<DoubleNameFilter />
							<DoubleNameTable />

						</CardBody>
					</Card>
				</div>
			</div> */}
		</>

	);
};
