/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { currencyFormatter, toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { CircularProgress } from "@material-ui/core";
import Table from 'react-bootstrap/Table'

export function TileCustomListWidget({
  className,
  iconColor = "success",
  widgetHeight = "450px",
  title = "",
  data = [],
  loading = false,
  th1 = "",
  th2 = "",
  icon = toAbsoluteUrl("/media/svg/icons/Layout/Layout-Grid.svg")
}) {
  return (
    <>
      <div
        className={`card card-custom overflow-auto ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className={`card-body ${loading && "d-flex justify-content-center"} text-center`}>
          {
            loading ? <div className="text-center align-self-center"><CircularProgress /></div> :
              <div className="d-flex justify-content-center align-content-center">
                <div className="align-self-center">
                  <p
                    className="text-hover-primary text-center font-weight-bold font-size-h4"
                  >
                    {title}
                  </p>
                  <div className="overflow-auto">
                    <Table className="overflow-auto">
                      <thead>
                        <tr>
                          <th className="text-left">No</th>
                          <th className="text-left">{th1}</th>
                          <th className="text-right">{th2}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data.map(((item, index) => {
                            return <tr>
                              <td className="text-left">{Number(index) + 1}</td>
                              <td className="text-left">{item.name}</td>
                              <td className="text-right">{currencyFormatter(item.total_donasi)}</td>
                            </tr>
                          }))
                        }

                      </tbody>
                    </Table>
                  </div>

                </div>
              </div>

          }


        </div>
      </div>
    </>
  );
}
