import React from "react";
import { AolConfigUIProvider } from "./AolConfigUIContext";
import { AolConfigCard } from "./AolConfigCard";
import { Route } from "react-router-dom";

export function AolConfigPage({ history }) {
  const aolConfigUIEvents = {
    openAolConfigRequestDialog: (id, status) => {
      history.push(`/aolConfig/${id}/${status}`);
    },
  };

  return (
    <AolConfigUIProvider aolConfigUIEvents={aolConfigUIEvents}>
      <AolConfigCard />
    </AolConfigUIProvider>
  );
}
