import axios from 'axios';
import { MasterDataURL } from '../../../config/UrlEndPoint';

export const URL = `${MasterDataURL}dashboard`;

// Get Bank All For use Combobox data
export function getTotalPenerimaanDonasi(filter, branch) {
  return axios.post(`${URL}/total_penerimaan_donasi`, { date: filter, branch });
}
export function getTargetPenerimaanDonasi(filter, branch) {
  return axios.post(`${URL}/target_penerimaan_donasi`, { date: filter, branch });
}

export function getTotalDonatur(filter, branch) {
  return axios.post(`${URL}/total_donatur`, { date: filter, branch });
}


export function getTotalRetensiDonatur(filter, branch) {
  return axios.post(`${URL}/total_retensi_donatur`, { date: filter, branch });
}

export function getTotalDonationType(filter, branch) {
  return axios.post(`${URL}/total_donation_type`, { date: filter, branch });
}

export function getTotalDonationChannel(filter, branch) {
  return axios.post(`${URL}/total_channel_type`, { date: filter, branch });
}

export function getTotalDonationThisYear(filter, branch) {
  return axios.post(`${URL}/total_penerimaan_donasi_tahun_ini`, { branch: branch });
}

export function getTotalDonationThisDay(filter, branch) {
  return axios.post(`${URL}/total_penerimaan_donasi_hari_ini`, { branch: branch });
}

export function getTotalDonationThisWeek(filter, branch) {
  return axios.post(`${URL}/total_penerimaan_donasi_minggu_ini`, { branch: branch });
}

export function getTotalDonationThisMonth(filter, branch) {
  return axios.post(`${URL}/total_penerimaan_donasi_bulan_ini`, { branch: branch });
}

export function getTotalDonationProgram(filter, branch) {
  return axios.post(`${URL}/total_donation_program`, { date: filter, branch });
}

export function getTotalDonationBank(filter, branch) {
  return axios.post(`${URL}/total_donation_bank`, { date: filter, branch });
}
export function getTotalDonationEchannel(filter, branch) {
  return axios.post(`${URL}/total_donation_echannel`, { date: filter, branch });
}

export function getTotalDonationBranch(filter, branch) {
  return axios.post(`${URL}/total_donation_branch`, { date: filter, branch });
}

export function getTotalDonationCounter(filter, branch) {
  return axios.post(`${URL}/total_donation_counter`, { date: filter, branch });
}

export function getTotalDonorType(filter, branch) {
  return axios.post(`${URL}/total_donatur_type`, { date: filter, branch });
}

export function getTotalDonorIndividu(filter, branch) {
  return axios.post(`${URL}/top_donatur_individu`, { date: filter, branch });
}

export function getTotalDonorInstitusi(filter, branch) {
  return axios.post(`${URL}/top_donatur_institution`, { date: filter, branch });
}

export function getTotalDonorPremium(filter, branch) {
  return axios.post(`${URL}/top_donatur_premium`, { date: filter, branch });
}


export function getTotalDonorGender(filter, branch) {
  return axios.post(`${URL}/total_donatur_gender`, { date: filter, branch });
}



// export function getTotalDonorInstitusi(filter, branch) {
//   return axios.post(`${URL}/top_donatur_institution`, { date: filter, branch });
// }



export function getTotalNominalTransaksi(filter, branch) {
  return axios.post(`${URL}/nominal_trans_fav`, { date: filter, branch });
}







