import React from 'react';
import { Modal } from 'react-bootstrap';
import { CollectorDialogList } from './CollectorDialogList';
import { ConfirmForm } from './ConfirmForm';
import { DonationURL } from '../../../config/UrlEndPoint';


export const CollectorDialog = ({ id, show, onHide, status }) => {

    function printDiv() {
        var printContents = document.getElementById("printContents").innerHTML;
        var originalContents = document.body.innerHTML;

        document.body.innerHTML = printContents;

        window.print();
        window.location.reload()

        document.body.innerHTML = originalContents;
    }

    const printSetoranKeuangan = (collect_batch_uuid, status) => {
        window.open(`${DonationURL}collector/print/${collect_batch_uuid}/${status}`)
    }

    return (
        <Modal
            // size="lg"
            show={show}
            onHide={onHide}
            aria-labelledby="modal-sizes-title-lg"
            size="xl"
            centered={true}
        >
            <div id="printContents">
                <Modal.Header closeButton>
                    <Modal.Title id="modal-sizes-title-lg">Approval Collector</Modal.Title>
                </Modal.Header>

                <Modal.Body className="overlay overlay-block cursor-default">

                    <CollectorDialogList id={id} status={status} />
                    <br />

                </Modal.Body>
            </div>
            {/* {
                    status === 'REQUEST' && ( */}
            <ConfirmForm
                id={id} onHide={onHide}
                status={status}
                printDiv={printDiv}
                printSetoranKeuangan={printSetoranKeuangan}
            />
            )
            {/* } */}

        </Modal>

    );
};
