import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./DonationExportUIHelpers";

const DonationExportUIContext = createContext();

export function useDonationExportUIContext() {
  return useContext(DonationExportUIContext);
}

export const DonationExportUIConsumer = DonationExportUIContext.Consumer;

export function DonationExportUIProvider({ donationExportUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initDonationExport = {
    name: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initDonationExport,
  };

  return (
    <DonationExportUIContext.Provider value={value}>
      {children}
    </DonationExportUIContext.Provider>
  );
}
