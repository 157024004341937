/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import AutorenewIcon from "@material-ui/icons/Autorenew";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openEditPartnershipPage, allowEdit, openManagePartnershipPage }
) => (
  <div className="d-flex">
    {allowEdit && (
      <OverlayTrigger
        overlay={
          <Tooltip id="products-edit-tooltip">Kelola Partnership</Tooltip>
        }
      >
        <a
          className="btn btn-icon btn-light btn-hover-info btn-sm mx-3"
          onClick={() => openManagePartnershipPage(row.uuid)}
        >
          <span className="svg-icon svg-icon-md svg-icon-info">
            <AutorenewIcon />
          </span>
        </a>
      </OverlayTrigger>
    )}
    {allowEdit && (
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Edit Partnership</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => openEditPartnershipPage(row.uuid)}
        >
          {row.id}
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>
    )}
  </div>
);
