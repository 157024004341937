import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../modules/Donatur/_redux/donatur/donaturAction';
import * as uiHelpers from '../DonaturUIHelpers';

import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
} from '../../../../_metronic/_helpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../_metronic/_partials/controls';
import { useDonaturUIContext } from '../DonaturUIContext';
import { RoleChecker } from '../../../RoleChecker';

const DonaturTable = (props) => {
    // Donatur UI Context
    const donaturUIContext = useDonaturUIContext();
    const donaturUIProps = useMemo(() => {
        return {
            queryParams: donaturUIContext.queryParams,
            setQueryParams: donaturUIContext.setQueryParams,
            newDonaturButtonClick: donaturUIContext.newDonaturButtonClick,
            openEditDonaturPage: donaturUIContext.openEditDonaturPage,
            openNewDonaturMember: donaturUIContext.openNewDonaturMember,
        };
    }, [donaturUIContext]);

    // Getting curret state of branch list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.donaturs }),
        shallowEqual,
    );


    const { totalCount, entities, listLoading } = currentState;

    const dispatch = useDispatch();
    useEffect(() => {
        //Requset ke Donatur Action
        dispatch(actions.fetchDonaturs(donaturUIProps.queryParams));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [donaturUIProps.queryParams, dispatch]);

    const allowEditDonatur = RoleChecker("Edit Donatur", "detail_donatur")
    // Table columns
    const columns = [
        {
            dataField: 'donor_id',
            text: 'ID Donatur',
        },
        {
            dataField: 'name',
            text: 'Nama',
        },
        {
            dataField: 'hp',
            text: 'HP',
        },
        {
            dataField: 'email',
            text: 'Email',
        },
        {
            dataField: 'type',
            text: 'Tipe Donatur',
        },
        {
            dataField: 'is_active',
            text: 'Status',
            formatter: columnFormatters.StatusColumnFormatter
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                openEditDonaturPage: donaturUIProps.openEditDonaturPage,
                openNewDonaturMember: donaturUIProps.openNewDonaturMember,
                allowEditDonatur
            },
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: '100px',
            },
        },
    ];

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: donaturUIProps.queryParams.pageSize,
        page: donaturUIProps.queryParams.pageNumber,
    };

    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                bordered={false}
                                remote
                                keyField="uuid"
                                data={entities === null ? [] : entities}
                                columns={columns}
                                // defaultSorted={uiHelpers.defaultSorted}
                                onTableChange={getHandlerTableChange(
                                    donaturUIProps.setQueryParams,
                                )}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
};

export default DonaturTable;
