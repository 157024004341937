import { Field, Formik } from "formik";
import React from "react";
import { Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { Input, Select } from "../../../../_metronic/_partials/controls";

export default function DonaturInteractionsModal({
  show,
  onHide,
  onClick,
  initialValues,
  isLoading,
  downloadFile,
}) {
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => onClick(values)}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal show={show} onHide={onHide} size="lg">
              <Modal.Header>
                <Modal.Title>Tambah Interaksi Donatur</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="form-group row">
                  <div className="col">
                    <Select
                      name="source"
                      label="Sumber"
                      onChange={(e) =>
                        setFieldValue(e.target.name, e.target.value)
                      }
                    >
                      <option value="">--PILIH--</option>
                      <option value="KONTER">Konter</option>
                      <option value="SMS">SMS</option>
                      <option value="EMAIL">Email</option>
                      <option value="WA">WhatsApp</option>
                      <option value="TELEPON">Telepon</option>
                      <option value="LIVECHAT">LiveChat</option>
                      <option value="FB">FaceBook</option>
                      <option value="IG">Instagram</option>
                      <option value="TWITTER">Twitter</option>
                      <option value="ECHANNEL">E-Channel</option>
                      <option value="KANTOR KAS">Kantor Kas</option>
                      <option value="PENJEMPUTAN">Penjemputan</option>
                      <option value="KUNJUNGAN DONATUR">Kunjungan Donatur</option>
                      <option value="EVENT">Event/Kegiatan Dompet Dhuafa</option>
                      <option value="FEEDBACK SURVEY KURBAN">Feedback Survey Kurban</option>
                      <option value="FEEDBACK SURVEY LAYANAN">Feedback Survey Layanan</option>
                      <option value="FEEDBACK SURVEY RAMADHAN">Feedback Survey Ramadhan</option>
                      <option value="FEEDBACK SURVEY">Feedback Survey</option>
                    </Select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <label>Subject (Perihal)</label>
                    <Field
                      className="form-control"
                      name="subject"
                      placeholder="Masukkan Perihal"
                      type=""
                      component={Input}
                      onChange={(e) =>
                        setFieldValue(e.target.name, e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <label>Deskripsi</label>
                    <Field
                      className="form-control"
                      name="description"
                      placeholder="Masukkan Deskripsi"
                      type=""
                      component="textarea"
                      onChange={(e) =>
                        setFieldValue(e.target.name, e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col">
                    <input
                      label="Pilih File"
                      name="file"
                      placeholder="Masukkan Perihal"
                      type="file"
                      onChange={(e) =>
                        setFieldValue(e.target.name, e.target.files[0])
                      }
                    />
                  </div>
                </div>
                {initialValues.file && (
                  <>
                    <OverlayTrigger overlay={<Tooltip>Download File</Tooltip>}>
                      <a
                        onClick={() => downloadFile(initialValues)}
                        style={{ color: "blue" }}
                      >
                        {initialValues.file.filename}
                      </a>
                    </OverlayTrigger>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-primary"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <Spinner as="span" animation="border" size="sm" />
                      <span className="ml-2">Loading</span>
                    </>
                  ) : (
                    <>Simpan</>
                  )}
                </button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
}
