import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../../_metronic/_partials/controls";
import AsyncSelect from "react-select/async";
import ReactSelect from "react-select";
import { findDonaturs } from "../../../../Donatur/_redux/donatur/donaturCrud";
import { genOptionLoader } from "../../../../../../_metronic/_utils/option-loaders";
import { Form as BootsrapForm } from "react-bootstrap";
import { findChannels } from "../../../../MasterData/_redux/channels/channelsCrud";
import { getSelectorStyles } from "../../../../../../_metronic/_helpers/style-helpers";

// Validation schema
const PartnershipEditSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Nama partnership wajb diisi"),
  channel: Yup.string().required("Channel penerima wajb diisi"),
});

export function PartnershipEditForm({
  savePartnership,
  actionsLoading,
  partnership,
  options = {},
}) {
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={partnership}
        validationSchema={PartnershipEditSchema}
        onSubmit={(values) => savePartnership(values)}
      >
        {({
          handleSubmit,
          setFieldValue,
          handleChange,
          handleBlur,
          values,
          errors,
          touched,
        }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Nama</label>
                </div>
                <div className="col-lg-9">
                  <Field
                    name="name"
                    component={Input}
                    placeholder="Nama patnership"
                    customFeedbackLabel=" "
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>E-channel</label>
                </div>
                <div className="col-lg-9">
                  <AsyncSelect
                    name="channel"
                    defaultOptions={options.channel}
                    onBlur={handleBlur}
                    placeholder="Pilih channel"
                    loadOptions={genOptionLoader(
                      (inputValue) =>
                        findChannels({
                          filter: { name: inputValue, type: "ECHANNEL" },
                          pageNumber: 1,
                          pageSize: 50,
                        }),
                      "items",
                      ({ name: label, uuid }) => {
                        return {
                          label,
                          value: uuid,
                        };
                      }
                    )}
                    value={
                      values.channel && values.channel.uuid
                        ? {
                            label: values.channel.name,
                            value: values.channel.uuid,
                          }
                        : null
                    }
                    onChange={({ label: name, value: uuid }) => {
                      setFieldValue("channel", { uuid, name });
                    }}
                    styles={getSelectorStyles("channel", errors, touched)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Notes</label>
                </div>
                <div className="col-lg-9">
                  <textarea
                    name="notes"
                    className="form-control"
                    value={values.notes}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Notes..."
                  />
                </div>
              </div>
              {!values.uuid && (
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label>Periode</label>
                  </div>
                  <div className="col-lg-9">
                    <ReactSelect
                      name="thk_period"
                      placeholder={"Periode Thk"}
                      options={options.period}
                      value={
                        values.thk_period && values.thk_period.uuid
                          ? {
                              label: values.thk_period.name ?? "",
                              value: values.thk_period.uuid ?? "",
                            }
                          : null
                      }
                      onChange={({ label: name, value: uuid }) => {
                        setFieldValue("thk_period", {
                          uuid,
                          name,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Donatur</label>
                </div>
                <div className="col-lg-9">
                  <AsyncSelect
                    name="chName"
                    defaultOptions={options.donor}
                    placeholder={"Pilih donatur"}
                    loadOptions={genOptionLoader(
                      (inputValue) =>
                        findDonaturs({
                          filter: { name: inputValue, is_not_individual: true },
                          pageNumber: 1,
                          pageSize: 50,
                        }),
                      "items",
                      ({ name, uuid, donor_id }) => {
                        const label = `${name} - ${donor_id}`;

                        return {
                          label,
                          value: uuid,
                        };
                      }
                    )}
                    value={
                      values.donor && values.donor.uuid
                        ? {
                            label: values.donor.name,
                            value: values.donor.uuid,
                          }
                        : null
                    }
                    onChange={({ label: name, value: uuid }) => {
                      setFieldValue("donor", { uuid, name });
                    }}
                  />
                </div>
              </div>
              {!values.uuid && (
                <div className="form-group row">
                  <div className="col-lg-3">
                    <label>Pengaturan Donatur</label>
                    <label className="text-danger" style={{ fontSize: 12 }}>
                      Jika sudah tersimpan, data simpan donatur tidak bisa
                      diubah lagi
                    </label>
                  </div>
                  <div className="col-lg-9">
                    <div key="inline-radio" className="mb-3">
                      <BootsrapForm.Check
                        inline
                        label="Simpan Data Donatur"
                        name="group1"
                        type="radio"
                        id="inline-radio-1"
                        onChange={(e) => setFieldValue("donor_save", true)}
                        value={values.donor_save}
                        checked={values.donor_save ? true : false}
                      />
                    </div>
                    <div key="inline-radio" className="mb-3">
                      <BootsrapForm.Check
                        inline
                        label="Jangan Simpan Data Donatur"
                        name="group1"
                        type="radio"
                        id="inline-radio-2"
                        onChange={(e) => setFieldValue("donor_save", false)}
                        value={values.donor_save}
                        checked={values.donor_save ? false : true}
                      />
                    </div>
                  </div>
                </div>
              )}
            </Form>
            <div className="float-right">
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
