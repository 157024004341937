import React, { useState, useMemo } from 'react';

import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify'
import { toastOption } from "../../../../../_metronic/_helpers";
import { generatePrintConsolidationDonor } from '../../_redux/donatur/donaturCrud';
import moment from 'moment'
import { DateRangePicker } from 'react-dates';
import { useDonaturTrxUIContext } from '../../donatur-trx/DonaturTrxUIContext';


export function PrintDonaturTrxConsolidationExportDialog({ show, onHide }) {
    const donaturTrxUIContext = useDonaturTrxUIContext();
    const donaturTrxUIProps = useMemo(() => {
        return {
            queryParams: donaturTrxUIContext.queryParams,
        };
    }, [donaturTrxUIContext]);

    const [file_name, setFile_name] = useState('')
    const [errorFile, setErrorFile] = useState('')
    const [fileName, setFileName] = useState('')
    const [errorMessage, setErrorMessage] = useState("none")
    const [onFocused, setOnFocused] = useState(null)
    const [tanggal, setTanggal] = useState(
        {
            start_date: moment().subtract(3, 'months'),
            end_date: moment(),
        }
    )
    console.log(donaturTrxUIProps.queryParams);
    const generateExcel = async () => {
        let value = {}
        value.filter = donaturTrxUIProps.queryParams.filter
        value.file_name = file_name
        // value.start_date = tanggal.start_date
        // value.end_date = tanggal.end_date
        if (file_name.length > 0) {
            console.log(value);
            generatePrintConsolidationDonor(value).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${file_name}.pdf`); //or any other extension
                document.body.appendChild(link);
                link.click();
                toast.success("SUCCESS", toastOption);
            }).catch(error => {
                toast.error(error.response.data.message, toastOption)
            })
            onHide()
            setErrorFile('')
            setFile_name('')
        } else {
            setErrorFile("Nama File Harus Diisi")
        }
        // console.log(value);

    }
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="modal-sizes-title-lg">Export Data Donatur Untuk Konsolidasi</Modal.Title>
                </Modal.Header>


                <Modal.Body className="overlay overlay-block cursor-default">
                    <div className="form-group text-center">
                        <div className="form-group text-center">
                            <div className="col">
                                <p><span className="text-danger">Hasil Export akan di
                                    sesuaikan dengan pencarian yang sudah anda sesuaikan.</span> <br></br>Jika sudah
                                    yakin,
                                    harap masukkan nama file dan klik tombol Confirm</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <input type="text" name="file_name"
                                    value={file_name} placeholder="Masukkan Nama File" className="form-control"
                                    onChange={(e) => setFile_name(e.target.value)} />
                                <small id="helpId" className="form-text text-danger">{errorFile}</small>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate"
                    >
                        Cancel
                    </button>
                    <> </>
                    <button
                        type="submit"
                        onClick={() => generateExcel()}
                        className="btn btn-primary btn-elevate"
                    >
                        Confirm
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}