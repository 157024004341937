/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, toastOption} from "../../../../../_metronic/_helpers";
import {deleteParent} from '../../_redux/donatur/donaturCrud';
import {toast} from "react-toastify";

export const ActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex,
    {fetchData, parent_uuid}
) => {
    const deleteDonaturMember = async (val) => {
        const value = {
            donor_uuid: val.donor_uuid,
            parent_uuid: parent_uuid
        }
        await deleteParent(value).then((response) => {
            fetchData(parent_uuid)
            toast.success(response.data.message, toastOption)
        }).catch(error => {
            toast.error(error.response.data.message, toastOption)
        })
    }
    return (
        <>
            <OverlayTrigger
                overlay={<Tooltip id="products-delete-tooltip">Hapus Anggota</Tooltip>}
            >
                <a
                    className="btn btn-icon btn-light btn-hover-danger btn-sm"
                    onClick={() => {
                        if (window.confirm(`Menghapus ${row.donor.name} sebagai donatur member?`)) {
                            deleteDonaturMember(row)
                        }
                    }

                    }
                >
			<span className="svg-icon svg-icon-md svg-icon-danger">
			  <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
			</span>
                </a>
            </OverlayTrigger>
        </>
    );
}
