import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { useCheckerHeaderUIContext } from './CollectHeaderUIContext';

const prepareFilter = (queryParams, values) => {
    const { searchText } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};

    //add to Object filter
    filter.name = searchText;

    newQueryParams.filter = filter;
    return newQueryParams;
};


export function CollectFilter(props) {

    const checkerHeaderUIContext = useCheckerHeaderUIContext();
    const checkerHeaderrUIProps = useMemo(() => {
        return {
            setQueryParams: checkerHeaderUIContext.setQueryParams,
            queryParams: checkerHeaderUIContext.queryParams,
        };
    }, [checkerHeaderUIContext]);

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(checkerHeaderrUIProps.queryParams, values);
        if (!isEqual(newQueryParams, checkerHeaderrUIProps.queryParams)) {
            newQueryParams.pageNumber = 1;
            checkerHeaderrUIProps.setQueryParams(newQueryParams);
        }
    };

    return (
        <Formik initialValues={{ searchText: '' }} onSubmit={(values) => {
            applyFilter(values);
        }}
        >{({ values, handleSubmit, handleBlur, setFieldValue }) => (
            <form onSubmit={handleSubmit} className="form form-label-right">
                <div className="form-group row">
                    <div className="col-lg-4">
                        <input
                            type="text"
                            className="form-control"
                            name="searchText"
                            placeholder="Search"
                            onBlur={handleBlur}
                            value={values.searchText}
                            onChange={(e) => {
                                setFieldValue('searchText', e.target.value);
                                handleSubmit();
                            }}
                        />
                        <small className="form-text text-muted">
                            <b>Cari</b> Berdasarkan Name
                        </small>
                    </div>
                </div>
            </form>
        )}
        </Formik>
    );
}
