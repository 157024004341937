import React from 'react';
import DonaturCard from './DonaturCard';
import { DonaturUIProvider } from './DonaturUIContext';
import { Route } from "react-router-dom";
// import {DonaturExportDialog} from "./donatur-table/donatur-table-dialog/DonaturExportDialog";

export function DonaturPage({ history }) {
	const donaturUIEvents = {
		newDonaturButtonClick: () => {
			history.push('/donatur/new');
		},
		openEditDonaturPage: (id) => {
			history.push(`/donatur/${id}/edit`);
		},
		openNewDonaturMember: (parent_uuid, donatur_type) => {
			history.push(`/donatur/${parent_uuid}/${donatur_type}`);
		},
		openDetailDonationPage: (id) => {
			history.push(`/donasi/${id}/detail`);
		}
	};

	return (
		<DonaturUIProvider donaturUIEvents={donaturUIEvents}>
			<DonaturCard />
		</DonaturUIProvider>
	);
}