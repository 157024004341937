import * as requestFromServer from "./donaturCrud";
import { donaturSlice, callTypes } from "./donaturSlice";
import { actions as auth } from "../../../Auth/_redux/authRedux";
import { toastOption } from "../../../../../_metronic/_helpers/ToastHelpers";
import { toast } from "react-toastify";

const { actions } = donaturSlice;

export const fetchDonaturs = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDonaturs(queryParams)
    .then((response) => {
      const {
        data: { total_items, items },
      } = response.data;
      dispatch(actions.donatursFetched({ total_items, items }));
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDonatur = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.donaturFetched({ donaturForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDonaturById(id)
    .then((response) => {
      let { data: donatur } = response.data; // alias data -> donatur
      donatur.type = donatur.type ? donatur.type : "INDIVIDU";
      dispatch(actions.donaturFetched({ donaturForEdit: donatur }));
      return donatur;
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchChilds = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.childsFetched({ listChild: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getChilds(id)
    .then((response) => {
      dispatch(actions.childsFetched({ childsFetched: response.data.data }));
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchParents = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.parentsFetched({ listParent: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getParents(id)
    .then((response) => {
      dispatch(actions.parentsFetched({ parentsFetched: response.data.data }));
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchDonationHistory = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getDonationList(queryParams)
    .then((response) => {
      const {
        data: { total_items, items },
      } = response.data;
      dispatch(actions.donationHistorysFetched({ total_items, items }));
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createDonatur = (donaturForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createDonatur(donaturForCreation)
    .then((response) => {
      dispatch(actions.donatursReload);
      return response.data;
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const updateDonatur = (donatur) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateDonatur(donatur)
    .then((response) => {
      dispatch(actions.donatursReload);
      return response.data;
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const updateDonaturProfile = (donatur) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateDonaturProfile(donatur)
    .then((response) => {
      dispatch(actions.donatursReload);
      return response.data;
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const changePasswordDonatur = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .changePasswordDonatur(payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const migrateDonation = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .migrateDonation(payload)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createInteractionDonatur = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createInteractionDonor(payload)
    .then((response) => {
      dispatch(actions.donatursReload);
      return response.data;
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const updateInteractionDonor = (payload) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateInteractionDonor(payload)
    .then((response) => {
      dispatch(actions.donatursReload);
      return response.data;
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const findDonorInteraction = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findDonorInteraction(queryParams)
    .then((response) => {
      const {
        data: { total_items, items },
      } = response.data;
      dispatch(actions.donorInteractionFetch({ total_items, items }));
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getDonorInteraction = (donorId, interactionId) => (dispatch) => {
  if (!donorId && !interactionId) {
    return dispatch(actions.donaturFetched({ donaturForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getDonorInteractionById(donorId, interactionId)
    .then((response) => {
      let { data } = response.data;
      dispatch(actions.donorInteractionById({ donorInteractionForEdit: data }));
      return data;
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const deleteDonorInteraction = (donorId, interactionId) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteDonorInteraction(donorId, interactionId)
    .then((response) => {
      let { data } = response.data;
      dispatch(actions.donatursReload);
      return data;
    })
    .catch((error) => {
      let message = error.message;

      if (error.response) {
        const { data } = error.response;

        if (data) {
          message = data.message;
        }
      }

      toast.error(message);

      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
