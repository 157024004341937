const quotes = [
  "Ada hati yang menunggumu pulang, ada hati yang menunggumu kembali, ada hati yang kosong sejak kamu pergi.",
  "Ada hati yang menunggumu pulang, ada hati yang menunggumu kembali, ada hati yang kosong sejak kamu pergi.",
  "Ada orang yang rela menunggu dengan sabar hanya untuk sebuah kabar.",
  "Aku di sini bukanlah menunggu apa yang telah berlalu pergi, namun menanti apa yang aku yakini.",
  "Aku menunggumu dengan kesabaran sembari melantunkan sebuah do'a. Semoga apa yang kita impikan menjadi nyata.",
  "Aku menunggumu sampai waktu tak izinkan kita bersatu.",
  "Aku selalu menunggumu walau kau tak memintaku menunggu.",
  "Aku takkan lelah mengejar cintamu, jikalau aku lelah mungkin di saat itu aku tak ada di dunia lagi.",
  "Biarlah rasa ini bersemayam dalam hati. Dan jika kau kembali lalu bertanya padaku lagi. Rasa ini masih sama dan masih menunggumu kembali",
  "Bila rindu ini masih milikmu, ku hadirkan sebuah tanya untukmu, harus berapa lama aku menunggumu?",
  "Dalam setiap detik ku menunggu, ku simpan beribu-ribu rasa rindu untukmu.",
  "Kau adalah alasan mengapa aku tetap menunggu.",
  "Ketika seseorang menunggumu, tak berarti dia tak ada hal lain untuk dilakukan, itu hanya berarti tak ada yg penting selain dirimu.",
  "Lalu perlahan-lahan aku sadar bahwa kamu adalah salah satu alasan mengapa aku rela tetap menunggu dan bertahan.",
  "Lebih baik menunggu sebentar lagi untuk mendapatkan jodoh terbaik, daripada mendapatkan dengan cepat seseorang yang kelak melukai hatimu.",
  "Menunggu itu sakit, jenuh dan membosankan, namun kenapa ketika aku ingin berhenti menunggumu tapi hatiku memaksaku untuk tetap memperjuangkanmu.",
  "Menunggu tidak bisa diukur dalam angka, tapi, sampai orang itu merasa lebih tenang dan sampai dia kembali.",
  "Percayalah, aku hebat dalam hal menunggu.",
  "Sadarlah bahwa ada orang yang menunggumu dengan sabar karena rasa cintanya yang teramat besar.",
  "Sekuat kemampuanku untuk menunggu, seabadi itu pula harapanku padamu.",
  "Senyum di bibirku pasti tidak akan hilang, karena aku menunggumu di sini sampai kau berada di sisiku.",
  "Teruslah berlari, dan jika kamu lelah menolehlah ke belakang. Kamu akan melihat aku yang selalu menunggumu.",
  "Hidup itu bukan tentang menunggu badai berlalu, tapi tentang bagaimana belajar menari dalam hujan.",
  "Jika kita tidak mudah menyerah, maka kita sudah dekat sekali dengan kesuksesan. Karena di dunia ini, ada dua orang yang susah sekali dikalahkan. Orang yang sabar dan orang yang tidak mudah menyerah.",
  "Kadang diam dan menunggu lebih bernilai dari bergerak tanpa arah.",
  "Kesabaran bukan berarti diam tak bergerak saat ditimpa musibah. Tapi sabar adalah aktif bergerak mencari kebaikan saat musibah datang.",
  "Kesabaran bukan tentang berapa lama kamu bisa menunggu, tapi seberapa baik kamu bersikap saat sedang menunggu.",
  "Kesempatan terbaik selalu datang saat sabar menunggu.",
  "Kesuksesan datang pada orang yang bergerak cepat ketika ia sedang menunggu.",
  "Ketika bicara menunggu, itu bukan tentang berapa jam, hari dan bulan. Kita bicara tentang titik di mana kita akhirnya memutuskan untuk percaya.",
  "Orang yang tidak sabar akan menunggu dua kali.",
  "Para pejuang yang baik akan menempatkan diri mereka di luar kemungkinan kekalahan, dan menunggu kesempatan untuk mengalahkan musuh.",
  "Pohon masalah selalu menyembunyikan buah yang manis. Kadang kita harus menunggu buah itu matang dan jatuh agar dapat merasakan betapa manisnya ia.",
  "Semua hal datang pada waktu yang tepat bagi mereka yang bisa menunggu.",
  "Terkadang dalam banyak keterbatasan, kita harus bersabar menunggu rencana terbaik datang, sambil terus melakukan apa yang bisa dilakukan.",
  "Untuk hati yang sedang diuji kesabaran, itu memang pahit pada awalnya. Namun percayalah, ia akan manis di penghujungnya.",
  "Waktu terkadang terlalu lambat bagi mereka yang menunggu, terlalu cepat bagi yang takut, terlalu panjang bagi yang gundah, dan terlalu pendek bagi yang bahagia.",
  "Aku akan menunggumu, seumpama hujan yang menunggu jatuh di atas tanah-tanah kering.",
  "Aku tidak pernah keberatan menunggu siapapun berapa lama pun selama aku mencintainya.",
  "Apapun yang kau katakan,bagaimanapun kau menolaknya, cinta akan tetap berada di sana,menunggumu mengakui keberadaannya.",
  "Cinta itu memang fitrah, tetapi jika bukan pada tempat dan saat yang tepat, ia harus rela menunggu hingga waktu itu tiba.",
  "Jangan buru-buru dalam cinta, karena itu tidak akan pernah habis. Biarkan cinta yang mengetuk pintu hatimu, sehingga saat kau mulai merasakannya, kau tahu itu nyata.",
  "Jika seseorang tetap menunggu saat kita pergi tanpa kabar. Maka dia berhak ditunggu, pun jika itu tiada pasti kembali.",
  "Kau jangan tergesa-gesa, tunggulah pelan-pelan. Emas murni tidak takut ditempa dengan api. Cinta tidak takut menunggu.",
  "Percayalah, Tuhan menciptakan kita berpasang-pasangan. Kita tidak perlu terlalu khawatir. Menurutku, kita hanya perlu menunggu saat yang tepat untuk cinta datang.",
  "Pria mempercayai Tuhan dengan mempertaruhkan penolakan. Wanita mempercayai Tuhan dengan menunggu.",
  "Setelah perpisahan, menunggu kamu itu tak ubahnya putaran nasib. Mungkin, tidak, mungkin, tidak, mungkin. Biarkan saja, setidaknya aku masih bisa menunggu.",
  "Tiap-tiap kisah cinta memiliki satu kehebatan. Dan kisah penantian semakin hebat karena kesabaran.",
  "Aku mencari segala bentuk rezeki, tapi tidak menemukan rezeki yang lebih baik daripada sabar.",
  "Bersabarlah terhadap apa yang menimpa kamu.",
  "Dan bersabarlah kamu, sesungguhnya janji Allah adalah benar.",
  "Dan bersabarlah, karena sesungguhnya Allah tidak menyia-nyiakan pahala orang yang berbuat kebaikan.",
  "Dan ketahuilah, sesungguhnya kemenangan itu beriringan dengan kesabaran. Jalan keluar beriringan dengan kesukaran. Dan sesudah kesulitan pasti akan datang kemudahan.",
  "Kesabaran itu ada dua macam: sabar atas sesuatu yang tidak kau ingin dan sabar menahan diri dari sesuatu yang kau ingini.",
  "Memang sangat sulit untuk bersabar, tetapi menyia-nyiakan pahala dari kesabaran itulah yang lebih buruk.",
  "Petunjuk tidak bisa dicapai kecuali dengan pengetahuan, dan arah tujuan yang benar tidak bisa dituju kecuali dengan kesabaran.",
  "Sesungguhnya kesabaran yang sebenarnya adalah pada saat goncangan yang pertama.",
  "Allah tidak suka pada manusia yang hanya menunggu tanpa berusaha.",
  "Jika Allah membuatmu menunggu, maka bersiaplah untuk menerima lebih dari apa yang kamu minta.",
  "Sabar memiliki dua sisi, sisi yang satu adalah sabar, sisi yang lain adalah bersyukur kepada Allah.",
  "Untuk mendapatkan apa yang kamu suka, pertama kamu harus sabar dengan apa yang kamu benci."
]


const quotesRandom = () => {
  return quotes[Math.ceil(Math.random() * 60)]
}


module.exports = { quotesRandom }