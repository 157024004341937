import React, { createContext, useCallback, useContext, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilterDonasi } from '../DonaturUIHelpers';

const DonaturTrxUIContext = createContext();

export function useDonaturTrxUIContext() {
	return useContext(DonaturTrxUIContext);
}

export const DonaturTrxUIConsumer = DonaturTrxUIContext.Consumer;

export function DonaturTrxUIProvider({ donaturUuid, children }) {
	initialFilterDonasi.filter.user_uuid = donaturUuid
	const [queryParams, setQueryParamsBase] = useState(initialFilterDonasi);
	const [openConsolidationDialog, setOpenConsolidationDialog] = useState(false);
	const setQueryParams = useCallback((nextQueryParams) => {
		setQueryParamsBase((prevQueryParams) => {
			if (isFunction(nextQueryParams)) {
				nextQueryParams = nextQueryParams(prevQueryParams);
			}

			if (isEqual(prevQueryParams, nextQueryParams)) {
				return prevQueryParams;
			}

			return nextQueryParams;
		});
	}, []);

	const closeConsolidationDialog = () => {
		setOpenConsolidationDialog(false)
	}




	const value = {
		queryParams,
		setQueryParams,
		setQueryParamsBase,
		openConsolidationDialog,
		closeConsolidationDialog,
		setOpenConsolidationDialog
	};

	return (
		<DonaturTrxUIContext.Provider value={value}>
			{children}
		</DonaturTrxUIContext.Provider>
	);
}
