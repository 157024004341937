import { createSlice } from '@reduxjs/toolkit';

const initialUserState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    userForEdit: undefined,
    lastError: null,
};

export const callTypes = {
    list: 'list',
    action: 'action',
};

export const userSlice = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
                state.actionsLoading = false;
            } else {
                state.listLoading = false;
                state.actionsLoading = true;
            }
        },

        // getUserById
        userFetched: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.userForEdit = action.payload.userForEdit;
        },
        // findUser
        usersFetched: (state, action) => {
            const { total_items, items } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = items;
            state.totalCount = total_items;
        },
        // Set to default state actionLoading & error
        userReload: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
        },

    },
});

