/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import AutorenewIcon from "@material-ui/icons/Autorenew";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openEditPeriodTemplatePage, allowEdit, openManagePeriodTemplatePage }
) => (
  <div className="d-flex">
    {allowEdit && (
      <a
        className="mx-3"
        style={{ textDecoration: "underline", textDecorationColor: "blue" }}
        onClick={() => openEditPeriodTemplatePage(row.uuid, "file_report_bg")}
      >
        Template Laporan
      </a>
    )}
    {allowEdit && (
      <a
        className="mx-3"
        style={{ textDecoration: "underline", textDecorationColor: "blue" }}
        onClick={() =>
          openEditPeriodTemplatePage(row.uuid, "file_certificate_bg")
        }
      >
        Template Sertifikat
      </a>
    )}
  </div>
);
