import React, { useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../_metronic/_helpers';
import { CheckerRequestForm } from './CheckerRequestForm';
import { CheckerRequestDialogHeader } from './CheckerRequestDialogHeader';
import * as actions from '../_redux/checkerAction';
import { useCheckerUIContext } from '../CheckerUIContext';
import { CheckerRequestDialogList } from './CheckerRequestDialogList';
import { DonationURL } from '../../../config/UrlEndPoint';

export const CheckerRequestDialog = ({ settle_batch_uuid, show, onHide, status_checker, history }) => {
	const checkerUIContext = useCheckerUIContext();
	const checkerUIProps = useMemo(() => {
		return {
			queryParams: checkerUIContext.queryParams,
		}
	}, [checkerUIContext])

	const dispatch = useDispatch();


	// FUNGSI UPLOAD FORM DARI CHECKER REQUEST FORM
	const approveChecker = () => {
		let data = {
			settle_batch_uuid: settle_batch_uuid,
			// status_checker: "SETTLE",
			status_checker: "APPROVED_CHECK", // status digunakan untuk di inputkan ke sub page check
		}

		dispatch(actions.updateStatusChecker(data)).then((response) => {
			toast.success(response.message, toastOption);
			dispatch(actions.fetchCheckers(checkerUIProps.queryParams)).then(() => onHide());
		});
	};
	const rejectChecker = (checker) => {
		let data = {
			settle_batch_uuid: settle_batch_uuid,
			status_checker: "REJECT",
			reason: checker.reason,
		}
		dispatch(actions.updateStatusChecker(data)).then((response) => {
			toast.success(response.message, toastOption);
			dispatch(actions.fetchCheckers(checkerUIProps.queryParams)).then(() => onHide());
		});
	};

	const { currentState } = useSelector(
		(state) => ({ currentState: state.checkers }),
		shallowEqual,
	);

	const { checkerDetail, error } = currentState

	if (error) {
		toast.error(error, toastOption)
	}

	useEffect(() => {
		// DATA UNTUK TAMPIL DI REQUEST FORM untuk tabel
		dispatch(actions.fetchCheckerDetail(settle_batch_uuid, status_checker))
	}, [settle_batch_uuid, status_checker, dispatch]);


	// function printDiv() {
	// 	var printContents = document.getElementById("printContents").innerHTML;
	// 	var originalContents = document.body.innerHTML;

	// 	document.body.innerHTML = printContents;

	// 	window.print();
	// 	// history.push("/checker");
	// 	window.location.reload()

	// 	document.body.innerHTML = originalContents;
	// }

	const printDiv = (settle_batch_uuid, status) => {
		window.open(`${DonationURL}checker/print/${settle_batch_uuid}/${status}`)
	}
	const printCash = (settle_batch_uuid, status) => {
		window.open(`${DonationURL}checker/print/cash/${settle_batch_uuid}/${status}`)
	}
	const printEdc = (settle_batch_uuid, status) => {
		window.open(`${DonationURL}checker/print/edc/${settle_batch_uuid}/${status}`)
	}
	const printGoods = (settle_batch_uuid, status) => {
		window.open(`${DonationURL}checker/print/goods/${settle_batch_uuid}/${status}`)
	}
	const printPartner = (settle_batch_uuid, status) => {
		window.open(`${DonationURL}checker/print/partner/${settle_batch_uuid}/${status}`)
	}

	return (
		<Modal
			aria-labelledby="example-modal-sizes-title-lg"
			show={show}
			onHide={onHide}
			size="xl"
			centered={true}
		>
			<div id="printContents">

				<CheckerRequestDialogHeader />
				<CheckerRequestDialogList
					checker={checkerDetail || []}
					status_checker={status_checker}
					approve={approveChecker}
					reject={rejectChecker}
					onHide={onHide}
					printDiv={printDiv}
					printCash={printCash}
					printEdc={printEdc}
					printGoods={printGoods}
					printPartner={printPartner}
				/>
			</div>


			{/* {(status_checker && status_checker !== 'SETTLE') && ( */}
			{/* <CheckerRequestForm
				// saveChecker={ saveChecker }
				status_checker={status_checker}
				approve={approveChecker}
				reject={rejectChecker}
				onHide={onHide}
				printDiv={printDiv}
			/> */}
			{/* )} */}

		</Modal>
	);
};
