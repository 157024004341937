import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { useDonaturUIContext } from '../DonaturUIContext';
import { donaturTypeList, initialFilter } from '../DonaturUIHelpers'
// import moment from "moment";
import { getBranchAll } from '../../MasterData/_redux/branchs/branchCrud'
import { DateRangePicker } from "react-dates";
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { letterChoice, } from '../DonaturUIHelpers'
import moment from 'moment'
import { toast } from 'react-toastify';

const prepareFilter = (queryParams, values) => {
    const { name, type, kd_cc, email, hp, city, provinsi, start_date, end_date, branch, is_letters, donor_id } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};

    //add to Object filter
    filter.name = name;
    filter.type = type
    filter.kd_cc = kd_cc
    filter.email = email
    filter.hp = hp
    filter.city = city
    filter.provinsi = provinsi
    filter.start_date = start_date ? start_date : ''
    filter.end_date = end_date ? end_date : ''
    filter.branch = branch
    filter.is_letters = is_letters
    filter.donor_id = donor_id ?? '';
    filter.filterCabang = true

    newQueryParams.filter = filter;
    return newQueryParams;
};

const DonaturFilter = ({ listLoading, onChangeStatus }) => {
    // Branchs UI Context
    const donaturUIContext = useDonaturUIContext();
    const donaturUIProps = useMemo(() => {
        return {
            setQueryParams: donaturUIContext.setQueryParams,
            queryParams: donaturUIContext.queryParams,
        };
    }, [donaturUIContext]);

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(donaturUIProps.queryParams, values);
        if (!isEqual(newQueryParams, donaturUIProps.queryParams)) {
            newQueryParams.pageNumber = 1;
            donaturUIProps.setQueryParams(newQueryParams);
        }
    };

    // initial data by local storage
    const initFilter = JSON.parse(localStorage.getItem('dataFilterDono'));

    const [branchList, setBranchList] = useState([])
    const [isReset, setIsReset] = useState(false);
    const [isSearch, setIsSearch] = useState(false);

    //UNTUK DATERANGE
    const [onFocused, setOnFocused] = useState(null)
    const [tanggal, setTanggal] = useState(
        {
            start_date: initFilter?.start_date ? moment(initFilter.start_date) : moment().subtract(30, 'days'),
            end_date: initFilter?.end_date ? moment(initFilter.end_date) : moment(),
        }
    );
    const fetchBranch = async (value) => {
        const result = await getBranchAll()
        setBranchList(result.data.data)
    }

    // get localStorage 
    const setDataByLocalStorage = (dataValues) => {
        if (dataValues.kd_cc) {
            initialFilter.filter.kd_cc = dataValues.kd_cc;
        }

        if (dataValues.donor_id) {
            initialFilter.filter.donor_id = dataValues.donor_id;
        }

        if (dataValues.name) {
            initialFilter.filter.name = dataValues.name;
        }

        if (dataValues.email) {
            initialFilter.filter.email = dataValues.email;
        }

        if (dataValues.start_date && dataValues.end_date) {
            initialFilter.filter.start_date = dataValues.start_date;
            initialFilter.filter.end_date = dataValues.end_date;
        }

        if (dataValues.hp) {
            initialFilter.filter.hp = dataValues.hp;
        }

        if (dataValues.city) {
            initialFilter.filter.city = dataValues.city;
        }

        if (dataValues.provinsi) {
            initialFilter.filter.provinsi = dataValues.provinsi;
        }

        if (dataValues.branch) {
            initialFilter.filter.branch = dataValues.branch;
        }

        if (dataValues.type) {
            initialFilter.filter.type = dataValues.type;
        }

        if (dataValues.is_letters) {
            initialFilter.filter.is_letters = dataValues.is_letters;
        }

        return initialFilter.filter;
    }
    
    // reset filter
    const resetFilterValue = (setFieldValue) => {
        setTanggal({
            start_date: moment().subtract(1, 'months'),
            end_date: moment()
        })
        setFieldValue("start_date", moment().subtract(1, 'months'));
        setFieldValue("end_date", moment());
        setFieldValue('donor_id', '');
        setFieldValue('kd_cc', '');
        setFieldValue('name', '');
        setFieldValue('email', '');
        setFieldValue('hp', '');
        setFieldValue('city', '');
        setFieldValue('provinsi', '');
        setFieldValue('branch', '');
        setFieldValue('type', '');
        setFieldValue('is_letters', '');
    }

    useEffect(() => {
        if (initFilter) {
            let dataFilter = setDataByLocalStorage(initFilter);
            onChangeStatus(true);
            applyFilter(dataFilter);   
        }

    },[]);

    useEffect(() => {
        fetchBranch()
    }, [])

    return (
        <Formik initialValues={initialFilter.filter} onSubmit={(values) => {
            let dataFilter = values
                if (isSearch) {
                    localStorage.setItem('dataFilterDono', JSON.stringify(values));
                    dataFilter = JSON.parse(localStorage.getItem('dataFilterDono'))
                }

                if (isReset) {
                    setIsReset(false);
                }

                applyFilter(dataFilter);
        }}
        >{({ values, handleSubmit, handleBlur, setFieldValue }) => (
            <>
                <form onSubmit={handleSubmit} className="form form-label-right">
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <DateRangePicker
                                small={true}
                                startDate={tanggal.start_date}
                                endDate={tanggal.end_date}
                                onDatesChange={({ startDate, endDate }) => {
                                    setTanggal({
                                        start_date: startDate,
                                        end_date: endDate
                                    })
                                    setFieldValue('start_date', startDate)
                                    setFieldValue('end_date', endDate)

                                }}
                                focusedInput={onFocused}
                                onFocusChange={focusedInput => setOnFocused(focusedInput)}
                                numberOfMonths={1}
                                isOutsideRange={() => false}
                                showClearDates={true}
                                startDateId="start_date"
                                endDateId="end_date"
                                hideKeyboardShortcutsPanel={true}
                            />
                            <small className="form-text text-muted ">
                                <b> hanya untuk export maks range 1 bulan</b>
                            </small>
                        </div>
                        <div className="col-lg-4">
                            <input
                                type="text"
                                className="form-control"
                                name="donor_id"
                                placeholder="Contoh: 322111"
                                onBlur={handleBlur}
                                defaultValue={initFilter?.donor_id ?? ''}
                                value={values.donor_id}
                                onChange={(e) => {
                                    setFieldValue('donor_id', e.target.value);
                                }}
                            />
                            <small className="form-text text-muted">
                                <b>Cari</b> ID Donatur
                            </small>
                        </div>
                        <div className="col-lg-4">
                            <input
                                type="text"
                                className="form-control"
                                name="kd_cc"
                                placeholder="Contoh: 08832"
                                onBlur={handleBlur}
                                defaultValue={initFilter?.kd_cc ?? ''}
                                value={values.kd_cc}
                                onChange={(e) => {
                                    setFieldValue('kd_cc', e.target.value);
                                }}
                            />
                            <small className="form-text text-muted">
                                Cari Berdasarkan <b>Kode CC</b>
                            </small>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                placeholder="Contoh: Hamba Allah"
                                onBlur={handleBlur}
                                defaultValue={initFilter?.name ?? ''}
                                value={values.name}
                                onChange={(e) => {
                                    setFieldValue('name', e.target.value);
                                }}
                            />
                            <small className="form-text text-muted">
                                Cari Berdasarkan <b>Nama Donatur</b>
                            </small>
                        </div>
                        <div className="col-lg-4">
                            <input
                                type="text"
                                className="form-control"
                                name="email"
                                placeholder="Contoh: fulan@fulan.com"
                                onBlur={handleBlur}
                                defaultValue={initFilter?.email ?? ''}
                                value={values.email}
                                onChange={(e) => {
                                    setFieldValue('email', e.target.value);

                                }}
                            />
                            <small className="form-text text-muted">
                                Cari berdasarkan <b>Email</b>
                            </small>
                        </div>
                        <div className="col-lg-4">
                            <input
                                type="text"
                                className="form-control"
                                name="hp"
                                placeholder="Contoh: 081281..."
                                onBlur={handleBlur}
                                defaultValue={initFilter?.hp ?? ''}
                                value={values.hp}
                                onChange={(e) => {
                                    setFieldValue('hp', e.target.value);

                                }}
                            />
                            <small className="form-text text-muted">
                                Cari berdasarkan <b>Nomor Hp</b>
                            </small>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <input
                                type="text"
                                className="form-control"
                                name="city"
                                placeholder="Contoh: bandung"
                                onBlur={handleBlur}
                                defaultValue={initFilter?.city ?? ''}
                                value={values.city}
                                onChange={(e) => {
                                    setFieldValue('city', e.target.value);

                                }}
                            />
                            <small className="form-text text-muted">
                                Cari Berdasarkan <b>Nama Kota</b>
                            </small>
                        </div>
                        <div className="col-lg-4">
                            <input
                                type="text"
                                className="form-control"
                                name="provinsi"
                                placeholder="Contoh: Jawa Barat"
                                onBlur={handleBlur}
                                defaultValue={initFilter?.provinsi ?? ''}
                                value={values.provinsi}
                                onChange={(e) => {
                                    setFieldValue('provinsi', e.target.value);

                                }}
                            />
                            <small className="form-text text-muted">
                                Cari Berdasarkan <b>Nama Provinsi</b>
                            </small>
                        </div>
                        <div className="col-lg-4">
                            <select
                                className="form-control"
                                placeholder="Nama Cabang"
                                name="branch"
                                onBlur={handleBlur}
                                defaultValue={initFilter?.branch ?? ''}
                                value={values.branch}
                                onChange={(e) => {
                                    setFieldValue('branch', e.target.value);

                                }}
                            >
                                <option value=''>ALL</option>
                                ,
                                {branchList.map(list =>
                                    <option key={list.uuid} value={list.uuid}>{list.name}</option>,
                                )}
                            </select>
                            <small className="form-text text-muted">
                                Cari berdasarkan <b>Cabang</b>
                            </small>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <select
                                className="form-control"
                                placeholder="Filter Type"
                                name="type"
                                onBlur={handleBlur}
                                defaultValue={initFilter?.type ?? ''}
                                value={values.type}
                                onChange={(e) => {
                                    setFieldValue('type', e.target.value);

                                }}
                            >
                                <option value=''>ALL</option>
                                ,
                                {donaturTypeList.map(list =>
                                    <option key={list.value} value={list.value}>{list.name}</option>,
                                )}
                            </select>
                            <small className="form-text text-muted">
                                Cari berdasarkan <b>Tipe Donatur</b>
                            </small>
                        </div>
                        <div className="col-lg-4">
                            <select
                                className="form-control"
                                placeholder="Filter Type"
                                name="is_letter"
                                onBlur={handleBlur}
                                defaultValue={initFilter?.is_letters ?? ''}
                                value={values.is_letters}
                                onChange={(e) => {
                                    setFieldValue('is_letters', e.target.value);

                                }}
                            >
                                <option value="">ALL</option>
                                {letterChoice.map(row =>
                                    <option key={row.name} value={row.value}>{row.name}</option>
                                )}
                            </select>
                            <small className="form-text text-muted">
                                Cari Berdasarkan <b>Penerimaan Hard Copy</b>
                            </small>
                        </div>
                    </div>
                </form>
                <button
                    onClick={() => {
                        setIsSearch(true);
                        onChangeStatus(true);
                        handleSubmit();
                    }}
                    className="btn btn-primary btn-elevate float-right mb-20"
                >
                    Cari
                </button>
                <button
                        onClick={() => {  
                            // reset data field
                            resetFilterValue(setFieldValue);
                            
                            // reset local storege
                            localStorage.removeItem('dataFilterDono');
                            setIsReset(true);
                            setIsSearch(false);
                            onChangeStatus(false);

                            handleSubmit("")
                            toast.success('Reset Filter Successfully');

                        }}
                        className="btn btn-warning btn-elevate float-right mb-20 mr-3"
                    >
                        Reset Pencarian
                    </button>
                <hr className="mt-25" />
            </>
        )}
        </Formik>

    );
}
    ;

export default DonaturFilter;

