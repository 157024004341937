import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  toAbsoluteUrl,
} from "../../../../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination,
} from "../../../../../../../_metronic/_partials/controls";
import { sizePerPageList } from "../../PartnershipUIHelpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchDonations } from "../../../../../Donation/_redux/donation/donationAction";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { getThkPartnershipBatchById } from "../../../_redux/partnershipAction";

const initQueryParams = {
  pageNumber: 1,
  pageSize: 10,
  filter: {},
};

export default function DetailImportBatch({
  match: {
    params: { batch_id },
  },
  history,
}) {
  const [queryParams, setQueryParams] = useState(initQueryParams);

  const dispatch = useDispatch();

  const { donationState, partnershipBatchEdit } = useSelector(
    (state) => ({
      donationState: state.donations,
      partnershipBatchEdit: state.thkPartnership.partnershipBatchEdit,
    }),
    shallowEqual
  );
  const { totalCount, entities, listLoading, error } = donationState;

  function fetchDonationPartnership() {
    dispatch(
      fetchDonations({
        ...queryParams,
        filter: {
          ...queryParams.filter,
          thk_partnership_batch_uuid: batch_id,
        },
      })
    );
  }

  function fetchPartnershipBatch() {
    dispatch(getThkPartnershipBatchById(batch_id));
  }

  useEffect(() => {
    fetchDonationPartnership();
    fetchPartnershipBatch();
  }, [queryParams, batch_id]);

  function handleClickDetailDonation(uuid) {
    history.push(`/donasi/${uuid}/detail`);
  }

  function handleBack() {
    history.goBack();
  }

  // Table columns
  const columns = [
    {
      dataField: "thk_partnership_batch_hid",
      text: "Batch ID",
    },
    {
      dataField: "donation_items[0].name_1",
      text: "Atas Nama",
    },
    {
      dataField: "donation_hid",
      text: "ID Donasi",
    },
    {
      dataField: "notes",
      text: "ID Kurban",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      text: "Actions",
      formatter: (_, row) => {
        return (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Detail Donasi</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => handleClickDetailDonation(row.uuid)}
              >
                {row.id}
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
          </>
        );
      },
      formatExtraData: {},
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  return (
    <Card>
      <CardHeader
        title={`Detail Batch: ${
          entities ? entities[0].thk_partnership_batch_hid : ""
        }`}
      >
        <CardHeaderToolbar>
          <button className="btn btn-secondary" onClick={handleBack}>
            Kembali
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="form-group row">
          <div className="col-lg-4">
            <label>Hewan Campaign</label>
            <input
              className="form-control"
              value={partnershipBatchEdit?.thk_livestock_campaign?.name}
              disabled
            />
          </div>
        </div>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={listLoading}
                paginationProps={paginationProps}
              >
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  bordered={false}
                  remote
                  keyField="uuid"
                  data={entities ? entities : []}
                  columns={columns}
                  // defaultSorted={uiHelpers.defaultSorted}
                  onTableChange={getHandlerTableChange(setQueryParams)}
                  // selectRow={getSelectRow({
                  //   entities,
                  //   ids: productsUIProps.ids,
                  //   setIds: productsUIProps.setIds,
                  // })}
                  {...paginationTableProps}
                >
                  <PleaseWaitMessage entities={entities} />
                  <NoRecordsFoundMessage entities={entities} />
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
}
