import moment from "moment";

export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];

export const initialFilter = {
  filter: {
    start_date: moment().subtract(3, "months"),
    end_date: moment(),
    donation_hid: "",
    donor_id: "",
    donor_name: "",
    donor_city: "",
    donation_status: "",
    channel_type: "",
    donation_type: "",
    donation_program: "",
    donation_project: "",
    donation_department: "",
    donation_reconcile_status: "",
    donation_reconcile_hid: "",
    user_input: "",
  },
  pageNumber: 1,
  pageSize: 100,
};

export const DONATION_STATUS = [
  { value: "SETTLE", name: "SETTLE" },
  { value: "COLLECT", name: "COLLECT" },
  { value: "DRAFT", name: "DRAFT" },
  { value: "RECOG", name: "RECOG" },
  { value: "VOID", name: "VOID" },
  { value: "REQUEST_VOID", name: "REQUEST VOID" },
  { value: "PENDING", name: "PENDING" },
  { value: "PAID", name: "PAID" },
];

export const DONATION_HEADERS_FINANCE_COLUMN = [
  "Trx Date",
  "Donation Status",
  "Donation ID",
  "Fund Type",
  "Program Donasi",
  "Project Donasi",
  "Channel Type",
  "Nama Bank",
  "Channel Name",
  "Pesan Bank",
  "Invoice ID",
  "Donation Amount",
  "Tanggal Input",
  "Department",
  "Bentuk Donasi",
];

export const DONATION_HEADERS_FUNDRAISER_COLUMN = [
  "Tanggal Donasi",
  "Donation Status",
  "Donation Hid",
  "Tipe Donatur",
  "Id Donatur",
  "Nama Donatur",
  "Alamat",
  "Hp",
  "Email",
  "Fund Type",
  "Kurban Kategori",
  "Kurban Name",
  "Program Donasi",
  "Proyek Donasi",
  "Channel Type",
  "Bank",
  "Channel Name / No Rekening",
  "Pesan Bank",
  "Invoice ID",
  "Donasi",
  "Department",
  "Bentuk Donasi"
];

export function getDataDonationColumn(data, exportType) {
  let returnData = [];
  if (exportType == "export_finance") {
    returnData = [
      data.donation_trx_date,
      data.donation_status,
      data.donation_hid,
      data.donation_fund_type,
      data.donation_fund_type === 'KURBAN' ? data.thk_livestock_name : data.program_name,
      data.donation_fund_type === 'KURBAN' ? data.thk_livestock_campaign_name :data.project_name,
      data.channel_type,
      data.bank_name,
      data.channel_name,
      data.donation_bank_message,
      data.donation_channel_trx_id,
      data.donation_item_amount,
      moment(data.donation_created_at).format("YYYY-MM-DD"),
      data.department_name,
      data.donation_shape
    ];
  } else if (exportType == "export_fundraiser") {
    returnData = [
      data.donation_trx_date,
      data.donation_status,
      data.donation_hid,
      data.donor_type,
      data.donor_hid,
      data.donor_name,
      data.donor_address,
      data.donor_hp,
      data.donor_email,
      data.donation_fund_type,
      data.thk_livestock_name,
      data.thk_livestock_campaign_name,
      data.program_name,
      data.project_name,
      data.channel_type,
      data.bank_name,
      data.channel_name,
      data.donation_bank_message,
      data.donation_channel_trx_id,
      data.donation_item_amount,
      data.department_name,
      data.donation_shape
    ];
  }

  return returnData;
}

export const DONATION_EXPORT_TYPE = [
  {
    label: "Dengan data donatur",
    value: "export_fundraiser",
    access: "Read Export Donation With Donatur"
  },
  {
    label: "Tanpa data donatur",
    value: "export_finance",
    access: "Read Export Donation Without Donatur"
  },
];
