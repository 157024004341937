import { createSlice } from '@reduxjs/toolkit';

const initialCheckerorState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    checkerDetail: null,
    lastError: null,
    tunaiItems: null,
    edcItems: null,
    totalTunaiDonations: null,
    totalEdcDonations: null,
    totalDonations: null,
    totalPartnerDonations: null,
    totalGoodsDonation: null,
};

export const callTypes = {
    list: 'list',
    action: 'action',
};

export const checkerSlice = createSlice({
    name: 'checker',
    initialState: initialCheckerorState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // getCheckerById
        checkerFetched: (state, action) => {
            const { count, rows } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.checkerDetail = rows;
            state.totalCount = count
        },
        // findChecker Request
        // s2-251 tambah tabel by cash dan tunai
        checkersFetched: (state, action) => {
            const { total_items, items, tunai_items, edc_items, total_tunai_donations, total_edc_donations, total_donations, total_partner_donations, total_goods_donations } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = items;
            state.totalCount = total_items;
            state.tunaiItems = tunai_items;
            state.edcItems = edc_items;
            state.totalTunaiDonations = total_tunai_donations;
            state.totalEdcDonations = total_edc_donations;
            state.totalDonations = total_donations;
            state.totalPartnerDonations = total_partner_donations;
            state.totalGoodsDonation = total_goods_donations;

        },
        // Set to default state actionLoading & error
        checkersReload: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
        },

    },
});

