import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
    Input,
    Select,
} from '../../../../_metronic/_partials/controls';
import { RoleChecker } from '../../../RoleChecker';

const CheckerRequestSchema = Yup.object().shape({
    reason: Yup.string().required('Keterangan Wajib Diisi Untuk Menolak'),
});

export function CheckerRequestForm({ onHide, approve, reject, printDiv, status_checker, checker, printEdc, printCash, totalEdc, totalCash, printPartner, printGoods, totalPartner, totalGoods }) {
    // Inisialisasi data form
    const initChecker = {
        status_checker: '',
        reason: '',
    };

    const statusValue = [
        {
            value: 'SETTLE',
            name: 'APPROVED',
        },
        {
            value: 'REJECT',
            name: 'REJECT',
        },
    ];
    const [requestValue, setRequestValue] = useState(false);

    const [showReason, setShowReason] = useState(false)


    const changeType = (event) => {
        let value = event.target.value;

        switch (value) {
            case 'REJECT':
                setRequestValue(true);
                break;
            case 'SETTLE':
                setRequestValue(false);
                break;
            default:
                setRequestValue(false);
                initChecker.reason = '';
        }
    };

    const allowApprove = RoleChecker("Approve", "checker")
    const allowReject = RoleChecker("Reject", "checker")
    const allowPrintCash = RoleChecker("Print Cash", "checker")
    const allowPrintEdc = RoleChecker("Print Edc", "checker")


    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={initChecker}
                validationSchema={CheckerRequestSchema}
                onSubmit={(values) => {
                    {
                        reject(values)
                        // console.log(values)
                    }
                }}
            >
                {({ handleSubmit, handleChange, setFieldValue }) => (
                    <>
                        <Modal.Body className="overlay overlay-block cursor-default">
                            {/* <Form>
                                <div className="col">
                                    <Select name="status_checker" label="Konfirmasi"
                                            onChange={(e) => {
                                                changeType(e);
                                                setFieldValue('status_checker', e.target.options[e.target.selectedIndex].value);
                                            }}
                                    >
                                        <option value="">--PILIH--</option>
                                        {
                                            statusValue.map(row =>
                                                <option key={row.value} value={row.value}>{row.name}</option>,
                                            )
                                        }
                                    </Select>
                                    <ErrorMessage name='status_checker'
                                                  render={msg => <div style={{color: 'red'}}>{msg}</div>}/>
                                </div>

                                {requestValue && (
                                    <div className="col-lg">
                                        <Field
                                            name="reason"
                                            component={Input}
                                            placeholder="Text"
                                            label="Keterangan"
                                        />
                                    </div>
                                )}

                            </Form> */}

                            <div className="form-group row">
                                <div className="col-lg-6">

                                </div>

                                {showReason && (
                                    <div className="col-lg-6">
                                        <Field
                                            name="reason"
                                            component={Input}
                                            placeholder="Keterangan"

                                            onChange={(e) => {
                                                // handleChange(e)
                                                setFieldValue('reason', e.target.value);
                                            }}
                                            label="keterangan"
                                        />
                                    </div>
                                )}

                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            {/* <button
                                type="button"
                                // onClick={onHide}
                                className="btn btn-primary btn-elevate"
                            >
                                Print
                            </button> */}

                            <button
                                type="submit"
                                onClick={onHide}
                                className="btn btn-light btn-elevate"
                            >
                                Cancel
                            </button>

                            {/* <button
                                type="button"
                                onClick={() => { printDiv(checker[0].settle_batch_uuid, status_checker) }}
                                className="btn btn-primary btn-elevate"
                            >
                                Print
                            </button> */}
                            {allowPrintCash && <button
                                type="button"
                                onClick={() => { printCash(checker[0].settle_batch_uuid, status_checker) }}
                                className="btn btn-primary btn-elevate"
                                disabled={totalCash > 0 ? false : true}
                            >
                                Print Cash
                            </button>}
                            {allowPrintEdc && <button
                                type="button"
                                onClick={() => { printEdc(checker[0].settle_batch_uuid, status_checker) }}
                                className="btn btn-primary btn-elevate"
                                disabled={totalEdc > 0 ? false : true}
                            >
                                Print EDC
                            </button>}

                            <button
                                type="button"
                                onClick={() => { printPartner(checker[0].settle_batch_uuid, status_checker) }}
                                className="btn btn-primary btn-elevate"
                                disabled={totalPartner > 0 ? false : true}
                            >
                                Print Mitra
                            </button>
                            <button
                                type="button"
                                onClick={() => { printGoods(checker[0].settle_batch_uuid, status_checker) }}
                                className="btn btn-primary btn-elevate"
                                disabled={totalGoods > 0 ? false : true}
                            >
                                Print Barang
                            </button>
                            {(status_checker && status_checker !== 'SETTLE') && (
                                <>

                                    {allowApprove && <button
                                        type="button"
                                        onClick={approve}
                                        className="btn btn-success btn-elevate"
                                    >
                                        Approve
                                    </button>}

                                    {allowReject && <button
                                        type="submit"
                                        onClick={showReason === false ? () => { setShowReason(true) } : () => { handleSubmit() }}
                                        className="btn btn-danger btn-elevate"
                                    >
                                        Reject
                                    </button>}
                                </>
                            )}

                        </Modal.Footer>
                    </>

                )}
            </Formik>
        </>

    );
};
