import { createSlice } from "@reduxjs/toolkit";

const initialPeriodTemplateState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  periodTemplateForEdit: undefined,
  lastError: null,
  periodTemplateBatchList: [],
  periodTemplateBatchEdit: {},
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const periodTemplateSlice = createSlice({
  name: "periodTemplate",
  initialState: initialPeriodTemplateState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getPeriodTemplateById
    periodTemplateFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.periodTemplateForEdit = action.payload.periodTemplateForEdit;
    },
    // findPeriodTemplate
    periodTemplatesFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // Set to default state actionLoading & error
    periodTemplateReload: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },

    // findPeriodTemplateBatch
    periodTemplatesBatchFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.periodTemplateBatchList = items;
      state.totalCount = total_items;
    },

    // findPeriodTemplateBatch
    periodTemplateBatchDetail: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.periodTemplateBatchEdit = action.payload.periodTemplateBatchEdit;
    },
  },
});
