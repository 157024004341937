import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'

export function DonaturMergeDonationDialog({ handleClose, handleShow, mergeDonation, show, donatur1, donatur2 }) {

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Gabungkan Donasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Apakah Anda Yakin Ingin Memindahkan Donasi Dari <span className="text-primary">{donatur2.name} - {donatur2.donor_id}</span> Ke <span className="text-danger">{donatur1.name} - {donatur1.donor_id}</span>?
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-warning" onClick={handleClose}>
            Batal
          </button>

          <button className="btn btn-danger" onClick={() => {
            mergeDonation(donatur1.uuid, donatur2.uuid, donatur1.name, donatur2.name, donatur1.donor_id, donatur2.donor_id)
            handleClose()
          }}>
            Gabungkan
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}