import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import ThkChannelReportDonationList from "./ThkChannelReportDonationList";
import { useQuery } from "../../../../../../_metronic/_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchThkChannelReport } from "../../_redux/thkChannelReportAction";
import ChannelDownloadBatch from "./channel-download-batch/ChannelDownloadBatch";

export default function ThkChannelReportManage({
  history,
  match: {
    params: { id },
  },
}) {
  const {
    location: { pathname },
  } = history;

  let query = useQuery();
  const activeTab = query.get("tab");

  const dispatch = useDispatch();

  function dispatchFetch() {
    dispatch(fetchThkChannelReport(id));
  }

  useEffect(() => {
    dispatchFetch();
  }, []);

  const { currentState, thkChannelReportForEdit = {} } = useSelector(
    (state) => ({
      currentState: state.thkChannelReport.thkChannelReportDonation,
      thkChannelReportForEdit: state.thkChannelReport.thkChannelReportForEdit,
    }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  const tabList = [
    {
      title: "List Donasi",
      key: "list_donasi",
      component: (
        <ThkChannelReportDonationList
          history={history}
          id={id}
          currentState={currentState}
          thkChannelReportForEdit={thkChannelReportForEdit}
        />
      ),
    },
    {
      title: "Download Batch",
      key: "list_batch",
      component: (
        <ChannelDownloadBatch channel={thkChannelReportForEdit?.channel} />
      ),
    },
  ];

  function handleBack() {
    history.goBack();
  }

  function handleClickTab(tab) {
    history.push({
      pathname,
      search: `?tab=${tab}`,
    });
  }

  return (
    <Card>
      <CardHeader title={`Channel ${thkChannelReportForEdit?.channel?.name}`}>
        <CardHeaderToolbar>
          <button className="btn btn-secondary" onClick={handleBack}>
            Kembali
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Tabs
          id="tab"
          activeKey={activeTab ?? "list_donasi"}
          onSelect={(tab) => {
            handleClickTab(tab);
          }}
          defaultActiveKey="list_donasi"
        >
          {tabList.map((list) => (
            <Tab eventKey={list.key} title={list.title} key={list.key}>
              <div className="mt-8">{list.component}</div>
            </Tab>
          ))}
        </Tabs>
      </CardBody>
    </Card>
  );
}
