import React from 'react';
import {
    badge,
} from '../../DonationUIHelpers';

export function StatusColumnFormatter(cellContent, row) {

    let data = badge(cellContent);
    return (
        <span className={`label label-lg label-light-${data.type} label-inline`}>
            {cellContent}
        </span>
    );
}
