import React, { useEffect, useState } from 'react';
import { useSubheader } from '../../_metronic/layout';
import { Alert, Card, Button, Table } from 'react-bootstrap';
import axios from 'axios';

export const MyPage = () => {
    const suhbeader = useSubheader();
    suhbeader.setTitle('My Custom title');

    const [branch, setBranch] = useState([]);

    const handleClick = (event) => {
        axios.get('http://localhost:3002/api/branch')
            .then((response) => {
                // console.log(response);
                // axios.get('https://jsonplaceholder.typicode.com/todos/1').then(response => {
                const { data: { items } } = response.data;
                setBranch(items);
            });

    };

    // const [value, setValue] = useState('');
    // const [count, setCount] = useState(-1);

    // useEffect(() => {
    //     setCount(count + 1);
    // }, [value]);
    // const onChange = ({ target }) => setValue(target.value);

    useEffect(() => {
        // document.title = `Ini Title baru ${count}`;
        return () => {
            document.title = 'Metronic || React Demo';
        };
    });

    return (<>
        <Alert variant="success">
            This is a Success alert—check it out!
        </Alert>
        <Card>
            <Card.Header>Featured</Card.Header>
            <Card.Body>
                <Card.Title>Master Data Cabang</Card.Title>
                <Card.Text>
                    With supporting text below as a natural lead-in to additional content.
                </Card.Text>
                {/*<Button variant="primary" onClick={() => setCount(count+1)}>Go somewhere</Button>*/}
                <Button variant="primary" onClick={handleClick}>Tampilkan</Button>

                {/*<div>*/}
                {/*    <input type="text" value={value} onChange={onChange} />*/}
                {/*    <div>Number of changes: {count}</div>*/}
                {/*</div>*/}

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Nama Cabang</th>
                        <th>Jumlah Channel</th>
                        <th>Username</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        branch.map((branch, index) => {
                            return <tr key={branch.uuid}>
                                <td>{index + 1}</td>
                                <td>{branch.name}</td>
                                <td>{branch.channel.length}</td>
                                <td>@mdo</td>
                            </tr>;
                        })
                    }

                    </tbody>
                </Table>

            </Card.Body>
        </Card>

    </>);
};
