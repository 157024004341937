import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { useDonaturTrxUIContext } from '../../donatur-trx/DonaturTrxUIContext';
import { statusDonasi, initialFilter } from './DonationUIHelpers'
import moment from 'moment'

import * as requestFt from '../../../MasterData/_redux/fund_type/fundtypeCrud';
import * as requestProj from '../../../MasterData/_redux/projects/projectsCrud';
import * as requestCh from '../../../MasterData/_redux/channels/channelsCrud';
import * as actions from '../../../MasterData/_redux/user/userAction'

import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { fetchDonationHistory } from '../../_redux/donatur/donaturAction'


const prepareFilter = (queryParams, values) => {
    const {
        projectName,
        donaType,
        donor_name,
        chName,
        status,
        start_date,
        end_date,
        donation_hid,
        total_amountlt,
        total_amountgt,
        user_uuid
    } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};

    //add to Object filter
    filter.donation_item_project = projectName ?? '';
    filter.donation_item_type = donaType ?? '';
    filter.donor_name = donor_name ?? '';
    filter.channel_uuid = chName ?? '';
    filter.user_uuid = user_uuid ?? '';
    filter.status = status ?? ''
    filter.start_date = start_date ?? '';
    filter.end_date = end_date ?? '';
    filter.donation_hid = donation_hid ?? ''
    filter.total_amountlt = total_amountlt ?? ''
    filter.total_amountgt = total_amountgt ?? ''
    filter.trxDate = ""

    newQueryParams.filter = filter;
    return newQueryParams;
};

export const DonationFilter = ({ user_uuid, donaturType, setCurrentFilter }) => {

    const donaturTrxUIContext = useDonaturTrxUIContext();
    const donaturTrxUIProps = useMemo(() => {
        return {
            queryParams: donaturTrxUIContext.queryParams,
            setQueryParams: donaturTrxUIContext.setQueryParams,
        };
    }, [donaturTrxUIContext]);

    const applyFilter = (values, user_uuid) => {
        const newQueryParams = prepareFilter(donaturTrxUIProps.queryParams, values);
        if (!isEqual(newQueryParams, donaturTrxUIProps.queryParams)) {
            newQueryParams.pageNumber = 1;
            newQueryParams.filter.user_uuid = user_uuid
            donaturTrxUIProps.setQueryParams(newQueryParams);
            setCurrentFilter(newQueryParams)
        }
        // console.log(donaturTrxUIProps.queryParams);
        // console.log(values);
    };

    const initUser = {
        filter: {
            // name: '',
            role: ['Fundraiser', 'FundCollCheck', 'KepalaCabang', 'SuperAdmin']
        },
        pageNumber: null,
        pageSize: null,
    };

    const [channelName, setChannelName] = useState([]);
    const [typeDona, setTypeDona] = useState([]);
    const [project, setProject] = useState([]);
    const [branchList, setBranchList] = useState([])

    //UNTUK DATERANGE
    const [onFocused, setOnFocused] = useState(null)
    const [tanggal, setTanggal] = useState(
        {
            start_date: null,
            end_date: null,
        }
    )

    const { userAuth } = useSelector(
        (state) => ({ userAuth: state.auth.user }),
        shallowEqual,
    );

    const { userList } = useSelector(
        (state) => ({ userList: state.users }),
        shallowEqual,
    );

    const fetchType = async () => {
        const result = await requestFt.getFundtypeAll();
        setTypeDona(result.data.data);
    };

    const fetchChannel = async () => {
        const result = await requestCh.getChanelByType('');
        setChannelName(result.data.data);
    };

    const fetchProject = async () => {
        const result = await requestProj.getProjectAll();
        setProject(result.data.data);
    };

    const dispatch = useDispatch();

    useEffect(() => {
        fetchType();
        fetchChannel();
        fetchProject();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(actions.fetchUsers(initUser));
        dispatch(fetchDonationHistory((donaturTrxUIProps.queryParams)))

        // eslint-disable-next-line
    }, [donaturTrxUIProps.queryParams, dispatch])

    // // state disable combobox Type Channel (BANK,COUNTER,ECHANNEL)
    // const [isDisabled, setIsDisabled] = useState(false);
    // useEffect(() => {
    //     if (donaturTrxUIProps.filterType !== undefined) {
    //         setIsDisabled(true);
    //     }
    //     // eslint-disable-next-line
    // }, []);

    return (
        <Formik
            initialValues={initialFilter.filter}
            onSubmit={(values) => {
                applyFilter(values, user_uuid);
            }}
        >
            {({ values, handleSubmit, handleBlur, setFieldValue }) => (
                <>
                    <form className="form form-label-right">
                        {/*ROW 1*/}
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <DateRangePicker
                                    small={true}
                                    startDate={tanggal.start_date}
                                    endDate={tanggal.end_date}
                                    onDatesChange={({ startDate, endDate }) => {
                                        setTanggal({
                                            start_date: startDate,
                                            end_date: endDate
                                        })
                                        setFieldValue('start_date', startDate)
                                        setFieldValue('end_date', endDate)
                                    }}
                                    focusedInput={onFocused}
                                    onFocusChange={focusedInput => setOnFocused(focusedInput)}
                                    numberOfMonths={1}
                                    isOutsideRange={() => false}
                                    showClearDates={true}
                                    startDateId="start_date"
                                    endDateId="end_date"
                                    hideKeyboardShortcutsPanel={true}
                                />
                                <small className="form-text text-muted">
                                    <b>Cari</b> Berdasarkan Rentan Waktu
                                </small>
                            </div>
                            <div className="col-lg-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="donation_hid"
                                    placeholder="Cari ID Donasi"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        setFieldValue('donation_hid', e.target.value);
                                    }}
                                />
                                <small className="form-text text-muted">
                                    <b>Cari</b> ID Donasi
                                </small>
                            </div>
                            {donaturType !== "INDIVIDU" && <div className="col-lg-4">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="donor_name"
                                    placeholder="Cari"
                                    onBlur={handleBlur}
                                    // value={values.donor_name}
                                    onChange={(e) => {
                                        setFieldValue('donor_name', e.target.value);
                                    }}
                                />
                                <small className="form-text text-muted">
                                    <b>Cari</b> Nama Donatur
                                </small>
                            </div>}
                        </div>

                        {/*ROW 2*/}
                        <div className="form-group row">
                            <div className="col-lg-4">
                                <select
                                    className="form-control"
                                    placeholder="Filter Type"
                                    name="chName"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        setFieldValue('chName', e.target.value);
                                    }}
                                    value={values.chName}
                                >
                                    <option value="">ALL</option>
                                    {channelName.map(ch =>
                                        <option key={ch.uuid}
                                            value={ch.uuid}> {ch.bank ? ch.bank.name : ch.type} - {ch.name}{ch.is_deleted ? " - [TERHAPUS]" : ""}</option>
                                    )}
                                </select>
                                <small className="form-text text-muted">
                                    <b>Cari</b> Nama Channel / Kode Rekening
                                </small>
                            </div>
                            <div className="col-lg-4">
                                <select
                                    className="form-control"
                                    placeholder="Cari Type"
                                    name="status"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        setFieldValue('status', e.target.value);
                                    }}
                                >
                                    <option value="">ALL</option>
                                    {statusDonasi.map(val =>
                                        <option key={val.value} value={val.value}>{val.name}</option>
                                    )}
                                </select>
                                <small className="form-text text-muted">
                                    <b>Cari</b> Status
                                </small>
                            </div>
                            <div className="col-lg-4">
                                <select
                                    className="form-control"
                                    placeholder="Jenis Donasi"
                                    name="donaType"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        setFieldValue('donaType', e.target.value);
                                    }}
                                    value={values.donaType}
                                >
                                    <option value="">ALL</option>
                                    {
                                        typeDona.map(row =>
                                            <option key={row.code} value={row.code}>{row.name}</option>,
                                        )
                                    }
                                </select>
                                <small className="form-text text-muted">
                                    <b>Cari</b> Jenis Donasi
                                </small>
                            </div>
                        </div>

                        {/*ROW 3*/}
                        <div className="form-group row">

                            <div className="col-lg-4">
                                <select
                                    className="form-control"
                                    placeholder="Cari Project"
                                    name="projectName"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        setFieldValue('projectName', e.target.value);
                                    }}
                                    value={values.projectName}
                                >
                                    <option value="">ALL</option>
                                    {
                                        project.map(row =>
                                            <option key={row.uuid} value={row.uuid}>{row.name}</option>,
                                        )
                                    }
                                </select>
                                <small className="form-text text-muted">
                                    <b>Cari</b> Nama Proyek
                                </small>
                            </div>
                            <div className="col-lg-4">
                                <input
                                    type="number"
                                    className="form-control"
                                    name="total_amountgt"
                                    placeholder="Cari berdasarkan Jumlah Lebih dari"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        setFieldValue('total_amountgt', e.target.value);
                                    }}
                                />
                                <small className="form-text text-muted">
                                    <b>Cari Berdasarkan</b> Jumlah Lebih Dari
                                </small>
                            </div>
                            <div className="col-lg-4">
                                <input
                                    type="number"
                                    className="form-control"
                                    name="total_amountlt"
                                    placeholder="Cari berdasarkan Jumlah Kurang dari"
                                    onBlur={handleBlur}
                                    onChange={(e) => {
                                        setFieldValue('total_amountlt', e.target.value);
                                    }}
                                />
                                <small className="form-text text-muted">
                                    <b>Cari Berdasarkan</b> Jumlah Kurang Dari
                                </small>
                            </div>
                        </div>
                    </form>

                    <button
                        onClick={() => handleSubmit()}
                        className="btn btn-primary btn-elevate float-right mb-20"
                    >
                        Cari
                    </button>
                    <hr className="mt-25" />
                </>

            )}
        </Formik>

    );

};

