/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import {Redirect, Switch, Route} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";
import {ContentRoute, Layout} from "../_metronic/layout";
import BasePage from "./BasePage";
import {Logout, AuthPage} from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import RedirectDonationReport from "./redirect-page/RedirectDonationReport";


export function Routes() {
    const {isAuthorized} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );

    const user = useSelector(
        ({ auth }) => auth?.user,
        shallowEqual
    );
    let is_change_password = true;
    if (user) {
        is_change_password = user.is_change_password
    }
    // const test = useSelector(
    //     ( state ) => (state),
    //     shallowEqual,
    // );
    // console.log(test)


    return (
            <Switch>
                <ContentRoute path="/report/kwitansi/:id" component={RedirectDonationReport} />
                {!isAuthorized ? (
                    /*Render auth page when user at `/auth` and not authorized.*/
                    <Route>
                        <AuthPage/>
                    </Route>
                ) : is_change_password ? (
                    <Redirect from="/auth" to="/user-profile/change-password"/>
                ):(
                    /*Otherwise redirect to root page (`/`)*/
                    
                    <Redirect from="/auth" to="/"/>
                )}

                <Route path="/error" component={ErrorsPage}/>
                <Route path="/logout" component={Logout}/>


                {!isAuthorized ? (
                    /*Redirect to `/auth` when user is not authorized*/
                    <Redirect to="/auth/login"/>
                ) : (
                    <Layout>
                        {is_change_password && (
                            <Redirect to="/user-profile/change-password"/>
                        )}
                        <BasePage/>
                    </Layout>
                )}
            </Switch>
    );
}
