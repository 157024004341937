import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Field } from 'formik';
import { isEqual } from 'lodash';
import AsyncSelect from "react-select/async";
import ReactSelect from "react-select";
import moment from 'moment'
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { DateRangePicker } from 'react-dates';
import { useKurbanListUIContext } from '../KurbanUIContext';
import { initialFilter } from '../KurbanUIHelpers'
import { getFundtypeAll } from "../../../../../MasterData/_redux/fund_type/fundtypeCrud";
import { getBankAll } from "../../../../../MasterData/_redux/bank/bankCrud";
import * as requestCh from '../../../../../MasterData/_redux/channels/channelsCrud';
import { getKurbanList } from "../../../../_redux/kurban/kurbanCrud";
import { findAllThkPeriods } from '../../../../thk-period/thk-period-api';
import { promiseTuplify } from '../../../../../../../_metronic/_utils/promise-utils';
import { genOptionLoader } from '../../../../../../../_metronic/_utils/option-loaders';
import { findBranchs } from '../../../../../MasterData/_redux/branchs/branchCrud';
import { findDonaturs } from '../../../../../Donatur/_redux/donatur/donaturCrud';

const prepareFilter = (queryParams, values) => {
    const {
        is_name,
        donation_hid,
        name,
        thk_livestock_uuid,
        status,
        date,
        start_date,
        end_date,
        thk_period,
        channel,
        branch,
        donor,
        donor_id,
        allow_merge,
        is_letter
    } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};

    filter.is_name = is_name ?? '';
    filter.donation_hid = donation_hid ?? '';
    filter.name = name ?? '';
    filter.thk_livestock_uuid = thk_livestock_uuid ?? '';
    filter.status = status ?? '';
    filter.chName = channel ? channel.uuid : '';
    filter.date = date ?? '';
    filter.start_date = start_date ? moment(start_date).format('YYYY-MM-DD') : '';
    filter.end_date = end_date ? moment(end_date).format('YYYY-MM-DD') : '';
    filter.thk_period_uuid = thk_period ? thk_period.uuid : '';
    filter.branch_uuid = branch ? branch.uuid : '';
    filter.donor_name = donor ? donor.name : '';
    filter.donor_id = donor_id ?? ''
    filter.allow_merge = allow_merge
    filter.is_letter = is_letter

    newQueryParams.filter = filter;
    return newQueryParams;
};

export const KurbanListFilter = () => {

    const kurbanListUIContext = useKurbanListUIContext();
    const kurbanListUIProps = useMemo(() => {
        return {
            setQueryParams: kurbanListUIContext.setQueryParams,
            queryParams: kurbanListUIContext.queryParams,
            filterType: kurbanListUIContext.filterType,
        };
    }, [kurbanListUIContext]);


    const [channelOptions, setChannelOptions] = useState([]);
    const [kurbanList, setKurbanList] = useState([])
    const [thkPeriodOptions, setThkPeriodOptions] = useState([]);
    const [onFocused, setOnFocused] = useState(null);
    const [branchOptions, setBranchOptions] = useState([])
    const [donaturOptions, setDonaturOptions] = useState([])

    function applyFilter(values) {
        const newQueryParams = prepareFilter(kurbanListUIProps.queryParams, values);
        if (!isEqual(newQueryParams, kurbanListUIProps.queryParams)) {
            newQueryParams.pageNumber = 1;
            kurbanListUIProps.setQueryParams(newQueryParams);
        }
    }

    async function kurbanFunction() {
        await getKurbanList().then(response => {
            setKurbanList(response.data.data)
        }).catch(error => {
            console.log(error)
        })
    }
    async function fetchChannel() {
        try {
            const {data: {data: {items = []}} = {}} = await requestCh.findChannels();
            
            const options = items.map(({bank, type, name, is_deleted, uuid}) => {
                const label = `${bank ? bank.name : type} - ${name}${is_deleted ? " - [TERHAPUS]" : ""}`;
                
                return {
                    label,
                    value: uuid
                }
            });

            setChannelOptions(options);
        } catch(error) {
            console.error(error);
        }
    }
    async function fetchThkPeriods() {
        const [res, error] = await promiseTuplify(findAllThkPeriods());
        
        if(error) {
            return;
        }
        
        if(res) {
            const {data: {data = []} = {}} = res;

            const options = data.map(({uuid, name}) => ({label: name, value: uuid}));
            setThkPeriodOptions(options);
        }
    }

    async function fetchBranchs() {
        const [res, error] = await promiseTuplify(findBranchs({ pageNumber: 1, pageSize: 40, filter: {} }));
        
        if(error) {
            return;
        }
        
        if(res) {
            const { data: { data: {items = []} } = {} } = res;

            const options = items.map(({ uuid, name }) => ({
              label: name,
              value: uuid,
            }));
            setBranchOptions(options);
        }
    }

    async function fetchDonors() {
        const [res, error] = await promiseTuplify(findDonaturs({ pageNumber: 1, pageSize: 50, filter: {} }));
        
        if(error) {
            return;
        }
        
        if(res) {
            const { data: { data: {items = []} } = {} } = res;

            const options = items.map(({ uuid, name, donor_id }) => {
                const label = `${name} - ${donor_id}`
                return {
                    label,
                    name,
                    value: uuid,
                }
            });
            setDonaturOptions(options);
        }
    }

    useEffect(() => {
        fetchThkPeriods();
        kurbanFunction();
        fetchChannel();
        fetchBranchs();
        fetchDonors();
        // eslint-disable-next-line
    }, []);

    return (
        <Formik 
            enableReinitialize={true} 
            initialValues={{
                ...initialFilter.filter,
            }} 
            onSubmit={(values) => {
                applyFilter(values);
            }}
        >
            {({ values, handleSubmit, handleBlur, setFieldValue, resetForm }) => (
                <form onSubmit={handleSubmit} className="form form-label-right">
                    {/*ROW 1 BY DONATUR*/}
                    <div className="form-group row">
                        <div className="col-lg-4">
                            <Field
                                id="date"
                                name="date"
                                type="date"
                                className="form-control"
                                onChange={(e) => {
                                    setFieldValue('date', e.target.value)
                                }}
                            />
                            <small className="form-text text-muted">
                                Cari Berdasarkan Tanggal Harian
                            </small>
                        </div>

                        {/*ROW 2*/}

                        <div className="col-lg-4">
                            <select
                                className="form-control"
                                name="is_name"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    setFieldValue('is_name', e.target.value);
                                }}
                            >
                                <option value="true">Ya</option>
                                <option value="false">Tidak</option>
                            </select>
                            <small className="form-text text-muted">
                                Tersedia Nama Pekurban
                            </small>
                        </div>

                        <div className="col-lg-4">
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                placeholder="Search"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    setFieldValue('name', e.target.value);
                                }}
                            />
                            <small className="form-text text-muted">
                                <b>Cari</b> Nama Pekurban
                            </small>
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-lg-4 mb-5">
                            <DateRangePicker 
                                small={true}
                                startDate={values.start_date}
                                endDate={values.end_date}
                                onDatesChange={({startDate, endDate}) => {
                                    setFieldValue("start_date", startDate)
                                    setFieldValue("end_date", endDate)

                                    setTimeout(handleSubmit, 0)
                                }}
                                focusedInput={onFocused}
                                onFocusChange={(focusedInput) => setOnFocused(focusedInput)}
                                numberOfMonths={1}
                                showClearDates={true}
                                isOutsideRange={() => false}
                                startDateId="start_date"
                                endDateId='end_date'
                                hideKeyboardShortcutsPanel={true}
                            />
                            <small className='form-text text-muted'>
                                <b>Cari</b>Tanggal Mulai/Akhir
                            </small>
                        </div>
                        <div className="col-lg-4">
                            <input
                                type="text"
                                className="form-control"
                                name="donation_hid"
                                placeholder="Search"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    setFieldValue('donation_hid', e.target.value);
                                }}
                            />
                            <small className="form-text text-muted">
                                <b>Cari</b> ID Donasi
                            </small>
                        </div>
                        <div className="col-lg-4">
                            <select name="status"
                                className="form-control"
                                onChange={e => {
                                    setFieldValue('status', e.target.value)
                                }}
                            >
                                <option value="">--PILIH--</option>
                                <option value="RECOG">RECOG</option>
                                <option value="SETTLE">SETTLE</option>
                                <option value="COLLECT">COLLECT</option>
                                <option value="DRAFT">DRAFT</option>
                                <option value="VOID">VOID</option>
                                <option value="REQUEST_VOID">REQUEST VOID</option>
                                <option value="PENDING">PENDING</option>
                                <option value="PAID">PAID</option>
                            </select>
                            <small className="form-text text-muted">
                                <b>Cari</b> Status
                            </small>
                        </div>
                    </div>

                    <div className="form-group row">
                        {/* Channel UUID */}
                        {/* {showRekeningField && (
                            <div className="col-lg-4">
                                <select name="channel_name"
                                    className="form-control"
                                    onChange={e => {
                                        handleSubmit(e)
                                        setFieldValue('channel_name', e.target.value)
                                    }}>
                                    <option value="">--PILIH--</option>
                                    {
                                        kodeRek.map(row =>
                                            <option key={row.uuid} value={row.name}>{row.name}</option>,
                                        )
                                    }
                                </select>
                                <small className="form-text text-muted">
                                    <b>Cari</b> Nomor Rekening
                                </small>
                            </div>
                        )} */}
                        {/* Channel UUID */}

                        <div className="col-lg-4 mb-5">
                            <ReactSelect 
                                name="thk_period"
                                placeholder={"Periode Thk"}
                                options={thkPeriodOptions}
                                value={values.thk_period && values.thk_period.uuid ? {
                                    label: values.thk_period.name ?? "",
                                    value: values.thk_period.uuid ?? "",
                                } : null}
                                onChange={({label: name, value: uuid}) => {
                                    setFieldValue("thk_period", {
                                        uuid,
                                        name
                                    })
                                }}
                            />
                            <small className="form-text text-muted">
                                <b>Cari</b> Periode Thk
                            </small>
                        </div>

                        <div className="col-lg-4">
                            <select
                                className="form-control"
                                name="thk_livestock_uuid"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    setFieldValue('thk_livestock_uuid', e.target.value);
                                }}
                            >
                                <option value="">ALL</option>
                                {kurbanList.map(row =>
                                    <option key={row.uuid} value={row.uuid}>
                                        {row.name} - {row.price}
                                    </option>
                                )}
                            </select>
                            <small className="form-text text-muted">
                                Jenis Hewan Kurban
                            </small>
                        </div>

                        <div className="col-lg-4">
                            <AsyncSelect 
                                name='chName'
                                defaultOptions={channelOptions}
                                loadOptions={
                                    genOptionLoader((inputValue) => requestCh.findChannels({filter: {name:inputValue}}),
                                    'items', ({bank, type, name, is_deleted, uuid}) => {
                                    const label = `${bank ? bank.name : type} - ${name}${is_deleted ? " - [TERHAPUS]" : ""}`;
                                    
                                    return {
                                        label,
                                        value: uuid
                                    }
                                })}
                                value={values.channel && values.channel.uuid ? 
                                    {label: values.channel.name, value: values.channel.uuid}
                                    :
                                    null
                                }
                                onChange={({label: name, value: uuid}) => {
                                    setFieldValue("channel", {uuid, name});
                                }}
                            />
                            {/* <Autocomplete
                                name="chName"
                                options={channelName}
                                getOptionLabel={(option) => option ? `${option.bank ? option.bank.name : option.type} - ${option.name}${option.is_deleted ? " - [TERHAPUS]" : ""}` : ''}
                                onChange={(e, value) => {
                                    if (value) {
                                        setFieldValue('chName', value.uuid)
                                    } else {
                                        setFieldValue('chName', "")
                                    }
                                }}
                                value={channelName.length > 0 && channelName.find((option) => option.uuid === values.chName)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder="Cari Channel"
                                        variant="outlined"
                                        disableclearable={"true"}
                                        size="small"

                                    />
                                )}
                            /> */}
                            <small className="form-text text-muted">
                                <b>Cari</b> Nama Channel / Kode Rekening
                            </small>
                            {/* <select
                                className="form-control"
                                placeholder="Filter Type"
                                name="chName"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    setFieldValue('chName', e.target.value);
                                    handleSubmit();
                                }}
                                value={values.chName}
                            >
                                <option value="">ALL</option>
                                {channelName.map(ch =>
                                    <option key={ch.uuid}
                                        value={ch.uuid}> {ch.bank ? ch.bank.name : ch.type} - {ch.name}</option>
                                )}
                            </select>
                            <small className="form-text text-muted">
                                <b>Cari</b> Nama Channel / Kode Rekening
                            </small> */}
                        </div>
                    </div>

                    <div className='form-group row'>
                        <div className='col-lg-4'>
                            <AsyncSelect 
                                name='branch_uuid'
                                defaultOptions={branchOptions}
                                loadOptions={
                                    genOptionLoader((inputValue) => findBranchs({filter: {name:inputValue}}),
                                    'items', ({ name, uuid }) => {
                                    return {
                                        label: name,
                                        value: uuid
                                    }
                                })}
                                value={values.branch && values.branch.uuid ? 
                                    {label: values.branch.name, value: values.branch.uuid}
                                    :
                                    null
                                }
                                onChange={({ label: name, value: uuid }) => {
                                    setFieldValue("branch", { uuid, name });
                                }}
                            />
                            <small className='form-text text-muted'>
                                <b>Cari</b>Berdasarkan Cabang
                            </small>
                        </div>
                        <div className='col-lg-4'>
                            <AsyncSelect 
                                name='donor_uuid'
                                defaultOptions={donaturOptions}
                                loadOptions={
                                    genOptionLoader((inputValue) => findDonaturs({ pageNumber: 1, pageSize: 50, filter: { name:inputValue } }),
                                    'items', ({ name, donor_id, uuid }) => {
                                        const label = `${name} - ${donor_id}`
                                    return {
                                        label,
                                        name,
                                        value: uuid
                                    }
                                })}
                                value={values.donor && values.donor.uuid ? 
                                    {label: values.donor.name, value: values.donor.uuid}
                                    :
                                    null
                                }
                                onChange={({ label, name, value: uuid }) => {
                                    setFieldValue("donor", { uuid, name });
                                }}
                            />
                            <small className='form-text text-muted'>
                                <b>Cari</b> Berdasarkan Nama Donatur
                            </small>
                        </div>
                        <div className='col-lg-4'>
                            <input
                                type="text"
                                className="form-control"
                                name="donor_id"
                                placeholder="Search"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    setFieldValue('donor_id', e.target.value);
                                }}
                            />
                            <small className='form-text text-muted'>
                                <b>Cari</b> ID Donatur
                            </small>
                        </div>
                    </div>

                    <div className='form-group row'>
                        <div className='col-lg-4'>
                            <select
                                className="form-control"
                                name="is_letter"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    const value = ""
                                    if (e.target.value === "true") {
                                        setFieldValue("is_letter", true);
                                    } else if (e.target.value === "true") {
                                        setFieldValue("is_letter", false);
                                    } else {
                                        setFieldValue("is_letter", null);
                                    }
                                }}
                            >
                                <option value="">ALL</option>
                                <option value={true}>Ya</option>
                                <option value={false}>Tidak</option>
                            </select>
                             <small className='form-text text-muted'>
                                <b>Cari</b> Terima Hard Copy
                            </small>
                        </div>
                        <div className='col-lg-4'>
                            <select
                                className="form-control"
                                name="allow_merge"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    const value = ""
                                    if (e.target.value === "true") {
                                        setFieldValue("allow_merge", true);
                                    } else if (e.target.value === "true") {
                                        setFieldValue("allow_merge", false);
                                    } else {
                                        setFieldValue("allow_merge", null);
                                    }
                                }}
                            >
                                <option value="">ALL</option>
                                <option value={true}>Ya</option>
                                <option value={false}>Tidak</option>
                            </select>
                            <small className='form-text text-muted'>
                                <b>Cari</b> Merge 1/7
                            </small>
                        </div>
                    </div>

                    <div className='form-group row'>
                        <div className="col d-flex justify-content-end">
                            <button 
                                type='button'
                                className="btn btn-outline-warning mr-4"
                                onClick={() => {
                                    resetForm();
                                    handleSubmit();
                                }}
                            >
                                Hapus Pencarian
                            </button>
                            <button 
                                type='submit'
                                className="btn btn-outline-primary"
                            >
                                Terapkan
                            </button>
                        </div>
                    </div>
                </form>
            )}
        </Formik>

    );

};

