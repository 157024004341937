import React, { createContext, useCallback, useContext, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { useDispatch } from 'react-redux';
import * as actions from '../../_redux/donatur/donaturAction'
import { initialFilterForParent } from '../../DonaturUIHelpers'

const DonaturTypeDialogUIContext = createContext();

export function useDonaturTypeDialogUIContext() {
	return useContext( DonaturTypeDialogUIContext );
}

export const DonaturTypeDialogUIContextConsumer = DonaturTypeDialogUIContext.Consumer

export function DonaturTypeDialogUIProvider( { parent, children } ) {
	const [ queryParams, setQueryParamsBase ] = useState( initialFilterForParent );
	const setQueryParams = useCallback( ( nextQueryParams ) => {
		setQueryParamsBase( ( prevQueryParams ) => {
			if ( isFunction( nextQueryParams ) ) {
				nextQueryParams = nextQueryParams( prevQueryParams );
			}

			if ( isEqual( prevQueryParams, nextQueryParams ) ) {
				return prevQueryParams;
			}

			return nextQueryParams;
		} );
	}, [] );

	const [ getChilds, setGetChilds ] = useState( false )
	const dispatch = useDispatch()
	if ( getChilds ) {
		dispatch( actions.fetchChilds( parent.uuid ) )
	}

	const value = {
		queryParams,
		setQueryParamsBase,
		setQueryParams,
		setGetChilds
	}
	return (
		<DonaturTypeDialogUIContext.Provider value={ value }>
			{ children }
		</DonaturTypeDialogUIContext.Provider>
	)
}