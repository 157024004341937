import React from 'react';
import { CollectUIProvider } from './CollectUIContext';
import { CollectList } from './list/CollectList';

export function CollectPage2(props) {
    return (
        <CollectUIProvider>
            <CollectList/>
        </CollectUIProvider>
    );
}