import React from "react";
import { AolBigQueryUIProvider } from "./AolBigQueryUIContext";
import { AolBigQueryCard } from "./AolBigQueryCard";
import { Route } from "react-router-dom";

export function AolBigQueryPage({ history }) {
  const aolBigQueryUIEvents = {
    openAolBigQueryRequestDialog: (id, status) => {
      history.push(`/aolBigQuery/${id}/${status}`);
    },
  };

  return (
    <AolBigQueryUIProvider aolBigQueryUIEvents={aolBigQueryUIEvents}>
      <AolBigQueryCard />
    </AolBigQueryUIProvider>
  );
}
