export const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
    { text: "50", value: 50 }
];

const year = new Date().getFullYear()

export const initialFilter = {
    filter: {
        name: "",
        priceGte: "",
        priceLte: "",
        category: "",
        type: "",
        periode: `${year}`,
    },
    pageNumber: 1,
    pageSize: 10
};


export const categoryHewan = [
    { value: "STANDAR", text: "Standar" },
    { value: "MEDIUM", text: "Medium" },
    { value: "PREMIUM", text: "Premium" },
]

export const tipeHewan = [
    { value: "SAPI", text: "Sapi" },
    { value: "17_SAPI", text: "1/7 Sapi" },
    { value: "KAMBING", text: "Kambing" },
]