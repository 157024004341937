import React, { createContext, useCallback, useContext, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './ExportListUIHelpers';
import { useLocation } from 'react-router-dom';

const ExportListUIContext = createContext()

export function useExportListUIContext() {
	return useContext(ExportListUIContext)
}

export function ExportListUIProvider({ exportListUIEvents, children }) {
	const location = useLocation()
	const { pathname } = location
	if (pathname === "/export/list") {
		initialFilter.filter.read_own = true
  } else {
		initialFilter.filter.read_own = false
	}
	const [queryParams, setQueryParamsBase] = useState(initialFilter);
	const setQueryParams = useCallback((nextQueryParams) => {
		setQueryParamsBase((prevQueryParams) => {
			if (isFunction(nextQueryParams)) {
				nextQueryParams = nextQueryParams(prevQueryParams);
			}

			if (isEqual(prevQueryParams, nextQueryParams)) {
				return prevQueryParams;
			}

			return nextQueryParams;
		});
	}, []);


	const value = {
		queryParams,
		setQueryParamsBase,
		setQueryParams,
		newExportListClick: exportListUIEvents.newExportListClick,
		reloadData: exportListUIEvents.reloadData,
		openChooseExportListPage: exportListUIEvents.openChooseExportListPage
	};


	return (
		<ExportListUIContext.Provider value={value}>
			{children}
		</ExportListUIContext.Provider>
	);
}
