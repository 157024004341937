import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import ChannelBatchTable from "./ChannelBatchTable";
import {
  deleteBatchChannelReport,
  getChannelBatchReport,
} from "../../../_redux/thkChannelReportAction";
import ConfirmationModal from "../../../../../../../_metronic/layout/components/confirmation-modal/ConfirmationModal";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../../../_metronic/_helpers";
import Loading from "../../../../../../../_metronic/_assets/js/components/loading";

export default function ChannelDownloadBatch({ channel = {} }) {
  const [donationCount, setDonationCount] = useState({
    count_finish: "0",
    count_reported: "0",
    total_count: "0",
  });

  const [queryParams, setQueryParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    filter: {},
  });

  const [deletedData, setDeletedData] = useState({
    showModal: false,
    channel_uuid: null,
    file_uuid: null,
  });

  const dispatch = useDispatch();

  const { currentState, thkChannelReport } = useSelector(
    (state) => ({
      currentState: state.thkChannelReport.channelBatchReport,
      thkChannelReport: state.thkChannelReport,
    }),
    shallowEqual
  );

  const { totalCount, entities, listLoading, error } = currentState;

  const { actionsLoading } = thkChannelReport;

  function getPartnershipBatch(channel_uuid) {
    dispatch(
      getChannelBatchReport({
        ...queryParams,
        filter: { channel_uuid },
      })
    );
  }

  useEffect(() => {
    if (channel.uuid) {
      getPartnershipBatch(channel.uuid);
    }
  }, [queryParams, channel]);

  function deleteBatchFile() {
    setDeletedData((prev) => ({
      ...prev,
      showModal: false,
    }));
    const { channel_uuid, file_uuid } = deletedData;
    dispatch(deleteBatchChannelReport({ channel_uuid, file_uuid })).then(
      (res) => {
        toast.success(res.message, toastOption);
        getPartnershipBatch(channel.uuid);
      }
    );
  }

  function handlecloseDeleteModal() {
    setDeletedData((prev) => ({
      showModal: false,
      channel_uuid: null,
      file_uuid: null,
    }));
  }

  return (
    <div>
      <Loading show={actionsLoading} />
      <ConfirmationModal
        show={deletedData.showModal}
        onHide={handlecloseDeleteModal}
        title="Hapus Laporan Batch"
        content={`Apakah anda yakin untuk menghapus laporan batch ini?`}
        subContent={`Laporan akan di generate ulang secara otomatis dalam 1 jam kedepan`}
        type="danger"
        onClick={() => deleteBatchFile()}
      />
      <ChannelBatchTable
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        entities={entities}
        listLoading={listLoading}
        totalCount={totalCount}
        setDeletedData={setDeletedData}
      />
    </div>
  );
}
