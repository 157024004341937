import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  toAbsoluteUrl,
} from "../../../../../../../_metronic/_helpers";
import { Pagination } from "../../../../../../../_metronic/_partials/controls";
import { sizePerPageList } from "../../PartnershipUIHelpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchDonations } from "../../../../../Donation/_redux/donation/donationAction";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { StatusColumnFormatter } from "../../../../../Donation/pages/list/donation-table/column-formatters";
import { fetchKurbanTableList } from "../../../../../Donation/_redux/kurban/kurbanAction";
import { findPartnershipDonation } from "../../../_redux/partnershipAction";
import { Formik } from "formik";
import ReactSelect from "react-select";
import { promiseTuplify } from "../../../../../../../_metronic/_utils/promise-utils";
import { findAllThkPeriods } from "../../../../../Donation/thk-period/thk-period-api";
import { QurbanFilter } from "./QurbanFilter";

const initQueryParams = {
  pageNumber: 1,
  pageSize: 10,
  filter: {
    thkPartnership: "",
    batch_id: "",
    dona_status: "",
    kurban_status: "",
    donation_hid: "",
    kurban_hid: "",
    thk_livestock_campaign: "",
    name: "",
  },
};

export default function QurbanTable({ thkPartnership = {}, history }) {
  const [queryParams, setQueryParams] = useState(initQueryParams);

  const dispatch = useDispatch();

  const { currentState } = useSelector(
    (state) => ({ currentState: state.thkPartnership.partnershipDonations }),
    shallowEqual
  );
  const { totalCount, entities, listLoading, error } = currentState;

  function fetchDonationPartnership() {
    if (thkPartnership.uuid) {
      dispatch(
        findPartnershipDonation({
          ...queryParams,
          filter: {
            ...queryParams.filter,
            thk_partnership_uuid: thkPartnership.uuid,
          },
        })
      );
    }
  }

  useEffect(() => {
    fetchDonationPartnership();
  }, [queryParams, thkPartnership]);

  function handleClickDetailDonation(uuid) {
    history.push(`/donasi/${uuid}/detail`);
  }

  // Table columns
  const columns = [
    {
      dataField: "thk_partnership_batch_hid",
      text: "Import Batch ID",
    },
    {
      dataField: "name_1",
      text: "Atas Nama",
    },
    {
      dataField: "thk_livestock_campaign_name",
      text: "Hewan Campaign",
    },
    {
      dataField: "donation_hid",
      text: "ID Donasi",
    },
    {
      dataField: "kurban_hid",
      text: "ID Kurban",
    },
    {
      dataField: "status",
      text: "Status Donasi",
      formatter: StatusColumnFormatter,
    },
    {
      dataField: "thk_kurban_status",
      text: "Status Kurban",
      formatter: (cell) => {
        if (cell) {
          const statusFormatter = {
            NOT_DISTRIBUTED: "Belum Di Distribusi",
            SETTLE: "Belum Di Distribusi",
            WAITING_PARTNER: "Belum Di proses",
            ACCEPTED_PARTNER: "Siap Potong",
            EXECUTED: "Terpotong",
            REPORTED: "Laporan Potong",
            FINISH: "FINISH",
          };
          let badge = "dark";
          switch (cell) {
            case "NOT_DISTRIBUTED":
              badge = "warning";
              break;
            case "SETTLE":
              badge = "warning";
              break;
            case "WAITING_PARTNER":
              badge = "warning";
              break;
            case "ACCEPTED_PARTNER":
              badge = "primary";
              break;
            case "EXECUTED":
              badge = "primary";
              break;
            case "REPORTED":
              badge = "primary";
              break;
            case "FINISH":
              badge = "success";
              break;

            default:
              break;
          }

          return (
            <span
              className={`label label-lg label-light-${badge} label-inline`}
            >
              {statusFormatter[cell]}
            </span>
          );
        }
      },
    },
    {
      text: "Actions",
      formatter: (_, row) => {
        const pdfUrl = `https://kis.dompetdhuafa.org/frontend/guest/status/reportPartnership/${row.uuid}`;
        return (
          <div className="d-flex">
            {["REPORTED", "FINISH"].includes(row.thk_kurban_status) && (
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">Download PDF</Tooltip>
                }
              >
                <a
                  className="btn btn-icon btn-light btn-hover-dark btn-sm mx-3"
                  href={pdfUrl}
                  target="_blank"
                >
                  <span className="svg-icon svg-icon-md svg-icon-dark">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Detail Donasi</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => handleClickDetailDonation(row.donation_uuid)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
          </div>
        );
      },
      formatExtraData: {},
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  return (
    <div>
      <QurbanFilter
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        thkPartnership={thkPartnership}
      />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="uuid"
                data={entities ? entities : []}
                columns={columns}
                // defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(setQueryParams)}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: productsUIProps.ids,
                //   setIds: productsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </div>
  );
}
