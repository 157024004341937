import React, { useState, useMemo } from 'react';

import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify'
import { toastOption } from "../../../../../_metronic/_helpers";
import { generateReportDonor } from '../../_redux/donatur/donaturCrud'
import moment from 'moment'
import { useDonaturUIContext } from "../../DonaturUIContext";
import { DateRangePicker } from 'react-dates';


export function DonaturExportDialog({ show, onHide }) {
    const donaturUIContext = useDonaturUIContext();
    const donaturUIProps = useMemo(() => {
        return {
            queryParams: donaturUIContext.queryParams,
            setQueryParams: donaturUIContext.setQueryParams
        };
    }, [donaturUIContext]);

    const [file_name, setFile_name] = useState('')
    const [errorFile, setErrorFile] = useState('')
    const [fileName, setFileName] = useState('')
    const [errorMessage, setErrorMessage] = useState("none")
    const [onFocused, setOnFocused] = useState(null)
    const [tanggal, setTanggal] = useState(
        {
            start_date: moment().subtract(3, 'months'),
            end_date: moment(),
        }
    )
    const generateExcel = async () => {
        let value = {}
        value.filter = donaturUIProps.queryParams.filter
        value.file_name = file_name
        value.start_date = tanggal.start_date
        value.end_date = tanggal.end_date
        if (file_name.length > 0) {
            generateReportDonor(value).then((response) => {
                // const file = new File([response.data], "test.xlsx", {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'})
                // const url = window.URL.createObjectURL(file);
                // const link = document.createElement('a');
                // link.href = url;
                // link.setAttribute('download', `Donatur_list_${moment().format(`DD_MM-YYYY`)}`);
                // document.body.appendChild(link);
                // link.click();
                toast.success(response.data.message, toastOption);
            }).catch(error => {
                // gunakan parse buffer karna request dengan response type arraybuffer
                // const err = JSON.parse(Buffer.from(error.response.data).toString('utf8'));
                toast.error(error.response.data.message, toastOption);
            })
            onHide()
            setErrorFile('')
            setFile_name('')
        } else {
            setErrorFile("Nama File Harus Diisi")
        }
        // console.log(value);

    }
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                aria-labelledby="modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="modal-sizes-title-lg">Export Data Donatur</Modal.Title>
                </Modal.Header>


                <Modal.Body className="overlay overlay-block cursor-default">
                    <div className="form-group text-center">
                        <div className="form-group text-center">
                            <div className="col">
                                <p>Maksimal Jangka Waktu Export Adalah 365 Hari,<br></br><span className="text-danger">Hasil Export akan di
                                    sesuaikan dengan pencarian yang sudah anda sesuaikan.</span> <br></br>Jika sudah
                                    yakin,
                                    harap klik tombol Confirm</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <input type="text" name="file_name"
                                    value={file_name} placeholder="Masukkan Nama File" className="form-control"
                                    onChange={(e) => setFile_name(e.target.value)} />
                                <small id="helpId" className="form-text text-danger">{errorFile}</small>
                            </div>
                            <div className="col-lg-12 text-center mt-3">
                                <DateRangePicker
                                    small={true}
                                    startDate={tanggal.start_date}
                                    endDate={tanggal.end_date}
                                    onDatesChange={({ startDate, endDate }) => {
                                        setTanggal({
                                            start_date: startDate,
                                            end_date: endDate
                                        })
                                    }}
                                    focusedInput={onFocused}
                                    onFocusChange={focusedInput => setOnFocused(focusedInput)}
                                    numberOfMonths={1}
                                    isOutsideRange={() => false}
                                    showClearDates={true}
                                    startDateId="start_date"
                                    endDateId="end_date"
                                    hideKeyboardShortcutsPanel={true}
                                />
                            </div>

                        </div>
                        {/* <form className="form-">
                            <input onChange={(e) => setFile_name(e.target.value)} placeholder="Masukkan Nama File"></input>
                        </form> */}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        onClick={onHide}
                        className="btn btn-light btn-elevate"
                    >
                        Cancel
                    </button>
                    <> </>
                    <button
                        type="submit"
                        onClick={() => generateExcel()}
                        className="btn btn-primary btn-elevate"
                    >
                        Confirm
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}