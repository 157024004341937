/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export const ActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex,
    { openDetailDonationPage },
) => {
    return (
        <>
            <OverlayTrigger
                overlay={<Tooltip id="donation-edit-tooltip">View Detail</Tooltip>}
            >
                <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={() => openDetailDonationPage(row.uuid)}
                >{row.id}
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                        <SVG
                            src={toAbsoluteUrl('/media/svg/icons/Communication/Write.svg')}
                        />
                    </span>
                </a>
            </OverlayTrigger>

        </>
    )
}
