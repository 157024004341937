import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import {
  authorizeAol,
  callbackAuthorize,
  saveConfigAol,
  getConfigAolBigQuery,
} from "./_redux/aolBigQueryCrud";
import { useQuery } from "../../../_metronic/_helpers";
import { toast } from "react-toastify";
import { getCoaDbList } from "../MasterData/_redux/coa-bigquery/coa-bigquery";
import { initAolScope } from "./AolBigQueryUIHelper";
import Loading from "../../../_metronic/_assets/js/components/loading";
import ConfirmationModal from "../../../_metronic/layout/components/confirmation-modal/ConfirmationModal";

export function AolBigQueryCard() {
  const [aolScopes, setAolScopes] = useState(initAolScope);
  const [aolDbList, setAolDbList] = useState([]);
  const [currentToken, setCurrentToken] = useState("");
  const [selectedDb, setSelectedDb] = useState({
    yayasan_db: null,
    wakaf_db: null,
  });
  const [showModal, setShowModal] = useState({
    confirmation: false,
  });

  const [loading, setLoading] = useState(false);

  const query = useQuery();
  const code = query.get("code");

  // const removeLocalStorage = () => {
  //   // remove to local storage
  //   localStorage.removeItem('yayasan_db_bq');
  //   localStorage.removeItem('wakaf_db_bq');
  //   localStorage.removeItem('token_bq');
  //   localStorage.removeItem('scope_bq');
  // }

  async function handleAuthorizeAol() {
    const payload = {
      scope: aolScopes,
    };
    const { data } = await authorizeAol(payload);

    if (data.data.isRedirect == true) {
      window.location.href = data.data.url;
    } else {
      // removeLocalStorage();
      toast.error("Terjadi Kesalahan!");
    }
  }

  async function handleCallbackAuthorize(code) {
    setLoading(true);
    const { data: callbackData } = await callbackAuthorize(code);
    if (!callbackData.status) {

       // handle remove local storage
      //  removeLocalStorage();
      return toast.error("Terjadi Kesalahan!");
    }

    const token = callbackData?.data?.val?.access_token;
    setCurrentToken(token);

    const scope = callbackData?.data?.val?.scope.replace(/ /g, "+");
    setAolScopes(scope);

    const { data: dbListData } = await getCoaDbList();
    setAolDbList(dbListData.data.d);

    // set to local storage
    // localStorage.setItem('token_bq', token);
    // localStorage.setItem('scope_bq', scope);
    localStorage.setItem('aol_db_bq', JSON.stringify(dbListData.data.d)); 

    setLoading(false);
  }

  function handleChangeScope(e) {
    setAolScopes(e.target.value);
  }

  function handleChangeSelect(e) {
    const key = e.target.name;
    const value = e.target.value;
    setSelectedDb((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  async function handleSaveConfig() {
    try {
      handleCloseModal("confirmation");
      setLoading(true);
      if (!selectedDb.yayasan_db) {
        setLoading(false);
        return toast.error("Harap memilih database untuk yayasan!");
      }
      if (!selectedDb.wakaf_db) {
        setLoading(false);
        return toast.error("Harap memilih database untuk wakaf!");
      }

      const data = {
        yayasan_db: selectedDb.yayasan_db,
        wakaf_db: selectedDb.wakaf_db,
      };

      const { data: saveResult } = await saveConfigAol(data);

      if (saveResult.status == true) {
        setLoading(false);

        // set to local storage
        // localStorage.setItem('yayasan_db_bq', data.yayasan_db);
        // localStorage.setItem('wakaf_db_bq', data.wakaf_db);

        return toast.success(saveResult.message);
      } else {
        setLoading(false);

        // handle remove local storage
        // removeLocalStorage();

        return toast.error("Terjadi Kesalahan");
      }
    } catch (error) {

      // handle remove local storage
      // removeLocalStorage();

      console.log(`error`, error);
    } finally {
      setLoading(false);
    }
  }

  function handleOpenModal(key) {
    setShowModal((prev) => ({
      ...prev,
      [key]: true,
    }));
  }

  function handleCloseModal(key) {
    setShowModal((prev) => ({
      ...prev,
      [key]: false,
    }));
  }

  // const validationLocalStorage = localStorage.getItem('yayasan_db_bq') && 
  // localStorage.getItem('wakaf_db_bq') && localStorage.getItem('token_bq') && 
  // localStorage.getItem('scope_bq');

  const [aolFound, setAolFound] = useState(false);

  const getDataConfigAolBQ = async () => {
    const { data } = await getConfigAolBigQuery();
    let dataAol = data.data;

    if (!dataAol) {
      return;
    }

    if (dataAol.length >= 5) {
      setAolFound(true);
      const dataFilter = dataAol.find((v) => v.key === "AOL_BIG_QUERY");

      setAolScopes(dataFilter.val.scope);
      setCurrentToken(dataFilter.val.access_token)
      setSelectedDb({
        yayasan_db: dataAol.find((v) => v.key === "YAYASAN_DB_BIGQUERY").val.id,
        wakaf_db: dataAol.find((v) => v.key === "WAKAF_DB_BIGQUERY").val.id,
      })
      return;
    }

    localStorage.removeItem('aol_db_bq')
    setAolFound(false);
  }

  useEffect(() => {
    if (code) {
      console.log(code);
      handleCallbackAuthorize(code);
    }
  }, [code]);

  // initial local storage found
  useEffect(() => {
    const aol_db = localStorage.getItem('aol_db_bq');
    
    if (aol_db) {
      setAolDbList(JSON.parse(aol_db));
    }

    getDataConfigAolBQ();
  },[]);
  

  return (
    <>
      <Loading show={loading} />
      <ConfirmationModal
        show={showModal.confirmation}
        onHide={() => handleCloseModal("confirmation")}
        onClick={handleSaveConfig}
        title={"Update AOL Big Query"}
        content={"Pastikan pemilihan config AOL Big Query sudah sesuai!"}
        type={"danger"}
      />
      <Card>
        <CardHeader title="Konfigurasi AOL Big Query">
          {aolFound && (
            <div class="mt-5">
              <ul class="list-group">
                <li id="connectionStatus" class="list-group-item list-group-item-success">Big Query Connected</li>
              </ul>
            </div>
          )} 
        </CardHeader>
        <CardBody>
          <div className="form-group row">
            <div className="col-lg-3">
              <label>Scope</label>
            </div>
            <div className="col-lg-9">
              <textarea
                disabled={currentToken && aolScopes}
                name="aol_scope"
                className="form-control"
                value={aolScopes}
                onChange={handleChangeScope}
              />
              {!aolFound && (
                <button
                  className="btn btn-primary float-right my-2"
                  onClick={handleAuthorizeAol}
                >
                  Authorized
                </button>
              )}
            </div>
          </div>

          {( code || aolFound ) && (
            <>
              <hr />
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Akses Token Big Query</label>
                </div>
                <div className="col-lg-9">{currentToken}</div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>List Database</label>
                </div>
                <div className="col-lg-9">
                  {aolDbList.map((item) => (
                    <>
                      <li key={item.id}>{item.alias}</li>
                    </>
                  ))}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <h5>Config DB</h5>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Yayasan</label>
                </div>
                <div className="col-lg-9">
                  <select
                    value={selectedDb.yayasan_db}
                    name="yayasan_db"
                    className="form-control"
                    onChange={handleChangeSelect}
                  >
                    <option value={""}>PILIH</option>
                    {aolDbList.map((db) => (
                      <option value={db.id}>{db.alias}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Wakaf</label>
                </div>
                <div className="col-lg-9">
                  <select
                    value={selectedDb.wakaf_db}
                    name="wakaf_db"
                    className="form-control"
                    onChange={handleChangeSelect}
                  >
                    <option value={""}>PILIH</option>
                    {aolDbList.map((db) => (
                      <option value={db.id}>{db.alias}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-primary float-right"
                  onClick={() => handleOpenModal("confirmation")}
                >
                  Simpan
                </button>
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}
