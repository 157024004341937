/* eslint-disable jsx-a11y/anchor-is-valid */
import { CircularProgress } from "@material-ui/core";
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, currencyFormatter } from "../../../../../_metronic/_helpers";
import { color } from "./StylingHelper";
import { numberFormatter } from "../../../../../_metronic/_helpers";

export function TilesTotalDonaturDonorWidget({
  className,
  iconColor = "info",
  widgetHeight = "150px",
  title = "",
  value = "",
  subTitle = "",
  subValue = "",
  loading = false,
  icon = toAbsoluteUrl("/media/svg/icons/Layout/Layout-Grid.svg"),
}) {
  return (
    <>
      <div className={`card card-custom ${className}`} style={{ height: widgetHeight }}>
        <div className={`card-body ${loading && "d-flex justify-content-center"}`}>
          {loading ? (
            <div className="text-center align-self-center">
              <CircularProgress />
            </div>
          ) : (
            <div>
              <i
                className="fas fa-users"
                style={{ color: color.primaryPurple, fontSize: "36px" }}
              ></i>
              <div className="text-muted font-weight-bold font-size-lg mt-8">Total Donatur:</div>
              <div className="text-dark font-weight-bolder font-size-h2 mt-1">
                {numberFormatter(value)}
              </div>

              <div className="text-muted font-weight-bold font-size-lg mt-3">Rata-rata Donasi:</div>
              <div className="text-dark font-weight-bolder font-size-h2 mt-1">{subValue}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
