import * as requestFromServer from './hewanKurbanCampaignCrud';
import { hewanKurbanCampaignSlice, callTypes } from './hewanKurbanCampaignSlice';
import { actions as auth } from '../../../Auth/_redux/authRedux'
import { toastOption } from '../../../../../_metronic/_helpers/ToastHelpers'
import { toast } from 'react-toastify'

const { actions } = hewanKurbanCampaignSlice;

export const fetchHewanKurbanCampaigns = (queryParams) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer.findHewanKurbanCampaign(queryParams)
        .then(response => {
            const { data: { total_items, items } } = response.data;
            dispatch(actions.hewanKurbanCampaignsFetched({ total_items, items }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchHewanKurbanCampaign = (id) => dispatch => {
    if (!id) {
        return dispatch(actions.hewanKurbanCampaignFetched({ hewanKurbanCampaignForEdit: undefined }));
    }

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.getHewanKurbanCampaignById(id)
        .then(response => {
            const { data: hewanKurbanCampaign } = response.data; // alias data -> donatur
            dispatch(actions.hewanKurbanCampaignFetched({ hewanKurbanCampaignForEdit: hewanKurbanCampaign }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const createHewanKurbanCampaign = (hewanKurbanCampaignForCreation) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.createHewanKurbanCampaign(hewanKurbanCampaignForCreation)
        .then(response => {
            dispatch(actions.hewanKurbanCampaignReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const updateHewanKurbanCampaign = (hewanKurbanCampaign, uuid) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.updateHewanKurbanCampaign(hewanKurbanCampaign, uuid)
        .then(response => {
            dispatch(actions.hewanKurbanCampaignReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};


export const deleteHewanKurbanCampaign = (hewanKurbanCampaign) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.deleteHewanKurbanCampaign(hewanKurbanCampaign)
        .then(response => {
            dispatch(actions.hewanKurbanCampaignReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};
