import React from "react";
import { Modal, Carousel, CarouselItem } from "react-bootstrap";

export default function PreviewImage({ show, onHide, images, title }) {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header>
        <Modal.Title>{title ? title : "Preview Gambar"}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        {images instanceof Array ? (
          images.map((image) => (
            <Carousel>
              <CarouselItem>
                <img
                  width="100%"
                  style={{ objectFit: "cover" }}
                  src={image}
                  alt=""
                />
              </CarouselItem>
            </Carousel>
          ))
        ) : (
          <img
            width="100%"
            style={{ objectFit: "cover" }}
            src={images}
            alt=""
          />
        )}
      </Modal.Body>
    </Modal>
  );
}
