import { createSlice } from "@reduxjs/toolkit";

const initialWakafState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  wakafForEdit: undefined,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const wakafSlice = createSlice({
  name: "wakaf",
  initialState: initialWakafState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getWakafById
    wakafFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.wakafForEdit = action.payload.wakafForEdit;
    },

    // findWakafs
    wakafsFetched: (state, action) => {
      // const { total_items, items, wakaf_item_array } = action.payload;
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
      // state.wakaf_item_array = wakaf_item_array
    },

    // Set to default state actionLoading & error
    wakafReload: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
  },
});
