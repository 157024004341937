import React from "react";
import { Route } from "react-router-dom";
import { PeriodTemplateCard } from "./PeriodTemplateCard";
import { PeriodTemplateUIProvider } from "./PeriodTemplateUIContext";
import { PrivateRoute } from "../../../../PrivateRoute";
import { RoleChecker } from "../../../../RoleChecker";

export function PeriodTemplatePage({ history }) {
  const periodTemplateUIEvents = {
    openEditPeriodTemplatePage: (id, type) => {
      history.push(`/qurban/period_template/${type}/${id}/edit`);
    },
  };

  return (
    <PeriodTemplateUIProvider periodTemplateUIEvents={periodTemplateUIEvents}>
      <PeriodTemplateCard />
    </PeriodTemplateUIProvider>
  );
}
