import React, { useMemo } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../../_metronic/_partials/controls';
import { useHewanKurbanUIContext } from './HewanKurbanUIContext';
import HewanKurbanTable from './hewan-kurban-table/HewanKurbanTable';
import { HewanKurbanFilter } from './hewan-kurban-table/hewan-kurban-filter/HewanKurbanFilter'
import { RoleChecker } from '../../../../../RoleChecker';

export const HewanKurbanCard = () => {

	const hewanKurbanUIContext = useHewanKurbanUIContext();
	const hewanKurbanUIProps = useMemo(() => {
		return {
			newHewanKurbanButtonClick: hewanKurbanUIContext.newHewanKurbanButtonClick,
		};
	}, [hewanKurbanUIContext]);

	return (
		<Card>
			<CardHeader title="Hewan Kurban List">
				<CardHeaderToolbar>
					{RoleChecker('Create', 'access_role') && <button
						type="button"
						className="btn btn-primary"
						onClick={hewanKurbanUIProps.newHewanKurbanButtonClick}
					>Hewan Kurban Baru
					</button>}
				</CardHeaderToolbar>
			</CardHeader>
			<CardBody>
				<HewanKurbanFilter />
				<HewanKurbanTable />
			</CardBody>
		</Card>
	);
};
