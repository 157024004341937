import React, { useMemo, useState, useEffect } from 'react';
import { ErrorMessage, Field, Formik } from 'formik';
import { Input, Select } from '../../../../_metronic/_partials/controls';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import * as actions from '../_redux/collectorAction';
import { useCollectorUIContext } from '../CollectorUIContext';
import { Modal } from 'react-bootstrap';
import { toastOption } from '../../../../_metronic/_helpers';
import { toast } from 'react-toastify'
import { values } from 'lodash';
import { RoleChecker } from '../../../RoleChecker';
// Validation schema
const validationSchema = Yup.object().shape({
    reason: Yup.string().required('Keterangan untuk reject tidak boleh kosong'),
});

export const ConfirmForm = ({ id, onHide, status, printDiv, printSetoranKeuangan }) => {

    const statuses = {
        batch_uuid: '',
        status: '',
        reason: '',
    };

    const dispatch = useDispatch();

    const [showReason, setShowReason] = useState(false);
    const changeConfirm = (e) => {
        let value = e.target.value;
        setShowReason(value === 'REJECT' ? true : false);
    };

    // Collector UI Context
    const collectorUIContext = useCollectorUIContext();
    const collectorUIProps = useMemo(() => {
        return {
            queryParams: collectorUIContext.queryParams,
        };
    }, [collectorUIContext]);

    // const saveConfirm = (values) => {
    //     let data = {
    //         batch_uuid: id,
    //         status_collect: values.status,
    //         reason: values.reason,
    //     };
    //     dispatch(actions.updateStatusCollect(data)).then((response) => {
    //             toast.success(response.message, toastOption)
    //             console.log(response);
    //             dispatch(actions.fetchCollects(collectorUIProps.queryParams))
    //             onHide()
    //         }
    //     ).catch((err) => {
    //         toast.error('GAGAL', toastOption)
    //     });
    // };

    const approve = () => {
        let data = {
            batch_uuid: id,
            status_collect: "APPROVED",
            reason: "",
        };
        dispatch(actions.updateStatusCollect(data)).then((response) => {
            toast.success(response.message, toastOption)
            console.log(response);
            dispatch(actions.fetchCollects(collectorUIProps.queryParams))
            onHide()
        }
        ).catch((err) => {
            console.log(err);
            toast.error(err, toastOption)
        });
    }

    const reject = (values) => {
        let data = {
            batch_uuid: id,
            status_collect: "REJECT",
            reason: values.reason,
        };
        dispatch(actions.updateStatusCollect(data)).then((response) => {
            toast.success(response.message, toastOption)
            console.log(response);
            dispatch(actions.fetchCollects(collectorUIProps.queryParams))
            onHide()
        }
        ).catch((err) => {
            console.log(err);
            toast.error(err, toastOption)
        });
    }


    const [printUrl, setPrintUrl] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        //Requset ke Collector Action
        dispatch(actions.fetchCollectDetail(id, status)).then(response => {
            setPrintUrl(response.url_print)
            setData(response.rows)
            // console.log(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);


    const downloadReportClick = () => {
        window.open(printUrl)
    }
    console.log(data);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={statuses}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                    reject(values)
                    // console.log(values)
                }
                }
            >{({ handleSubmit, handleChange, setFieldValue }) => (

                <>

                    <div className="form-group row">
                        {/* <div className="col-lg-5">
                            <Select
                                name="status"
                                label="status"
                                onChange={(e) => {
                                    handleChange(e);
                                    // changeConfirm(e);
                                    setFieldValue('reason', '');
                                }}
                            >
                                <option value="">--PILIH--</option>
                                <option value="APPROVED">APPROVED</option>
                                <option value="REJECT">REJECT</option>
                            </Select>
                            <ErrorMessage name='status'
                                          render={msg => <div style={{color: 'red'}}>{msg}</div>}/>
                        </div> */}
                        <div className="col-lg-6">

                        </div>

                        {showReason && (
                            <div className="col-lg-6">
                                <Field
                                    name="reason"
                                    component={Input}
                                    placeholder="Keterangan"

                                    onChange={(e) => {
                                        // handleChange(e)
                                        setFieldValue('reason', e.target.value);
                                    }}
                                    label="Keterangan"
                                />
                            </div>
                        )}
                    </div>

                    <Modal.Footer>
                        <button
                            type="submit"
                            onClick={onHide}
                            className="btn btn-light btn-elevate"
                        >
                            Cancel
                        </button>
                        {/* {RoleChecker('Print', 'collector') && navigator.userAgent !== 'sandra-mobile-app' &&
                            <button
                                type="button"
                                onClick={() => { printDiv() }}
                                className="btn btn-primary btn-elevate"
                            >
                                Print
                            </button>
                        } */}

                        {RoleChecker('Print', 'collector') && navigator.userAgent === 'sandra-mobile-app' &&
                            <button
                                type="button"
                                onClick={() => { downloadReportClick() }}
                                className="btn btn-primary btn-elevate"
                            >
                                Print Thermal
                            </button>
                        }

                        {RoleChecker('Print', 'collector') && navigator.userAgent !== 'sandra-mobile-app' &&
                            <button
                                type="button"
                                // onClick={() => { printDiv() }}
                                onClick={() => { printSetoranKeuangan(data[0].collect_batch_uuid, status) }}
                                className="btn btn-primary btn-elevate"
                            >
                                Print
                            </button>
                        }

                        {/* <button
                            type="button"
                            onClick={() => { printSetoranKeuangan(data[0].collect_batch_uuid, status) }}
                            className="btn btn-info btn-elevate"
                        >
                            Setorkan ke Keuangan
                        </button> */}
                        {
                            status === 'REQUEST' && (
                                <>
                                    {RoleChecker('Approve', 'collector') && <button
                                        type="submit"
                                        onClick={approve}
                                        className="btn btn-success btn-elevate"
                                    >
                                        Approve
                                    </button>}
                                    {RoleChecker('Reject', 'collector') && <button
                                        type="submit"
                                        // onClick={() => handleSubmit()}
                                        onClick={showReason === false ? () => { setShowReason(true) } : () => { handleSubmit() }}
                                        className="btn btn-danger btn-elevate"
                                    >
                                        Reject
                                    </button>}
                                </>

                            )}
                    </Modal.Footer>
                </>
            )}

            </Formik>
        </>
    );
};

