import axios from 'axios';
import { DonationURL } from '../../../../config/UrlEndPoint';

export const URL = `${DonationURL}livestock_campaign`;


// Get HewanKurbanCampaign All For use Combobox data
export function getHewanKurbanCampaignAll() {
    return axios.get(URL);
}

// //Get By ID
export function getHewanKurbanCampaignById(hewanKurbanCampaignId) {
    return axios.get(`${URL}/${hewanKurbanCampaignId}`);
}

// items => filtered/sorted result for table data
export function findHewanKurbanCampaign(queryParams) {
    return axios.post(`${URL}/find`, queryParams);
}

// POST => POST: Create the hewanKurbanCampaign on the server
export function createHewanKurbanCampaign(hewanKurbanCampaign) {
    return axios.post(URL, hewanKurbanCampaign);
}

// UPDATE => PUT: update the hewanKurbanCampaign on the server
export function updateHewanKurbanCampaign(hewanKurbanCampaign, uuid) {
    return axios.put(`${URL}/${uuid}`, hewanKurbanCampaign);
}

// UPDATE => Delete: Delete the hewanKurbanCampaign on the server
export function deleteHewanKurbanCampaign(hewanKurbanCampaign) {
    return axios.delete(`${URL}/${hewanKurbanCampaign.uuid}`, hewanKurbanCampaign);
}


