import axios from 'axios';
import { MasterDataURL } from '../../../../config/UrlEndPoint';

export const URL = `${MasterDataURL}fundtype`;
export const URLCOA = `${MasterDataURL}coa_bigquery`
// CREATE - item
export function createFundtype(fundtype) {
    return axios.post(URL, fundtype);
}

// READ - get All item
export function getFundtypeAll() {
    return axios.get(URL)
}

// READ - Get item by type name`
export function getFundtype(type) {
    return axios.post(`${URL}/find`, type);
}

export function migrateFundType(data) {
    return axios.post(`${URL}/migration`, data);
}


// READ - get Item by ID PARAMS
export function getFundtypeById(fundtypeId) {
    return axios.get(`${URL}/${fundtypeId}`);
}

// UPDATE => PUT: update the fundtype on the server
export function updateFundtype(fundtype) {
    return axios.put(`${URL}/${fundtype.code}`, fundtype);
}

export function getCoaAccountNumber(db, account_type, name, pageNumber, pageSize) {
    return axios.post(`${URLCOA}/get_all_account_number_bigquery`, { db, account_type, pageNumber, pageSize, name });
}

export function getCoaDbList() {
    return axios.get(`${URLCOA}/get_db_list_bigquery`)
}

export function getAolProject(payload) {
    return axios.post(`${URLCOA}/get_aol_project_bigquery`, payload);
}



