import React from 'react';
import { DoubleNameCard } from './DoubleNameCard';
import { DoubleNameUIProvider } from './DoubleNameUIContext';


export function DoubleNamePage({ history }) {

    return (
        <DoubleNameUIProvider>

            <DoubleNameCard />
        </DoubleNameUIProvider>
    );
}
