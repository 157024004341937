import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/partnershipAction";
import * as uiHelpers from "../PartnershipUIHelpers";

import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { usePartnershipUIContext } from "../PartnershipUIContext";
import { RoleChecker } from "../../../../../RoleChecker";
// import { ToastContainer } from 'react-toastify';

const PartnershipTable = (props) => {
  // Partnership UI Context
  const partnershipUIContext = usePartnershipUIContext();
  const partnershipUIProps = useMemo(() => {
    return {
      queryParams: partnershipUIContext.queryParams,
      setQueryParams: partnershipUIContext.setQueryParams,
      openEditPartnershipPage: partnershipUIContext.openEditPartnershipPage,
      openManagePartnershipPage: partnershipUIContext.openManagePartnershipPage,
    };
  }, [partnershipUIContext]);

  // Getting curret state of partnership list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.thkPartnership }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    //Requset ke Branch Action
    dispatch(actions.fetchPartnerships(partnershipUIProps.queryParams));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnershipUIProps.queryParams, dispatch]);

  const allowEdit = RoleChecker("Edit", "thk_partnership");

  // Table columns
  const columns = [
    {
      dataField: "thk_period.name",
      text: "Periode",
    },
    {
      dataField: "name",
      text: "Name Partnership",
    },
    {
      dataField: "donor.name",
      text: "Donatur / Lembaga",
    },
    {
      dataField: "channel.name",
      text: "Channel",
    },
    {
      dataField: "notes",
      text: "Notes",
    },
    // {
    //   dataField: "branch.name",
    //   text: "Cabang",
    // },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditPartnershipPage: partnershipUIProps.openEditPartnershipPage,
        openManagePartnershipPage: partnershipUIProps.openManagePartnershipPage,
        allowEdit,
      },
      classes: "text-right pr-0",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: partnershipUIProps.queryParams.pageSize,
    page: partnershipUIProps.queryParams.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                // defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  partnershipUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: productsUIProps.ids,
                //   setIds: productsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default PartnershipTable;
