import React, { createContext, useContext, useEffect, useState } from 'react';
import * as reqCollect from '../_redux/collectorCrud';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../_metronic/_helpers';
// import { useDispatch } from 'react-redux';

const CollectUIContext = createContext();

export function useCollectUIContext() {
    return useContext(CollectUIContext);
}

export const CollectUIConsumer = CollectUIContext.Consumer;

export function CollectUIProvider({ currentDonationId, children, history }) {

    // Fungsi pilih checker
    const initChecker = {
        uuid: undefined,
        name: undefined,
        email: undefined,
        channel_uuid: undefined,
    };

    const [checker, setChecker] = useState(initChecker);
    const [showChecker, setShowChecker] = useState(false);
    const openChecker = () => {
        setShowChecker(true);
    };

    const closeChecker = () => {
        setShowChecker(false);
    };

    const selectedChecker = (data) => {
        setChecker({
            uuid: data.uuid,
            name: data.name,
            email: data.email,
            channel_uuid: data.channel_uuid,
        });
        setShowChecker(false);
    };
    // End Fungsi pilih checker

    const [isDisabled, setIsDisabled] = useState(false); // Disable button request
    // Fungsi tampil item donasi
    const [item, setItem] = useState([]);
    useEffect(() => {

        async function fetchItem() {
            const resCollect = await reqCollect.getDonationApproved();
            setItem(resCollect.data.data);
            if (resCollect.data.data.length === 0) {
                setIsDisabled(true);
            }
        }

        fetchItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const dispatch = useDispatch();

    const requestChecker = () => {
        setIsDisabled(true);
        if (checker.uuid !== undefined) {
            let data = { settle_user_uuid: checker.uuid };

            reqCollect.updateRequestChecker(data)
                .then(response => {
                    toast.success(response.data.message, toastOption);
                    setItem([]);
                    setChecker(initChecker);// Reset To Default jika berhasil request Checker
                }).catch(err => {
                toast.error(err.response.data.message, toastOption);
                setIsDisabled(false);
            });
        } else {
            toast.error('Harap pilih user Checker', toastOption);
            setIsDisabled(false);
        }
    };

    const value = {
        checker,
        showChecker,
        openChecker,
        closeChecker,
        selectedChecker,
        requestChecker,
        item,
        isDisabled,
    };

    return (
        <CollectUIContext.Provider value={value}>
            {children}
        </CollectUIContext.Provider>
    );
}
