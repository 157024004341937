import { createSlice } from "@reduxjs/toolkit";

const initialPartnershipState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  partnershipForEdit: undefined,
  lastError: null,
  partnershipBatchList: [],
  partnershipBatchEdit: {},
  partnershipDonations: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
  },
  partnershipBatchReport: {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: [],
  },
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const partnershipSlice = createSlice({
  name: "partnership",
  initialState: initialPartnershipState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getPartnershipById
    partnershipFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.partnershipForEdit = action.payload.partnershipForEdit;
    },
    // findPartnership
    partnershipsFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = items;
      state.totalCount = total_items;
    },
    // findPartnership
    partnershipsDonations: (state, action) => {
      const { total_items, items } = action.payload;
      state.partnershipDonations.listLoading = false;
      state.partnershipDonations.error = null;
      state.partnershipDonations.entities = items;
      state.partnershipDonations.totalCount = total_items;
    },
    // findPartnershipBatchReport
    partnershipsBatchReport: (state, action) => {
      const { total_items, items } = action.payload;
      state.partnershipBatchReport.listLoading = false;
      state.partnershipBatchReport.error = null;
      state.partnershipBatchReport.entities = items;
      state.partnershipBatchReport.totalCount = total_items;
    },
    // Set to default state actionLoading & error
    partnershipReload: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },

    // findPartnershipBatch
    partnershipsBatchFetched: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.partnershipBatchList = items;
      state.totalCount = total_items;
    },

    // findPartnershipBatch
    partnershipBatchDetail: (state, action) => {
      const { total_items, items } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.partnershipBatchEdit = action.payload.partnershipBatchEdit;
    },
  },
});
