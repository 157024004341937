import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./DonorImportUIHelpers";

const DonorImportUIContext = createContext();

export function useDonorImportUIContext() {
  return useContext(DonorImportUIContext);
}

export const DonorImportUIConsumer = DonorImportUIContext.Consumer;

export function DonorImportUIProvider({ donorImportUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    newDonorImportButtonClick: donorImportUIEvents.newDonorImportButtonClick,
    openEditDonorImportPage: donorImportUIEvents.openEditDonorImportPage,
  };

  return <DonorImportUIContext.Provider value={value}>{children}</DonorImportUIContext.Provider>;
}
