import { createSlice } from '@reduxjs/toolkit';

let initialDonorGrowthState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    lastError: null,
    entitiesMonthly: null,
};

export const callTypes = {
    list: 'list',
    action: 'action',
};

export const donorGrowthSlice = createSlice({
    name: 'donorGrowth',
    initialState: initialDonorGrowthState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // getBranchById
        donaturFetched: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        // findBranchs
        donatursFetched: (state, action) => {
            const { total_items, items } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = items;
            state.totalCount = total_items;
        },

        donatursMonthlyFetched: (state, action) => {
            const { data } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entitiesMonthly = data;
        },

        // Set to default state actionLoading & error
        donatursReload: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
        },

    },
});

