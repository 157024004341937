import React, { useMemo } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { Modal } from 'react-bootstrap';
import { useCollectUIContext } from '../CollectUIContext';
import { CheckerHeaderUIProvider } from './CollectHeaderUIContext';
import { CollectFilter } from './CollectFilter';
import { CollectTable } from './CollectTable';

export function CollectHeader(props) {

    const collectUIContext = useCollectUIContext();
    const collectUIProps = useMemo(() => {
        return {
            checker: collectUIContext.checker,
            showChecker: collectUIContext.showChecker,
            openChecker: collectUIContext.openChecker,
            closeChecker: collectUIContext.closeChecker,
        };
    }, [collectUIContext]);

    const { name, email } = collectUIProps.checker;
    return (
        <>
            <Card border="primary">
                <CardHeader title="Pilih Checker">
                    <CardHeaderToolbar>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={collectUIProps.openChecker}
                        >Select
                        </button>
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>

                    <table>
                        <tbody>
                        <tr>
                            <td width="60px"><p><b>Nama</b></p></td>
                            <td width="10%"><p>:</p></td>
                            <td><p><b>{name}</b></p></td>
                        </tr>
                        <tr>
                            <td><p><b>Email</b></p></td>
                            <td><p>:</p></td>
                            <td><p><b>{email}</b></p></td>
                        </tr>
                        <tr>
                            <td><p><b>Telp</b></p></td>
                            <td><p>:</p></td>
                            <td><p><b>-</b></p></td>
                        </tr>
                        </tbody>
                    </table>

                    <Modal
                        size="lg"
                        show={collectUIProps.showChecker}
                        onHide={collectUIProps.closeChecker}
                        aria-labelledby="modal-sizes-title-lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="modal-sizes-title-lg">Select Checker</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                            <CheckerHeaderUIProvider>

                                <CollectFilter/>
                                <CollectTable/>

                            </CheckerHeaderUIProvider>

                        </Modal.Body>
                    </Modal>
                </CardBody>
            </Card>
        </>
    );
}

