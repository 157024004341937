import React, { useEffect, useState } from "react";
import { getDonationCountPartnership } from "../../_redux/partnershipCrud";
import PartnershipBatchTable from "./PartnershipBatchTable";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  deleteBatchPartnershipReport,
  getPartnershipBatchReport,
} from "../../_redux/partnershipAction";
import Loading from "../../../../../../_metronic/_assets/js/components/loading";
import ConfirmationModal from "../../../../../../_metronic/layout/components/confirmation-modal/ConfirmationModal";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../../_metronic/_helpers";

export default function PartnershipDownloadBatch({ thkPartnership }) {
  const [donationCount, setDonationCount] = useState({
    count_finish: "0",
    count_reported: "0",
    total_count: "0",
  });

  const [queryParams, setQueryParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    filter: {},
  });

  const [deletedData, setDeletedData] = useState({
    showModal: false,
    thk_partnership_uuid: null,
    file_uuid: null,
  });

  const dispatch = useDispatch();

  const { currentState, thkPartnershipState } = useSelector(
    (state) => ({
      currentState: state.thkPartnership.partnershipBatchReport,
      thkPartnershipState: state.thkPartnership,
    }),
    shallowEqual
  );

  const { totalCount, entities, listLoading, error } = currentState;

  const { actionsLoading } = thkPartnershipState;

  function getPartnershipBatch(thk_partnership_uuid) {
    dispatch(
      getPartnershipBatchReport({
        ...queryParams,
        filter: { thk_partnership_uuid },
      })
    );
  }

  async function getDonationCount(uuid) {
    const { data } = await getDonationCountPartnership(uuid);
    const { count_finish, count_reported, total_count } = data.response[0];
    setDonationCount({
      count_finish,
      count_reported,
      total_count,
    });
  }

  useEffect(() => {
    if (thkPartnership.uuid) {
      getPartnershipBatch(thkPartnership.uuid);
    }
  }, [queryParams, thkPartnership]);

  useEffect(() => {
    if (thkPartnership.uuid) {
      getDonationCount(thkPartnership.uuid);
    }
  }, [thkPartnership]);

  function deleteBatchFile() {
    setDeletedData((prev) => ({
      ...prev,
      showModal: false,
    }));
    const { thk_partnership_uuid, file_uuid } = deletedData;
    dispatch(
      deleteBatchPartnershipReport({ thk_partnership_uuid, file_uuid })
    ).then((res) => {
      toast.success(res.message, toastOption);
      getPartnershipBatch(thkPartnership.uuid);
    });
  }

  function handlecloseDeleteModal() {
    setDeletedData((prev) => ({
      showModal: false,
      thk_partnership_uuid: null,
      file_uuid: null,
    }));
  }

  return (
    <div>
      <Loading show={actionsLoading} />
      <ConfirmationModal
        show={deletedData.showModal}
        onHide={handlecloseDeleteModal}
        title="Hapus Laporan Batch"
        content={`Apakah anda yakin untuk menghapus laporan batch ini?`}
        subContent={`Laporan akan di generate ulang secara otomatis dalam 1 jam kedepan`}
        type="danger"
        onClick={() => deleteBatchFile()}
      />
      <PartnershipBatchTable
        queryParams={queryParams}
        setQueryParams={setQueryParams}
        entities={entities}
        listLoading={listLoading}
        totalCount={totalCount}
        setDeletedData={setDeletedData}
      />
    </div>
  );
}
