import React, { useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../../_metronic/_partials/controls';
import { currencyFormatter } from '../../../../../_metronic/_helpers';
import { useCollectUIContext } from '../CollectUIContext';
import Moment from 'react-moment';
import { CollectHeader } from '../header/CollectHeader';
import BootstrapTable from 'react-bootstrap-table-next';
import { RoleChecker } from '../../../../RoleChecker';

export function CollectList(props) {

	const collectUIContext = useCollectUIContext();
	const collectUIProps = useMemo(() => {
		return {
			item: collectUIContext.item,
			requestChecker: collectUIContext.requestChecker,
			isDisabled: collectUIContext.isDisabled,
		};
	}, [collectUIContext]);

	const paymentTypeDeterminer = (cash, bank) => {
		if (cash > 0 && bank == 0) {
			return (
				<span>
					<strong style={{ color: 'green' }}>Cash</strong>
				</span>
			)
		} else if (cash > 0 && bank > 0) {
			return (
				<span>
					<strong style={{ color: 'red' }}>Bank + Cash</strong>
				</span>
			)

		} else if (cash == 0 && bank > 0) {
			return (
				<span>
					<strong style={{ color: 'blue' }}>Bank</strong>
				</span>
			);
		} else if (cash == 0 && bank == 0) {
			return (
				<span>
					<strong style={{ color: 'purple' }}>Partner</strong>
				</span>
			);
		}
	}
	return (
		<>
			<Card border="primary">
				<CardHeader title="Daftar Request Disetujui">

				</CardHeader>
				<CardBody>

					<CollectHeader />
					<Table striped hover responsive className="text-center">
						<thead>
							<tr>
								<th>#</th>
								<th>Fundraiser Name</th>
								<th>Donation Item</th>
								<th>Tipe Pembayaran</th>
								<th>Jumlah</th>
								<th>Tanggal Collect</th>
							</tr>
						</thead>
						<tbody>
							{
								collectUIProps.item.map((row, index) =>
									<tr key={row.collect_batch_uuid}>
										<td>{index + 1}</td>
										<td>{row.name}</td>
										<td>{row.trx_item}</td>
										<td>{paymentTypeDeterminer(row.payment_cash, row.payment_edc)}</td>
										<td>{currencyFormatter(row.trx_total)}</td>
										<td>
											<Moment format="DD-MMM-YYYY h:mm:ss">{row.collected_at}</Moment>
										</td>
									</tr>,
								)
							}
						</tbody>
					</Table><br />

				</CardBody>
				<CardBody>
					<CardHeaderToolbar>
						{RoleChecker('Collect', 'collector') && <button
							disabled={collectUIProps.isDisabled}
							type="button"
							className="btn btn-danger float-right"
							onClick={collectUIProps.requestChecker}
						>Request Checker
						</button>}
					</CardHeaderToolbar>
				</CardBody>
			</Card>


		</>
	);
}

