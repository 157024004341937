import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useThkChannelReportUIContext } from "../../ThkChannelReportUIContext";
import { initialFilter } from "../../ThkChannelReportUIHelpers";
import AsyncSelect from "react-select/async";
import ReactSelect from "react-select";
import { promiseTuplify } from "../../../../../../../_metronic/_utils/promise-utils";
import { findAllThkPeriods } from "../../../../../Donation/thk-period/thk-period-api";
import { genOptionLoader } from "../../../../../../../_metronic/_utils/option-loaders";
import { findDonaturs } from "../../../../../Donatur/_redux/donatur/donaturCrud";
import { findChannels } from "../../../../../MasterData/_redux/channels/channelsCrud";
import { Select } from "../../../../../../../_metronic/_partials/controls";

const prepareFilter = (queryParams, values) => {
  const { report_template_type, thk_period, channel } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  //add to Object filter
  filter.report_template_type = report_template_type ?? "";
  filter.thk_period_uuid = thk_period?.uuid ?? "";
  filter.channel_uuid = channel?.uuid ?? "";
  newQueryParams.filter = filter;
  return newQueryParams;
};

export const ThkChannelReportFilter = ({ listLoading }) => {
  const [options, setOptions] = useState({
    period: [],
    channel: [],
  });

  useEffect(() => {
    fetchThkPeriods();
    fetchChannel();
  }, []);

  // ThkChannelReport UI Context
  const thkChannelReportUIContext = useThkChannelReportUIContext();
  const thkChannelReportUIProps = useMemo(() => {
    return {
      setQueryParams: thkChannelReportUIContext.setQueryParams,
      queryParams: thkChannelReportUIContext.queryParams,
      filterType: thkChannelReportUIContext.filterType,
    };
  }, [thkChannelReportUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      thkChannelReportUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, thkChannelReportUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      thkChannelReportUIProps.setQueryParams(newQueryParams);
    }
  };

  async function fetchThkPeriods() {
    const [res, error] = await promiseTuplify(findAllThkPeriods());

    if (error) {
      return;
    }

    if (res) {
      const { data: { data = [] } = {} } = res;

      const options = data.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        period: options,
      }));
    }
  }

  async function fetchChannel() {
    const [res, error] = await promiseTuplify(
      findChannels({
        pageNumber: 1,
        pageSize: 20,
        filter: {},
      })
    );

    if (error) {
      return;
    }

    if (res) {
      const { data: { data: { items = [] } } = {} } = res;

      const options = items.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        channel: options,
      }));
    }
  }

  return (
    <Formik
      initialValues={initialFilter.filter}
      onSubmit={(values) => {
        applyFilter(values);
      }}
      onReset={(values) => {
        applyFilter(values);
      }}
    >
      {({ values, handleSubmit, handleBlur, setFieldValue, handleReset }) => (
        <form onSubmit={handleSubmit} className="form form-label-right">
          <div className="form-group row">
            <div className="col-lg-4">
              <select
                name="report_template_type"
                className="form-control"
                value={values.report_template_type}
                onChange={(e) => setFieldValue(e.target.name, e.target.value)}
              >
                <option value={""}>--PILIH--</option>
                <option value={"1"}>1</option>
                <option value={"2"}>2</option>
              </select>
              <small className="form-text text-muted">
                <b>Cari</b> Berdasarkan Tipe
              </small>
            </div>
            <div className="col-lg-4 mb-5">
              <ReactSelect
                name="thk_period"
                placeholder={"pilih periode"}
                options={options.period}
                value={
                  values.thk_period && values.thk_period.uuid
                    ? {
                        label: values.thk_period.name ?? "",
                        value: values.thk_period.uuid ?? "",
                      }
                    : null
                }
                onChange={({ label: name, value: uuid }) => {
                  setFieldValue("thk_period", {
                    uuid,
                    name,
                  });
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Berdasarkan Periode
              </small>
            </div>
            <div className="col-lg-4 mb-5">
              <AsyncSelect
                name="channel"
                defaultOptions={options.channel}
                placeholder={"Pilih channel"}
                loadOptions={genOptionLoader(
                  (inputValue) =>
                    findChannels({
                      filter: { name: inputValue },
                      pageNumber: 1,
                      pageSize: 50,
                    }),
                  "items",
                  ({ name: label, uuid }) => {
                    return {
                      label,
                      value: uuid,
                    };
                  }
                )}
                value={
                  values.channel && values.channel.uuid
                    ? {
                        label: values.channel.name,
                        value: values.channel.uuid,
                      }
                    : null
                }
                onChange={({ label: name, value: uuid }) => {
                  setFieldValue("channel", { uuid, name });
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Channel
              </small>
            </div>
          </div>
          <div className="form-group float-right">
            <button
              className="btn btn-outline-danger mx-1"
              onClick={handleReset}
            >
              Reset Pencarian
            </button>
            <button className="btn btn-warning mx-1" onClick={handleSubmit}>
              Terapkan
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};
