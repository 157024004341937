import { LinearProgress, Typography, withStyles } from "@material-ui/core";
import React, { useEffect } from "react";

export default function Loading({
  show,
  percentage,
  message = "Harap Tunggu...",
}) {
  const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#08703F",
    },
  }))(LinearProgress);

  useEffect(() => {
    if (show) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [show]);

  return (
    <>
      {show && (
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#000",
            zIndex: 1000,
            opacity: 0.5,
            position: "fixed",
            top: 0,
            left: 0,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%)",
              color: "#FFF",
              width: "50%",
            }}
          >
            <div style={{ width: "100%", mr: 1 }}>
              {percentage ? (
                <BorderLinearProgress
                  variant="determinate"
                  value={percentage}
                  style={{ height: "8px" }}
                />
              ) : (
                <BorderLinearProgress style={{ height: "8px" }} />
              )}
            </div>
            <div style={{ minWidth: 35 }}>
              {percentage ? (
                <Typography variant="body2" color="text.secondary">
                  {`${percentage}%`}
                </Typography>
              ) : (
                <Typography variant="body2" color="text.secondary">
                  {message}
                </Typography>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
