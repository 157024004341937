import React, { createContext, useCallback, useContext, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './HewanKurbanUIHelpers';

const HewanKurbanUIContext = createContext();

export function useHewanKurbanUIContext() {
    return useContext(HewanKurbanUIContext);
}

export const HewanKurbanUIConsumer = HewanKurbanUIContext.Consumer;

export function HewanKurbanUIProvider({ hewanKurbanUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const initHewanKurban = {
        name: "",
        price: "",
        category: "",
        type: "",
    };

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        initHewanKurban,
        newHewanKurbanButtonClick: hewanKurbanUIEvents.newHewanKurbanButtonClick,
        openEditHewanKurbanPage: hewanKurbanUIEvents.openEditHewanKurbanPage,
    };

    return (
        <HewanKurbanUIContext.Provider value={value}>
            {children}
        </HewanKurbanUIContext.Provider>
    );

}
