import moment from "moment";

export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];

export const initialFilter = {
  filter: {
    name: "",
    kd_cc: "",
    email: "",
    hp: "",
    city: "",
    provinsi: "",
    type: "",
    branch: "",
    start_date: moment().subtract(1, "months"),
    end_date: moment(),
    is_letters: "",
    filterCabang: true,
  },
  pageNumber: 1,
  pageSize: 10,
};

// for popup donor filter in some component
export const initialFilterForChild = {
  filter: {
    name: "",
    kd_cc: "",
    email: "",
    hp: "",
    city: "",
    provinsi: "",
    type: "",
    branch: "",
    start_date: moment().subtract(1, "months"),
    end_date: moment(),
    is_letter: "",
    filterCabang: false,
  },
  pageNumber: 1,
  pageSize: 10,
};

export const initUpdateBranch = {
  branch_uuid: "",
};

export const initialFilterDonasi = {
  filter: {
    trxDate: "",
    user_uuid: "",
    type_donor: "",
    donation_item_project: "",
    donation_item_type: "",
    donor_name: "",
    channel_uuid: "",
    start_date: "",
    end_date: "",
    status: "",
    total_amountlt: "",
    total_amountgt: "",
    donation_hid: "",
  },
  pageNumber: 1,
  pageSize: 10,
};

export const initialFilterForParent = {
  filter: {
    name: "",
    type: "INDIVIDU",
  },
  pageNumber: 1,
  pageSize: 10,
};

export const donaturTypeList = [
  {
    value: "INDIVIDU",
    name: "Individu",
  },
  {
    value: "LEMBAGA",
    name: "Lembaga",
  },
  {
    value: "KOMUNITAS",
    name: "Komunitas",
  },
];

export const isAlive = [
  {
    value: true,
    name: "Hidup",
  },
  {
    value: false,
    name: "Meninggal",
  },
];

export const greetingValue = [
  {
    value: "Bapak",
  },
  {
    value: "Ibu",
  },
  {
    value: "Mr.",
  },
  {
    value: "Mrs.",
  },
  {
    value: "Haji",
  },
  {
    value: "Hajjah",
  },
];
export const isActive = [
  {
    value: true,
    name: "Aktif",
  },
  {
    value: false,
    name: "Non Aktif",
  },
];

export let type = [
  {
    value: "INDIVIDU",
    name: "Individu",
  },
  {
    value: "LEMBAGA",
    name: "Lembaga/Perusahaan",
  },
  {
    value: "KOMUNITAS",
    name: "Komunitas",
  },
];

export const jenisKelamin = [
  {
    value: "-",
    name: "-",
  },
  {
    value: "L",
    name: "Pria",
  },
  {
    value: "P",
    name: "Wanita",
  },
];

export const pendidikan = [
  {
    value: "SD",
    name: "SD",
  },
  {
    value: "SMP",
    name: "SMP",
  },
  {
    value: "SMA",
    name: "SMA",
  },
  {
    value: "S1",
    name: "S1",
  },
  {
    value: "S2",
    name: "S2",
  },
  {
    value: "S3",
    name: "S3",
  },
  {
    value: "LAIN-LAIN",
    name: "LAIN-LAIN",
  },
];

export const letterChoice = [
  {
    value: true,
    name: "Ya",
  },
  {
    value: false,
    name: "Tidak",
  },
];

export let initDonatur = {
  name: "",
  hp: "",
  email: "",
  province: "",
  city: "",
  district: "",
  postal_code: "",
  address: "",
  npwp: "",
  npwz: "",
  nik: "",
  is_alive: "",
  is_active: "",
  greeting: "",
  type: "",
  kode_rekening: "",
  pic: "",
  pic_hp: "",
  pic_email: "",
  parent_uuid: "",
};

export const DONATUR_NOTIFICATION_TYPES = {
  WHATSAPP: "WHATSAPP",
  EMAIL: "EMAIL",
  SMS: "SMS",
};

export const initDonaturProfile = {
  jenis_kelamin: "",
  pendidikan: "",
  pekerjaan: "",
  tanggal_lahir: undefined,
};

export const initDonaturPassword = {
  password: "",
  cPassword: "",
};

export const initLetter = [
  {
    value: "",
    name: "-PILIH-",
  },
];

export const DONATUR_IS_PROSPECT = [
  {
    value: true,
    name: "PROSPEK",
  },
  {
    value: false,
    name: "NON PROSPEK",
  },
];

export const isActiveLabel = (status) => {
  let data = {};
  if (status) {
    data = {
      type: "success",
      name: "ACTIVE",
    };
  } else {
    data = {
      type: "danger",
      name: "NON ACTIVE",
    };
  }

  return data;
};

export const badge = (status) => {
  let data = {};
  switch (status) {
    case "VOID":
      data = { type: "danger" };
      break;
    case "REQUEST_VOID":
      data = { type: "warning" };
      break;
    case "SETTLE":
      data = { type: "success" };
      break;
    default:
      data = { type: "info" };
  }

  return data;
};

export function getDonaturInitialValues(donatur) {
  const {
    uuid = "",
    donor_id = "",
    branch_uuid = "",
    name = "",
    hp = "",
    hp2 = "",
    email = "",
    address = "",
    country = "",
    province = "",
    city = "",
    district = "",
    postal_code = "",
    nik = "",
    kode_rekening = "",
    json_profile = {
      jenis_kelamin: "",
      pendidikan: "",
      pekerjaan: "",
      tanggal_lahir: "",
      hobi: "",
      description: "",
    },
    type = "",
    npwp = "",
    npwz = "",
    is_alive = true,
    is_active = true,
    greeting = "",
    sync = false,
    is_email = true,
    is_letters = false,
    pic = "",
    pic_hp = "",
    pic_email = "",
    kd_cc = "",
    password = "",
    otp = "",
    created_at = "",
    updated_at = "",
    category_uuid = "",
    category,
    branch = {
      name: "",
    },
    donor_log = [],
    notification_allowed_types = [],
    donor_source,
    is_prospect,
  } = donatur ?? {};

  const values = {
    uuid: uuid ?? "",
    donor_id: donor_id ?? "",
    branch_uuid: branch_uuid ?? "",
    name: name ?? "",
    hp: hp ?? "",
    hp2: hp2 ?? "",
    email: email ?? "",
    address: address ?? "",
    country: country ?? "",
    province: province ?? "",
    city: city ?? "",
    district: district ?? "",
    postal_code: postal_code ?? "",
    nik: nik ?? "",
    kode_rekening: kode_rekening ?? "",
    json_profile: {
      jenis_kelamin:
        json_profile && json_profile.jenis_kelamin
          ? {
              label: (jenisKelamin.find(({ value }) => value === json_profile.jenis_kelamin) ?? {})
                .name,
              value: json_profile.jenis_kelamin,
            }
          : { label: "", value: "" },
      pendidikan:
        json_profile && json_profile.pendidikan
          ? {
              label: (pendidikan.find(({ value }) => value === json_profile.pendidikan) ?? {}).name,
              value: json_profile.pendidikan,
            }
          : { label: "", value: "" },
      pekerjaan: (json_profile && json_profile.pekerjaan) ?? "",
      tanggal_lahir: (json_profile && json_profile.tanggal_lahir) ?? "",
      hobi: (json_profile && json_profile.hobi) ?? "",
      description: (json_profile && json_profile.description) ?? "",
    },
    type: type ?? "",
    npwp: npwp ?? "",
    npwz: npwz ?? "",
    is_alive: is_alive ?? true,
    is_active: is_active ?? true,
    greeting: greeting ?? "",
    sync: sync ?? false,
    is_email: is_email ?? true,
    is_letters: is_letters ?? false,
    pic: pic ?? "",
    pic_hp: pic_hp ?? "",
    pic_email: pic_email ?? "",
    kd_cc: kd_cc ?? "",
    password: password ?? "",
    otp: otp ?? "",
    created_at: created_at ?? "",
    updated_at: updated_at ?? "",
    category_uuid: category_uuid ?? "",
    branch: {
      uuid: (branch && branch.uuid) ?? "",
      name: (branch && branch.name) ?? "",
    },
    category: {
      uuid: (category && category.uuid) ?? "",
      name: (category && category.name) ?? "",
    },
    donor_source: {
      label: (donor_source && donor_source.name) ?? "",
      value: (donor_source && donor_source.uuid) ?? "",
    },
    donor_log: donor_log ?? [],
    notification_allowed_types: {
      SMS: false,
      WHATSAPP: false,
      EMAIL: false,
    },
    is_prospect: is_prospect === null || is_prospect === true ? true : false
  };

  if (notification_allowed_types instanceof Array) {
    values.notification_allowed_types.EMAIL = 
    notification_allowed_types.includes(DONATUR_NOTIFICATION_TYPES.EMAIL) || !values.uuid;
    values.notification_allowed_types.SMS =
      notification_allowed_types.includes(DONATUR_NOTIFICATION_TYPES.SMS) || !values.uuid;
    values.notification_allowed_types.WHATSAPP =
      notification_allowed_types.includes(DONATUR_NOTIFICATION_TYPES.WHATSAPP) || !values.uuid;
  }

  return values;
}
