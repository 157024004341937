import React, { useState } from 'react';
import * as Yup from "yup"
import Select from 'react-select'
import { searchDonaturs } from '../_redux/donatur/donaturCrud';
import DonaturTrx from '../donatur-trx/DonaturTrx';
import {
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CardHeaderToolbar,
    ModalProgressBar,
} from '../../../../_metronic/_partials/controls';
import { DonaturMergeDonationDialog } from './DonaturMergeDonationDialog';
import * as actions from '../_redux/donatur/donaturAction'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { copyValue, currencyFormatter, toastOption } from '../../../../_metronic/_helpers';
import { Backdrop } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export const DonaturMergeDonation = ({ donatur, tab, openDetailDonationPage, actionsLoading }) => {
    const dispatch = useDispatch();


    const [donatur2, setDonatur2] = useState([])
    const [selectedDonatur2, setSelectedDonatur2] = useState()

    const onInputDonatur2 = async (value) => {
        if (value.length >= 3) {
            await searchDonaturs({ name: value }).then(response => {
                let options = response.data.data.map((item) => {
                    return { label: `${item.name} - ${item.donor_id}`, value: item }
                })
                setDonatur2(options)
            })
        }
    };

    const mergeDonation = (donatur1_uuid, donatur2_uuid, donatur1_name, donatur2_name, donatur1_id, donatur2_id) => {
        dispatch(actions.migrateDonation({
            donor_uuid_receiver: donatur1_uuid,
            donor_uuid_sender: donatur2_uuid,
            donor_name_receiver: donatur1_name,
            donor_name_sender: donatur2_name,
            donor_id_receiver: donatur1_id,
            donor_id_sender: donatur2_id
        }))
            .then(response => {
                if (response) {
                    toast.success(response.message, toastOption);
                }
            })
    }
    // modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    console.log(selectedDonatur2);


    return (
        <>
            {donatur &&
                <div className="row">
                    <Card className="col-lg-12">
                        <CardHeader title="Pilih Donatur Yang Akan Di Gabungkan">

                        </CardHeader>
                        <CardBody>
                            <div className="row">
                                <div className="col-md-6">
                                    <div><h5>Donatur Pemberi</h5></div>
                                    <Select
                                        onInputChange={(e) => {
                                            onInputDonatur2(e)
                                        }}
                                        onChange={(e) => {
                                            setSelectedDonatur2(e)
                                        }}
                                        options={donatur2}
                                        isClearable={true} />
                                </div>
                                <div className="col-md-6">
                                    <div><h5>Donatur Penerima</h5></div>
                                    <Select
                                        className=""
                                        placeHolder="Input Penerima Satu"

                                        defaultValue={[{ label: `${donatur.name} - ${donatur.donor_id}`, value: donatur.uuid }]}

                                        isDisabled={true}
                                        isClearable={true} />
                                </div>
                            </div>


                        </CardBody>
                    </Card>

                    {selectedDonatur2 &&
                        <>
                            <div className="col-lg-12">
                                <Card>
                                    <CardHeader title={`Daftar Donasi Dari ${selectedDonatur2.value.name} - ${selectedDonatur2.value.donor_id}`}>

                                    </CardHeader>
                                    <DonaturTrx openDetailDonationPage={openDetailDonationPage} donaturType={selectedDonatur2.value.type} user_uuid={selectedDonatur2.value.uuid} tab={tab} merge={true} />
                                    <CardFooter>
                                        <button className="btn btn-primary float-right" onClick={handleShow}>Gabungkan Donasi</button>
                                    </CardFooter>
                                </Card>

                            </div>




                            <DonaturMergeDonationDialog handleClose={handleClose} handleShow={handleShow} donatur1={donatur} donatur2={selectedDonatur2.value} show={show} mergeDonation={mergeDonation} />

                        </>
                    }
                </div>
            }
            <Backdrop open={actionsLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}