import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';

import { useDonaturUIContext } from './DonaturUIContext';
import DonaturFilter from './donatur-filter/DonaturFilter';
import DonaturTable from './donatur-table/DonaturTable';
import { Badge, Collapse } from "react-bootstrap";
import { DonaturExportDialog } from "./donatur-table/donatur-table-dialog/DonaturExportDialog";
import { shallowEqual, useSelector } from "react-redux";
import { RoleChecker } from '../../RoleChecker';

const DonaturCard = () => {

    const donaturUIContext = useDonaturUIContext();
    const donaturUIProps = useMemo(() => {
        return {
            queryParams: donaturUIContext.queryParams,
            newDonaturButtonClick: donaturUIContext.newDonaturButtonClick,
            openEditDonaturPage: donaturUIContext.openEditDonaturPage,
            filterType: donaturUIContext.filterType,
            // openDialogExport: donaturUIContext.openDialogExport,
            showExportDonorDialog: donaturUIContext.showExportDonorDialog,
            closeExportDonorDialog: donaturUIContext.closeExportDonorDialog,
            openExportDonorDialog: donaturUIContext.openExportDonorDialog
        };
    }, [donaturUIContext]);

    const { userAuth } = useSelector(
        (state) => ({ userAuth: state.auth.user }),
        shallowEqual,
    );

    const { user } = useSelector(
        (state) => ({ user: state.auth.user }),
        shallowEqual,
    );

    const [statusFilter, setStatusFilter] = useState(false);
    const [open, setOpen] = useState(false);

    const changeShow = () => {
        setOpen(!open);
    };
    useEffect(() => {
        if (donaturUIProps.filterType !== undefined) {
            setStatusFilter(true);
            // setIsDisabled(true);
        }
        // eslint-disable-next-line
    }, [donaturUIProps.filterType]);

    const access = ['DataProses', 'AdminCabang', 'SuperAdmin', 'KepalaCabang']


    return (
        <Card>
            <CardHeader title="Daftar Donatur">
                <CardHeaderToolbar>
                    {statusFilter && (
                        <Badge className="mr-5" pill variant="danger">Filter Aktif</Badge>
                    )}
                    <button
                        type="button"
                        className="btn btn-warning mr-2"
                        onClick={changeShow}
                        aria-controls="collapse-filter-donatur"
                        aria-expanded={open}
                    >Cari
                    </button>
                    {(navigator.userAgent !== 'sandra-mobile-app' && RoleChecker("Export Donatur", 'daftar_donatur')) && (
                        <button
                            type="button"
                            className="btn btn-info mr-2"
                            onClick={donaturUIProps.openExportDonorDialog}
                        >Export Donatur
                        </button>
                    )}
                    {RoleChecker("Create Donatur", 'daftar_donatur') && <button
                        type="button"
                        className="btn btn-primary mr-2"
                        onClick={donaturUIProps.newDonaturButtonClick}
                    >Tambah Donatur
                    </button>}
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <Collapse in={!open}>
                    <div id="collapse-filter-donatur">
                        <DonaturFilter onChangeStatus={setStatusFilter}/>
                    </div>
                </Collapse>
                <DonaturExportDialog
                    show={donaturUIProps.showExportDonorDialog}
                    onHide={donaturUIProps.closeExportDonorDialog}
                />
                <DonaturTable />

            </CardBody>
        </Card>
    );
};

export default DonaturCard;
