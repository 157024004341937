import React, { createContext, useCallback, useContext, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './CheckerUIHelper';

const CheckerUIContext = createContext();

export function useCheckerUIContext() {
    return useContext(CheckerUIContext);
}

export const CheckerUIConsumer = CheckerUIContext.Consumer;

export function CheckerUIProvider({ checkerUIEvents, children }) {
    // const [batchId, setBatchID] = useState(currentBatchId)
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        openCheckerRequestDialog: checkerUIEvents.openCheckerRequestDialog,
    };

    return (
        <CheckerUIContext.Provider value={value}>
            {children}
        </CheckerUIContext.Provider>
    );
}
