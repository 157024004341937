import React, { createContext, useCallback, useContext, useState, useEffect } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './DoubleNameUIHelpers';

const DoubleNameUIContext = createContext();

export function useDoubleNameUIContext() {
    return useContext(DoubleNameUIContext);
}

export const DoubleNameUIConsumer = DoubleNameUIContext.Consumer;

export function DoubleNameUIProvider({ children }) {
    const [selectedDonor, setSelectedDonor] = useState(null)
    const [queryParams, setQueryParamsBase] = useState({ ...initialFilter });
    const [queryParamsDetail, setQueryParamsDetail] = useState({ ...initialFilter, filter: { ...initialFilter.filter } });
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    useEffect(() => {
        setQueryParamsDetail((prev) => {
            const oldPrev = { ...prev }
            oldPrev.pageNumber = 1
            oldPrev.filter.name = selectedDonor

            return oldPrev
        })
    }, [selectedDonor])



    const initDoubleName = {
        name: '',
    };

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        initDoubleName,
        setSelectedDonor,
        selectedDonor,
        setQueryParamsDetail,
        queryParamsDetail
    };

    return (
        <DoubleNameUIContext.Provider value={value}>
            {children}
        </DoubleNameUIContext.Provider>
    );

}
