import React, { createContext, useCallback, useContext, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './KurbanUIHelpers';

const KurbanListUIContext = createContext()

export function useKurbanListUIContext() {
	return useContext(KurbanListUIContext)
}

export function KurbanListUIProvider({ kurbanListUIEvents, children }) {
	const [queryParams, setQueryParamsBase] = useState(initialFilter);
	const setQueryParams = useCallback((nextQueryParams) => {
		setQueryParamsBase((prevQueryParams) => {
			if (isFunction(nextQueryParams)) {
				nextQueryParams = nextQueryParams(prevQueryParams);
			}

			if (isEqual(prevQueryParams, nextQueryParams)) {
				return prevQueryParams;
			}

			return nextQueryParams;
		});
	}, []);


	const value = {
		queryParams,
		setQueryParamsBase,
		setQueryParams,
		newKurbanListClick: kurbanListUIEvents.newKurbanListClick,
		openDetailKurbanListPage: kurbanListUIEvents.openDetailKurbanListPage,
		openChooseKurbanListPage: kurbanListUIEvents.openChooseKurbanListPage
	};


	return (
		<KurbanListUIContext.Provider value={value}>
			{children}
		</KurbanListUIContext.Provider>
	);
}
