import React, {useState, useEffect} from 'react';
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { currencyFormatter } from '../../../../_metronic/_helpers';

export const CheckerDonationTotal = () => {

    const { currentState } = useSelector(
		( state ) => ({ currentState: state.checkers }),
		shallowEqual,
	);
    // s2-251 tambah tabel by cash dan tunai
	const { totalDonations } = currentState

    // const [newEntities, setNewEntities] = useState([])
    // const [totalDonasiArray, setTotalDonasiArray] = useState([])
    // const [totalDonasi, setTotalDonasi] = useState(0)

    // useEffect( () => {
    //     if (entities) {
    //         if (entities.length > 0 ) {
    //             setNewEntities(entities)
    //         } else {
    //             setNewEntities([])
    //         }
    //     }
	// }, [entities] );

    // useEffect( () => {
    //     newEntities.length > 0 ? setTotalDonasiArray(newEntities.map(donasi => donasi.total_trx)) : setTotalDonasiArray([])
    // }, [newEntities])

    // useEffect( () => {
    //     const reducer = (accumulator, currentValue) => accumulator + currentValue;
    //     if (totalDonasiArray.length > 0) {
    //         setTotalDonasi(totalDonasiArray.reduce(reducer))
    //      } else {
    //         setTotalDonasi(0)
    //      } 
    // })

    return (
        <Card>
			<CardHeader title="Total Donasi"></CardHeader>
            <CardBody>
                <div className="row">
                    <div className="col-lg-4"><h1>{currencyFormatter(totalDonations)}</h1></div>
                </div>
            </CardBody>
        </Card>
    )
}