import React, { useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useHewanKurbanUIContext } from '../HewanKurbanUIContext';
import { HewanKurbanEditDialogHeader } from './HewanKurbanEditDialogHeader';
import { HewanKurbanEditForm } from './HewanKurbanEditForm';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../_redux/hewan-kurban/hewanKurbanAction';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../../../../_metronic/_helpers';

export const HewanKurbanEditDialog = ({ id, show, onHide }) => {

    // HewanKurban UI Context
    const hewanKurbanUIContext = useHewanKurbanUIContext();
    const hewanKurbanUIProps = useMemo(() => {
        return {
            initHewanKurban: hewanKurbanUIContext.initHewanKurban,
            queryParams: hewanKurbanUIContext.queryParams,
            setQueryParams: hewanKurbanUIContext.setQueryParams,
        };
    }, [hewanKurbanUIContext]);

    // HewanKurban Redux state
    const dispatch = useDispatch();
    const { actionsLoading, hewanKurbanForEdit, error, roleMaster } = useSelector(
        (state) => ({
            actionsLoading: state.hewanKurbans.actionsLoading,
            hewanKurbanForEdit: state.hewanKurbans.hewanKurbanForEdit,
            roleMaster: state.hewanKurbans.roleMaster,
            error: state.hewanKurbans.error
        }),
        shallowEqual,
    );

    if (error) {
        toast.error(error, toastOption)
    }
    useEffect(() => {
        // server call for getting HewanKurban by id
        dispatch(actions.fetchHewanKurban(id));
    }, [id, dispatch]);

    const saveHewanKurban = (hewanKurban) => {
        if (!id) {
            dispatch(actions.createHewanKurban(hewanKurban)).then((response) => {
                if (response)
                    toast.success(response.message, toastOption);
                dispatch(actions.fetchHewanKurbans(hewanKurbanUIProps.queryParams)).then(() => onHide());
            });

        } else {
            dispatch(actions.updateHewanKurban(hewanKurban)).then((response) => {
                if (response)

                    toast.success(response.message, toastOption);
                dispatch(actions.fetchHewanKurbans(hewanKurbanUIProps.queryParams)).then(() => onHide());
            });
        }
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            aria-labelledby="modal-sizes-title-lg"
            size="xl"
            backdrop="static"
            fullscreen={true}
            animation={true}
        >
            <HewanKurbanEditDialogHeader id={id} />
            <HewanKurbanEditForm
                saveHewanKurban={saveHewanKurban}
                actionsLoading={actionsLoading}
                hewanKurban={hewanKurbanForEdit || hewanKurbanUIProps.initHewanKurban}
                onHide={onHide}
                roleMaster={roleMaster}
                id={id}
            />
        </Modal>
    );
};
