import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { useDoubleRekeningUIContext } from '../../DoubleRekeningUIContext';
import { initialFilter } from '../../DoubleRekeningUIHelpers';

const prepareFilter = (queryParams, values) => {
	const { name } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};

	//add to Object filter
	filter.name = name ?? '';
	newQueryParams.filter = filter;
	return newQueryParams;
};

export const DoubleRekeningFilter = ({ listLoading }) => {
	// DoubleRekening UI Context
	const doubleRekeningUIContext = useDoubleRekeningUIContext();
	const doubleRekeningUIProps = useMemo(() => {
		return {
			setQueryParams: doubleRekeningUIContext.setQueryParams,
			queryParams: doubleRekeningUIContext.queryParams,
			filterType: doubleRekeningUIContext.filterType,
		};
	}, [doubleRekeningUIContext]);

	const applyFilter = (values) => {
		const newQueryParams = prepareFilter(doubleRekeningUIProps.queryParams, values);
		if (!isEqual(newQueryParams, doubleRekeningUIProps.queryParams)) {
			newQueryParams.pageNumber = 1;
			doubleRekeningUIProps.setQueryParams(newQueryParams);
		}
	};

	return (
		<Formik initialValues={initialFilter.filter} onSubmit={(values) => {
			applyFilter(values);
		}}
		>{({ values, handleSubmit, handleBlur, setFieldValue }) => (
			<form onSubmit={handleSubmit} className="form form-label-right">
				<div className="form-group row">
					<div className="col-lg-12">
						<input
							type="text"
							className="form-control"
							name="name"
							placeholder="Search"
							onBlur={handleBlur}
							value={values.name}
							onChange={(e) => {
								setFieldValue('name', e.target.value);
							}}
						/>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Nama Donatur
						</small>
					</div>
				</div>
				<button onClick={handleSubmit} type='submit' className='btn btn-primary'>Cari</button>
			</form>
		)}
		</Formik>

	);
};

