import React, { useMemo } from 'react';
import { Card, CardHeader, CardBody } from '../../../../_metronic/_partials/controls';
import Moment from 'react-moment';

export function DonaturLog({ logDonatur }) {

    // const donationDetailUIContext = useDonationDetailUIContext();
    // const donationDetailUIProps = useMemo(() => {
    //     return {
    //         donationLog: donationDetailUIContext.donationLog,
    //     };
    // }, [donationDetailUIContext]);

    const renderBeforeLog = (row) => {
        let text = ""
        for (const key in row.snapshot_before) {
            // console.log(` ${key}:${row.snapshot_before[key]}, `)
            text += ` ${key} : ${row.snapshot_before[key]}, `
        }
        // console.log(text);
        return <p className='text-break'>{text}</p>
    }

    const renderAfterLog = (row) => {
        let text = ""
        for (const key in row.snapshot_after) {
            // console.log(` ${key}:${row.snapshot_after[key]}, `)
            text += ` ${key} : ${row.snapshot_after[key]}, `
        }
        // console.log(text);
        return <p className='text-break'>{text}</p>
    }

    // console.log(logDonatur)
    return (
        <Card border="primary">
            <CardHeader title="Log Donatur"></CardHeader>
            <CardBody>

                <div className="card-body pt-4">
                    <div className="timeline timeline-6 mt-3">

                        {logDonatur && logDonatur.map(row =>
                            <div className="timeline-item align-items-start" key={row.uuid}>
                                <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                                    <Moment format="HH:mm">{row.created_at}</Moment>
                                </div>

                                <div className="timeline-badge">
                                    <i className="fa fa-genderless text-warning icon-xl"></i>
                                </div>

                                <div className="font-weight-mormal font-size-lg timeline-content text-muted pl-3">
                                    <span className="text-primary font-italic">{row.user_name}</span>,&nbsp;
                                    <span className="text-dark">
                                        <Moment format="DD-MMM-YYYY">{row.created_at}</Moment>
                                    </span>
                                    <br />
                                    {row.action}<br /><br />
                                    <p className='text-primary'>Data Sebelum</p>
                                    {renderBeforeLog(row)}<br /> <br />
                                    <p className='text-warning'>Data Sesudah</p>
                                    {/* {JSON.stringify(row.snapshot_after)} */}
                                    {renderAfterLog(row)}
                                </div>
                            </div>
                        )}

                    </div>
                </div>

            </CardBody>
        </Card>

    );
}
