import { Field, Formik } from "formik";
import React from "react";
import { Modal } from "react-bootstrap";
import { Input } from "../../../../../../../_metronic/_partials/controls";
import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  // email: Yup.string().when("hp", {
  //   is: (value) => !value,
  //   then: Yup.string()
  //     .email()
  //     .required("Email is required"),
  // }),
  // hp: Yup.string().when("email", {
  //   is: (value) => !value,
  //   then: Yup.string().required("Phone number is required"),
  // }),
  donor: Yup.string().required("Donor is required"),
  name_1: Yup.string().required("Atas nama 1"),
});

export default function EditModal({
  initValues = { data: { email: "", hp: "" }, error: [] },
  onHide,
  show,
  onSave,
}) {
  const nameList = [
    "name_1",
    "name_2",
    "name_3",
    "name_4",
    "name_5",
    "name_6",
    "name_7",
  ];
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initValues.data}
      validationSchema={ValidationSchema}
      onSubmit={(values) => onSave(values)}
    >
      {({ setFieldValue, values, handleSubmit }) => (
        <Modal show={show} onHide={onHide} size="lg">
          <Modal.Header>
            <h4>Edit Data</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group row">
              <ol>
                {initValues?.error?.map((item) => (
                  <li>{item.message}</li>
                ))}
              </ol>
            </div>
            <div className="form-group row">
              <div className="col">
                <Field
                  component={Input}
                  name="donor"
                  label={`Nama Donatur`}
                  placeholder={`Masukkan nama donatur`}
                />
              </div>
            </div>
            {nameList.map((name, index) => (
              <div className="form-group row">
                <div className="col">
                  <Field
                    component={Input}
                    name={name}
                    label={`Nama ${index + 1}`}
                    placeholder={`Masukkan nama ${index + 1}`}
                  />
                </div>
              </div>
            ))}
            <div className="form-group row">
              <div className="col">
                <Field
                  component={Input}
                  name="hp"
                  label="HP"
                  placeholder="Masukkan Hp"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col">
                <Field
                  component={Input}
                  name="email"
                  label="Email"
                  placeholder="Masukkan email"
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="float-right">
              <button className="btn btn-primary" onClick={handleSubmit}>
                Simpan
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}
