import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Input } from '../../../../_metronic/_partials/controls';

export const DonaturProfileForm = ({ donaturProfile, btnRef, saveDonaturProfile }) => {
    function submitDonaturProfile(values) {
        saveDonaturProfile(values);
    }
    
    return (
        <Formik
            enableReinitialize={true}
            initialValues={{...donaturProfile}}
            onSubmit={submitDonaturProfile}
        >
            {({ handleSubmit, values }) => (
                <Form className="form form-label-right">
                    <div className="form-group row">
                        <div className="col-lg">
                            <Field
                                name="hobi"
                                component={Input}
                                placeholder="Contoh: Melukis, Memasak...."
                                label="Hobi"
                            />
                        </div>
                    </div>
                    <div className="form-group row">
                        <div className="col-lg">
                            <Field
                                name="description"
                                component={Input}
                                placeholder="Donatur ini adalah ..."
                                label="Contoh: Tentang Donatur"
                            />
                        </div>
                    </div>

                    <button
                        type="submit"
                        style={{ display: 'none' }}
                        ref={btnRef}
                        onSubmit={() => handleSubmit()}
                    ></button>
                </Form>
            )}

        </Formik>
    )
}