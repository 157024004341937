import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { usePeriodTemplateUIContext } from "./PeriodTemplateUIContext";
import PeriodTemplateTable from "./period-template-table/PeriodTemplateTable";
import { PeriodTemplateFilter } from "./period-template-table/period-template-filter/PeriodTemplateFilter";
import { RoleChecker } from "../../../../RoleChecker";

export const PeriodTemplateCard = () => {
  const periodTemplateUIContext = usePeriodTemplateUIContext();
  const periodTemplateUIProps = useMemo(() => {
    return {
      newPeriodTemplateButtonClick:
        periodTemplateUIContext.newPeriodTemplateButtonClick,
    };
  }, [periodTemplateUIContext]);

  return (
    <Card>
      <CardHeader title="Template Laporan">
        <CardHeaderToolbar>
          {/* {RoleChecker("Create", "thk_periodTemplate") && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={periodTemplateUIProps.newPeriodTemplateButtonClick}
            >
              Period Template Baru
            </button>
          )} */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <PeriodTemplateFilter /> */}
        <PeriodTemplateTable />
      </CardBody>
    </Card>
  );
};
