import axios from 'axios';
import { MasterDataURL } from '../../../../config/UrlEndPoint';

export const URL = `${MasterDataURL}bank`;

// Get Bank All For use Combobox data
export function getBankAll() {
    return axios.get(URL);
}

export function getBankAllNotFiltered() {
    return axios.get(`${URL}/not_filtered`);
}

// Get Bank All For use Combobox data
export function getBankAllCompany() {
    return axios.get(`${URL}/allcompany`);
}

// //Get By ID
export function getBankById(bankId) {
    return axios.get(`${URL}/${bankId}`);
}

// items => filtered/sorted result for table data
export function findBank(queryParams) {
    return axios.post(`${URL}/find`, queryParams);
}

// POST => POST: Create the bank on the server
export function createBank(bank) {
    return axios.post(URL, bank);
}

// UPDATE => PUT: update the bank on the server
export function updateBank(bank) {
    return axios.put(`${URL}/${bank.uuid}`, bank);
}

