import { useSelector, shallowEqual } from "react-redux";

export function RoleChecker(action, subject) {
  const { access } = useSelector(
    (state) => ({ access: state.auth.user.access_role.access }),
    shallowEqual,
  );
  let accessRole = access.filter((item) => {
    if (
      item.subject === subject &&
      item.action === action &&
      item.inverted !== true
    ) {
      return item;
    }
  });
  // console.log('====================================');
  // console.log(accessRole);
  // console.log(action);
  // console.log(subject);
  // console.log('====================================');
  return accessRole.length > 0;
}

export function RoleCheckerNoRedux(action, subject, access) {
  let accessRole = access.filter((item) => {
    if (
      item.subject === subject &&
      item.action === action &&
      item.inverted !== true
    ) {
      return item;
    }
  });
  return accessRole.length > 0;
}

