import axios from "axios";
import { MasterDataURL } from "../../../config/UrlEndPoint";

export const MASTD_URL = `${MasterDataURL}aol_bigquery_callback`;

export function authorizeAol(payload) {
  return axios.post(`${MASTD_URL}/authorize`, payload);
}

export function callbackAuthorize(code) {
  return axios.get(`${MASTD_URL}/oauth`, { params: { code } });
}

export function saveConfigAol(payload) {
  return axios.post(`${MASTD_URL}/save_config`, payload);
}

export function getConfigAolBigQuery() {
  return axios.get(`${MASTD_URL}/aol_big_query`);
}
