import * as requestFromServer from './doubleRekeningCrud';
import { doubleRekeningSlice, callTypes } from './doubleRekeningSlice';
import { actions as auth } from '../../../Auth/_redux/authRedux'
import { toastOption } from '../../../../../_metronic/_helpers/ToastHelpers'
import { toast } from 'react-toastify'

const { actions } = doubleRekeningSlice;

export const fetchDoubleRekenings = (queryParams) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer.findDoubleRekening(queryParams)
        .then(response => {
            const { data: { total_items, items } } = response.data;
            dispatch(actions.doubleRekeningsFetched({ total_items, items }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchDoubleRekening = (id) => dispatch => {
    if (!id) {
        return dispatch(actions.doubleRekeningFetched({ doubleRekeningForEdit: undefined }));
    }

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.getDoubleRekeningById(id)
        .then(response => {
            const { data: doubleRekening } = response.data; // alias data -> donatur
            dispatch(actions.doubleRekeningFetched({ doubleRekeningForEdit: doubleRekening }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const createDoubleRekening = (doubleRekeningForCreation) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.createDoubleRekening(doubleRekeningForCreation)
        .then(response => {
            dispatch(actions.doubleRekeningReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const updateDoubleRekening = (doubleRekening) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.updateDoubleRekening(doubleRekening)
        .then(response => {
            dispatch(actions.doubleRekeningReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const deleteDoubleRekening = (doubleRekening) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.deleteDoubleRekening(doubleRekening)
        .then(response => {
            dispatch(actions.doubleRekeningReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};
