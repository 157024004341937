import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { usePartnershipUIContext } from "../../PartnershipUIContext";
import {
  DONATION_STATUS,
  KURBAN_STATUS,
  initialFilter,
} from "../../PartnershipUIHelpers";
import AsyncSelect from "react-select/async";
import ReactSelect from "react-select";
import { promiseTuplify } from "../../../../../../../_metronic/_utils/promise-utils";
import { findAllThkPeriods } from "../../../../../Donation/thk-period/thk-period-api";
import { genOptionLoader } from "../../../../../../../_metronic/_utils/option-loaders";
import { findDonaturs } from "../../../../../Donatur/_redux/donatur/donaturCrud";
import { findHewanKurbanCampaign } from "../../../../../Donation/_redux/hewan-kurban-campaign/hewanKurbanCampaignCrud";
import { currencyFormatter } from "../../../../../../../_metronic/_helpers";

const prepareFilter = (queryParams, values) => {
  const {
    batch_id,
    dona_status,
    kurban_status,
    donation_hid,
    kurban_hid,
    thk_livestock_campaign,
    name,
  } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  //add to Object filter
  filter.batch_id = batch_id ?? "";
  filter.donation_hid = donation_hid ?? "";
  filter.kurban_hid = kurban_hid ?? "";
  filter.donation_status = dona_status.value ?? "";
  filter.kurban_status = kurban_status.value ?? "";
  filter.thk_livestock_campaign_uuid = thk_livestock_campaign.value ?? "";
  filter.name = name ?? "";
  newQueryParams.filter = filter;
  return newQueryParams;
};

export const QurbanFilter = ({
  queryParams,
  setQueryParams,
  thkPartnership,
}) => {
  const [options, setOptions] = useState({
    period: [],
    donationStatus: DONATION_STATUS,
    kurbanStatus: KURBAN_STATUS,
    livestockCampaign: [],
  });

  const applyFilter = (values) => {
    if (values.is_reset) {
      setQueryParams((...prev) => ({
        ...prev,
        filter: {
          ...prev.thkPartnership,
          batch_id: "",
          dona_status: "",
          kurban_status: "",
          donation_hid: "",
          thk_livestock_campaign: "",
          kurban_hid: "",
          name: "",
        },
      }));
    }
    const newQueryParams = prepareFilter(queryParams, values);
    if (!isEqual(newQueryParams, queryParams)) {
      newQueryParams.pageNumber = 1;
      setQueryParams(newQueryParams);
    }
  };

  const { currentState } = useSelector(
    (state) => ({ currentState: state.hewanKurbanCampaigns }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  // async function fetchThkPeriods() {
  //   const [res, error] = await promiseTuplify(findAllThkPeriods());

  //   if (error) {
  //     return;
  //   }

  //   if (res) {
  //     const { data: { data = [] } = {} } = res;

  //     const options = data.map(({ uuid, name }) => ({
  //       label: name,
  //       value: uuid,
  //     }));

  //     setOptions((prev) => ({
  //       ...prev,
  //       period: options,
  //     }));
  //   }
  // }

  // async function fetchDonor() {
  //   const [res, error] = await promiseTuplify(
  //     findDonaturs({
  //       pageNumber: 1,
  //       pageSize: 20,
  //       filter: { is_not_individual: true },
  //     })
  //   );

  //   if (error) {
  //     return;
  //   }

  //   if (res) {
  //     const { data: { data: { items = [] } } = {} } = res;

  //     const options = items.map(({ uuid, name, donor_id }) => ({
  //       label: `${name} - ${donor_id}`,
  //       value: uuid,
  //     }));

  //     setOptions((prev) => ({
  //       ...prev,
  //       donor: options,
  //     }));
  //   }
  // }

  // async function fetchThkCampaign() {
  //   const [res, error] = await promiseTuplify(
  //     findHewanKurbanCampaign({
  //       pageNumber: 1,
  //       pageSize: 20,
  //       filter: { thk_partnership_uuid: thkPartnership.uuid },
  //     })
  //   );

  //   if (error) {
  //     return;
  //   }

  //   if (res) {
  //     const { data: { data: { items = [] } } = {} } = res;

  //     const options = items.map(({ name, price, uuid }) => ({
  //       label: `${name} - ${currencyFormatter(price)}`,
  //       value: uuid,
  //     }));

  //     setOptions((prev) => ({
  //       ...prev,
  //       livestockCampaign: options,
  //     }));
  //   }
  // }

  useEffect(() => {
    if (entities && entities.length >= 1) {
      const options = entities.map(({ name, price, uuid }) => ({
        label: `${name} - ${currencyFormatter(price)}`,
        value: uuid,
      }));
      setOptions((prev) => ({
        ...prev,
        livestockCampaign: options,
      }));
    }
    // unnecessary data should not be fetched
    // fetchThkPeriods();
    // fetchDonor();
    // fetchThkCampaign();
    // getThkCampaign();
  }, [entities]);

  return (
    <Formik
      initialValues={queryParams.filter}
      onSubmit={(values) => {
        applyFilter(values);
      }}
      onReset={(values) => {
        applyFilter({ ...values, is_reset: true });
      }}
    >
      {({ values, handleSubmit, handleBlur, setFieldValue, handleReset }) => (
        <form onSubmit={handleSubmit} className="form form-label-right">
          <div className="form-group row">
            <div className="col-lg-4">
              <input
                type="text"
                className="form-control"
                name="batch_id"
                placeholder="Search"
                onBlur={handleBlur}
                value={values.batch_id}
                onChange={(e) => {
                  setFieldValue(e.target.name, e.target.value);
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Berdasarkan Batch ID
              </small>
            </div>
            <div className="col-lg-4">
              <input
                type="text"
                className="form-control"
                name="donation_hid"
                placeholder="Search"
                onBlur={handleBlur}
                value={values.donation_hid}
                onChange={(e) => {
                  setFieldValue(e.target.name, e.target.value);
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Berdasarkan Donation HID
              </small>
            </div>
            <div className="col-lg-4">
              <input
                type="text"
                className="form-control"
                name="kurban_hid"
                placeholder="Search"
                onBlur={handleBlur}
                value={values.kurban_hid}
                onChange={(e) => {
                  setFieldValue(e.target.name, e.target.value);
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Berdasarkan Kurban HID
              </small>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4 mb-5">
              <ReactSelect
                name="dona_status"
                placeholder={"pilih status donasi"}
                options={options.donationStatus}
                value={
                  values.dona_status && values.dona_status.value
                    ? {
                        label: values.dona_status.label ?? "",
                        value: values.dona_status.value ?? "",
                      }
                    : null
                }
                onChange={({ label, value }) => {
                  setFieldValue("dona_status", {
                    label,
                    value,
                  });
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Status Donasi
              </small>
            </div>
            <div className="col-lg-4 mb-5">
              <ReactSelect
                name="kurban_status"
                placeholder={"pilih status kurban"}
                options={options.kurbanStatus}
                value={
                  values.kurban_status && values.kurban_status.value
                    ? {
                        label: values.kurban_status.label ?? "",
                        value: values.kurban_status.value ?? "",
                      }
                    : null
                }
                onChange={({ label, value }) => {
                  setFieldValue("kurban_status", {
                    label,
                    value,
                  });
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Berasarkan Status Kurban
              </small>
            </div>
            <div className="col-lg-4 mb-5">
              <AsyncSelect
                name="thk_livestock_campaign"
                placeholder={"pilih hewan campaign"}
                defaultOptions={options.livestockCampaign}
                loadOptions={genOptionLoader(
                  (inputValue) =>
                    findHewanKurbanCampaign({
                      pageNumber: 1,
                      pageSize: 20,
                      filter: {
                        name: inputValue,
                        thk_partnership_uuid: thkPartnership.uuid,
                      },
                    }),
                  "items",
                  ({ name, price, uuid: value }) => {
                    return {
                      label: `${name} - ${currencyFormatter(price)}`,
                      value,
                      price,
                    };
                  }
                )}
                value={
                  values.thk_livestock_campaign &&
                  values.thk_livestock_campaign.value
                    ? {
                        label: values.thk_livestock_campaign.label ?? "",
                        value: values.thk_livestock_campaign.value ?? "",
                      }
                    : null
                }
                onChange={({ label, value }) => {
                  setFieldValue("thk_livestock_campaign", {
                    label,
                    value,
                  });
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Hewan Campaign
              </small>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-lg-4 mb-5">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Search"
                onBlur={handleBlur}
                value={values.name}
                onChange={(e) => {
                  setFieldValue(e.target.name, e.target.value);
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Berdasarkan Atas Nama
              </small>
            </div>
          </div>
          <div className="form-group float-right">
            <button
              className="btn btn-outline-danger mx-1"
              onClick={handleReset}
            >
              Reset Pencarian
            </button>
            <button className="btn btn-warning mx-1" onClick={handleSubmit}>
              Terapkan
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};
