import axios from 'axios';
import {DonationURL} from '../../../../config/UrlEndPoint';
import {URL} from "../../../Donatur/_redux/donatur/donaturCrud";

export const DONATION_URL = `${DonationURL}donation`;

//Get By ID
export function getKurbanList() {
    return axios.get(`${DonationURL}livestock`);
}
//  get Table list
export function getKurbanTableList(params) {
    return axios.post(`${DonationURL}donation/kurbanlist/find`, params);
}
