import axios from 'axios';
import { DonorURL, DonationURL } from '../../../../config/UrlEndPoint'
import { DONATION_URL } from "../../../Donation/_redux/donation/donationCrud";

export const URL = `${DonorURL}donatur`;
export const URLDONATION = `${DonationURL}donation`;
export const URLCONSOLIDATION = `${DonationURL}consolidation`;


// items => filtered/sorted result
export function findDonaturs(queryParams) {
    return axios.post(`${URL}/find`, queryParams);
}

// get ALL donatur with search
export function searchDonaturs(search) {
    return axios.post(`${URL}/search`, search);
}

//Get By ID
export function getDonaturById(donaturId) {
    return axios.get(`${URL}/${donaturId}`);
}

// POST => POST: Create the donatur on the server
export function createDonatur(donatur) {
    return axios.post(URL, donatur);
}

// GET => GET ALL PARENT BY TYPE
export function getParents(id) {
    return axios.get(`${URL}/parent/${id}`)
}

//GET => GET ALL PARENT BY TYPE
export function getChilds(id) {
    return axios.get(`${URL}/child/${id}`)
}

// UPDATE => PUT: update the donatur on the server
export function updateDonatur(donatur) {
    return axios.put(`${URL}/${donatur.uuid}`, donatur);
}

export function updateDonaturProfile(donatur) {
    return axios.put(`${URL}/${donatur.uuid}/profile`, donatur);
}

// UPDATE => BRANCH DONATUR
export function updateBranch(donatur) {
    return axios.put(`${URL}/branch/change`, donatur);
}

// GET DONATUR FROM DONATUR URL
export function getDonationList(donatur) {
    return axios.post(`${URLDONATION}/donorid`, donatur)
}

// add parent
export function addParent(donatur) {
    return axios.post(`${URL}/parent/add`, donatur)
}

// remove parent
export function deleteParent(donatur) {
    return axios.delete(`${URL}/parent/delete`, { data: donatur })
}

//Generate EXCEL
export function generateReportDonor(payload) {
    return axios.post(`${URL}/report`, payload);
}

//Change Password
export function changePasswordDonatur(payload) {
    return axios.post(`${URL}/change_password`, payload);
}

export function migrateDonation(payload) {
    return axios.put(`${URLDONATION}/migrate_donation`, payload);
}

export function generatePrintConsolidationDonor(payload) {
    return axios.post(`${URLCONSOLIDATION}/print_consolidation_donor`, payload, { responseType: 'blob' });
}

export const createInteractionDonor = payload => {
    return axios.post(`${URL}_interaction`, payload);
}

export const findDonorInteraction = payload => {
    return axios.post(`${URL}_interaction/find`, payload);
}

export function getDonorInteractionById(donaturId, interactionId) {
    return axios.get(`${URL}_interaction/${donaturId}/${interactionId}`);
}

export const updateInteractionDonor = (payload) => {
  return axios.put(`${URL}_interaction`, payload);
};

export function deleteDonorInteraction(donaturId, interactionId) {
  return axios.delete(`${URL}_interaction/${donaturId}/${interactionId}`);
}

export function exportDonorDonation(payload) {
    return axios({
      url: `${URLDONATION}/donorid/export`,
      method: "POST",
      data: payload,
      responseType: "blob",
    });
}
