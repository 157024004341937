import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../_redux/donatur-double-name/doubleNameAction';
import * as uiHelpers from '../DoubleNameUIHelpers';

import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
} from '../../../../../_metronic/_helpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../../_metronic/_partials/controls';
import { useDoubleNameUIContext } from '../DoubleNameUIContext';
// import { ToastContainer } from 'react-toastify';

const DoubleNameTable = (props) => {
    // DoubleName UI Context
    const doubleNameUIContext = useDoubleNameUIContext();
    const doubleNameUIProps = useMemo(() => {
        return {
            queryParams: doubleNameUIContext.queryParams,
            setQueryParams: doubleNameUIContext.setQueryParams,
            setSelectedDonor: doubleNameUIContext.setSelectedDonor
        };
    }, [doubleNameUIContext]);


    // Getting curret state of doubleName list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.doubleName }),
        shallowEqual,
    );

    const { totalCount, entities, listLoading } = currentState;

    const dispatch = useDispatch();
    useEffect(() => {

        //Requset ke Branch Action
        dispatch(actions.fetchDoubleNames(doubleNameUIProps.queryParams));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doubleNameUIProps.queryParams, dispatch]);

    // Table columns
    const columns = [
        {
            dataField: 'donor_name',
            text: 'Nama Donatur',
            // sort: true,
            // sortCaret: sortCaret,
        },
        {
            dataField: 'duplicate',
            text: 'Total Duplikat',
            // sort: true,
            // sortCaret: sortCaret,
        },
    ];

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        style: { backgroundColor: '#DFF6FF' },
        onSelect: (row, isSelect, rowIndex, e) => {
            doubleNameUIProps.setSelectedDonor(row.donor_name)
        },
    };


    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: doubleNameUIProps.queryParams.pageSize,
        page: doubleNameUIProps.queryParams.pageNumber,
    };

    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                bordered={false}
                                remote
                                keyField="donor_name"
                                selectRow={selectRow}
                                data={entities === null ? [] : entities}
                                columns={columns}
                                // defaultSorted={uiHelpers.defaultSorted}
                                onTableChange={getHandlerTableChange(
                                    doubleNameUIProps.setQueryParams,
                                )}
                                // selectRow={getSelectRow({
                                //   entities,
                                //   ids: productsUIProps.ids,
                                //   setIds: productsUIProps.setIds,
                                // })}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    );
};

export default DoubleNameTable;
