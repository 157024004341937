import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";

export default function ExportDonationModal({ show, onHide, handleClick }) {
  const [input, setInput] = useState({
    filename: "",
  });

  useEffect(() => {
    if (show) {
      setInput({ filename: "" });
    }
  }, [show]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <h4>Export Donasi</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col">
            <label>Nama File</label>
            <input
              name="filename"
              onChange={handleChange}
              className="form form-control"
              placeholder="Nama file..."
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="float-right">
          <button
            className="btn btn-dark mx-1"
            onClick={() => handleClick({ ...input, isFiltered: true })}
          >
            Export dengan filter
          </button>
          <button
            className="btn btn-primary mx-1"
            onClick={() => handleClick({ ...input, isFiltered: false })}
          >
            Export keseluruhan
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
