import React from 'react';
import Moment from 'react-moment';

export const DateColumnFormatter = (
    cellContent,
    row,
    rowIndex,
) => (
    <>
        <Moment format="DD-MMM-YYYY">{cellContent}</Moment>
    </>
);
