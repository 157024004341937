import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

const ConfirmationModal = ({
  show,
  onHide,
  content,
  title,
  onClick,
  type,
  subContent
}) => {
  const [icon, setIcon] = useState({
    type: "",
    color: "",
    className: ""
  });
  useEffect(() => {
    if (show) {
      const iconType = {};
      switch (type) {
        case "info":
          iconType.type = "info";
          iconType.color = "#E5EAEE";
          iconType.className = "fas fa-info-circle";
          break;
        case "warning":
          iconType.type = "warning";
          iconType.color = "#FFA800";
          iconType.className = "fad fa-exclamation-triangle";
          break;
        case "success":
          iconType.type = "success";
          iconType.color = "#1BC5BD";
          iconType.className = "fas fa-check-circle";
          break;
        case "danger":
          iconType.type = "danger";
          iconType.color = "#F64E60";
          iconType.className = "fas fa-exclamation-circle";
          break;

        default:
          break;
      }
      setIcon(iconType);
    }
  }, [show]);

  return (
    <>
      <Modal
        size="md"
        show={show}
        centered
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <i
              className={`${icon.className}`}
              style={{ fontSize: "64px", color: `${icon.color}` }}
            ></i>
          </div>
          <div className="mt-8 text-center">
            <strong>{content}</strong>
          </div>
          {subContent && (
            <div className="text-center" style={{ color: icon.color }}>
              <strong>{subContent}</strong>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              onClick={onClick}
              className="btn btn-primary btn-elevate"
            >
              Yakin
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  content: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf(["info", "warning", "danger", "success"])
};

ConfirmationModal.defaultProps = {
  show: false,
  onHide: null,
  title: "",
  content: "",
  type: "info",
  onClick: null
};

export default ConfirmationModal;
