import React, { useState, useEffect } from 'react';
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { currencyFormatter } from '../../../../_metronic/_helpers';

export const CollectorDonationTotal = () => {

    const { currentState } = useSelector(
        (state) => ({ currentState: state.collects }),
        shallowEqual,
    );

    // s2-135 Sandra 2 Mobile : Collector Approve : Perlu total rekap sesuai jenis pembayaran
    const { totalDonations } = currentState;
    return (
        <Card>
            <div className="row">
                <div className="col-sm-6">
                    <CardHeader title="Total Seluruh Donasi Tersaring"></CardHeader>
                    <CardBody>
                            <div><h2>{currencyFormatter(totalDonations)}</h2></div>
                    </CardBody>
                </div>
                {/* <div className="col-sm-6">
                    <CardHeader title="Total Donasi Berdasarkan Filter"></CardHeader>
                    <CardBody>
                            <div><h2>{currencyFormatter(totalFilteredDonations)}</h2></div>
                    </CardBody>
                </div> */}
            </div>

        </Card>
    )
}