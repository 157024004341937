import { createSlice } from '@reduxjs/toolkit';

const initialCilkurState = {
	listLoading: false,
	actionsLoading: false,
	totalCount: 0,
	entities: null,
	cilkurForEdit: undefined,
	lastError: null,
	cilkurData: {
		donation: [],
		cilkur_hid: "",
		thk_type: "",
		installment_monthly_amount: 0,
		installment_total: 0,
		status: '',
		reminder_date: "",
		donor_uuid: ""
	}
};

export const callTypes = {
	list: 'list',
	action: 'action',
};

export const cilkurSlice = createSlice({
	name: 'cilkur',
	initialState: initialCilkurState,
	reducers: {
		catchError: (state, action) => {
			state.error = action.payload.error;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = false;
			} else {
				state.actionsLoading = false;
			}
		},

		startCall: (state, action) => {
			state.error = null;
			if (action.payload.callType === callTypes.list) {
				state.listLoading = true;
			} else {
				state.actionsLoading = true;
			}
		},

		// getCilkurById
		cilkurFetched: (state, action) => {
			state.actionsLoading = false;
			state.error = null;
			state.cilkurData = action.payload.cilkurData;
		},

		// findCilkur
		cilkursFetched: (state, action) => {
			const { total_items, items } = action.payload;
			state.listLoading = false;
			state.error = null;
			state.entities = items;
			state.totalCount = total_items;
		},

		// Set to default state actionLoading & error
		cilkurReload: (state, action) => {
			state.actionsLoading = false;
			state.listLoading = false;
			state.error = null;
		},

	},
});

