import React, { useMemo } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';

import { useExportListUIContext } from './ExportListUIContext';
import { ExportListTable } from './export-list-table/ExportListTable';
import { ExportListFilter } from "./export-list-filter/ExportListFilter";

export const ExportListCard = () => {

    const exportListUIContext = useExportListUIContext();
    const exportListUIProps = useMemo(() => {
        return {
            newExportListClick: exportListUIContext.newExportListClick,
            openDetailExportListPage: exportListUIContext.openDetailExportListPage,
        };
    }, [exportListUIContext]);

    return (
        <Card>
            <CardHeader title="Daftar Export">
                <CardHeaderToolbar>
                    {/* <button
                        type="button"
                        className="btn btn-primary"
                        onClick={exportListUIProps.newExportListClick}
                    >Konfirmasi Baru
                    </button> */}
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <ExportListFilter />
                <ExportListTable />

            </CardBody>
        </Card>
    );
};

