import React from "react";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  getHandlerTableChange,
  toAbsoluteUrl,
} from "../../../../../../_metronic/_helpers";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { sizePerPageList } from "../PartnershipUIHelpers";
import { RoleChecker } from "../../../../../RoleChecker";

export default function PartnershipBatchTable({
  queryParams = {},
  setQueryParams,
  listLoading,
  entities,
  totalCount,
  setDeletedData,
}) {
  function handleDeletedData(data) {
    const { batch_uuid, file } = data;
    setDeletedData((prev) => ({
      ...prev,
      showModal: true,
      thk_partnership_uuid: batch_uuid,
      file_uuid: file.uuid,
    }));
  }

  const allowDelete = RoleChecker("Delete", "thk_partnership");

  // Table columns
  const columns = [
    {
      dataField: "batch_uuid",
      text: "Batch ID",
    },
    {
      dataField: "batch_created_at",
      text: "Generated At",
      formatter: (cell) => {
        return moment(cell).format("YYYY MM DD HH:mm:ss");
      },
    },
    {
      dataField: "total",
      text: "Total Report",
    },
    {
      dataField: "file.filename",
      text: "Filename",
    },
    {
      text: "Actions",
      formatter: (_, row) => {
        const urlDownload = `${row?.file?.adapter}${row?.file?.path}`;
        return (
          <>
            {row.file ? (
              <>
                <OverlayTrigger
                  overlay={
                    <Tooltip id="products-edit-tooltip">
                      Download Laporan
                    </Tooltip>
                  }
                >
                  <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    href={urlDownload}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <i className="flaticon2-download text-success icon-lg" />
                    </span>
                  </a>
                </OverlayTrigger>
                {allowDelete && (
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="products-edit-tooltip">Hapus Batch</Tooltip>
                    }
                  >
                    <a
                      className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
                      onClick={() => {
                        handleDeletedData(row);
                      }}
                    >
                      <span className="svg-icon svg-icon-md svg-icon-danger">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Trash.svg"
                          )}
                        />
                      </span>
                    </a>
                  </OverlayTrigger>
                )}
              </>
            ) : (
              <div className="float-right">
                <h6>Process Generating...</h6>
              </div>
            )}
          </>
        );
      },
      formatExtraData: {},
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="uuid"
                data={entities ? entities : []}
                columns={columns}
                onTableChange={getHandlerTableChange(setQueryParams)}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
