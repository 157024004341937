import React from 'react';
import { CircularProgress, Backdrop } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';



export function LoadingAnimation({ open }) {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1000000,
      color: '#000000',
    },
  }));
  const classes = useStyles();
  return (
    <div>
      <Backdrop className={classes.backdrop} open={open}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}
