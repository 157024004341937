import axios from 'axios';
import { DonorURL } from '../../../../config/UrlEndPoint';

export const URL = `${DonorURL}double_rekening`;

// Get DoubleRekening All For use Combobox data
export function getDoubleRekeningAll() {
    return axios.get(URL);
}

// //Get By ID
export function getDoubleRekeningById(doubleRekeningId) {
    return axios.get(`${URL}/${doubleRekeningId}`);
}

// items => filtered/sorted result for table data
export function findDoubleRekening(queryParams) {
    return axios.post(`${URL}/find`, queryParams);
}

export function findDoubleRekeningDetail(queryParams) {
    return axios.post(`${URL}/find/detail`, queryParams);
}


// POST => POST: Create the doubleRekening on the server
export function createDoubleRekening(doubleRekening) {
    return axios.post(URL, doubleRekening);
}

// UPDATE => PUT: update the doubleRekening on the server
export function updateDoubleRekening(doubleRekening) {
    return axios.put(`${URL}/${doubleRekening.uuid}`, doubleRekening);
}

export function deleteDoubleRekening(doubleRekening) {
    return axios.delete(`${URL}/${doubleRekening.uuid}`);
}

