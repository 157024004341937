import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./ThkChannelReportUIHelpers";

const ThkChannelReportUIContext = createContext();

export function useThkChannelReportUIContext() {
  return useContext(ThkChannelReportUIContext);
}

export const ThkChannelReportUIConsumer = ThkChannelReportUIContext.Consumer;

export function ThkChannelReportUIProvider({
  thkChannelReportUIEvents,
  children,
}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initThkChannelReport = {
    name: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initThkChannelReport,
    openEditThkChannelReportPage:
      thkChannelReportUIEvents.openEditThkChannelReportPage,
    newThkChannelReportButtonClick:
      thkChannelReportUIEvents.newThkChannelReportButtonClick,
    openEditThkChannelReportList:
      thkChannelReportUIEvents.openEditThkChannelReportList,
  };

  return (
    <ThkChannelReportUIContext.Provider value={value}>
      {children}
    </ThkChannelReportUIContext.Provider>
  );
}
