import * as requestFromServer from './collectorCrud';
import { collectorSlice, callTypes } from './collectorSlice';
import { toast } from "react-toastify";
import { toastOption } from "../../../../_metronic/_helpers";
import { actions as auth } from "../../Auth";

const { actions } = collectorSlice;

export const fetchCollects = (queryParams) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer
        .findCollector(queryParams)
        .then(response => {
            console.log(response);
            const { data: { total_items, items, edc_items, partner_items, tunai_items, tunai_edc_items, total_tunai_edc_donations, total_edc_donations, total_partner_donations, total_tunai_donations, total_donations, goods_items, total_goods_donations } } = response.data;
            dispatch(actions.collectsFetched({ total_items, items, edc_items, partner_items, tunai_items, tunai_edc_items, total_tunai_edc_donations, total_edc_donations, total_partner_donations, total_tunai_donations, total_donations, goods_items, total_goods_donations }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchCollectDetail = (id, status) => dispatch => {
    if (!id) {
        return dispatch(actions.collectFetched({ collectDetail: undefined }));
    }

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .getCollectById(id, status)
        .then(response => {
            // alias data to collect;
            const { data: collect } = response.data;
            // dispatch(actions.collectFetched({ collectDetail: collect }));
            return collect;
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

// Update status Collector (Approved, Rejected)
export const updateStatusCollect = (data) => dispatch => {

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer
        .updateCollectStatus(data)
        .then(response => {
            dispatch(actions.collectsReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};
