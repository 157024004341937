import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../_redux/kurban/kurbanAction';
import * as uiHelpers from '../KurbanUIHelpers';
import { DateColumnFormatter } from './column-formatters/DateColumnsFormatter'

import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage, toastOption,
} from '../../../../../../../_metronic/_helpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../../../../_metronic/_partials/controls';
import { useKurbanListUIContext } from '../KurbanUIContext';
import { toast } from "react-toastify";
// import { act } from 'react-dom/test-utils';

export const KurbanListTable = (props) => {
    // KurbanList UI Context
    const kurbanListUIContext = useKurbanListUIContext();
    const kurbanListUIProps = useMemo(() => {
        return {
            queryParams: kurbanListUIContext.queryParams,
            setQueryParams: kurbanListUIContext.setQueryParams,
            newKurbanListClick: kurbanListUIContext.newKurbanListClick,
            openDetailKurbanListPage: kurbanListUIContext.openDetailKurbanListPage,
            openChooseKurbanListPage: kurbanListUIContext.openChooseKurbanListPage,
        };
    }, [kurbanListUIContext]);

    // Getting curret state of branch list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.kurbanList }),
        shallowEqual,
    );

    const { listLoading, entities, totalCount } = currentState

    const dispatch = useDispatch();
    useEffect(() => {
        //Requset ke Donatur Action
        dispatch(actions.fetchKurbanTableList(kurbanListUIProps.queryParams));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, kurbanListUIProps.queryParams]);

    // Table columns
    const columns = [
        {
            dataField: 'donation.donation_hid',
            text: 'ID Donasi',
        },
        {
            dataField: 'donation.trx_date',
            text: 'Tanggal Transaksi',
        },
        {
            dataField: "thk_livestock.thk_period.name",
            text: "Periode Thk"
        },
        {
            dataField: 'donation.channel.type',
            text: 'Tipe',
        },
        {
            dataField: 'donation.channel.name',
            text: 'Nama Channel',
        },
        {
            dataField: 'donation.status',
            text: 'Status',
            formatter: columnFormatters.StatusColumnFormatter
        },
        {
            dataField: "",
            text: 'Hewan',
            formatter: (cell, row) => {
                if (row.thk_livestock_campaign_name) {
                    return row.thk_livestock_campaign_name
                } else {
                    return row.thk_livestock_name
                }
            }
        },
        {
            dataField: 'donation.donor.name',
            text: 'Donatur',
        },
        {
            dataField: 'name_1',
            text: 'Nama 1',
        },
        {
            dataField: 'name_2',
            text: 'Nama 2',
        },
        {
            dataField: 'name_3',
            text: 'Nama 3',
        },
        {
            dataField: 'name_4',
            text: 'Nama 4',
        },
        {
            dataField: 'name_5',
            text: 'Nama 5',
        },
        {
            dataField: 'name_6',
            text: 'Nama 6',
        },
        {
            dataField: 'name_7',
            text: 'Nama 7',
        },
        {
            dataField: 'action',
            text: 'Actions',
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                openDetailKurbanListPage: kurbanListUIProps.openDetailKurbanListPage,
            },
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: '100px',
            },
        },
    ];


    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: kurbanListUIProps.queryParams.pageSize,
        page: kurbanListUIProps.queryParams.pageNumber,
    };

    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                bordered={false}
                                remote
                                keyField="uuid"
                                data={entities === null ? [] : entities}
                                columns={columns}
                                // defaultSorted={uiHelpers.defaultSorted}
                                onTableChange={getHandlerTableChange(
                                    kurbanListUIProps.setQueryParams,
                                )}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
        </>
    )

}