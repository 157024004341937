import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  currencyFormatter,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  toAbsoluteUrl,
  toastOption,
} from "../../../../../../../_metronic/_helpers";
import { Pagination } from "../../../../../../../_metronic/_partials/controls";
import { sizePerPageList } from "../../PartnershipUIHelpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { findPartnershipBatch } from "../../../_redux/partnershipAction";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function ThkImportTable({ thkPartnership, history }) {
  const initQueryParams = {
    pageNumber: 1,
    pageSize: 10,
    filter: {},
  };

  const [queryParams, setQueryParams] = useState(initQueryParams);

  const dispatch = useDispatch();

  useEffect(() => {
    if (thkPartnership.uuid) {
      dispatch(
        findPartnershipBatch({
          ...queryParams,
          filter: {
            ...queryParams.filter,
            thk_partnership_uuid: thkPartnership.uuid,
          },
        })
      );
    }
  }, [queryParams, thkPartnership]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.thkPartnership }),
    shallowEqual
  );

  const {
    totalCount,
    entities,
    listLoading,
    partnershipBatchList,
  } = currentState;

  function handleClickImport() {
    history.push(
      `/qurban/partnership/${thkPartnership.uuid}/manage/import-form`
    );
  }

  function handleClickDetailImport(batch_uuid) {
    history.push(`/qurban/partnership/${batch_uuid}/batch-import-detail`);
  }

  // Table columns
  const columns = [
    {
      dataField: "hid",
      text: "Batch ID",
    },
    {
      dataField: "user_name",
      text: "Penginput",
    },
    {
      dataField: "createdAt",
      text: "Tanggal",
      formatter: (cell) => {
        return moment(cell).format("DD-MMM-YYYY HH:mm:ss");
      },
    },
    // {
    //   dataField: "",
    //   text: "Total",
    //   // formatter: (cell) => {
    //   //   return moment(cell).format("DD-MMM-YYYY")
    //   // },
    // },
    {
      text: "Actions",
      formatter: (_, row) => {
        return (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Detail Donasi</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-info btn-sm mx-3"
                onClick={() => handleClickDetailImport(row.uuid)}
              >
                <span className="svg-icon svg-icon-md svg-icon-info">
                  <i class="fas fa-eye"></i>
                </span>
              </a>
            </OverlayTrigger>
          </>
        );
      },
      formatExtraData: {},
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  return (
    <>
      <div className="float-right">
        <button className="btn btn-primary" onClick={() => handleClickImport()}>
          Import
        </button>
      </div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="uuid"
                data={partnershipBatchList ? partnershipBatchList : []}
                columns={columns}
                onTableChange={getHandlerTableChange(setQueryParams)}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={partnershipBatchList} />
                <NoRecordsFoundMessage entities={partnershipBatchList} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
