import axios from 'axios';
import { DonorURL } from '../../../../config/UrlEndPoint';

export const URL = `${DonorURL}double_name`;

// Get DoubleName All For use Combobox data
export function getDoubleNameAll() {
    return axios.get(URL);
}

// //Get By ID
export function getDoubleNameById(doubleNameId) {
    return axios.get(`${URL}/${doubleNameId}`);
}

// items => filtered/sorted result for table data
export function findDoubleName(queryParams) {
    return axios.post(`${URL}/find`, queryParams);
}

export function findDoubleNameDetail(queryParams) {
    return axios.post(`${URL}/find/detail`, queryParams);
}


// POST => POST: Create the doubleName on the server
export function createDoubleName(doubleName) {
    return axios.post(URL, doubleName);
}

// UPDATE => PUT: update the doubleName on the server
export function updateDoubleName(doubleName) {
    return axios.put(`${URL}/${doubleName.uuid}`, doubleName);
}

export function deleteDoubleName(doubleName) {
    return axios.delete(`${URL}/${doubleName.uuid}`);
}

