import React, { useMemo, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { useCollectorUIContext } from '../../CollectorUIContext';
import { initialFilter, collectStatusList, paymentTypeList } from '../../CollectorUIHelpers';
// filter berdasarkan tanggal dan pembayaran commented untuk menunggu info berikutnya
import { DateRangePicker } from 'react-dates';
import { Card, CardBody, CardHeader } from '../../../../../_metronic/_partials/controls'


const prepareFilter = (queryParams, values) => {
    const { searchName, collectStatus, start_date, end_date, paymentType } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};

    //add to Object filter
    filter.name = searchName ?? '';
    filter.status = collectStatus ?? '';
    filter.start_date = start_date ? start_date : ''
    filter.end_date = end_date ? end_date : ''
    filter.paymentType = paymentType ? paymentType : ''

    newQueryParams.filter = filter;
    return newQueryParams;
};

export const CollectorFilter = ({ listLoading }) => {
    // Collector UI Context
    const collectorUIContext = useCollectorUIContext();
    const collectorUIProps = useMemo(() => {
        return {
            setQueryParams: collectorUIContext.setQueryParams,
            queryParams: collectorUIContext.queryParams,
        };
    }, [collectorUIContext]);

    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(collectorUIProps.queryParams, values);
        if (!isEqual(newQueryParams, collectorUIProps.queryParams)) {
            newQueryParams.pageNumber = 1;
            collectorUIProps.setQueryParams(newQueryParams);
        }
    };

    // UNTUK DATERANGE
    const [onFocused, setOnFocused] = useState(null)
    const [tanggal, setTanggal] = useState(
        {
            start_date: null,
            end_date: null,
        }
    )
    // UNTUK MENAMPIKAN TITLE JENIS PEMBAYARAN YANG DITAMPIKAN 
    const [paymentTitle, setPaymentTitle] = useState('')

    useEffect(() => {
        setPaymentTitle(collectorUIProps.queryParams.filter.paymentType)
    }, [collectorUIProps.queryParams.filter.paymentType])

    return (
        <>
            <Formik initialValues={initialFilter.filter} onSubmit={(values) => {
                applyFilter(values);
            }}
            >{({ values, handleSubmit, handleBlur, setFieldValue }) => (
                <form onSubmit={handleSubmit} className="form form-label-right">
                    <div className="form-group row">
                        <div className="col-lg-3 d-print-none">
                            <input
                                type="text"
                                className="form-control"
                                name="searchName"
                                placeholder="Search"
                                onBlur={handleBlur}
                                value={values.searchName}
                                onChange={(e) => {
                                    setFieldValue('searchName', e.target.value);
                                    handleSubmit();
                                }}
                            />
                            <small className="form-text text-muted">
                                <b>Cari</b> Berdasarkan Name FR
                            </small>
                        </div>
                        <div className="col-lg-3 d-print-none">
                            <select
                                className="form-control"
                                placeholder="Filter Type"
                                name="collectStatus"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    setFieldValue('collectStatus', e.target.value);
                                    handleSubmit();
                                }}
                                value={values.collectStatus}
                            >
                                {collectStatusList.map(list =>
                                    <option key={list.value} value={list.value}>{list.name}</option>,
                                )}
                            </select>
                            <small className="form-text text-muted">
                                <b>Filter</b> Collect Status
                            </small>
                        </div>
                        {/* <div className="col-lg-3">
                            <select
                                className="form-control"
                                placeholder="Filter Type"
                                name="paymentType"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    setFieldValue('paymentType', e.target.value);
                                    handleSubmit();
                                }}
                                value={values.pay}
                            >
                                {paymentTypeList.map(list =>
                                    <option key={list.value} value={list.value}>{list.value}</option>,
                                )}
                            </select>
                            <small className="form-text text-muted">
                                <b>Filter</b> Payment Type
                            </small>
                        </div> */}
                        <div className="col-lg-3">
                            <DateRangePicker
                                small={true}
                                startDate={tanggal.start_date}
                                endDate={tanggal.end_date}
                                onDatesChange={({ startDate, endDate }) => {
                                    setTanggal({
                                        start_date: startDate,
                                        end_date: endDate
                                    })
                                    setFieldValue('start_date', startDate)
                                    setFieldValue('end_date', endDate)
                                    handleSubmit()
                                }}
                                focusedInput={onFocused}
                                onFocusChange={focusedInput => setOnFocused(focusedInput)}
                                numberOfMonths={1}
                                isOutsideRange={() => false}
                                showClearDates={true}
                                startDateId="start_date"
                                endDateId="end_date"
                                hideKeyboardShortcutsPanel={true}
                            />
                            <small className="form-text text-muted d-print-none">
                                Cari berdasarkan <b>Jarak Waktu</b>
                            </small>
                            <small className="form-text text-muted d-none">
                                Data Kolektor <b>Tangggal</b>
                            </small>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
        </>


    );
};

