import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { useThkChannelReportUIContext } from "./ThkChannelReportUIContext";
import ThkChannelReportTable from "./thk-channel-report-table/ThkChannelReportTable";
import { ThkChannelReportFilter } from "./thk-channel-report-table/thk-channel-report-filter/ThkChannelReportFilter";
import { RoleChecker } from "../../../../RoleChecker";

export const ThkChannelReportCard = () => {
  const thkChannelReportUIContext = useThkChannelReportUIContext();
  const thkChannelReportUIProps = useMemo(() => {
    return {
      newThkChannelReportButtonClick:
        thkChannelReportUIContext.newThkChannelReportButtonClick,
    };
  }, [thkChannelReportUIContext]);

  return (
    <Card>
      <CardHeader title="Template Laporan">
        <CardHeaderToolbar>
          {RoleChecker("Create", "thk_channel_report") && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={thkChannelReportUIProps.newThkChannelReportButtonClick}
            >
              Tema Channel Baru
            </button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ThkChannelReportFilter />
        <ThkChannelReportTable />
      </CardBody>
    </Card>
  );
};
