import React, { useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../_redux/donatur/donaturAction';
import * as columnFormatters from './column-formatters';

export const DonaturType = ( { donaturType, parent_uuid } ) => {
	const { currentState } = useSelector(
		( state ) => ({ currentState: state.donaturs }),
		shallowEqual,
	);

	const { dataChilds } = currentState;

	const fetchData = async (parent_uuid)=>{
		dispatch( actions.fetchChilds( parent_uuid ) );
	}

	const dispatch = useDispatch();
	useEffect( () => {
		fetchData(parent_uuid)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ parent_uuid ] );

	const columns = [
		{
			dataField: 'donor.name',
			text: 'Nama',
		},
		{
			dataField: 'donor.hp',
			text: 'No. HP',
		},
		{
			dataField: 'donor.email',
			text: 'Email',
		},
		{
			dataField: 'donor.is_active',
			text: 'Status',
			formatter:columnFormatters.StatusColumnFormatter
		},
		{
			dataField: 'action',
			text: 'Actions',
			formatter: columnFormatters.ActionsColumnFormatter,
			formatExtraData: {
				parent_uuid:parent_uuid,
				fetchData:fetchData
			},
			classes: 'text-right pr-0',
			headerClasses: 'text-right pr-3',
			style: {
				minWidth: '100px',
			},
		},

	];

	return (
		<>
			<BootstrapTable
				wrapperClasses="table-responsive"
				classes="table table-head-custom table-vertical-center overflow-hidden"
				bootstrap4
				bordered={ false }
				remote
				keyField="uuid"
				data={ dataChilds === undefined ? [] : dataChilds }
				columns={ columns }
			>
			</BootstrapTable>
		</>
	);
};

export default DonaturType;
