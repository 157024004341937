import { CircularProgress } from "@material-ui/core";
import React from "react";

export const ActionButton = ({
  className,
  style = {},
  type = "button",
  variant = "primary",
  onClick = () => null,
  ref = null,
  loading = false,
  children,
  text = "Submit",
}) => {
  function handleClick() {
    if (!loading) {
      onClick();
    }
  }

  return (
    <button
      ref={ref}
      type={type}
      className={`btn btn-${variant} ${className} d-flex justify-content-center align-items-center`}
      style={{ minWidth: "75px", minHeight: "36px", ...style }}
      onClick={handleClick}
    >
      {loading ? (
        <CircularProgress size={15} color="inherit" />
      ) : (
        <>{children ? children : <span>{text}</span>}</>
      )}
    </button>
  );
};
