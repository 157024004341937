import React, { useEffect } from 'react'
import Loading from '../../_metronic/_assets/js/components/loading';
import { useQuery } from '../../_metronic/_helpers';
import { PublicReportURL } from '../config/UrlEndPoint';


export default function RedirectDonationReport({ match: { params } }) {

  let query = useQuery();

  useEffect(() => {
    const donationId = query.get("id")
    if (params.id) {
      window.location.href = `${PublicReportURL}donation-receipt/${params.id}/formulir-setoran-donasi.pdf`
      return
    } else if (donationId) {
      window.location.href = `${PublicReportURL}donation-receipt/${donationId}/formulir-setoran-donasi.pdf`
      return
    }
    window.location.href = `${PublicReportURL}donation-receipt/${donationId}/formulir-setoran-donasi.pdf`
  }, [])

  return (
    <>
      <Loading show={true} />
    </>
  )
}
