import * as XLSX from "xlsx";

export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];

export const initialFilter = {
  filter: {
    name: "",
    thk_period: {},
    donor: {},
  },
  pageNumber: 1,
  pageSize: 10,
};

export const FILE_TYPE = {
  file_report_bg: "file_report_bg",
  file_certificate_bg: "file_certificate_bg",
};

export const FILE_TYPE_LABEL = {
  file_report_bg: "Laporan",
  file_certificate_bg: "Sertifikat",
};
