import React, { useEffect, useMemo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../_redux/exportListAction';
import * as crud from '../_redux/exportListCrud'
import * as uiHelpers from '../ExportListUIHelpers';
import { DateColumnFormatter } from './column-formatters/DateColumnsFormatter'
import { StatusColumnFormatter } from './column-formatters/StatusColumnFormatter'

import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage, toastOption,
} from '../../../../_metronic/_helpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../_metronic/_partials/controls';
import { useExportListUIContext } from '../ExportListUIContext';
import { toast } from "react-toastify";
import Modal from 'react-bootstrap/Modal'
import { RoleChecker } from '../../../RoleChecker';
import { useLocation } from 'react-router-dom';
// import { act } from 'react-dom/test-utils';
import { DonationURL } from "../../../config/UrlEndPoint"

export const ExportListTable = (props) => {
    // ExportList UI Context
    const exportListUIContext = useExportListUIContext();
    const exportListUIProps = useMemo(() => {
        return {
            queryParams: exportListUIContext.queryParams,
            setQueryParams: exportListUIContext.setQueryParams,
            newExportListClick: exportListUIContext.newExportListClick,
            reloadData: exportListUIContext.reloadData,
            openChooseExportListPage: exportListUIContext.openChooseExportListPage,
        };
    }, [exportListUIContext]);

    // Getting curret state of branch list from store (Redux)
    const { currentState } = useSelector(
        (state) => ({ currentState: state.exportList }),
        shallowEqual,
    );

    const { listLoading, entities, totalCount, error, loadingProgress } = currentState
    const [triggerFetch, setTriggerFetch] = useState(false)
    const [show, setShow] = useState(false);
    const [selected, setSelected] = useState('')

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    if (error) {
        toast.error(error, toastOption)
    }
    const dispatch = useDispatch();
    useEffect(() => {
        //Requset ke Donatur Action
        dispatch(actions.fetchExportListTableList(exportListUIProps.queryParams));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, exportListUIProps.queryParams, triggerFetch]);

    const downloadFile = (value) => {
        const dataToSend = {}
        dataToSend.file_name = value.file_name
        dataToSend.file = value
        if (value.type === "SANDRA_DONATION") {
            dispatch(actions.downloadDonationExport(dataToSend)).then(response => {
            }).catch(error => {
                toast.error(error.message, toastOption)
            })
        } else if (value.type === "SANDRA_DONOR") {
            dispatch(actions.downloadDonorExport(dataToSend)).then(response => {

            }).catch(error => {
                toast.error(error.message, toastOption)
            })
        } else if (value.type === "SANDRA_CONSOLIDATION") {
            const url = `${DonationURL}uploads/consolidation/${value.file.filename}`;
            window.open(url)
            // dispatch(actions.downloadConsolidationExport(dataToSend)).then(response => {

            // }).catch(error => {
            //     toast.error(error.message, toastOption)
            // })
        } else if (value.type === "SANDRA_WAKAF") {
            dispatch(actions.downloadWakafExport(dataToSend)).then(response => {

            }).catch(error => {
                toast.error(error.message, toastOption)
            })
        }


    }

    const deleteFile = (value) => {
        if (value.type === "SANDRA_DONATION") {
            dispatch(actions.deleteExportDonation(value.uuid)).then(response => {
                setTriggerFetch(!triggerFetch)
            }).catch(error => {
                toast.error(error.message, toastOption)
            })
        } else if (value.type === "SANDRA_DONOR") {
            dispatch(actions.deleteExportDonor(value.uuid)).then(response => {
                setTriggerFetch(!triggerFetch)

            }).catch(error => {
                toast.error(error.message, toastOption)
            })
        } else if (value.type === "SANDRA_CONSOLIDATION") {
            dispatch(actions.deleteExportConsolidation(value.uuid)).then(response => {
                setTriggerFetch(!triggerFetch)
            }).catch(error => {
                toast.error(error.message, toastOption)
            })
        } else if (value.type === "SANDRA_WAKAF") {
            dispatch(actions.deleteExportWakaf(value.uuid)).then(response => {
                setTriggerFetch(!triggerFetch)
            }).catch(error => {
                toast.error(error.message, toastOption)
            })
        }

    }

    const allowDelete = RoleChecker('Delete', 'daftar_export')

    const location = useLocation()

    const { pathname } = location


    // Table columns
    const columns = [
        {
            dataField: 'file_name',
            text: 'Nama File',
        },
        {
            dataField: 'type',
            text: 'type',
            formatter: StatusColumnFormatter
        },
        {
            dataField: 'created_at',
            text: 'Tanggal Export',
            formatter: DateColumnFormatter
        },
        pathname === "/export/list" ?
        {
            dataField: 'action',
            text: 'Actions',
            formatter: columnFormatters.ActionsColumnFormatter,
            formatExtraData: {
                downloadFile: downloadFile,
                loadingProgress: loadingProgress,
                showDeleteModal: handleShow,
                setSelected: setSelected,
                allowDelete
            },
            classes: 'text-right pr-0',
            headerClasses: 'text-right pr-3',
            style: {
                minWidth: '100px',
            },
        } : {
            dataField: 'user',
            text: 'User',
            formatter: (cell) => {
                if (cell) {
                    return (
                      <>
                        <span>{`${cell.name} - ${cell.email}`}</span>
                      </>
                    );
                } 
            }
        },
    ];


    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: totalCount,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: exportListUIProps.queryParams.pageSize,
        page: exportListUIProps.queryParams.pageNumber,
    };

    return (
        <>
            <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                {({ paginationProps, paginationTableProps }) => {
                    return (
                        <Pagination
                            isLoading={listLoading}
                            paginationProps={paginationProps}
                        >
                            <BootstrapTable
                                wrapperClasses="table-responsive"
                                classes="table table-head-custom table-vertical-center overflow-hidden"
                                bootstrap4
                                bordered={false}
                                remote
                                keyField="uuid"
                                data={entities === null ? [] : entities}
                                columns={columns}
                                // defaultSorted={uiHelpers.defaultSorted}
                                onTableChange={getHandlerTableChange(
                                    exportListUIProps.setQueryParams,
                                )}
                                {...paginationTableProps}
                            >
                                <PleaseWaitMessage entities={entities} />
                                <NoRecordsFoundMessage entities={entities} />
                            </BootstrapTable>
                        </Pagination>
                    );
                }}
            </PaginationProvider>
            {/* delete modal */}
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Hapus File Export</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Apakah Anda Yakin Ingin Menghapus File Export Dengan Nama <span className="text-warning">{selected.file_name}</span> ?
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-warning" onClick={handleClose}>
                        Batal
                    </button>

                    <button className="btn btn-danger" onClick={() => {
                        deleteFile(selected)
                        handleClose()
                    }}>
                        Hapus
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )

}