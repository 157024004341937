import React, { useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { useDonationExportUIContext } from "./DonationExportUIContext";
import { RoleChecker } from "../../../../RoleChecker";
import { DonationExportEditForm } from "./DonationExportEditForm";
import {
  DONATION_HEADERS_FINANCE_COLUMN,
  DONATION_HEADERS_FUNDRAISER_COLUMN,
  getDataDonationColumn,
  initialFilter,
} from "./DonationExportUIHelpers";
import DonationExportModal from "./donation-export-modal/DonationExportModal";
import * as ExcelJS from "exceljs";
import { findDonations } from "../_redux/donationExportCrud";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../_metronic/_helpers";
import Loading from "../../../../../_metronic/_assets/js/components/loading";
import moment from "moment";

export const DonationExportCard = ({ history, match }) => {
  const [dataValues, setDataValues] = useState(initialFilter);
  const [modal, setModal] = useState({
    confirmation: false,
    process: false,
  });

  function handleSetDataValues(values) {
    const payload = {
      ...values,
      channel: values?.channel?.uuid,
      donation_program: values?.donation_program?.uuid,
      donation_project: values?.donation_project?.uuid,
      donation_department: values?.donation_department?.uuid,
      donation_type: values?.donation_type?.uuid,
      user_input: values?.user_input?.uuid,
    };
    setModal((prev) => ({
      ...prev,
      confirmation: true,
    }));
    setDataValues((prev) => ({
      ...prev,
      filter: payload,
    }));
  }

  function handleRemoveDataValues() {
    setModal((prev) => ({
      ...prev,
      confirmation: false,
    }));
    setDataValues(initialFilter);
  }

  async function handleExport() {
    const { start_date, end_date } = dataValues.filter;
    const monthDateRange =
      moment(end_date)
        .startOf("day")
        .diff(moment(start_date).startOf("day"), "day") > 92;
    if (!start_date || !end_date) {
      return toast.error(
        "harap pilih pilih jarak waktu pencarian",
        toastOption
      );
    }
    if (monthDateRange) {
      return toast.error(
        "harap pilih jarak waktu pencarian kurang dari 3 bulan",
        toastOption
      );
    }
    setModal((prev) => ({
      ...prev,
      confirmation: false,
      process: true,
    }));
    generateAndDownloadExcel();
  }

  function handleHideModal() {
    setModal((prev) => ({
      process: false,
      confirmation: false,
    }));
  }

  async function generateExcelData(data) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    const exportType = dataValues.filter?.export_type;

    // Add headers to the worksheet
    if (exportType == "export_finance") {
      worksheet.addRow(DONATION_HEADERS_FINANCE_COLUMN); //  column headers
    } else if (exportType == "export_fundraiser") {
      worksheet.addRow(DONATION_HEADERS_FUNDRAISER_COLUMN); //  column headers
    }

    // Add data rows
    data.forEach((item) => {
      worksheet.addRow(getDataDonationColumn(item, exportType)); //  data fields
    });

    return workbook;
  }

  // 1220710013
  async function generateExcelWithPagination() {
    const pageSize = 100;
    let pageNumber = 1;

    const allData = [];

    while (true) {
      const {
        data: { data },
      } = await findDonations({
        pageNumber,
        pageSize,
        filter: dataValues.filter,
      });
      if (data.items.length === 0) {
        // No more data, break the loop
        break;
      }
      allData.push(...data.items);
      pageNumber++;
    }

    const workbook = await generateExcelData(allData);
    return workbook;
  }

  function mergeWorkbooks(workbooks) {
    const mergedWorkbook = new ExcelJS.Workbook();

    workbooks.forEach((workbook) => {
      workbook.eachSheet((worksheet) => {
        const newWorksheet = mergedWorkbook.addWorksheet(worksheet.name);
        worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
          row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
            const newCell = newWorksheet.getCell(rowNumber, colNumber);
            newCell.value = cell.value;
            newCell.style = cell.style;
          });
        });
      });
    });

    return mergedWorkbook;
  }

  async function generateAndDownloadExcel() {
    const workbook = await generateExcelWithPagination();
    const mergedWorkbook = mergeWorkbooks([workbook]);

    const buffer = await mergedWorkbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Export Donasi ${moment().format("YYYY-MM-DD HH:mm")}.xlsx`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    handleHideModal();
    toast.success("Export Donation Successfully!", toastOption);
  }

  return (
    <>
      <Loading show={modal.process} message="Generating..." />
      <DonationExportModal
        show={modal.confirmation}
        onHide={handleRemoveDataValues}
        onClick={handleExport}
      />
      <Card>
        <CardHeader title="Export Donasi"></CardHeader>
        <CardBody>
          <DonationExportEditForm
            initialValues={dataValues.filter}
            onSubmit={handleSetDataValues}
          />
        </CardBody>
      </Card>
    </>
  );
};
