import React from 'react';
import { CheckerUIProvider } from './CheckerUIContext';
import { CheckerCard } from './CheckerCard';
import { CheckerRequestDialog } from './checker-request-dialog/CheckerRequestDialog'
import { Route } from 'react-router-dom';

export function CheckerPage2( { history } ) {
	const checkerUIEvents = {
		openCheckerRequestDialog: ( id, status ) => {
			history.push( `/checker/${ id }/${ status }` );
		}
	}


	return (
		<CheckerUIProvider checkerUIEvents={ checkerUIEvents }>
			<Route path="/checker/:id/:status">
				{ ( { history, match } ) => (
					<CheckerRequestDialog
						show={ match != null }
						settle_batch_uuid={ match && match.params.id }
						onHide={ () => {
							history.push( '/checker' );
						} }
						status_checker={ match && match.params.status }
						history={history}
					/>
				) }
			</Route>
			<CheckerCard/>
		</CheckerUIProvider>
	)
}
