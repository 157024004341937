import React, { createContext, useContext, useEffect, useState } from 'react';
import * as requestChecker from '../_redux/checkerCrud';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../_metronic/_helpers';
import { useDispatch } from 'react-redux';
import * as actionsRecon from '../../Donation/_redux/reconcile/reconcileAction';

const CheckUIContext = createContext();

export function useCheckUIContext() {
    return useContext(CheckUIContext);
}

// export const CheckUIConsumer = CheckUIConsumer.Consumer;

export function CheckUIProvider({ settle_batch_uuid, currentDonationId, children, history }) {

    // Fungsi pilih checker
    const initBankPooling = {
        uuid: undefined,
        bank_uuid: undefined,
        bank_name: undefined,
        branch_uuid: undefined,
        branch_name: undefined,
        account_number: undefined
    };

    const [bank, setBank] = useState(initBankPooling);
    const [showBank, setShowBank] = useState(false);
    const openBank = () => {
        setShowBank(true);
    };

    const closeBank = () => {
        setShowBank(false);
    };

    const selectedBank = (data) => {
        setBank({
            uuid: data.uuid,
            bank_uuid: data.bank_uuid,
            bank_name: data.bank.name,
            branch_uuid: data.branch_uuid,
            branch_name: data.branch.name,
            account_number: data.account_number,
        });
        setShowBank(false);
    };
    // End Fungsi pilih checker

    const [isDisabled, setIsDisabled] = useState(false); // Disable button request
    // Fungsi tampil item donasi
    const [item, setItem] = useState([]);
    useEffect(() => {

        async function fetchItem() {
            const resCheck = await requestChecker.getDonationApprovedCheck();
            setItem(resCheck.data.data);
            if (resCheck.data.data.length === 0) {
                setIsDisabled(true);
            }
        }

        fetchItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatch = useDispatch();

    const createRekonsiliasi = async (donation_uuids, value) => {
        if (bank.uuid === undefined) {
            return toast.error('Bank pengumpul wajib dipilih', toastOption);
        }
        
        try {
            const formData = new FormData();

            if (donation_uuids.length > 0) {
                for (const index in donation_uuids) {
                    let item = donation_uuids[index];
                    formData.append(`donations[${index}][uuid]`, item);
                }
            }

            formData.append('bank_pooling_uuid', bank.uuid);
            formData.append('diff_description', value.diff_description);
            formData.append('channel_charge', value.channel_charge);
            formData.append('diff_amount', value.diff_amount);
            formData.append('amount', value.amount);
            formData.append('trx_date', value.trx_date);
            formData.append('file', value.file);
            dispatch(actionsRecon.createReconcile(formData)).then(response => {
                toast.success(response, toastOption);
            })
            
            setItem([]);
            setBank(initBankPooling);// Reset To Default jika berhasil request bank
            
        } catch (err) {
            toast.error(err.response.data.message, toastOption);
        }
        
    };

    const value = {
        bank,
        showBank,
        openBank,
        closeBank,
        selectedBank,
        createRekonsiliasi,
        item,
        isDisabled,
    };

    return (
        <CheckUIContext.Provider value={value}>
            {children}
        </CheckUIContext.Provider>
    );
}
