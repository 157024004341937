/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { numberFormatter, toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import {
  DropdownCustomToggler,
  DropdownMenu4,
} from "../../../../../_metronic/_partials/dropdowns";
import { useHtmlClassService } from "../../../../../_metronic/layout";
import { currencyFormatter } from "../../../../../_metronic/_helpers";

export function BarWidgetCustom({
  className,
  chartColor = "success",
  baseColor = "success",
  title = '',
  series,
  loading,
  chartID,
}) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor]);

  var options = {
    chart: {
      type: "bar",
      height: 314,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Jumlah Donasi",
        data: series,
      },
      // {
      //   name: "Donasi Tahun Sebelumnya",
      //   data: [35, 41, 62, 42, 13, 18, 29, 37, 36],
      // },
    ],
    stroke: {
      width: [5, 8],
      curve: "straight",
      dashArray: [0, 8],
    },
    xaxis: {
      categories: [
        "1 - 10RB",
        "10RB - 100RB",
        "100RB - 500RB",
        "500RB - 1JT",
        "1JT - 2JT",
        "2JT - 5JT",
        "5JT - 10JT",
      ],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: undefined,
      formatter: function (val, index) {
        return numberFormatter(val)
      },
      textAnchor: 'middle',
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        fontWeight: 'bold',
        colors: undefined
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#fff',
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: '#000',
          opacity: 0.45
        }
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: '#000',
        opacity: 0.45
      }
    },
    yaxis: {
      labels: {
        formatter: function (val, index) {
          return numberFormatter(val)
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
    },
  };

  useEffect(() => {
    const element = document.getElementById(`${chartID}`);

    if (!element) {
      return;
    }

    // const options = getChartOption(layoutProps);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, loading]);

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">{title}</div>
            </div>
          </div>
          <div className="card-toolbar">
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">
          {/* begin::Chart */}
          <div
            id={`${chartID}`}
            data-color={chartColor}
            style={{ height: "150px" }}
          />
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );
}