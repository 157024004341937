import React, { useEffect, useState } from "react";
import { ListGroup, Modal } from "react-bootstrap";
import { Input, Select } from "../../../../_metronic/_partials/controls";
import { ActionButton } from "../../../../_metronic/_partials/controls/ActionButton";
import { Field, Formik } from "formik";
import {
  DONATUR_EDUCAITON,
  DONATUR_GENDER,
  DONATUR_GREETING,
  DONATUR_TYPE,
  IS_ACTIVE,
  IS_ALIVE,
} from "../DonorImportUIHelpers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { findDistrict } from "../../MasterData/_redux/province/provinceCrud";
import ReactSelect from "react-select";

export default function ModalEditDonor({
  show,
  onHide,
  initValues = {},
  onSubmit,
}) {
  const [district, setDistrict] = useState([]);
  const [postalCode, setPostalCode] = useState([]);
  const [showPIC, setShowPIC] = useState("INDIVIDU");
  const [kotaValue, setKotaValue] = useState("");

  useEffect(() => {
    if (show) {
      let value = initValues?.data?.district;
      let query = {
        district: value,
        city: value,
      };

      if (initValues?.data?.district !== null) {
        if (initValues?.data?.district !== "") {
          findDistrict(query).then((response) => {
            setPostalCode(response.data.data[0].postalcode);
          });
        }
      }

      if (initValues?.data?.name) {
        if (
          !initValues?.data?.district &&
          !initValues?.data?.city &&
          !initValues?.data?.province
        ) {
          setKotaValue(``);
        } else {
          setKotaValue(
            `${initValues?.data?.district}, ${initValues?.data?.city}, ${initValues?.data?.province}`
          );
        }
      } else {
        setKotaValue(``);
      }

      initValues?.data?.type === ""
        ? setShowPIC("INDIVIDU")
        : setShowPIC(initValues?.data?.type);
      // eslint-disable-next-line
    }
  }, [initValues]);

  const onInputChange = (e) => {
    const value = e.target.value;

    if (value.length >= 3) {
      let query = {
        district: value,
        city: value,
      };
      findDistrict(query).then((response) => {
        setDistrict(response.data.data);
      });
    }
  };

  const onSelectTag = (value, setFieldValue) => {
    if (value) {
      const array = value.split(",");
      if (array.length === 3) {
        let index = district.findIndex(
          (i) =>
            i.district === array[0].trim() &&
            i.city === array[1].trim() &&
            i.province_name === array[2].trim()
        );

        setPostalCode(district[index].postalcode);

        setFieldValue("province", district[index].province_name);
        setFieldValue("city", district[index].city);
        setFieldValue("district", district[index].district);
        setKotaValue(
          `${district[index].district}, ${district[index].city}, ${district[index].province_name}`
        );
      }
    } else {
      setPostalCode([]);
      setFieldValue("province", "");
      setFieldValue("city", "");
      setFieldValue("district", "");
      setKotaValue("");
      setFieldValue("postal_code", "");
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initValues.data}
      onSubmit={onSubmit}
    >
      {({ setFieldValue, values, handleSubmit, handleChange }) => (
        <>
          <Modal
            size="xl"
            show={show}
            centered
            onHide={onHide}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                Edit Donatur
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group row">
                <div className="col">
                  <h4>Error List</h4>
                  <ul>
                    {initValues?.error?.map((list) => (
                      <li>{list.message}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="form-group row">
                <div className="col">
                  <label>Tipe Donatur</label>
                  <Select
                    name="type"
                    withFeedbackLabel={false}
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                  >
                    {DONATUR_TYPE.map((row) => (
                      <option key={row.value} value={row.value}>
                        {row.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="col">
                  <label>Nama</label>
                  <Field
                    component={Input}
                    name="name"
                    placeholder="Masukan nama..."
                    onChange={handleChange}
                    customFeedbackLabel=" "
                  />
                </div>
                <div className="col">
                  <label>Sapaan</label>
                  <Select name="greeting" withFeedbackLabel={false}>
                    {DONATUR_GREETING.map((row) => (
                      <option key={row.value} value={row.value}>
                        {row.value}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col">
                  <label>HP</label>
                  <Field
                    component={Input}
                    name="hp"
                    placeholder="Masukan hp..."
                    onChange={handleChange}
                    customFeedbackLabel=" "
                  />
                </div>
                <div className="col">
                  <label>Email</label>
                  <Field
                    component={Input}
                    name="email"
                    placeholder="Masukan email..."
                    onChange={handleChange}
                    customFeedbackLabel=" "
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col">
                  <label>Address</label>
                  <Field
                    component={Input}
                    name="address"
                    placeholder="Masukan Alamat..."
                    onChange={handleChange}
                    customFeedbackLabel=" "
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col">
                  <Autocomplete
                    id="searchPostalCode"
                    // disableClearable
                    onChange={(e, value) => {
                      onSelectTag(value, setFieldValue);
                    }}
                    value={kotaValue}
                    options={district.map(
                      (option) =>
                        `${option.district}, ${option.city}, ${option.province_name}`
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cari Kota / Kecamatan"
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: "search" }}
                        onChange={(e) => {
                          onInputChange(e);
                        }}
                      />
                    )}
                  />
                </div>
                <div className="col">
                  <label>Kode Pos</label>
                  <Select name="postal_code" withFeedbackLabel={false}>
                    <option value="">-PILIH-</option>
                    {postalCode.map((row) => (
                      <option key={row} value={row}>
                        {row}
                      </option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="form-group row">
                <div className="col">
                  <label>NIK / Nomor KTP / Nomor Perusahaan</label>
                  <Field
                    component={Input}
                    name="nik"
                    placeholder="Masukan provinsi..."
                    onChange={handleChange}
                    customFeedbackLabel=" "
                  />
                </div>
                <div className="col">
                  <label>NPWP</label>
                  <Field
                    component={Input}
                    name="npwp"
                    placeholder="Masukan npwp..."
                    onChange={handleChange}
                    customFeedbackLabel=" "
                  />
                </div>
                <div className="col">
                  <label>NPWZ</label>
                  <Field
                    component={Input}
                    name="npwz"
                    placeholder="Masukan npwz..."
                    onChange={handleChange}
                    customFeedbackLabel=" "
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col">
                  <label>Status Kehidupan</label>
                  {(showPIC === "INDIVIDU" || showPIC === "") && (
                    <>
                      <div className="co">
                        <Select name="is_alive" withFeedbackLabel={false}>
                          {IS_ALIVE.map((row) => (
                            <option key={row.value} value={row.value}>
                              {row.name}
                            </option>
                          ))}
                        </Select>
                      </div>
                    </>
                  )}
                </div>
                <div className="col">
                  <label>Status Keaktifan</label>
                  <Select name="is_active" withFeedbackLabel={false}>
                    {IS_ACTIVE.map((row) => (
                      <option key={row.value} value={row.value}>
                        {row.name}
                      </option>
                    ))}
                  </Select>
                </div>
                <div className="col">
                  <label>Kode Rekening</label>
                  <Field
                    component={Input}
                    name="kode_rekening"
                    placeholder="Masukan npwz..."
                    onChange={handleChange}
                    customFeedbackLabel=" "
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col">
                  <label>Tanggal Lahir</label>
                  <Field
                    type="date"
                    component={Input}
                    name="tanggal_lahir"
                    withFeedbackLabel={false}
                    onChange={(e) => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                  />
                </div>
                <div className="col">
                  <label>Jenis Kelamin</label>
                  <ReactSelect
                    // isClearable
                    name="jenis_kelamin"
                    placeholder="Jenis Kelamin..."
                    options={DONATUR_GENDER.map(({ value, name: label }) => ({
                      label,
                      value,
                    }))}
                    value={
                      values?.jenis_kelamin
                        ? {
                            label: DONATUR_GENDER.find(
                              (item) => item.value === values?.jenis_kelamin
                            ).name,
                            value: values?.jenis_kelamin,
                          }
                        : null
                    }
                    onChange={(selected) => {
                      if (!selected) {
                        return setFieldValue("jenis_kelamin");
                      }

                      setFieldValue("jenis_kelamin", selected.value);
                    }}
                  />
                </div>
                <div className="col">
                  <label>Pekerjaan</label>
                  <Field
                    component={Input}
                    name="pekerjaan"
                    placeholder="Masukan provinsi..."
                    onChange={handleChange}
                    customFeedbackLabel=" "
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-4">
                  <label>Pendidikan Terakhir</label>
                  <ReactSelect
                    name="pendidikan"
                    placeholder="Pendidikan Terakhir..."
                    options={DONATUR_EDUCAITON.map(
                      ({ value, name: label }) => ({
                        label,
                        value,
                      })
                    )}
                    value={
                      values?.pendidikan
                        ? {
                            label: DONATUR_EDUCAITON.find(
                              (item) => item.value === values?.pendidikan
                            ).name,
                            value: values?.pendidikan,
                          }
                        : null
                    }
                    onChange={(selected) => {
                      if (!selected) {
                        return setFieldValue("pendidikan", "");
                      }

                      setFieldValue("pendidikan", selected.value);
                    }}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex align-items-center">
                <ActionButton
                  type="button"
                  style={{ width: "75px", height: "35.59px" }}
                  className="btn btn-secondary btn-elevate mr-3"
                  onClick={onHide}
                  text="Batal"
                />
                <ActionButton
                  type="button"
                  style={{ width: "75px", height: "35.59px" }}
                  className="btn btn-primary btn-elevate"
                  onClick={handleSubmit}
                  text="Simpan"
                />
              </div>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </Formik>
  );
}
