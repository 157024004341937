import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  Pagination,
} from "../../../../../../_metronic/_partials/controls";
import BootstrapTable from "react-bootstrap-table-next";
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  getHandlerTableChange,
  toAbsoluteUrl,
} from "../../../../../../_metronic/_helpers";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchThkChannelReport,
  findThkChannelReportDonation,
} from "../../_redux/thkChannelReportAction";
import { sizePerPageList } from "../ThkChannelReportUIHelpers";
import { StatusColumnFormatter } from "../../../../Donation/pages/list/donation-table/column-formatters";
import SVG from "react-inlinesvg";
import ThkChannelReportDonationFilter from "./ThkChannelReportDonationFilter";

export default function ThkChannelReportDonationList({
  history,
  id,
  currentState,
  thkChannelReportForEdit,
}) {
  const [queryParams, setQueryParams] = useState({
    pageNumber: 1,
    pageSize: 10,
    filter: {
      uuid: id,
      name: "",
      donation_hid: "",
      kurban_hid: "",
      dona_status: "",
      kurban_status: "",
      thk_partnership: "",
      thk_livestock_campaign: "",
    },
  });

  const { totalCount, entities, listLoading } = currentState;

  const dispatch = useDispatch();

  function dispatchFetch() {
    dispatch(findThkChannelReportDonation(queryParams));
  }

  useEffect(() => {
    dispatchFetch();
  }, [queryParams]);

  function handleClickDetailDonation(uuid) {
    history.push(`/donasi/${uuid}/detail`);
  }

  // Table columns
  const columns = [
    {
      dataField: "donation_item_name_1",
      text: "Atas Nama",
    },
    {
      dataField: "thk_livestock_campaign_name",
      text: "Hewan Campaign",
    },
    {
      dataField: "donation_hid",
      text: "ID Donasi",
    },
    {
      dataField: "kurban_hid",
      text: "ID Kurban",
    },
    {
      dataField: "donation_status",
      text: "Status Donasi",
      formatter: StatusColumnFormatter,
    },
    {
      dataField: "kurban_status",
      text: "Status Kurban",
      formatter: (cell) => {
        if (cell) {
          const statusFormatter = {
            NOT_DISTRIBUTED: "Belum Di Distribusi",
            SETTLE: "Belum Di Distribusi",
            WAITING_PARTNER: "Belum Di proses",
            ACCEPTED_PARTNER: "Siap Potong",
            EXECUTED: "Terpotong",
            REPORTED: "Laporan Potong",
            FINISH: "FINISH",
          };
          let badge = "dark";
          switch (cell) {
            case "NOT_DISTRIBUTED":
              badge = "warning";
              break;
            case "SETTLE":
              badge = "warning";
              break;
            case "WAITING_PARTNER":
              badge = "warning";
              break;
            case "ACCEPTED_PARTNER":
              badge = "primary";
              break;
            case "EXECUTED":
              badge = "primary";
              break;
            case "REPORTED":
              badge = "primary";
              break;
            case "FINISH":
              badge = "success";
              break;

            default:
              break;
          }

          return (
            <span
              className={`label label-lg label-light-${badge} label-inline text-nowrap`}
            >
              {statusFormatter[cell]}
            </span>
          );
        }
      },
    },
    {
      text: "Actions",
      formatter: (_, row) => {
        const pdfUrl = `https://kis.dompetdhuafa.org/frontend/guest/status/channelReport/${row.donation_item_uuid}`;
        return (
          <div className="d-flex ">
            {["REPORTED", "FINISH"].includes(row.kurban_status) && (
              <OverlayTrigger
                overlay={
                  <Tooltip id="products-edit-tooltip">Download PDF</Tooltip>
                }
              >
                <a
                  className="btn btn-icon btn-light btn-hover-dark btn-sm mx-3"
                  href={pdfUrl}
                  target="_blank"
                >
                  <span className="svg-icon svg-icon-md svg-icon-dark">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")}
                    />
                  </span>
                </a>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Detail Donasi</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => handleClickDetailDonation(row.donation_uuid)}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
          </div>
        );
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  function handleBack() {
    history.goBack();
  }

  return (
    <div>
      <ThkChannelReportDonationFilter
        queryParams={queryParams}
        setQueryParams={setQueryParams}
      />
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                // defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(setQueryParams)}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: productsUIProps.ids,
                //   setIds: productsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </div>
  );
}
