/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { color } from "./StylingHelper";
import { currencyFormatter } from "../../../../../_metronic/_helpers";
import { CircularProgress } from "@material-ui/core";


export function TilesReceivedDonorWidget({
  className,
  iconColor = "success",
  widgetHeight = "150px",
  title = "",
  value = "",
  subTitle = "",
  subValue = "",
  loading = false,
  icon = toAbsoluteUrl("/media/svg/icons/Layout/Layout-Grid.svg")
}) {
  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className={`card-body ${loading && "d-flex justify-content-center"}`}>
          {loading ? <div className="text-center align-self-center"><CircularProgress /></div> :
            <div>
              <i
                className="far fa-money-bill-alt"
                style={{ color: color.greenPrimary, fontSize: "36px" }}
              ></i>
              {/* <span className={`svg-icon svg-icon-3x svg-icon-${iconColor}`}>
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}
            />
          </span> */}
              <div className="text-muted font-weight-bold font-size-lg mt-8">
                Total Penerimaan Donasi:
              </div>
              <div className="text-dark font-weight-bolder font-size-h3 mt-1">
                {value}
              </div>

              {/* <div className="text-muted font-weight-bold font-size-lg mt-3">
                1 Tahun Terakhir
              </div> */}
              {/* <div className="text-dark font-weight-bolder font-size-h2 mt-1">
            1 Tahun Terakhir
          </div> */}
            </div>}

        </div>
      </div>
    </>
  );
}
