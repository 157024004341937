export const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '50', value: 50 },
];

export const initialFilter = {
    filter: {
        name: '',
        email: '',
        role: ''
    },
    pageNumber: 1,
    pageSize: 10,
};

export const UserStatusBadge = {
    true: { text: 'Active', type: 'success' },
    false: { text: 'Not active', type: 'danger' },
};

export const role = [
    {
        name: 'Fundraiser',
        value: 'Fundraiser'
    }, {
        name: 'Collector',
        value: 'Collector'
    }, {
        name: 'Checker',
        value: 'Checker'
    }, {
        name: 'Data Proses',
        value: 'DataProses'
    }, {
        name: 'Customer Care',
        value: 'CustomerCare'
    }, {
        name: 'Keuangan',
        value: 'Keuangan'
    }, {
        name: 'Admin Cabang',
        value: 'AdminCabang'
    }, {
        name: 'Pickup',
        value: 'Pickup'
    }, {
        name: 'Fundraiser, Collector, Checker',
        value: 'FundCollCheck'
    }, {
        name: 'Kepala Cabang',
        value: 'KepalaCabang'
    }, {
        name: 'Helpdesk',
        value: 'Helpdesk'
    }, {
        name: 'Mitra Echannel',
        value: 'MitraEchannel'
    }
]

export const badge = (role) => {
    let data = {};
    switch (role) {
        case 'SuperAdmin':
            data = { text: 'Super Admin', type: 'danger' };
            break;
        case 'Fundraiser':
            data = { text: 'Fundraiser', type: 'primary' };
            break;
        case 'FundCollCheck':
            data = { text: 'FundCollCheck', type: 'primary' };
            break;
        case 'KepalaCabang':
            data = { text: 'Kepala Cabang', type: 'primary' };
            break;
        case 'Collector':
            data = { text: 'Collector', type: 'info' };
            break;
        case 'Checker':
            data = { text: 'Checker', type: 'info' };
            break;
        case 'Helpdesk':
            data = { text: 'Helpdesk', type: 'info' };
            break;
        case 'DataProses':
            data = { text: 'Data Proses', type: 'primary' };
            break;
        case 'CustomerCare':
            data = { text: 'Customer Care', type: 'primary' };
            break;
        case 'Keuangan':
            data = { text: 'Keuangan', type: 'success' };
            break;
        case 'AdminCabang':
            data = { text: 'Admin Cabang', type: 'warning' };
            break;
        default:
            data = { text: role, type: 'warning' };
    }

    return data;
};
