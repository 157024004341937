import React from 'react';
import { Modal } from 'react-bootstrap';
import { DonaturTypeDialogUIProvider } from './DonaturTypeDialogUIContext';
import { DonaturTypeDialogTable } from './DonaturTypeDialogTable';
import { DonaturTypeFilter } from './DonaturTypeFilter';

export function DonaturTypeDialog( { onHide, show, parent } ) {
	return (
		<>
			<Modal
				size="lg"
				show={ show }
				onHide={ onHide }
				aria-labelledby="modal-sizes-title-lg"
			>
				<Modal.Header closeButton>
					<Modal.Title id="modal-sizes-title-lg">Select Donatur</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<DonaturTypeDialogUIProvider parent={parent}>
						<DonaturTypeFilter/>
						<DonaturTypeDialogTable onHide={ onHide } parent={parent}/>
					</DonaturTypeDialogUIProvider>

				</Modal.Body>
			</Modal>
		</>
	)
}