/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";

export function HewanKurbanCampaignEditDialogHeader({ id }) {

    const [title, setTitle] = useState("");
    // Channel Redux state
    const { hewanKurbanCampaignForEdit, actionsLoading } = useSelector(
        (state) => ({
            hewanKurbanCampaignForEdit: state.hewanKurbanCampaigns.hewanKurbanCampaignForEdit,
            actionsLoading: state.channels.actionsLoading,
        }),
        shallowEqual
    );

    useEffect(() => {
        let _title = id ? "" : "Hewan Kurban Campaign Baru";
        if (hewanKurbanCampaignForEdit && id) {
            _title = "Edit Hewan Kurban Campaign";
        }

        setTitle(_title);
        // eslint-disable-next-line
    }, [hewanKurbanCampaignForEdit, actionsLoading]);

    return (
        <>
            {actionsLoading && <ModalProgressBar variant="query" />}
            <Modal.Header closeButton>
                <Modal.Title id="modal-sizes-title-lg">{title}</Modal.Title>
            </Modal.Header>
        </>
    );
}
