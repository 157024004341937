import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ThkTemplateEditForm } from "./ThkReportEditForm";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../../_metronic/_partials/controls";
import Loading from "../../../../../../../_metronic/_assets/js/components/loading";
import * as actions from "../../../_redux/partnershipAction";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../../../_metronic/_helpers";

export const ThkReportTemplateCard = ({ history }) => {
  const [options, setOptions] = useState({
    period: [],
  });

  const location = useLocation();
  const {
    partnership_uuid,
    period_year,
    period_uuid,
    report_file,
    file_type,
    certificate_file,
    report_template_type,
  } = location.state;

  const dispatch = useDispatch();
  const { currentState } = useSelector(
    (state) => ({ currentState: state.thkPartnership }),
    shallowEqual
  );

  const { actionsLoading } = currentState;

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      period: [
        {
          label: period_year,
          value: period_uuid,
        },
      ],
    }));
  }, [location]);

  const savePhoto = (payload, file_type, uuid) => {
    const formData = new FormData();
    formData.append("type", file_type);
    formData.append("file", payload.image);
    formData.append("report_template_type", payload.report_template_type);

    dispatch(actions.updateReportTemplate(formData, uuid)).then((response) => {
      toast.success(response.message, toastOption);
      dispatch(actions.reloadPartnershipState());
      handleBack();
    });
  };

  function handleBack() {
    history.goBack();
  }

  return (
    <>
      <Loading show={actionsLoading} />
      <Card>
        <CardHeader title={`Edit Template`}>
          <CardHeaderToolbar>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleBack}
            >
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <ThkTemplateEditForm
            savePhoto={savePhoto}
            partnership_uuid={partnership_uuid}
            file_type={file_type}
            report_file={report_file}
            options={options}
            certificate_file={certificate_file}
            report_template_type={report_template_type}
          />
        </CardBody>
      </Card>
    </>
  );
};
