import { createSlice } from '@reduxjs/toolkit';

const initialChannelsState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    channelForEdit: undefined,
    lastError: null,
};
export const callTypes = {
    list: 'list',
    action: 'action',
};

export const channelsSlice = createSlice({
    name: 'channels',
    initialState: initialChannelsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },
        // getChannelById
        channelFetched: (state, action) => {
            state.actionsLoading = false;
            state.channelForEdit = action.payload.channelForEdit;
            state.error = null;
        },
        // findChannels
        channelsFetched: (state, action) => {
            const { total_items, items } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = items;
            state.totalCount = total_items;
        },
        // createChannel
        channelCreated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            // state.entities.push(action.payload.channel);
        },
        // updateChannel
        channelUpdated: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
        },
        // updateChannel
        channelReload: (state, action) => {
            state.listLoading = false;
            state.actionsLoading = false;
            state.error = null;
        },

    },
});

