import axios from 'axios';
import { AuthURL } from '../../../config/UrlEndPoint';

export const LOGIN_URL = `${AuthURL}signin`;
export const ME_URL = `${AuthURL}me`;


export function login(email, password) {
    return axios.post(LOGIN_URL, { email, password });
}

export function getUserByToken() {
    // Authorization head should be fulfilled in interceptor.
    return axios.get(ME_URL);
}



