import axios from 'axios';
import { DonationURL } from '../../../config/UrlEndPoint';

export const DONATION_URL = `${DonationURL}donation`;

// request data list collector yang akan di collect
export function findCollector(queryParams) {
    return axios.post(`${DONATION_URL}/collect/request/find`, queryParams);
}

//Get Transaksi By collect_batch_ID
export function getCollectById(collect_batch_uuid, status) {
    return axios.get(`${DONATION_URL}/collect/request/${collect_batch_uuid}/${status}`);
}

// UPDATE => PUT: update the collector status on the server
export function updateCollectStatus(data_update) {
    return axios.put(`${DONATION_URL}/collect/process`, data_update);
}

//Get List transaksi yang sudah dilakukan approved
export function getDonationApproved(data) {
    return axios.get(`${DONATION_URL}/checker`);
}

//Request Checker Donation
export function updateRequestChecker(settle_user_uuid) {
    return axios.put(`${DONATION_URL}/requestchecker`, settle_user_uuid);
}
