import React, { useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../_redux/donatur/donaturAction';
import * as columnFormatters from './column-formatters';

export const DonaturParentTable = ( {uuid } ) => {

	const { currentState } = useSelector(
		( state ) => ({ currentState: state.donaturs }),
		shallowEqual,
	);

	const { dataParents } = currentState;

	const fetchData = async (uuid)=>{
		dispatch( actions.fetchParents( uuid ) );
	}

	const dispatch = useDispatch();
	useEffect( () => {
		fetchData(uuid)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ uuid ] );

	const columns = [
		{
			dataField: 'donor_p.name',
			text: 'Nama',
		},
		{
			dataField: 'donor_p.pic',
			text: 'Nama PIC',
		},
		{
			dataField: 'donor_p.pic_hp',
			text: 'HP PIC',
		},
		{
			dataField: 'donor_p.pic_email',
			text: 'Email PIC',
		},
		{
			dataField: 'donor_p.is_active',
			text: 'Status',
			formatter:columnFormatters.StatusColumnFormatter
		},
		{
			dataField: 'action',
			text: 'Aksi',
			formatter: columnFormatters.ActionsColumnFormatter,
			formatExtraData: {
				child_uuid:uuid,
				fetchData:fetchData
			},
			classes: 'text-right pr-0',
			headerClasses: 'text-right pr-3',
			style: {
				minWidth: '100px',
			},
		},

	];

	return (
		<>
			<BootstrapTable
				wrapperClasses="table-responsive"
				classes="table table-head-custom table-vertical-center overflow-hidden"
				bootstrap4
				bordered={ false }
				remote
				keyField="uuid"
				data={ dataParents === undefined ? [] : dataParents }
				columns={ columns }
			>
			</BootstrapTable>
		</>
	);
};

export default DonaturParentTable;
