import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls"; // import { Collapse } from 'react-bootstrap';
import { DonaturTrxTable } from "./DonaturTrxTable";
import { DonaturTrxUIProvider } from "./DonaturTrxUIContext";
import { DonationFilter } from "./donation-filter/DonationFilter";
import { Collapse, Badge } from "react-bootstrap";
import { useDonaturTrxUIContext } from "../donatur-trx/DonaturTrxUIContext";
import { PrintDonaturTrxConsolidationExportDialog } from "./consolidation-download/PrintDonaturTrxConsolidationExportDialog";
import { RoleChecker } from "../../../RoleChecker";
import ExportDonationModal from "./ExportDonationModal";
import { exportDonorDonation } from "../_redux/donatur/donaturCrud";
import Loading from "../../../../_metronic/_assets/js/components/loading";
import { toast } from "react-toastify";

const DonaturTrx = ({
  user_uuid,
  donaturType,
  tab,
  openDetailDonationPage,
  merge,
}) => {
  const [exportModal, setExportModal] = useState({
    show: false,
  });
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const changeShow = () => {
    setOpen(!open);
  };

  const donaturTrxUIContext = useDonaturTrxUIContext();
  const donaturTrxUIProps = useMemo(() => {
    return {
      openConsolidationDialog: !merge
        ? donaturTrxUIContext.openConsolidationDialog
        : null,
      closeConsolidationDialog: !merge
        ? donaturTrxUIContext.closeConsolidationDialog
        : null,
      setOpenConsolidationDialog: !merge
        ? donaturTrxUIContext.setOpenConsolidationDialog
        : null,
      queryParams: donaturTrxUIContext.queryParams,
    };
  }, [donaturTrxUIContext]);

  const [currentFilter, setCurrentFilter] = useState(
    donaturTrxUIProps.queryParams
  );

  function handleShowModal() {
    setExportModal((prev) => ({
      ...prev,
      show: !prev.show,
    }));
  }

  async function handleClickExport(payload) {
    handleShowModal();
    setLoading(true);
    const data = { ...currentFilter, ...payload };
    await exportDonorDonation(data)
      .then((res) => {
        const url = URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.target = "_blank";
        link.setAttribute("download", `${payload.filename}.xlsx`);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        console.log(`err`, err);
        toast.error("Gagal export data");
      })
      .then(() => {
        setLoading(false);
      });
  }

  return (
    <div>
      <Loading show={loading} />
      <ExportDonationModal
        {...exportModal}
        onHide={handleShowModal}
        setExportModal={setExportModal}
        handleClick={handleClickExport}
      />
      <div className="d-flex justify-content-end mb-2">
        {tab === "riwayat_donasi" && (
          <button
            className="btn btn-outline-dark mx-2"
            onClick={handleShowModal}
          >
            Export
          </button>
        )}
        {RoleChecker("Cetak Konsolidasi", "detail_donatur") && (
          <button
            type="button"
            className="btn btn-info mr-2"
            onClick={() => donaturTrxUIProps.setOpenConsolidationDialog(true)}
          >
            Cetak Konsolidasi
          </button>
        )}
        <button
          type="button"
          className="btn btn-warning mr-2"
          onClick={changeShow}
          aria-controls="collapse-filter-donasi"
          aria-expanded={open}
        >
          Cari
        </button>
      </div>

      <DonaturTrxUIProvider donaturUuid={user_uuid}>
        <Collapse in={open}>
          <div id="collapse-filter-donasi">
            <DonationFilter
              user_uuid={user_uuid}
              donaturType={donaturType}
              setCurrentFilter={setCurrentFilter}
            />
          </div>
        </Collapse>
        {!merge && (
          <PrintDonaturTrxConsolidationExportDialog
            show={donaturTrxUIProps.openConsolidationDialog}
            onHide={donaturTrxUIProps.closeConsolidationDialog}
          />
        )}
        <DonaturTrxTable
          donaturType={donaturType}
          tab={tab}
          openDetailDonationPage={openDetailDonationPage}
          setCurrentFilter={setCurrentFilter}
          user_uuid={user_uuid}
        />
      </DonaturTrxUIProvider>
    </div>
  );
};

export default DonaturTrx;
