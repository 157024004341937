import React, { useMemo, useState, useEffect } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { useHewanKurbanUIContext } from '../../HewanKurbanUIContext';
import { initialFilter, categoryHewan, tipeHewan } from '../../HewanKurbanUIHelpers';
import CurrencyInput from 'react-currency-input-field';
import { getThkPeriodAll } from '../../../../../_redux/hewan-kurban/hewanKurbanCrud';

const prepareFilter = (queryParams, values) => {
	const { name, type, category, priceGte, priceLte, periode } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};

	//add to Object filter
	filter.name = name ?? '';
	filter.type = type ?? '';
	filter.category = category ?? '';
	filter.priceGte = priceGte ?? '';
	filter.priceLte = priceLte ?? '';
	filter.periode = periode ?? '';
	newQueryParams.filter = filter;
	return newQueryParams;
};

export const HewanKurbanFilter = ({ listLoading }) => {
	// HewanKurban UI Context
	const hewanKurbanUIContext = useHewanKurbanUIContext();
	const hewanKurbanUIProps = useMemo(() => {
		return {
			setQueryParams: hewanKurbanUIContext.setQueryParams,
			queryParams: hewanKurbanUIContext.queryParams,
			filterType: hewanKurbanUIContext.filterType,
		};
	}, [hewanKurbanUIContext]);

	const applyFilter = (values) => {
		const newQueryParams = prepareFilter(hewanKurbanUIProps.queryParams, values);
		if (!isEqual(newQueryParams, hewanKurbanUIProps.queryParams)) {
			newQueryParams.pageNumber = 1;
			hewanKurbanUIProps.setQueryParams(newQueryParams);
		}
	};

	const [Periode, setPeriode] = useState([])

	useEffect(() => {

		const getPeriode = async () => {
			await getThkPeriodAll().then((response) => {
				setPeriode(response.data.data)
			}).catch((error) => {
				console.log(error);
			})
		}
		getPeriode()

	}, [])


	return (
		<Formik initialValues={initialFilter.filter} onSubmit={(values) => {
			applyFilter(values);
		}}
		>{({ values, handleSubmit, handleBlur, setFieldValue }) => (
			<form onSubmit={handleSubmit} className="form form-label-right">
				<div className="row">
					<div className="col-lg-4 mb-2">
						<input
							type="text"
							className="form-control"
							name="name"
							placeholder="Search"
							onBlur={handleBlur}
							value={values.name}
							onChange={(e) => {
								setFieldValue('name', e.target.value);
							}}
						/>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Nama Hewan Kurban
						</small>

					</div>
					<div className="col-lg-4 mb-2">

						<select
							name="type"
							placeholder="Text"
							label="Tipe"
							onBlur={handleBlur}
							className='form-control'
							onChange={(e) => {
								setFieldValue('type', e.target.value);
							}}
						>
							<option value="">Pilih</option>
							{
								tipeHewan.map((item) => {
									return <option key={item.value} value={item.value}>{item.text}</option>
								})
							}
						</select>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Tipe Hewan Kurban
						</small>
					</div>

					<div className="col-lg-4 mb-2">

						<CurrencyInput
							name="priceGte"
							placeholder="Harga Hewan Kurban Lebih Dari Sama Dengan"
							intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
							className="form-control"
							value={values.priceGte}
							decimalsLimit={2}
							label="Jumlah"
							onValueChange={(value, name) => {
								if (value) {
									setFieldValue(name, Number(value))
								} else {
									setFieldValue(name, "")
								}
							}}
						/>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Harga Hewan Kurban Lebih Dari Sama Dengan
						</small>
					</div>
					<div className="col-lg-4 mb-2">

						<CurrencyInput
							name="priceLte"
							placeholder="Harga Hewan Kurban Kurang Dari Sama Dengan"
							intlConfig={{ locale: 'id-ID', currency: 'IDR' }}
							className="form-control"
							value={values.priceLte}
							decimalsLimit={2}
							label="Jumlah"
							onValueChange={(value, name) => {
								if (value) {
									setFieldValue(name, Number(value))
								} else {
									setFieldValue(name, "")
								}
							}}
						/>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Harga Hewan Kurban Kurang Dari Sama Dengan
						</small>
					</div>

					<div className="col-lg-4 mb-2">

						<select
							name="periode"
							placeholder="Periode"
							label="periode"
							onBlur={handleBlur}
							className='form-control'
							onChange={(e) => {
								setFieldValue('periode', e.target.value);
							}}
							value={values.periode}
						>
							<option value="">Pilih</option>
							{
								Periode.map((item) => {
									return <option key={item.name} value={item.name}>{item.name}</option>
								})
							}
						</select>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Periode Kurban
						</small>
					</div>
				</div>
				<button
					type="submit"
					onClick={() => handleSubmit()}
					className="btn btn-primary btn-elevate float-right mb-2"
				>
					Cari
				</button>
			</form>
		)}
		</Formik>

	);
};

