import React from 'react';
import {Formik, Field, Form} from 'formik';
import {Input, Select} from '../../../../_metronic/_partials/controls';
import {jenisKelamin, pendidikan} from '../DonaturUIHelpers';
import * as Yup from "yup"

const Schema = Yup.object().shape({
        password: Yup.string().required('New Password is required'),
        cPassword: Yup.string().required('Password confirmation is required')
            .when('password', {
                is: (val) => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref('password')],
                    'Password and Confirm Password didn\'t match',
                ),
            }),
    });

export const DonaturChangePassword = ({donaturPassword, btnRef, saveDonaturPassword}) => {
    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={donaturPassword}
                validationSchema={Schema}
                onSubmit={(values) => {
                    saveDonaturPassword(values);
                }}
            >
                {({handleSubmit, handleChange, setFieldValue, values}) => (
                    <>
                        <Form className="form form-label-right">
                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                    New Password
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                    <Field
                                        name="password"
                                        component={Input}
                                        placeholder="New Password"
                                        onChange={(e) => {
                                            setFieldValue("password", e.target.value)
                                            }}
                                    />
                                </div>
                            </div>
                            <div className="form-group row">
                                <label className="col-xl-3 col-lg-3 col-form-label text-alert">
                                    Verify Password
                                </label>
                                <div className="col-lg-9 col-xl-6">
                                        <Field
                                            name="cPassword"
                                            component={Input}
                                            placeholder="Verify Password"
                                            // label="Verify Password"
                                        />
                                </div>
                            </div>

                            <button
                                type="submit"
                                style={{display: 'none'}}
                                ref={btnRef}
                                onSubmit={() => handleSubmit()}
                            ></button>

                        </Form>

                    </>
                )}

            </Formik>
        </>
    )
}