import axios from 'axios';
import { MasterDataURL } from '../../../config/UrlEndPoint';
import { DonationURL } from '../../../config/UrlEndPoint';
import { DonorURL } from '../../../config/UrlEndPoint';

export const MASTERDATA_URL = `${MasterDataURL}export_list`;

//Get category
export function getMasterCategoryAll() {
    return axios.get(`${MasterDataURL}donor_category`);
}
//  get Table list
export function getExportListTableList(params) {
    return axios.post(`${MASTERDATA_URL}`, params);
}

export function deleteExportConsolidation(uuid) {
    return axios.delete(`${DonationURL}consolidation/delete_export_consolidation/${uuid}`);
}

export function deleteExportDonation(uuid) {
    return axios.delete(`${DonationURL}donation/delete_export_donation/${uuid}`);
}
export function deleteExportWakaf(uuid) {
    return axios.delete(`${DonationURL}donation/wakaflist/delete_export/${uuid}`);
}

export function deleteExportDonor(uuid) {
    return axios.delete(`${DonorURL}donatur/delete_export_donor/${uuid}`);
}


export function downloadFileDonation(value) {
    return axios.post(`${DonationURL}donation/download_export_donation`, value, { responseType: 'blob' });
}
export function downloadFileWakaf(value) {
    return axios.post(`${DonationURL}donation/wakaflist/download_export`, value, { responseType: 'blob' });
}
export function downloadFileDonor(value) {
    return axios.post(`${DonorURL}donatur/download_export_donor`, value, { responseType: 'blob' });
}
