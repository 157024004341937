import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../_redux/donor-import-action";
import * as uiHelpers from "../DonorImportUIHelpers";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useDonorImportUIContext } from "../DonorImportUIContext";
import { RoleChecker } from "../../../RoleChecker";
import { useState } from "react";
import moment from "moment";

const DonorImportTable = () => {
  const donorImportUIContext = useDonorImportUIContext();
  const donorImportUIProps = useMemo(() => {
    return {
      queryParams: donorImportUIContext.queryParams,
      setQueryParams: donorImportUIContext.setQueryParams,
      openEditDonorImportPage: donorImportUIContext.openEditDonorImportPage,
    };
  }, [donorImportUIContext]);

  // Getting curret state of branch list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.donorImport }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchDonorImports(donorImportUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donorImportUIProps.queryParams, dispatch]);

  const allowEdit = RoleChecker("Edit", "mp_program");

  const [loading, setLoading] = useState(false);

  // Table columns
  const columns = [
    {
      dataField: "user",
      text: "User",
      formatter: (cell) => {
        const { name, email } = cell;
        return (
          <span>
            {name} - {email}
          </span>
        );
      },
    },
    {
      dataField: "created_at",
      text: "Tanggal Import",
      formatter: (cell) => {

        // convert ke timezone asia
        let convertDateToIndo = moment(cell);
        const date = convertDateToIndo.format("YYYY-MM-DD HH:mm");
        return <span>{date}</span>;
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        allowEdit,
        openEditDonorImportPage: donorImportUIProps.openEditDonorImportPage,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: donorImportUIProps.queryParams.pageSize,
    page: donorImportUIProps.queryParams.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                onTableChange={getHandlerTableChange(
                  donorImportUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default DonorImportTable;
