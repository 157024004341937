import { createSlice } from '@reduxjs/toolkit';

let initialDonaturState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    donaturForEdit: undefined,
    lastError: null,
    donorInteraction: [],
    donorInteractionTotalCount: 0,
    donorInteractionForEdit: {},
};

export const callTypes = {
    list: 'list',
    action: 'action',
};

export const donaturSlice = createSlice({
    name: 'donatur',
    initialState: initialDonaturState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // getBranchById
        donaturFetched: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.donaturForEdit = action.payload.donaturForEdit;
        },
        // findBranchs
        donatursFetched: (state, action) => {
            const { total_items, items } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = items;
            state.totalCount = total_items;
        },

        childsFetched: (state, action) => {
            state.dataChilds = action.payload.childsFetched
            state.listLoading = false;
            state.error = null;
            state.actionsLoading = false;
        },

        parentsFetched: (state, action) => {
            state.dataParents = action.payload.parentsFetched
            state.listLoading = false;
            state.error = null;
            state.actionsLoading = false;
        },

        donationHistorysFetched: (state, action) => {
            const { total_items, items } = action.payload;
            state.dataDonation = items
            state.listLoading = false;
            state.error = null;
            state.actionsLoading = false;
            state.totalDonationCount = total_items
        },

        // Set to default state actionLoading & error
        donatursReload: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
        },

        // findDonorInteraction
        donorInteractionFetch: (state, action) => {
            const { total_items, items } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.donorInteraction = items;
            state.donorInteractionTotalCount = total_items;
        },

        // getDonorInteractionById
        donorInteractionById: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.donorInteractionForEdit =
              action.payload.donorInteractionForEdit;
        },

    },
});

