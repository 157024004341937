import React, { createContext, useCallback, useContext, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './HewanKurbanCampaignUIHelpers';

const HewanKurbanCampaignUIContext = createContext();

export function useHewanKurbanCampaignUIContext() {
    return useContext(HewanKurbanCampaignUIContext);
}

export const HewanKurbanCampaignUIConsumer = HewanKurbanCampaignUIContext.Consumer;

export function HewanKurbanCampaignUIProvider({ hewanKurbanCampaignUIEvents, children }) {
    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const initHewanKurbanCampaign = {
        name: "",
        price: "",
        category: "",
        type: "",
        channel_uuid: ''
    };

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        initHewanKurbanCampaign,
        newHewanKurbanCampaignButtonClick: hewanKurbanCampaignUIEvents.newHewanKurbanCampaignButtonClick,
        openEditHewanKurbanCampaignPage: hewanKurbanCampaignUIEvents.openEditHewanKurbanCampaignPage,
    };

    return (
        <HewanKurbanCampaignUIContext.Provider value={value}>
            {children}
        </HewanKurbanCampaignUIContext.Provider>
    );

}
