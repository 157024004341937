import React from 'react';
import { Card, CardBody, CardHeader } from '../../../_metronic/_partials/controls';
import { CollectorList } from './list/CollectorList';
import { CollectorFilter } from './list/collector-filter/CollectorFilter';
// disabled display total donasi sampai info berikutnya
import { CollectorDonationTotal } from './collector-donation-total/CollectorDonationTotal'

export function CollectorCard(props) {

    return (
        <>
            <Card>
                <CardHeader title="Approval Collector"></CardHeader>
                <CardBody>
                    <CollectorFilter/>
                    <CollectorList/>
                    <CollectorDonationTotal />
                </CardBody>
            </Card>
        </>
    );
}
