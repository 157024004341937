import * as requestFromServer from './donationCrud';
import { donationSlice, callTypes } from './donationSlice';
import { actions as auth } from '../../../Auth/_redux/authRedux'
import { toastOption } from '../../../../../_metronic/_helpers/ToastHelpers'
import { toast } from 'react-toastify'

const { actions } = donationSlice;

export const fetchDonations = (queryParams) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.findDonations(queryParams)
		.then(response => {
			// const { data: { total_items, items, donation_item_array } } = response.data;
			// dispatch(actions.donationsFetched({ total_items, items, donation_item_array }));
			const { data: { total_items, items } } = response.data;
			dispatch(actions.donationsFetched({ total_items, items }));
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const fetchDonation = (id) => dispatch => {
	if (!id) {
		return dispatch(actions.donationFetched({ donationForEdit: undefined }));
	}

	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.getDonationById(id)		
		// .getDonationByIdQuery(id)
		.then(response => {
			// alias data to donation;
			const { data: donation } = response.data;
			dispatch(actions.donationFetched({ donationForEdit: donation }));
			return donation;
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const createDonation = (donationData) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.createDonation(donationData)
		.then(response => {
			dispatch(actions.donationReload);
			return response.data;
		})
		.catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const updateDonationItem = (donationItem, donationId) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.putDonationItem(donationItem, donationId)
		.then(response => {
			dispatch(actions.donationReload);
			return response.data;
		})
		.catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			toast.error(error, toastOption);
			// dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const updateDonationDate = (date, uuid) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.updateDonationDate(date, uuid)
		.then(response => {
			dispatch(actions.donationReload);
			return response.data;
		})
		.catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const updateDonationDepartment = (uuid, department_uuid) => dispatch => {
	console.log(uuid, department_uuid);
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.updateDonationDepartment(uuid, department_uuid)
		.then(response => {
			dispatch(actions.donationReload);
			return response.data;
		})
		.catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};


export const updateDonationChannel = (data, uuid) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.updateDonationChannel(data, uuid)
		.then(response => {
			dispatch(actions.donationReload);
			return response.data;
		})
		.catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};
