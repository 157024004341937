/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../../../_metronic/layout";
import { KTUtil } from "../../../../../_metronic/_assets/js/components/util";
import { color } from "./StylingHelper";
import { CircularProgress } from "@material-ui/core";

export function TilesRetentionDonatur({ className, widgetHeight, chartID, loading = false, total, persen = 0 }) {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray100: objectPath.get(
        uiService.config,
        "js.colors.gray.gray100"
      ),
      colorsGrayGray700: objectPath.get(
        uiService.config,
        "js.colors.gray.gray700"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById(chartID);
    if (!element) {
      return;
    }

    const height = parseInt(KTUtil.css(element, "height"));
    const options = getChartOptions(layoutProps, height, persen);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, loading]);

  return (
    <>
      <div
        className={`card card-custom ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className={`card-body d-flex ${loading && "justify-content-center"} no-wrap`}>
          {loading ? <div className="text-center align-self-center"><CircularProgress /></div> :
            <div className="align-self-center">
              <h3 className="card-title font-weight-bolder text-center mb-1">
                Retensi Donatur
                <i
                  className="fas fa-star ml-2"
                  style={{ color: color.primaryPurple }}
                ></i>
              </h3>
              <div className="flex-grow-1">
                <div id={`${chartID}`} style={{ height: "150px" }}></div>
                <h5 className="font-weight-bolder text-center">{new Intl.NumberFormat('id-ID').format(total)} Donatur</h5>
                <h5 className="font-weight-bolder text-center">1 Tahun Terakhir</h5>
              </div>



            </div>
          }
        </div>
      </div>
    </>
    // <div
    //   className={`card card-custom  d-flex no-wrap ${className}`}
    //   style={{ height: widgetHeight }}
    // >
    //   {/* Header */}
    //   <div className="card-header border-0 pt-5 align-self-center justify-content-center ">
    //     <div className="">
    //       <h3 className="card-title font-weight-bolder ">
    //         Retensi Donatur
    //         <i
    //           className="fas fa-star ml-2"
    //           style={{ color: color.primaryPurple }}
    //         ></i>
    //       </h3>
    //       <div className="flex-grow-1">
    //         <div id={`${chartID}`} style={{ height: "150px" }}></div>
    //         <h5 className="font-weight-bolder text-center">1 Tahun Terakhir</h5>
    //       </div>
    //     </div>

    //   </div>
    // </div>
  );
}

function getChartOptions(layoutProps, height, persen) {
  const options = {
    series: [persen],
    chart: {
      height: height,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
        },
        dataLabels: {
          showOn: "always",
          name: {
            show: false,
            fontWeight: "500",
          },
          value: {
            color: layoutProps.colorsGrayGray700,
            fontSize: "28px",
            fontWeight: "500",
            offsetY: 12,
            show: true,
          },
        },
        track: {
          background: color.secondaryPurple,
          strokeWidth: "100%",
        },
      },
    },
    colors: [color.primaryPurple],
    stroke: {
      lineCap: "round",
    },
    labels: ["Progress"],
  };
  return options;
}
