import React, { useMemo } from 'react';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../../_metronic/_partials/controls';
import { useDoubleRekeningUIContext } from './DoubleRekeningUIContext';
import DoubleRekeningTable from './double-rekening-table/DoubleRekeningTable';
import { DoubleRekeningFilter } from './double-rekening-table/double-rekening-filter/DoubleRekeningFilter'
import { RoleChecker } from '../../../RoleChecker';
import { Divider } from '@material-ui/core';
import DoubleRekeningTableDetail from './double-rekening-table-detail/DoubleRekeningTableDetail';
import { DoubleRekeningFilterDetail } from './double-rekening-table-detail/double-rekening-filter-detail/DoubleRekeningFilterDetail';
import DividerWithText from '../../../../_metronic/layout/components/extras/DividerWithText';

export const DoubleRekeningCard = () => {

	const doubleRekeningUIContext = useDoubleRekeningUIContext();
	const doubleRekeningUIProps = useMemo(() => {
		return {
			newDoubleRekeningButtonClick: doubleRekeningUIContext.newDoubleRekeningButtonClick,
			selectedDonor: doubleRekeningUIContext.selectedDonor
		};
	}, [doubleRekeningUIContext]);

	return (
		<>
			<Card>
				<CardHeader title="Daftar Double Donatur Bedasarkan Rekening">
					<CardHeaderToolbar>
					</CardHeaderToolbar>
				</CardHeader>
				<CardBody>
					<div className='row'>
						<div className='col-lg-12 mb-md-10'>
							<div className="mb-5">
								<DividerWithText>
									Daftar Donatur Double
								</DividerWithText>

							</div>
							<DoubleRekeningFilter />
							<DoubleRekeningTable />

						</div>
						<div className='col-lg-12 d-flex  mb-10 justify-content-center align-item-center'>
							<Divider orientation="horizontal" flexItem />
						</div>
						<div className='col-lg-12'>
							<div className="mb-5">

								<DividerWithText>
									Daftar Detail Donatur
								</DividerWithText>
							</div>
							{doubleRekeningUIProps.selectedDonor !== null ? <div>
								{/* <DoubleRekeningFilterDetail /> */}
								<DoubleRekeningTableDetail />

							</div> :
								<h1 className='text-center'>Harap Pilih Donatur Terlebih Dahulu</h1>}

						</div>
					</div>

				</CardBody>
			</Card>
			{/* <div className='row' >
				<div className='col-lg-6'>
					<Card>
						<CardHeader title="Daftar Double Donatur Bedasarkan Nama">
							<CardHeaderToolbar>
							</CardHeaderToolbar>
						</CardHeader>
						<CardBody>
							<DoubleRekeningFilter />
							<DoubleRekeningTable />

						</CardBody>
					</Card>
				</div>
			</div> */}
		</>

	);
};
