import * as requestFromServer from './userCrud';
import { userSlice, callTypes } from './userSlice';
import { actions as auth } from '../../../Auth/_redux/authRedux';
import { toastOption } from '../../../../../_metronic/_helpers/ToastHelpers';
import { toast } from 'react-toastify';

const { actions } = userSlice;

export const fetchUsers = (queryParams) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer.findUser(queryParams)
        .then(response => {
            const { data: { total_items, items } } = response.data;
            dispatch(actions.usersFetched({ total_items, items }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout());
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchUser = (id) => dispatch => {
    if (!id) {
        return dispatch(actions.userFetched({ bankForEdit: undefined }));
    }

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.getUserById(id)
        .then(response => {
            const { data: bank } = response.data; // alias data -> donatur
            dispatch(actions.userFetched({ userForEdit: bank }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout());
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const createUser = (userForCreation) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.createUser(userForCreation)
        .then(response => {
            dispatch(actions.userReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout());
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const updateUser = (user) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.updateUser(user)
        .then(response => {
            dispatch(actions.userReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout());
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};
