import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../../_metronic/_partials/controls";
import AsyncSelect from "react-select/async";
import ReactSelect from "react-select";
import { findDonaturs } from "../../../../Donatur/_redux/donatur/donaturCrud";
import { genOptionLoader } from "../../../../../../_metronic/_utils/option-loaders";
import { Form as BootsrapForm } from "react-bootstrap";

export function PeriodTemplateEditForm({
  savePeriodTemplate,
  actionsLoading,
  periodTemplate,
  options = {},
  fileType,
}) {
  function getImageUrl(image = {}) {
    if (image instanceof File) {
      return URL.createObjectURL(image);
    }

    const { adapter, path } = image;

    if (adapter && path) {
      return adapter + path;
    }

    return null;
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={periodTemplate}
        onSubmit={(values) => savePeriodTemplate(values)}
      >
        {({
          handleSubmit,
          values,
          setFieldValue,
          handleChange,
          handleBlur,
        }) => (
          <>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-3">
                  <label>Periode</label>
                </div>
                <div className="col-lg-9 mb-5">
                  <ReactSelect
                    name="thk_period"
                    placeholder={"Periode Thk"}
                    isDisabled={true}
                    options={options.period}
                    value={
                      values.thk_period && values.thk_period.uuid
                        ? {
                            label: values.thk_period.name ?? "",
                            value: values.thk_period.uuid ?? "",
                          }
                        : null
                    }
                    onChange={({ label: name, value: uuid }) => {
                      setFieldValue("thk_period", {
                        uuid,
                        name,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-3">
                  <label htmlFor="image">File</label>
                </div>
                <div className="col-md-9 mb-4 md-mb-0">
                  <div className="input-group mb-3">
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="image"
                        onChange={(e) => {
                          setFieldValue("image", e.target.files[0]);
                        }}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="image"
                        aria-describedby="inputGroupFileAddon02"
                      >
                        Pilih Gambar
                      </label>
                    </div>
                  </div>
                  {getImageUrl(values.image) && (
                    <div
                      className="mt-5"
                      style={{ height: "auto", maxWidth: "100%" }}
                    >
                      <img
                        src={getImageUrl(values.image)}
                        style={{ width: "100%", height: "100%" }}
                        alt="program image"
                      />
                    </div>
                  )}
                </div>
              </div>
            </Form>
            <div className="float-right">
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
