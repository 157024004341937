import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { HewanKurbanCampaignCard } from './HewanKurbanCampaignCard';
import { HewanKurbanCampaignEditDialog } from './hewan-kurban-edit-dialog/HewanKurbanCampaignEditDialog';
import { HewanKurbanCampaignUIProvider } from './HewanKurbanCampaignUIContext';
import { PrivateRoute } from "../../../../../PrivateRoute";
import { RoleChecker } from '../../../../../RoleChecker';


export function HewanKurbanCampaignPage({ history }) {

    const hewanKurbanCampaignUIEvents = {
        newHewanKurbanCampaignButtonClick: () => {
            history.push('/qurban/kurban/hewan-kurban-campaign/new');
        },
        openEditHewanKurbanCampaignPage: (id) => {
            history.push(`/qurban/kurban/hewan-kurban-campaign/${id}/edit`);
        },
    };

    return (
        <HewanKurbanCampaignUIProvider hewanKurbanCampaignUIEvents={hewanKurbanCampaignUIEvents}>

            <Route path="/qurban/kurban/hewan-kurban-campaign/new">
                {({ history, match }) => (
                    <HewanKurbanCampaignEditDialog
                        show={match != null}
                        onHide={() => {
                            history.push('/qurban/kurban/hewan-kurban-campaign/');
                        }}
                    />
                )}
            </Route>

            <Route path="/qurban/kurban/hewan-kurban-campaign/:id/edit">
                {({ history, match }) => (
                    <HewanKurbanCampaignEditDialog
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push('/qurban/kurban/hewan-kurban-campaign/');
                        }}
                    />
                )}
            </Route>



            <HewanKurbanCampaignCard />
        </HewanKurbanCampaignUIProvider>
    );
}
