import React from 'react';
import { DoubleRekeningCard } from './DoubleRekeningCard';
import { DoubleRekeningUIProvider } from './DoubleRekeningUIContext';


export function DoubleRekeningPage({ history }) {

    return (
        <DoubleRekeningUIProvider>

            <DoubleRekeningCard />
        </DoubleRekeningUIProvider>
    );
}
