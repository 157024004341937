import axios from 'axios';
import { MasterDataURL } from '../../../../config/UrlEndPoint';

export const URL = `${MasterDataURL}`;

// export function getProjectAll() {
//     return axios.get(URL);
// }

// items => filtered
export function findDistrict(queryParams) {
    return axios.post(`${URL}postal_code/find`, queryParams);
}

//Get By ID
// export function getProjectById(projectId) {
//     return axios.get(`${URL}/${projectId}`);
// }
