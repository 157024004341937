export function genOptionLoader(fetcher, source_name, mapper) {
  return async (inputValue, callback) => {
    const {data: {data} = {}} = await fetcher(inputValue);
    const items = data[source_name] ?? [];

    if(!(items instanceof Array)) {
      return callback([]);
    }

    const options = items.map(mapper);
    
    callback(options)
  }
}