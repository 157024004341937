/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export const ActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex,
    { openDetailPage },
) => (
    <>
        {/* <OverlayTrigger
            overlay={<Tooltip id="detail-trx-tooltip">View Detail</Tooltip>}
        > */}
        <div className="">
            <button
                type="button"
                className="btn btn-info mb-2"
                onClick={() => openDetailPage(row.collect_batch_uuid, row.collect_status)}
                
                // onClick={donationUIProps.openDonationExportDialog}
            >Detail
            </button>
            {/* <br/>
            <button
                type="button"
                className="btn btn-success mb-2"
                // onClick={donationUIProps.openDonationExportDialog}
            >Approve
            </button>
            <br/>
            <button
                type="button"
                className="btn btn-danger"
                // onClick={donationUIProps.openDonationExportDialog}
            >Reject
            </button> */}
        </div>
        {/* </OverlayTrigger> */}
    </>
);
