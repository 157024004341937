import React from "react";
import { Route } from "react-router-dom";
import { DonationExportCard } from "./DonationExportCard";
import { DonationExportUIProvider } from "./DonationExportUIContext";
import { PrivateRoute } from "../../../../PrivateRoute";
import { RoleChecker } from "../../../../RoleChecker";

export function DonationExportPage({ history, match }) {
  const donationExportUIEvents = {};

  return (
    <DonationExportUIProvider donationExportUIEvents={donationExportUIEvents}>
      <DonationExportCard history={history} match={match} />
    </DonationExportUIProvider>
  );
}
