/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";
import AutorenewIcon from "@material-ui/icons/Autorenew";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  {
    openEditThkChannelReportPage,
    allowEdit,
    openEditThkChannelReportList,
    allowRead,
  }
) => (
  <div className="">
    {allowRead && (
      <OverlayTrigger
        overlay={<Tooltip id="mp-reportedit-tooltip">List Donasi</Tooltip>}
      >
        <a
          className="btn btn-icon btn-light btn-hover-info btn-sm mx-3"
          onClick={() => openEditThkChannelReportList(row.uuid)}
        >
          <span className="svg-icon svg-icon-md svg-icon-info">
            <i class="fas fa-list"></i>
          </span>
        </a>
      </OverlayTrigger>
    )}
    {allowEdit && (
      <OverlayTrigger
        overlay={
          <Tooltip id="mp-reportedit-tooltip">Edit Channel Report</Tooltip>
        }
      >
        <a
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => openEditThkChannelReportPage(row.uuid)}
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>
    )}
  </div>
);
