import * as XLSX from "xlsx";

export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 },
];

export const initialFilter = {
  pageNumber: 1,
  pageSize: 10,
  sortOrder: "DESC",
};

// data import dono old
// export const DONOR_IMPORT_FIELDS = {
//   "Tipe Donatur *": "type",
//   "Nama *": "name",
//   Sapaan: "greeting",
//   HP: "hp",
//   Email: "email",
//   Alamat: "address",
//   // "Negara": "country",
//   Provinsi: "province",
//   Kota: "city",
//   Kecamatan: "district",
//   Kodepos: "postal_code",
//   "NIK / Nomor KTP / Nomor Perusahaan": "nik",
//   NPWP: "npwp",
//   NPWZ: "npwz",
//   "Status Hidup": "is_alive",
//   "Status Keaktifan": "is_active",
//   "Kode Rekening": "kode_rekening",
//   "Tanggal Lahir *": "tanggal_lahir",
//   "Jenis Kelamin *": "jenis_kelamin",
//   Pekerjaan: "pekerjaan",
//   "Pendidikan Terakhir *": "pendidikan",
// };


// data import dono new
export const DONOR_IMPORT_FIELDS = {
  "Tipe Donatur": "type",
  "Nama Donatur *": "name",
  "Sapaan": "greeting",
  "No HP *": "hp",
  "Email *": "email",
  "Alamat": "address",
  "NPWP": "npwp",
  "Tanggal Lahir": "tanggal_lahir",
  "Jenis Kelamin": "jenis_kelamin",
  "Kategori Prospek": "kat_prospek",
  "Kategori Donatur": "kat_donatur",
  "Sumber Data Donatur": "sumber_data"
};

export const DONOR_IMPORT_LABELS = [
  "Nama *",
  "Email *",
  "HP *",
  "Kota *",
  "Kecamatan *",
  "Tanggal Lahir *",
  "Jenis Kelamin *",
  "Pendidikan Terakhir *",
];

export const DONATUR_TYPE = [
  {
    value: "INDIVIDU",
    name: "Individu",
  },
  {
    value: "LEMBAGA",
    name: "Lembaga/Perusahaan",
  },
  {
    value: "KOMUNITAS",
    name: "Komunitas",
  },
];

export const IS_ACTIVE = [
  {
    value: true,
    name: "Aktif",
  },
  {
    value: false,
    name: "Non Aktif",
  },
];

export const IS_ALIVE = [
  {
    value: true,
    name: "Hidup",
  },
  {
    value: false,
    name: "Meninggal",
  },
];

export const DONATUR_GREETING = [
  {
    value: "Bapak",
  },
  {
    value: "Ibu",
  },
  {
    value: "Mr.",
  },
  {
    value: "Mrs.",
  },
  {
    value: "Haji",
  },
  {
    value: "Hajjah",
  },
];

export const DONATUR_EDUCAITON = [
  {
    value: "SD",
    name: "SD",
  },
  {
    value: "SMP",
    name: "SMP",
  },
  {
    value: "SMA",
    name: "SMA",
  },
  {
    value: "S1",
    name: "S1",
  },
  {
    value: "S2",
    name: "S2",
  },
  {
    value: "S3",
    name: "S3",
  },
  {
    value: "LAIN-LAIN",
    name: "LAIN-LAIN",
  },
];

export const DONATUR_GENDER = [
  {
    value: "L",
    name: "Pria",
  },
  {
    value: "P",
    name: "Wanita",
  },
];

export async function readSpreadsheet(file) {
  if (!(file instanceof File)) {
    return;
  }

  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data);
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  const sheet_json = XLSX.utils.sheet_to_json(worksheet);

  return sheet_json;
}

export function getDonorImportInitialValues(donorImport) {
  const { uuid = "" } = donorImport ?? {};

  const values = {
    uuid: uuid ?? "",
    file: null,
  };

  return values;
}
