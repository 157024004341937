import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import ReactSelect from "react-select";
import { promiseTuplify } from "../../../../../../_metronic/_utils/promise-utils";
import { findAllThkPeriods } from "../../../../Donation/thk-period/thk-period-api";
import { genOptionLoader } from "../../../../../../_metronic/_utils/option-loaders";
import { findDonaturs } from "../../../../Donatur/_redux/donatur/donaturCrud";
import { findChannels } from "../../../../MasterData/_redux/channels/channelsCrud";
import { Select } from "../../../../../../_metronic/_partials/controls";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { findHewanKurbanCampaign } from "../../../../Donation/_redux/hewan-kurban-campaign/hewanKurbanCampaignCrud";
import { findPartnership } from "../../../partnership/_redux/partnershipCrud";
import { DONATION_STATUS, KURBAN_STATUS } from "../ThkChannelReportUIHelpers";

const prepareFilter = (queryParams, values) => {
  const {
    name,
    donation_hid,
    kurban_hid,
    dona_status,
    kurban_status,
    thk_partnership,
    thk_livestock_campaign,
  } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  //add to Object filter
  filter.name = name ?? "";
  filter.donation_hid = donation_hid ?? "";
  filter.kurban_hid = kurban_hid ?? "";
  filter.donation_status = dona_status?.value ?? "";
  filter.kurban_status = kurban_status?.value ?? "";
  filter.thk_partnership_uuid = thk_partnership?.uuid ?? "";
  filter.thk_livestock_campaign_uuid = thk_livestock_campaign?.uuid ?? "";
  newQueryParams.filter = filter;
  return newQueryParams;
};

export default function ThkChannelReportDonationFilter({
  queryParams,
  setQueryParams,
}) {
  const [options, setOptions] = useState({
    thkPeriod: [],
    thkLivestockCampaign: [],
    donationStatus: DONATION_STATUS,
    kurbanStatus: KURBAN_STATUS,
    thkPartnership: [],
  });

  useEffect(() => {
    fetchThkPeriods();
    fetchThkLivestockCampaign();
    fetchThkPartnership();
  }, []);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(queryParams, values);
    if (!isEqual(newQueryParams, queryParams)) {
      newQueryParams.pageNumber = 1;
      newQueryParams.filter.uuid = queryParams.filter.uuid;
      setQueryParams(newQueryParams);
    }
  };

  async function fetchThkPeriods() {
    const [res, error] = await promiseTuplify(findAllThkPeriods());

    if (error) {
      return;
    }

    if (res) {
      const { data: { data = [] } = {} } = res;

      const options = data.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        thkPeriod: options,
      }));
    }
  }

  async function fetchThkLivestockCampaign() {
    const [res, error] = await promiseTuplify(
      findHewanKurbanCampaign({
        filter: {},
        pageNumber: 1,
        pageSize: 50,
      })
    );

    if (error) {
      return;
    }

    if (res) {
      const { data: { data = [] } = {} } = res;

      const options = data.items.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        thkLivestockCampaign: options,
      }));
    }
  }

  async function fetchThkPartnership() {
    const [res, error] = await promiseTuplify(
      findPartnership({
        filter: {},
        pageNumber: 1,
        pageSize: 50,
      })
    );

    if (error) {
      return;
    }

    if (res) {
      const { data: { data = [] } = {} } = res;

      const options = data.items.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        thkPartnership: options,
      }));
    }
  }

  return (
    <Formik
      initialValues={queryParams.filter}
      onSubmit={(values) => {
        applyFilter(values);
      }}
      onReset={(values) => {
        applyFilter(values);
      }}
    >
      {({ values, handleSubmit, handleBlur, setFieldValue, handleReset }) => (
        <form onSubmit={handleSubmit} className="form form-label-right">
          <div className="form-group row">
            <div className="col-lg-4 mb-5">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Search"
                onBlur={handleBlur}
                value={values.name}
                onChange={(e) => {
                  setFieldValue(e.target.name, e.target.value);
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Berdasarkan Atas Nama
              </small>
            </div>
            <div className="col-lg-4">
              <input
                type="text"
                className="form-control"
                name="donation_hid"
                placeholder="Search"
                onBlur={handleBlur}
                value={values.donation_hid}
                onChange={(e) => {
                  setFieldValue(e.target.name, e.target.value);
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Berdasarkan Donation HID
              </small>
            </div>
            <div className="col-lg-4">
              <input
                type="text"
                className="form-control"
                name="kurban_hid"
                placeholder="Search"
                onBlur={handleBlur}
                value={values.kurban_hid}
                onChange={(e) => {
                  setFieldValue(e.target.name, e.target.value);
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Berdasarkan Kurban HID
              </small>
            </div>
          </div>
          <div className="form-group row">
            {/* <div className="col-lg-4 mb-5">
              <ReactSelect
                name="dona_status"
                placeholder={"pilih status donasi"}
                options={options.donationStatus}
                value={
                  values.dona_status && values.dona_status.value
                    ? {
                        label: values.dona_status.label ?? "",
                        value: values.dona_status.value ?? "",
                      }
                    : null
                }
                onChange={({ label, value }) => {
                  setFieldValue("dona_status", {
                    label,
                    value,
                  });
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Status Donasi
              </small>
            </div> */}
            {/* <div className="col-lg-4 mb-5">
              <ReactSelect
                name="kurban_status"
                placeholder={"pilih status kurban"}
                options={options.kurbanStatus}
                value={
                  values.kurban_status && values.kurban_status.value
                    ? {
                        label: values.kurban_status.label ?? "",
                        value: values.kurban_status.value ?? "",
                      }
                    : null
                }
                onChange={({ label, value }) => {
                  setFieldValue("kurban_status", {
                    label,
                    value,
                  });
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Berasarkan Status Kurban
              </small>
            </div> */}
            {/* <div className="col-lg-4 mb-5">
              <AsyncSelect
                name="thk_partnership"
                defaultOptions={options.thkPartnership}
                placeholder={"Pilih Partnership"}
                loadOptions={genOptionLoader(
                  (inputValue) =>
                    findPartnership({
                      filter: { name: inputValue },
                      pageNumber: 1,
                      pageSize: 50,
                    }),
                  "items",
                  ({ name: label, uuid }) => {
                    return {
                      label,
                      value: uuid,
                    };
                  }
                )}
                value={
                  values.thk_partnership && values.thk_partnership.uuid
                    ? {
                        label: values.thk_partnership.name,
                        value: values.thk_partnership.uuid,
                      }
                    : null
                }
                onChange={({ label: name, value: uuid }) => {
                  setFieldValue("thk_partnership", { uuid, name });
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Partnership
              </small>
            </div> */}
            <div className="col-lg-4 mb-5">
              <AsyncSelect
                name="thk_livestock_campaign"
                defaultOptions={options.thkLivestockCampaign}
                placeholder={"Pilih Hewan Campaign"}
                loadOptions={genOptionLoader(
                  (inputValue) =>
                    findHewanKurbanCampaign({
                      filter: {
                        name: inputValue,
                        thk_partnership_uuid: values.thk_partnership.uuid,
                      },
                      pageNumber: 1,
                      pageSize: 50,
                    }),
                  "items",
                  ({ name: label, uuid }) => {
                    return {
                      label,
                      value: uuid,
                    };
                  }
                )}
                value={
                  values.thk_livestock_campaign &&
                  values.thk_livestock_campaign.uuid
                    ? {
                        label: values.thk_livestock_campaign.name,
                        value: values.thk_livestock_campaign.uuid,
                      }
                    : null
                }
                onChange={({ label: name, value: uuid }) => {
                  setFieldValue("thk_livestock_campaign", { uuid, name });
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Hewan Campaign
              </small>
            </div>
          </div>
          {/* <div className="form-group row">
            
          </div> */}
          <div className="form-group float-right">
            <button
              className="btn btn-outline-danger mx-1"
              onClick={handleReset}
            >
              Reset Pencarian
            </button>
            <button className="btn btn-warning mx-1" onClick={handleSubmit}>
              Terapkan
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}
