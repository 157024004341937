/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo } from "react";
import SVG from "react-inlinesvg";
import { Dropdown } from "react-bootstrap";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import {
  DropdownCustomToggler,
  DropdownMenu4,
} from "../../../../../_metronic/_partials/dropdowns";
import { useHtmlClassService } from "../../../../../_metronic/layout";
import { currencyFormatter } from "../../../../../_metronic/_helpers";
import { TilesDonationWidget } from "./TilesDonationWidget";
import { pembilang } from "../../../../../_metronic/_helpers/angkaTerbilangFormatter";
import moment from "moment";
import 'moment/locale/id';

export function ProgressWidget({
  className,
  chartColor = "success",
  baseColor = "success",
  series,
  labels = [],
  loading,
  chartID,
  loading1 = false,
  value1 = "",
  subValue1 = "",
  loading2 = false,
  value2 = "",
  subValue2 = "",
  loading3 = false,
  value3 = "",
  subValue3 = "",
}) {

  if (labels.length > 0) {
    labels = labels.map((item) => {
      // moment.locale("id")
      return moment(item).format("MMMM")
    })
  }
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray300: objectPath.get(
        uiService.config,
        "js.colors.gray.gray300"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.${chartColor}`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.${chartColor}`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService, chartColor]);

  var options = {
    chart: {
      type: "line",
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    series: [
      {
        name: "Total",
        data: series,
      },
      // {
      //   name: "Donasi Tahun Sebelumnya",
      //   data: [35, 41, 62, 42, 13, 18, 29, 37, 36],
      // },
    ],
    stroke: {
      width: [5, 8],
      curve: "straight",
      dashArray: [0, 8],
    },
    xaxis: {
      categories: labels,
    },
    yaxis: {
      labels: {
        formatter: function (val, index) {

          function singkatPembilang(labelValue) {
            // Nine Zeroes for Billions
            return Math.abs(Number(labelValue)) >= 1.0e+9

              ? Math.abs(Number(labelValue)) / 1.0e+9 + " Miliar"
              // Six Zeroes for Millions 
              : Math.abs(Number(labelValue)) >= 1.0e+6

                ? Math.abs(Number(labelValue)) / 1.0e+6 + " Juta"
                // Three Zeroes for Thousands
                : Math.abs(Number(labelValue)) >= 1.0e+3

                  ? Math.abs(Number(labelValue)) / 1.0e+3 + " Ribu"

                  : Math.abs(Number(labelValue));
          }
          return singkatPembilang(val)
        }
      }
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return currencyFormatter(val)
        },
      },
    },
  };

  useEffect(() => {
    const element = document.getElementById(`${chartID}`);

    if (!element) {
      return;
    }

    // const options = getChartOption(layoutProps);
    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, loading]);

  return (
    <>
      {/* begin::Tiles Widget 1 */}
      <div className={`card card-custom ${className}`}>
        {/* begin::Header */}
        <div className="card-header border-0 pt-5">
          <div className="card-title">
            <div className="card-label">
              <div className="font-weight-bolder">Donasi</div>
            </div>
          </div>
          <div className="card-toolbar">
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Items */}
        <div className="row p-2">
          <div className="col-md-6 col-lg-4">
            <TilesDonationWidget className="gutter-b" baseColor="danger" widgetHeight="150px" icon="fas fa-calendar-day" title="Donasi Hari Ini" value={value1} subValue={subValue1} loading={loading1} />
          </div>
          <div className="col-md-6 col-lg-4">
            <TilesDonationWidget className="gutter-b" baseColor="primary" widgetHeight="150px" icon="fas fa-calendar-week" title="Donasi Minggu Ini" value={value2} subValue={subValue2} loading={loading2} />
          </div>
          <div className="col-md-12 col-lg-4">
            <TilesDonationWidget className="gutter-b" baseColor="warning" widgetHeight="150px" icon="fas fa-calendar-alt" title="Donasi Bulan Ini" value={value3} subValue={subValue3} loading={loading3} />
          </div>
        </div>
        {/* end::Items */}

        {/* begin::Body */}
        <div className="card-body d-flex flex-column px-0">
          {/* begin::Chart */}
          <div
            id={`${chartID}`}
            data-color={chartColor}
            style={{ height: "150px" }}
          />
          {/* end::Chart */}
        </div>
        {/* end::Body */}
      </div>
      {/* end::Tiles Widget 1 */}
    </>
  );
}