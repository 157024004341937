import moment from "moment";

export const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '50', value: 30 },
];

export const initialFilter = {
    filter: {
        is_name: "true",
        name: "",
        donation_hid: '',
        status: '',
        thk_livestock_uuid: '',
        chName: '',
        date: '',
        start_date: moment().subtract(1, 'months'),
        end_date: moment(),
        thk_period: {
            uuid: "",
            name: ""
        },
        channel: {
            uuid: "",
            name: ""
        }
    },
    pageNumber: 1,
    pageSize: 10
};

export const badge = (status) => {
    let data = {};
    switch (status) {
        case 'RECOG':
            data = { text: 'RECOG', type: 'info' };
            break;
        case 'SETTLE':
            data = { text: 'SETTLE', type: 'success' };
            break;
        case 'VOID':
            data = { text: 'VOID', type: 'danger' };
            break;
        case 'REQUEST_VOID':
            data = { text: 'REQUEST_VOID', type: 'warning' };
            break;
        default:
            data = { text: status, type: 'primary' };
    }


    return data;
};
