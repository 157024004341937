import { createSlice } from '@reduxjs/toolkit';

const initialNotificationConsolidationListState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    notificationConsolidationListForEdit: undefined,
    lastError: null,
};

export const callTypes = {
    list: 'list',
    action: 'action',
};

export const notificationConsolidationSlice = createSlice({
    name: 'notificationConsolidationList',
    initialState: initialNotificationConsolidationListState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // findNotificationConsolidationLists
        notificationConsolidationListsFetched: (state, action) => {
            // const { total_items, items, notificationConsolidationList_item_array } = action.payload;
            const { total_items, items } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = items;
            state.totalCount = total_items;
            // state.notificationConsolidationList_item_array = notificationConsolidationList_item_array
        },

        // Set to default state actionLoading & error
        notificationConsolidationListReload: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
        },

    },
});

