import { useEffect, useState } from "react";
import { promiseTuplify } from "../../_metronic/_utils/promise-utils";

export function useLoadOptions(fetcher, source_name, mapper) {
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);

  useEffect(() => {
    initOptions();
  }, []);

  async function initOptions() {
    try {
      setLoading(true);

      const [res, error] = await promiseTuplify(fetcher(""));

      if (error) {
        return setDefaultOptions([]);
      }

      if (res) {
        const { data: { data: { items = [] } } = {} } = res;

        if (items instanceof Array) {
          return setDefaultOptions(items.map(mapper));
        }
      }
    } catch (error) {
      console.log("useLoadOptions error:", error);

      setDefaultOptions([]);
    } finally {
      setLoading(false);
    }
  }

  function loadOptions(inputValue, callback) {
    if (currentTimeout) {
      clearTimeout(currentTimeout);
    }

    setCurrentTimeout(
      setTimeout(async () => {
        try {
          setLoading(true);

          const { data: { data } = {} } = await fetcher(inputValue);

          let items = data[source_name] ?? [];
          if (!source_name) {
            items = data ?? [];
          }

          if (!(items instanceof Array)) {
            return callback([]);
          }

          const options = items.map(mapper);

          if (callback instanceof Function) {
            callback(options);
          }
        } catch {
          if (callback instanceof Function) {
            callback([]);
          }
        } finally {
          setLoading(false);
        }
      }, 500)
    );
  }

  return [defaultOptions, loadOptions, { loading }];
}
