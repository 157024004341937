import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { useCheckUIContext } from '../CheckUIContext';

const CheckHeaderUIContext = createContext();

export function useCheckerHeaderUIContext() {
    return useContext(CheckHeaderUIContext);
}

export const CheckerHeaderUIConsumer = CheckHeaderUIContext.Consumer;

export const initialFilter = {
    filter: {
        account_number: "",
        branch_uuid: "",
        bank_uuid: "",
    },
    pageNumber: 1,
    pageSize: 10
};

export function CheckerHeaderUIProvider({ children }) {

    const checkUIContext = useCheckUIContext();
    const checkUIProps = useMemo(() => {
        return {
            showBank: checkUIContext.showBank,
            selectedBank: checkUIContext.selectedBank
        };
    }, [checkUIContext]);



    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        selectedBank: checkUIProps.selectedBank,
    };

    return (
        <CheckHeaderUIContext.Provider value={value}>
            {children}
        </CheckHeaderUIContext.Provider>
    );
}
