import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import * as actions from '../../_redux/donatur/donaturAction';
import * as uiHelpers from '../../DonaturUIHelpers';
import {
	getHandlerTableChange,
	NoRecordsFoundMessage,
	PleaseWaitMessage,
} from '../../../../../_metronic/_helpers';
import * as columnFormatters from './ActionsColumnsFormatter';
import { useDonaturTypeDialogUIContext } from './DonaturTypeDialogUIContext';
import { Pagination } from '../../../../../_metronic/_partials/controls';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

export function DonaturTypeDialogTable( { onHide, donaturType, parent } ) {
	const donaturTypeDialogUIContext = useDonaturTypeDialogUIContext()
	const donaturTypeDialogUIProps = useMemo( () => {
		return {
			queryParams: donaturTypeDialogUIContext.queryParams,
			setQueryParams: donaturTypeDialogUIContext.setQueryParams,
			selectedDonor: donaturTypeDialogUIContext.selectedDonor,
			setSelectedDonor: donaturTypeDialogUIContext.setSelectedDonor,
			setGetChilds:donaturTypeDialogUIContext.setGetChilds
		}
	}, [ donaturTypeDialogUIContext ] )

	const { currentState } = useSelector(
		( state ) => ({ currentState: state.donaturs }),
		shallowEqual,
	);

	const { totalCount, entities, listLoading } = currentState;

	const dispatch = useDispatch();
	useEffect(() => {
		//Requset ke Donatur Action
		dispatch(actions.fetchDonaturs(donaturTypeDialogUIProps.queryParams));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [donaturTypeDialogUIProps.queryParams, dispatch]);

	const columns = [
		{
			dataField: 'name',
			text: 'Name',
		},
		{
			dataField: 'hp',
			text: 'HP',
		},
		{
			dataField: 'email',
			text: 'Email',
		},
		{
			dataField: 'kode_rekening',
			text: 'Kode Rekening',
		},

		{
			dataField: 'action',
			text: 'Actions',
			formatter: columnFormatters.ActionsColumnFormatter,
			formatExtraData: {
				parent:parent,
				onHide: onHide,
				setGetChilds:donaturTypeDialogUIProps.setGetChilds
			},
			classes: 'text-right pr-0',
			headerClasses: 'text-right pr-3',
			style: {
				minWidth: '100px',
			},
		},
	]

	// Table pagination properties
	const paginationOptions = {
		custom: true,
		totalSize: totalCount,
		sizePerPageList: uiHelpers.sizePerPageList,
		sizePerPage: donaturTypeDialogUIProps.queryParams.pageSize,
		page: donaturTypeDialogUIProps.queryParams.pageNumber,
	};

	return (
		<>
			<PaginationProvider pagination={ paginationFactory( paginationOptions ) }>
				{ ( { paginationProps, paginationTableProps } ) => {
					return (
						<Pagination
							isLoading={ listLoading }
							paginationProps={ paginationProps }
						>
							<BootstrapTable
								wrapperClasses="table-responsive"
								classes="table table-head-custom table-vertical-center overflow-hidden"
								bootstrap4
								bordered={ false }
								remote
								keyField="uuid"
								data={ entities === null ? [] : entities }
								columns={ columns }
								onTableChange={ getHandlerTableChange(
									donaturTypeDialogUIProps.setQueryParams,
								) }
								{ ...paginationTableProps }
							>
								<PleaseWaitMessage entities={ entities }/>
								<NoRecordsFoundMessage entities={ entities }/>
							</BootstrapTable>
						</Pagination>
					);
				} }
			</PaginationProvider>
		</>
	)

}