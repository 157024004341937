import axios from "axios";
import { DonationURL } from "../../../../config/UrlEndPoint";

export const ThkPeriodTemplateUrl = `${DonationURL}thk_period`;

//Get By ID
export function getPeriodTemplateById(periodTemplateId) {
  return axios.get(`${ThkPeriodTemplateUrl}/${periodTemplateId}`);
}

// items => filtered/sorted result for table data
export function findPeriodTemplate(queryParams) {
  return axios.post(`${ThkPeriodTemplateUrl}/find`, queryParams);
}

// UPDATE => PUT: update the periodTemplate on the server
export function updatePeriodTemplate(periodTemplate, uuid) {
  return axios.put(
    `${ThkPeriodTemplateUrl}/update_template/${uuid}`,
    periodTemplate
  );
}
