/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
/* eslint-disable no-unused-vars*/
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
    Card,
    CardBody,
    CardHeader,
    CardHeaderToolbar,
    ModalProgressBar,
} from '../../../../_metronic/_partials/controls';
import { toast } from 'react-toastify';
import { copyValue, toastOption } from '../../../../_metronic/_helpers';
import DonaturEditForm from './DonaturEditForm';
import DonaturTrx from '../donatur-trx/DonaturTrx';
import { DonaturTrxUIProvider } from '../donatur-trx/DonaturTrxUIContext';
import {
    initDonatur,
    initDonaturProfile,
    initUpdateBranch,
    initDonaturPassword
} from '../DonaturUIHelpers'
import * as actions from '../../Donatur/_redux/donatur/donaturAction';
import { DonaturProfileForm } from '../donatur-profile/DonaturProfileForm';
import { DonaturType } from '../donatur-type/DonaturType';
import { DonaturTypeDialog } from '../donatur-type/donatur-type-dialog/DonaturTypeDialog';
import DonaturParentTable from "../donatur-parent-table/DonaturParentTable";
import { DonaturChangePassword } from '../donatur-change-password/DonaturChangePassword';
import { DonaturMergeDonation } from '../donatur-merge-donation/DonaturMergeDonation';
import { Form, Formik } from "formik";
import { getBranchAll } from '../../MasterData/_redux/branchs/branchCrud'
import { updateBranch } from '../_redux/donatur/donaturCrud'
import { RoleChecker } from '../../../RoleChecker';
import { DonaturLog } from '../donatur-log/DonaturLog';
import { DonaturInteractions } from '../donatur-interactions/DonaturInteractions';
import DonaturInteractionsModal from '../donatur-interactions/DonaturInteractionsModal';
import FileSaver from "file-saver"

export const DonaturEdit = ({
    history,
    match: {
        params: { id, parent_uuid },
    },
}) => {
    const { user } = useSelector(
        (state) => ({ user: state.auth.user }),
        shallowEqual,
    );

    const backToList = () => {
        history.push(`/donatur`);
    };
    const backToParent = () => {
        history.push(`/donatur/${parent_uuid}/edit`)
    }
    const openDetailDonationPage = (id) => {
        history.push(`/donasi/${id}/detail`);
    }
    // Tabs
    const [tab, setTab] = useState('basic');
    const [title, setTitle] = useState('');
    const [isShow, setIsShow] = useState(false)
    const cabangRole = ['SuperAdmin', 'DataProses', 'AdminCabang']
    const [showCabang, setShowCabang] = useState(false)
    const [branchList, setBranchList] = useState([])

    const dispatch = useDispatch();

    const { actionsLoading, donaturForEdit, error, isLetter, donorInteraction ,donorInteractionTotalCount, donorInteractionForEdit } = useSelector((state) => ({
        actionsLoading: state.donaturs.actionsLoading,
        donaturForEdit: state.donaturs.donaturForEdit,
        isLetter: state.donaturs.is_letter,
        error: state.donaturs.error,
        donorInteraction: state.donaturs.donorInteraction,
        donorInteractionTotalCount: state.donaturs.donorInteractionTotalCount,
        donorInteractionForEdit: state.donaturs.donorInteractionForEdit,
    }),
        shallowEqual,
    );

    const fetchBranch = async (value) => {
        const result = await getBranchAll()
        setBranchList(result.data.data)
    }

    if (error) {
        toast.error(error, toastOption)
        if (id) {
            history.push(`/donatur/${id}/edit`)

        } else {
            history.push(`/donatur/new`)
        }
        // backToList();
    }

    useEffect(() => {
        dispatch(actions.fetchDonatur(id))
        fetchBranch()
    }, [id, dispatch]);

    useEffect(() => {
        let _title = id ? '' : 'Donatur Baru';
        if (donaturForEdit && id && donaturForEdit.donor_id) {
            _title = `Edit Donatur ${donaturForEdit.name} - ${donaturForEdit.donor_id}`;
        }
        if (donaturForEdit && id && !donaturForEdit.donor_id) {
            _title = `Edit Donatur ${donaturForEdit.name}`;
        }
        if (parent_uuid && !id) {
            _title = `Member Donatur Baru`;
        }
        setTitle(_title);

        const isCrossBranch = user.access_role.access.some(
            (item) => item.subject === "cross_branch"
        );
        // * Check Role lintas cabang
        if (isCrossBranch) {
            setShowCabang(true)
        } else {

        if (cabangRole.includes(user.role) ||
            (user.role === 'AdminCabang' && user.channel.branch_uuid === donaturForEdit.branch_uuid)) {
            setShowCabang(true)
        }
        
        }
        // donaturForEdit && setTab('riwayat_donasi')


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [donaturForEdit, id]);

    function saveDonatur(values, done) {
        // Menggunakan copy value karna values tidak bisa assign data baru didalamnya
        let data = copyValue(values)
        parent_uuid ? data.parent_uuid = copyValue(parent_uuid) : data.parent_uuid = ''

        if (!id) {
            dispatch(actions.createDonatur(data)).then(response => {
                if (response.parent_uuid) {
                    toast.success(response.message, toastOption);
                    
                    backToParent()
                    done()
                } else {
                    toast.success(response.message, toastOption);

                    backToList();
                    done();
                }
            })
        } else {
            //DELETE OBJECT KEY JAVASCRIPT
            const filterValue = data; 
            delete filterValue.donor_log
            delete filterValue.password
            delete filterValue.created_at
            delete filterValue.updated_at

            dispatch(actions.updateDonatur(filterValue)).then(response => {
                if (response) {
                    toast.success(response.message, toastOption);
                    backToList();
                    done();
                }
            });
        }
    };

    function saveDonaturProfile(values) {
        if (id) {
            const profileUpdate = {
                uuid: id,
                json_profile: {
                    ...values
                }
            }

            dispatch(actions.updateDonaturProfile(profileUpdate))
                .then(response => {
                    toast.success(response.message, toastOption);
                    backToList();
                })
                .catch(error => {
                    backToList();
                })
        }
    }

    function saveDonaturPassword(values) {
        const passwordUpdate = {
            donor_uuid: id,
            password: values.password
        }
        if (id) {
            dispatch(actions.changePasswordDonatur(passwordUpdate))
                .then(response => {
                    toast.success(response.message, toastOption);
                    backToList();
                })
                .catch(error => {
                    backToList();
                })
        }
    };
    // s2-266 fix update branch pada user tidak bisa dilakukan
    async function updateDonaturBranch(value) {

        // fix update if branch null
        if (value.branch_uuid) {
            const data = {
                donor_uuid: donaturForEdit.uuid,
                branch_uuid: value.branch_uuid
            }
            await updateBranch(data).then(response => {
                toast.success(response.data.message, toastOption)
                backToList()
            }).catch(error => {
                toast.error(error.response.message, toastOption)
            })
        }

    }

    const addDonaturChild = () => {
        setTab('basic')
        history.push(`/donatur/${donaturForEdit.uuid}/${donaturForEdit.type}`);
    }

    const openDialog = () => {
        setIsShow(true)
    }

    const closeDialog = () => {
        setIsShow(false)
    }

    const btnRef = useRef();
    
    function saveDonaturClick() {
        if (btnRef && btnRef.current) {
            btnRef.current.click();
        }
    };

    // console.log('====================================');
    // console.log(donaturForEdit);
    // console.log('====================================');

    // * DONOR INTERACTION
    const [queryParams, setQueryParams] = useState({
        pageNumber: 1,
        pageSize: 10,
        donor_uuid: id,
        filter: {
            donor_uuid: id
        }
    })
    const [initialValue, setInitialValue] = useState({})

    useEffect(() => {
        dispatch(actions.findDonorInteraction(queryParams))
    }, [queryParams, dispatch])


    // * INTERACTIONS MODAL
  const [openInteractionsModal, setOpenInteractionsModal] = useState(false);

  useEffect(() => {
    setInitialValue(donorInteractionForEdit);
  }, [donorInteractionForEdit]);

  const saveInteraction = values => {
      const formData = new FormData()

      formData.append("donor_uuid", donaturForEdit.uuid)
      formData.append("source", values.source)
      formData.append("subject", values.subject)
      formData.append("description", values.description)
      formData.append("file", values.file)
      if (values.uuid) {
          formData.append("uuid", values.uuid)
          dispatch(actions.updateInteractionDonor(formData)).then((res) => {
            toast.success(res.message, toastOption);
            dispatch(actions.fetchDonatur(id));
            dispatch(actions.findDonorInteraction(queryParams));
            setOpenInteractionsModal(false);
            setInitialValue({});
          });
      } else {
          dispatch(actions.createInteractionDonatur(formData)).then(res => {
              toast.success(res.message, toastOption)
              dispatch(actions.fetchDonatur(id));
              dispatch(actions.findDonorInteraction(queryParams));
              setOpenInteractionsModal(false)
              setInitialValue({});
          })
      }

  }

  const editInteraction = uuid => {
        // ? ID FROM ID DONATUR & UUID FROM ID INTERACTION
      dispatch(actions.getDonorInteraction(id, uuid))
      setOpenInteractionsModal(true)
  }

  const downloadFileInteraction = value => {
    FileSaver.saveAs(
      `${value.file.adapter}${value.file.path}`,
      value.file.filename
    );
  }

  const deleteInteraction = uuid => {
       // ? ID FROM ID DONATUR & UUID FROM ID INTERACTION
      dispatch(actions.deleteDonorInteraction(id, uuid)).then(res => {
          toast.success("Successful Deleted", toastOption);
          dispatch(actions.fetchDonatur(id));
          dispatch(actions.findDonorInteraction(queryParams));
          setOpenInteractionsModal(false);
      })
  }

    return (
        <>
            <DonaturInteractionsModal
                show={openInteractionsModal}
                onHide={() => {
                    setOpenInteractionsModal(false)
                    setInitialValue({})
                }}
                onClick={saveInteraction}
                initialValues={initialValue}
                isLoading={actionsLoading}
                downloadFile={downloadFileInteraction}
            />
            {/*<ToastContainer/>*/}
            <Card>
                {actionsLoading && <ModalProgressBar />}
                <CardHeader title={title}>
                    <CardHeaderToolbar>
                        <button
                            type="button"
                            className="btn btn-light"
                            onClick={parent_uuid ? backToParent : backToList}
                        >
                            <i className="fa fa-arrow-left"></i>
                            Back
                        </button>
                        {`  `}
                    </CardHeaderToolbar>
                </CardHeader>
                <CardBody>
                    <ul className="nav nav-tabs nav-tabs-line " role="tablist">
                        <li className="nav-item" onClick={() => setTab('basic')}>
                            <a
                                className={`nav-link ${tab === 'basic' && 'active'}`}
                                data-toggle="tab"
                                role="tab"
                                aria-selected={(tab === 'basic').toString()}
                            >
                                Info Dasar
                            </a>
                        </li>
                        {id && (
                            <li className="nav-item" onClick={() => setTab('profile')}>
                                <a
                                    className={`nav-link ${tab === 'profile' && 'active'}`}
                                    data-toggle="tab"
                                    role="tab"
                                    aria-selected={(tab === 'profile').toString()}
                                >
                                    Profil Donatur
                                </a>
                            </li>
                        )}

                        {RoleChecker("Ganti Password Donatur", "detail_donatur") && id && (
                            <li className="nav-item" onClick={() => setTab('changePass')}>
                                <a
                                    className={`nav-link ${tab === 'changePass' && 'active'}`}
                                    data-toggle="tab"
                                    role="tab"
                                    aria-selected={(tab === 'changePass').toString()}
                                >
                                    Ganti Password
                                </a>
                            </li>
                        )}
                        {RoleChecker("Penggabungan Donasi", "detail_donatur") && id && (
                            <li className="nav-item" onClick={() => setTab('merge_donation')}>
                                <a
                                    className={`nav-link ${tab === 'merge_donation' && 'active'}`}
                                    data-toggle="tab"
                                    role="tab"
                                    aria-selected={(tab === 'migrate_donation').toString()}
                                >
                                    Penggabungan Donasi
                                </a>
                            </li>
                        )}

                        {id && (
                            <>
                                {/*add Space*/'\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}
                                <li className="nav-item" onClick={() => setTab('riwayat_donasi')}>
                                    <a
                                        className={`nav-link ${tab === 'riwayat_donasi' && 'active'}`}
                                        data-toggle="tab"
                                        role="button"
                                        aria-selected={(tab === 'riwayat_donasi').toString()}
                                    >
                                        Riwayat Donasi
                                    </a>
                                </li>
                            </>
                        )}

                        {id && (
                            <>
                                {/* <li className="nav-item" onClick={() => setTab('cetak_konsolidasi')}>
                                    <a
                                        className={`nav-link ${tab === 'cetak_konsolidasi' && 'active'}`}
                                        data-toggle="tab"
                                        role="button"
                                        aria-selected={(tab === 'cetak_konsolidasi').toString()}
                                    >
                                        Cetak Konsolidasi
                                    </a>
                                </li> */}
                            </>
                        )}
                        {id && (donaturForEdit && donaturForEdit.type !== 'INDIVIDU' && !donaturForEdit.parent_uuid) && (
                            <>
                                {/*add Space*/'\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}
                                <li className="nav-item" onClick={() => setTab('riwayat_donasi_child')}>
                                    <a
                                        className={`nav-link ${tab === 'riwayat_donasi_child' && 'active'}`}
                                        data-toggle="tab"
                                        role="button"
                                        aria-selected={(tab === 'riwayat_donasi_child').toString()}
                                    >
                                        Riwayat Donasi Anggota
                                    </a>
                                </li>
                            </>
                        )}

                        {id && (donaturForEdit && donaturForEdit.type !== 'INDIVIDU' && !donaturForEdit.parent_uuid) && (
                            <>
                                {/*add Space*/'\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}
                                <li className="nav-item" onClick={() => setTab('komunitas')}>
                                    <a
                                        className={`nav-link ${tab === 'komunitas' && 'active'}`}
                                        data-toggle="tab"
                                        role="button"
                                        aria-selected={(tab === 'komunitas').toString()}
                                    >
                                        {donaturForEdit.type === 'LEMBAGA' && 'Daftar Lembaga'}
                                        {donaturForEdit.type === 'KOMUNITAS' && 'Daftar Komunitas'}
                                    </a>
                                </li>
                            </>
                        )}

                        {id && (donaturForEdit && donaturForEdit.type === 'INDIVIDU') && (
                            <>
                                {/*add Space*/'\xa0\xa0\xa0\xa0\xa0\xa0\xa0'}
                                <li className="nav-item" onClick={() => setTab('parent_list')}>
                                    <a
                                        className={`nav-link ${tab === 'parent_list' && 'active'}`}
                                        data-toggle="tab"
                                        role="button"
                                        aria-selected={(tab === 'parent_list').toString()}
                                    >
                                        Daftar Kelompok
                                    </a>
                                </li>
                            </>
                        )}

                        {/*PINDAH BRANCH*/}
                        {showCabang && id && (
                            <li className="nav-item" onClick={() => setTab('cabang')}>
                                <a
                                    className={`nav-link ${tab === 'cabang' && 'active'}`}
                                    data-toggle="tab"
                                    role="tab"
                                    aria-selected={(tab === 'cabang').toString()}
                                >
                                    Cabang
                                </a>
                            </li>
                        )}

                        {id && (donaturForEdit && donaturForEdit.type === 'INDIVIDU') && (
                            <li className="nav-item" onClick={() => setTab('interaksi')}>
                                <a
                                    className={`nav-link ${tab === 'interaksi' && 'active'}`}
                                    data-toggle="tab"
                                    role="tab"
                                    aria-selected={(tab === 'interaksi').toString()}>
                                    Interaksi Donatur
                                </a>
                            </li>
                        )}

                    </ul>

                    <div className="mt-5">
                        {tab === 'basic' && (
                            <DonaturEditForm
                                donatur={donaturForEdit || initDonatur}
                                btnRef={btnRef}
                                saveDonatur={saveDonatur}
                                parent_uuid={parent_uuid}
                            />
                        )}
                        {tab === 'profile' && id && (
                            <DonaturProfileForm
                                donaturProfile={donaturForEdit.json_profile || initDonaturProfile}
                                btnRef={btnRef}
                                saveDonaturProfile={saveDonaturProfile}
                            />
                        )}
                        {tab === 'changePass' && id && (
                            <DonaturChangePassword
                                donaturPassword={initDonaturPassword}
                                btnRef={btnRef}
                                saveDonaturPassword={saveDonaturPassword}
                            />
                        )}

                        {tab === 'merge_donation' && id && (
                            <DonaturTrxUIProvider donaturUuid={id}>
                            <DonaturMergeDonation
                                donatur={donaturForEdit}
                                openDetailDonationPage={openDetailDonationPage}
                                tab={tab}
                                actionsLoading={actionsLoading}
                            />
                            </DonaturTrxUIProvider>
                        )}
                        {tab === 'riwayat_donasi' && id && (
                            <DonaturTrxUIProvider donaturUuid={id}>
                                        <DonaturTrx openDetailDonationPage={openDetailDonationPage} donaturType={donaturForEdit.type} user_uuid={id} tab={tab} pageType="riwayat" />
                            </DonaturTrxUIProvider>
                        )}

                        {/* {tab === 'cetak_konsolidasi' && id && (
                            <DonaturTrxUIProvider donaturUuid={id}>
                                <Card>
                                    <CardBody>
                                        <DonaturTrx openDetailDonationPage={openDetailDonationPage} donaturType={donaturForEdit.type} user_uuid={id} tab={tab} pageType="konsolidasi" />
                                    </CardBody>
                                </Card>
                            </DonaturTrxUIProvider>
                        )} */}

                        {tab === 'riwayat_donasi_child' && id && (
                            <DonaturTrxUIProvider donaturUuid={id}>
                                <Card>
                                    <CardBody>
                                        <DonaturTrx openDetailDonationPage={openDetailDonationPage} donaturType={donaturForEdit.type} user_uuid={id} tab={tab} />
                                    </CardBody>
                                </Card>
                            </DonaturTrxUIProvider>
                        )}
                        {tab === 'komunitas' && id && (
                            <>
                                <Card>
                                    <CardHeader>
                                        <CardHeaderToolbar>
                                            <button
                                                type="button"
                                                className="btn btn-primary ml-2"
                                                onClick={addDonaturChild}
                                            >
                                                Buat Donatur Baru
                                            </button>

                                            <button
                                                type="button"
                                                className="btn btn-outline-primary ml-2"
                                                onClick={openDialog}
                                            >
                                                Tambah Anggota
                                            </button>
                                        </CardHeaderToolbar>
                                    </CardHeader>
                                    <CardBody>
                                        <DonaturTypeDialog
                                            parent={donaturForEdit || undefined}
                                            show={isShow}
                                            onHide={closeDialog}
                                        />
                                        <DonaturType
                                            parent_uuid={donaturForEdit.parent_uuid || donaturForEdit.uuid}
                                        />
                                    </CardBody>
                                </Card>
                            </>
                        )}
                        {tab === 'parent_list' && id && (
                            <Card>
                                <CardBody>
                                    <DonaturParentTable
                                        uuid={donaturForEdit.uuid}
                                    />
                                </CardBody>
                            </Card>
                        )}

                        {tab === 'cabang' && id && (
                            <>
                                <Card>
                                    <CardBody>
                                        <h4>Donatur ini berada di
                                            cabang <b>{donaturForEdit && donaturForEdit.branch_uuid && donaturForEdit.branch.name}</b>
                                        </h4>
                                        <p>Silahkan ubah kolom dibawah jika ingin pindah cabang</p>
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={({ branch_uuid: donaturForEdit.branch_uuid }) || initUpdateBranch}
                                            onSubmit={(values) => {
                                                updateDonaturBranch(values);
                                            }}>
                                            {({ handleSubmit, handleChange, setFieldValue, values, handleBlur }) => (
                                                <>
                                                    <Form className="form form-label-right">
                                                        <div className="form-group row">
                                                            <div className="col-lg-3">
                                                                <select
                                                                    className="form-control"
                                                                    name="branch_uuid"
                                                                    value={values.branch_uuid}
                                                                    onChange={(e) => {
                                                                        setFieldValue('branch_uuid', e.target.value);
                                                                    }}
                                                                >
                                                                    <option value="">ALL</option>
                                                                    {branchList.map(value =>
                                                                        <option key={value.uuid}
                                                                            value={value.uuid}>{value.name}</option>
                                                                    )}
                                                                </select>

                                                            </div>
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            style={{ display: 'none' }}
                                                            ref={btnRef}
                                                            onSubmit={() => handleSubmit()}
                                                        ></button>
                                                    </Form>
                                                </>
                                            )}

                                        </Formik>
                                    </CardBody>
                                </Card>

                            </>
                        )}

                        {tab === 'interaksi' && id && (
                            <>
                                <Card>
                                    <CardHeader title="Interaksi Donatur">
                                    <CardHeaderToolbar>
                                        <button className='btn btn-primary' onClick={() => setOpenInteractionsModal(true)}>Tambah Interaksi</button>
                                    </CardHeaderToolbar>
                                    </CardHeader>
                                    <CardBody>
                                        <DonaturInteractions 
                                            entities={donorInteraction} 
                                            totalCount={donorInteractionTotalCount} 
                                            queryParams={queryParams} 
                                            setQueryParams={setQueryParams}
                                            editInteraction={editInteraction}
                                            downloadFile={downloadFileInteraction}
                                            deleteInteraction={deleteInteraction}
                                        />
                                    </CardBody>
                                </Card>
                            </>
                        )}


                    </div>
                </CardBody>
                <CardBody>
                    <CardHeaderToolbar>
                        {tab !== 'komunitas' && tab !== "merge_donation" && tab !== "interaksi" && (
                            <button
                                type="submit"
                                className="btn btn-primary ml-2 float-right"
                                onClick={saveDonaturClick}
                            >
                                Save
                            </button>
                        )}
                    </CardHeaderToolbar>
                </CardBody>
            </Card>
            {donaturForEdit && <DonaturLog logDonatur={donaturForEdit.donor_log} />}
        </>

    );
}
    ;