import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Formik, Form, Field } from "formik";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { Editor } from "@tinymce/tinymce-react";
import { Select } from "../../../../_metronic/_partials/controls";
import {
  CardBody,
  CardFooter,
  Input,
} from "../../../../_metronic/_partials/controls";
import {
  DONOR_IMPORT_FIELDS,
  getDonorImportInitialValues,
  readSpreadsheet,
} from "../DonorImportUIHelpers";
import moment from "moment";
import * as actions from "../_redux/donor-import-action";

const DonorImportSchema = yup.object().shape({});

export function DonorImportEditForm({
  report,
  saveDonorImport,
  setIsNotification,
  loading,
  setLoading,
  setOpenEditDonor,
  donorRows,
  setDonorRows,
  errorRows,
  setErrorRows,
}) {
  const [spreadSheet, setSpreadSheet] = useState(null);

  useEffect(() => {
    if (spreadSheet) {
      parseDonorRows(spreadSheet);
    }
  }, [spreadSheet]);

  async function parseDonorRows() {
    const result = await readSpreadsheet(spreadSheet);

    if (!(result instanceof Array)) {
      return [];
    }

    const rows = result.map((row) => {
      const item = {
        uuid: uuidv4(),
      };

      Object.entries(DONOR_IMPORT_FIELDS).forEach(([label, field]) => {
        item[field] = row[label];
        // ? Format date

        if (label === "Tanggal Lahir") {
          // ? Tanggal lahir

          // row[label] = '24/05/1998'
          if (row[label] !== 'kosong' && row[label] !== null && !Number.isInteger(row[label])) {
            const trxDate = moment(row[label], "DD/MM/YYYY").format("YYYY-MM-DD");
            console.log(trxDate, item[field]);
  
            item[field] = trxDate;
          }

          // row[label] = 35940
          if (row[label] !== 'kosong' && row[label] !== null && Number.isInteger(row[label])) {
            const trxDate = moment(
              Math.floor(row[label] - 25569) * 86400 * 1000
            ).format("YYYY-MM-DD");
  
            item[field] = trxDate;
          }
          
        }
        // ? Format HP to string
        if (label === "No HP *" && row[label]) {
          item[field] = row[label].toString();
        }
      });

      return item;
    });

    setDonorRows(rows);
  }

  function submitReport(values) {
    const { uuid: _, image, file, status, ...data } = values;

    if (image instanceof File) {
      data.image = image;
    }

    if (file instanceof File) {
      data.file = file;
    }

    data.status = status.value;

    return saveDonorImport(data);
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={getDonorImportInitialValues(report)}
      validationSchema={DonorImportSchema}
      onSubmit={() => saveDonorImport(donorRows)}
    >
      {({ handleSubmit, values, setFieldValue }) => (
        <Form className="form form-label-right">
          <CardBody>
            <div className="form-group row mb-5">
              <div className="col-lg-3">
                <Select
                    name="send_notif"
                    label="Kirim Notifikasi"
                    onChange={(e) => {
                        setIsNotification(e.target.value);
                    }}
                >
                    <option value="">Pilih</option>
                    <option value="SEND">Ya</option>
                    <option value="DONT_SEND">Tidak</option>
                </Select>

              </div>
              <div className="col-md-6 mb-5 md-mb-0 mt-md-0 mt-5">
                <label htmlFor="image">File Import Donatur (.xlsx)</label>
                <div className="input-group mb-3">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="image"
                      onChange={(e) => {
                        setSpreadSheet(e.target.files[0]);
                      }}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="image"
                      aria-describedby="inputGroupFileAddon02"
                    >
                      Pilih File
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </CardBody>
        </Form>
      )}
    </Formik>
  );
}
