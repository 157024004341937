import React, { useEffect, useMemo } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../_redux/checkerAction';
import * as uiHelpers from '../CheckerUIHelper'
import {
	getHandlerTableChange,
	NoRecordsFoundMessage,
	PleaseWaitMessage,
} from '../../../../_metronic/_helpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../_metronic/_partials/controls';
import { useCheckerUIContext } from '../CheckerUIContext';
import { Card, CardBody, CardHeader } from '../../../../_metronic/_partials/controls';
import { toast } from 'react-toastify';
import { toastOption } from '../../../../_metronic/_helpers';


export const CheckerTable = (props) => {
	//	CONTEXT simpan dan kirim data
	const checkerUIContext = useCheckerUIContext();
	const checkerUIProps = useMemo(() => {
		return {
			queryParams: checkerUIContext.queryParams,
			setQueryParams: checkerUIContext.setQueryParams,
			openCheckerRequestDialog: checkerUIContext.openCheckerRequestDialog,
		}
	}, [checkerUIContext])

	const { currentState } = useSelector(
		(state) => ({ currentState: state.checkers }),
		shallowEqual,
	);

	const { entities, totalEdcDonations, totalTunaiDonations, totalPartnerDonations, totalGoodsDonation, error } = currentState

	if (error) {
		toast.error(error, toastOption)
	}
	// console.log(currentState);

	const dispatch = useDispatch();
	useEffect(() => {
		//Requset ke Collector Action
		dispatch(actions.fetchCheckers(checkerUIProps.queryParams));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [checkerUIProps.queryParams, dispatch]);


	// Table columns
	const columns = [
		{
			dataField: 'settle_batch_uuid',
			text: 'Batch UUID',
		},
		{
			dataField: 'collect_user_name',
			text: 'Nama Collector',
			classes: 'text-left',
			headerClasses: 'text-left',
		},
		{
			dataField: 'status',
			text: 'Status',
			classes: 'text-left',
			headerClasses: 'text-left',
			formatter: columnFormatters.StatusColumnFormatter,
		},
		{
			dataField: 'total_item',
			text: 'Banyaknya Donasi',
			classes: 'text-left',
			headerClasses: 'text-left',
		},
		{
			dataField: 'donation_cash',
			text: 'Tunai',
			classes: 'text-right',
			headerClasses: 'text-right',
			formatter: columnFormatters.PriceColumnFormatter,
		},
		{
			dataField: 'donation_edc',
			text: 'EDC',
			classes: 'text-right',
			headerClasses: 'text-right',
			formatter: columnFormatters.PriceColumnFormatter,
		},
		{
			dataField: 'donation_partner',
			text: 'Mitra Konter',
			classes: 'text-right',
			headerClasses: 'text-right',
			formatter: columnFormatters.PriceColumnFormatter,
		},
		{
			dataField: 'donation_goods',
			text: 'Barang',
			classes: 'text-right',
			headerClasses: 'text-right',
			formatter: columnFormatters.PriceColumnFormatter,
		},
		{
			dataField: 'total_trx',
			text: 'Jumlah Donasi',
			classes: 'text-right',
			headerClasses: 'text-right',
			formatter: columnFormatters.PriceColumnFormatter,
		},
		{
			dataField: 'action',
			text: 'Aksi',
			formatter: columnFormatters.ActionsColumnFormatter,
			formatExtraData: {
				openCheckerRequestDialog: checkerUIProps.openCheckerRequestDialog,
			},
			classes: 'text-right pr-0',
			headerClasses: 'text-right pr-3',
			style: {
				minWidth: '100px',
			},
		},
	];


	// Table pagination properties
	// const paginationOptions = {
	// 	custom: true,
	// 	totalSize: totalCount,
	// 	sizePerPageList: uiHelpers.sizePerPageList,
	// 	sizePerPage: checkerUIProps.queryParams.pageSize,
	// 	page: checkerUIProps.queryParams.pageNumber,
	// };

	// s2-251 tambah tabel by cash dan tunai
	return (
		<>
			<Card>
				<CardHeader title="Tabel Donasi Tunai"></CardHeader>
				<BootstrapTable
					wrapperClasses="table-responsive"
					classes="table table-head-custom table-vertical-center overflow-hidden"
					bootstrap4
					bordered={false}
					remote
					keyField="collect_batch_uuid"
					data={entities === null ? [] : entities}
					columns={columns}
				>
					<PleaseWaitMessage entities={entities} />
					<NoRecordsFoundMessage entities={entities} />
				</BootstrapTable>
				<hr></hr>
				<h5 className="text-right">Total Donasi Tunai {columnFormatters.PriceColumnFormatter(totalTunaiDonations)}</h5>
				<hr></hr>
				<h5 className="text-right">Total Donasi EDC {columnFormatters.PriceColumnFormatter(totalEdcDonations)}</h5>
				<hr></hr>
				<h5 className="text-right">Total Donasi Mitra Konter {columnFormatters.PriceColumnFormatter(totalPartnerDonations)}</h5>
				<hr></hr>
				<h5 className="text-right">Total Donasi Mitra Konter {columnFormatters.PriceColumnFormatter(totalGoodsDonation)}</h5>
			</Card>


			{/* <Card>
				<CardHeader title="Tabel Donasi EDC"></CardHeader>
				<BootstrapTable
					wrapperClasses="table-responsive"
					classes="table table-head-custom table-vertical-center overflow-hidden"
					bootstrap4
					bordered={false}
					remote
					keyField="collect_batch_uuid"
					data={edcItems === null ? [] : edcItems}
					columns={columns}
				>
					<PleaseWaitMessage entities={edcItems} />
					<NoRecordsFoundMessage entities={edcItems} />
				</BootstrapTable>
				<hr></hr>
				<h5 className="text-right">Total Donasi EDC {columnFormatters.PriceColumnFormatter(totalEdcDonations)}</h5>
			</Card> */}
			{/* <PaginationProvider pagination={ paginationFactory( paginationOptions ) }>
				{ ( { paginationProps, paginationTableProps } ) => {
					return (
						<Pagination
							isLoading={ listLoading }
							paginationProps={ paginationProps }
						> */}
			{/* <BootstrapTable
								wrapperClasses="table-responsive"
								classes="table table-head-custom table-vertical-center overflow-hidden"
								bootstrap4
								bordered={ false }
								remote
								keyField="settle_batch_uuid"
								data={ entities === null ? [] : entities }
								columns={ columns }
								// onTableChange={
								// 	getHandlerTableChange( checkerUIProps.setQueryParams )
								// }
								// { ...paginationTableProps }
							>
								<PleaseWaitMessage entities={ entities }/>
								<NoRecordsFoundMessage entities={ entities }/>
							</BootstrapTable> */}
			{/* </Pagination>
					);
				} }

			</PaginationProvider> */}

		</>
	);

}