import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  currencyFormatter,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  toAbsoluteUrl,
  toastOption,
} from "../../../../../../../_metronic/_helpers";
import { Pagination } from "../../../../../../../_metronic/_partials/controls";
import { sizePerPageList } from "../../PartnershipUIHelpers";
import ThkCampaignForm from "./ThkCampaignForm";
import { createHewanKurbanCampaign } from "../../../../../Donation/_redux/hewan-kurban-campaign/hewanKurbanCampaignCrud";
import { toast } from "react-toastify";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchHewanKurbanCampaigns } from "../../../../../Donation/_redux/hewan-kurban-campaign/hewanKurbanCampaignAction";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { updateHewanKurbanCampaign } from "../../../../../Donation/_redux/hewan-kurban-campaign/hewanKurbanCampaignCrud";

export default function ThkCampaignTable({ thkPartnership = {} }) {
  const initQueryParams = {
    pageNumber: 1,
    pageSize: 10,
    filter: {},
  };

  const [queryParams, setQueryParams] = useState(initQueryParams);
  const [initValues, setInitValues] = useState(thkPartnership);
  const [showModal, setShowModal] = useState({
    campaignForm: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    fetchCampaignList();
  }, [queryParams, thkPartnership]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.hewanKurbanCampaigns }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  function fetchCampaignList() {
    if (thkPartnership.uuid) {
      dispatch(
        fetchHewanKurbanCampaigns({
          ...queryParams,
          filter: {
            ...queryParams.filter,
            thk_partnership_uuid: thkPartnership.uuid,
          },
        })
      );
    }
  }

  function handleOpenModal(key) {
    setShowModal((prev) => ({
      ...prev,
      [key]: true,
    }));
  }

  function handleCloseModal(key) {
    setInitValues({});
    setShowModal((prev) => ({
      ...prev,
      [key]: false,
    }));
  }

  async function saveThkCampaign(payload) {
    const data = {
      ...payload,
      thk_livestock_uuid: payload.thk_livestock.uuid,
      branch_uuid: thkPartnership.branch_uuid,
      thk_partnership_uuid: thkPartnership.uuid,
      quota_type: "PARTNERSHIP",
    };
    if (!data.uuid) {
      const createCampaign = await createHewanKurbanCampaign(data);
      if (createCampaign.status === 200) {
        toast.success(createCampaign.data.message, toastOption);
        fetchCampaignList();
        handleCloseModal("campaignForm");
      } else {
        toast.error(createCampaign.data.message, toastOption);
      }
    } else {
      const updateCampaign = await updateHewanKurbanCampaign(data, data.uuid);
      if (updateCampaign.status === 200) {
        toast.success(updateCampaign.data.message, toastOption);
        fetchCampaignList();
        handleCloseModal("campaignForm");
      } else {
        toast.error(updateCampaign.data.message, toastOption);
      }
    }
  }

  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Hewan Campaign",
    },
    {
      dataField: "thk_livestock.name",
      text: "Hewan Kurban",
    },
    {
      dataField: "thk_livestock.type",
      text: "Tipe Hewan",
    },
    {
      dataField: "price",
      text: "Harga Jual",
      classes: "text-right",
      headerClasses: "text-right",
      formatter: (cell) => {
        return currencyFormatter(cell);
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: (cell, row) => {
        return (
          <>
            <OverlayTrigger
              overlay={
                <Tooltip id="products-edit-tooltip">Edit Campaign</Tooltip>
              }
            >
              <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => {
                  setShowModal((prev) => ({
                    ...prev,
                    campaignForm: true,
                  }));
                  setInitValues(row);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
              </a>
            </OverlayTrigger>
          </>
        );
      },
      formatExtraData: {},
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList,
    sizePerPage: queryParams.pageSize,
    page: queryParams.pageNumber,
  };

  return (
    <>
      <ThkCampaignForm
        show={showModal.campaignForm}
        onHide={() => handleCloseModal("campaignForm")}
        thkPartnership={thkPartnership}
        onSave={saveThkCampaign}
        initialValues={initValues}
      />
      <div className="float-right">
        <button
          className="btn btn-primary"
          onClick={() => handleOpenModal("campaignForm")}
        >
          Hewan Campaign Baru
        </button>
      </div>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="uuid"
                data={entities ? entities : []}
                columns={columns}
                onTableChange={getHandlerTableChange(setQueryParams)}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
