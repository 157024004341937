import axios from 'axios';
import { MasterDataURL } from '../../../../config/UrlEndPoint';

export const URL = `${MasterDataURL}project`;
export const URL_COA = `${MasterDataURL}coa`;

export function getProjectAll() {
    return axios.get(URL);
}

export function getProjectAllInOne(queryParams) {
    return axios.post(`${URL}/all_in_one`, queryParams);
}

// items => filtered/sorted result
export function findProjects(queryParams) {
    return axios.post(`${URL}/find`, queryParams);
}

//Get By ID
export function getProjectById(projectId) {
    return axios.get(`${URL}/${projectId}`);
}

// POST => POST: Create the data on the server
export function createProject(project) {
    return axios.post(URL, project);
}

// POST => POST: Add Relation Type on Project
export function addProjectType(projectType) {
    return axios.post(`${URL}/typeproject`, projectType);
}

// UPDATE => PUT: update the branch on the server
export function updateProject(project) {
    return axios.put(`${URL}/${project.uuid}`, project);
}

// DELETE => DELETE: Remove Relation Type on Project
export function removeProjectType(projectType) {
    return axios.delete(`${URL}/typeproject`, { data: projectType });
}

// POST => POST: Get COA Project No
export function getProjectALLCOA(body) {
    return axios.post(`${URL_COA}/get_project`, body);
}

export function manageProjectProgram(payload) {
    return axios.post(`${URL}/manage_project_program`, payload);
}

export function getProjectLog(uuid) {
    return axios.get(`${URL}/project_log/${uuid}`)
}


