import { createSlice } from "@reduxjs/toolkit";

let initialVersionPlatformState = {
  listLoading: false,
  actionsLoading: false,
  latestVersion: {},
  entities: null,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const versionPlatformSlice = createSlice({
  name: "versionPlatform",
  initialState: initialVersionPlatformState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // get latest version
    versionPlatformFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.latestVersion = action.payload;
    },
  },
});
