import { createSlice } from '@reduxjs/toolkit';

const initialRecapState = {
    listLoading: false,
    actionsLoading: false,
    entities: null,
    recapForEdit: undefined,
    lastError: null,
};

export const callTypes = {
    list: 'list',
    action: 'action',
};

export const recapSlice = createSlice({
    name: 'recap',
    initialState: initialRecapState,
    reducers: {
        catchError: (state, action) => {
            state.error = action.payload.error;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // getRecapById
        recapFetched: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.recapForEdit = action.payload.recapForEdit;
        },

        // findRecaps
        recapsFetched: (state, action) => {
            // const { total_items, items, recap_item_array } = action.payload;
            const { items } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = items;
            // state.recap_item_array = recap_item_array
        },

        // Set to default state actionLoading & error
        recapReload: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
        },

    },
});

