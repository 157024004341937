import * as requestFromServer from './exportListCrud';
import { exportListSlice, callTypes } from './exportListSlice';
import { actions as auth } from '../../Auth/_redux/authRedux'
import { toastOption } from '../../../../_metronic/_helpers/ToastHelpers'
import { toast } from 'react-toastify'
import axios from 'axios';
import { DonationURL } from '../../../config/UrlEndPoint';
import { DonorURL } from '../../../config/UrlEndPoint';


const { actions } = exportListSlice;

export const fetchExportListTableList = (queryParams) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getExportListTableList(queryParams)
		.then(response => {
			// const { data: { total_items, items, donation_item_array } } = response.data;
			// dispatch(actions.donationsFetched({ total_items, items, donation_item_array }));
			const { data: { total_items, items } } = response.data;
			dispatch(actions.exportListsFetched({ total_items, items }));
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const downloadDonationExport = (queryParams) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return axios.post(`${DonationURL}donation/download_export_donation`, queryParams, {
		responseType: 'blob',
		onDownloadProgress: (ProgressEvent) => {
			let percentCompleted = Math.floor(ProgressEvent.loaded / ProgressEvent.total * 100)
			dispatch(actions.startCall({ loadingProgress: percentCompleted }));
			return percentCompleted
		}
	})
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${queryParams.file_name}`); //or any other extension
			document.body.appendChild(link);
			link.click();
			dispatch(actions.exportListReload());
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			if (error.response.status === 404) {
				toast.error("File Tidak Ditemukan Di Server", toastOption);
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const downloadDonorExport = (queryParams) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return axios.post(`${DonorURL}donatur/download_export_donor`, queryParams, {
		responseType: 'blob',
		onDownloadProgress: (ProgressEvent) => {
			let percentCompleted = Math.floor(ProgressEvent.loaded / ProgressEvent.total * 100)
			dispatch(actions.startCall({ loadingProgress: percentCompleted }));
			return percentCompleted
		}
	})
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${queryParams.file_name}`); //or any other extension
			document.body.appendChild(link);
			link.click();
			dispatch(actions.exportListReload());
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			if (error.response.status === 404) {
				toast.error("File Tidak Ditemukan Di Server", toastOption);
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const downloadConsolidationExport = (queryParams) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return axios.post(`${DonationURL}consolidation/download_export_consolidation`, queryParams, {
		responseType: 'blob',
		onDownloadProgress: (ProgressEvent) => {
			let percentCompleted = Math.floor(ProgressEvent.loaded / ProgressEvent.total * 100)
			dispatch(actions.startCall({ loadingProgress: percentCompleted }));
			return percentCompleted
		}
	})
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${queryParams.file_name}`); //or any other extension
			document.body.appendChild(link);
			link.click();
			dispatch(actions.exportListReload());
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			if (error.response.status === 404) {
				toast.error("File Tidak Ditemukan Di Server", toastOption);
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const downloadWakafExport = (queryParams) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return axios.post(`${DonationURL}donation/wakaflist/download_export`, queryParams, {
		responseType: 'blob',
		onDownloadProgress: (ProgressEvent) => {
			let percentCompleted = Math.floor(ProgressEvent.loaded / ProgressEvent.total * 100)
			dispatch(actions.startCall({ loadingProgress: percentCompleted }));
			return percentCompleted
		}
	})
		.then(response => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', `${queryParams.file_name}`); //or any other extension
			document.body.appendChild(link);
			link.click();
			dispatch(actions.exportListReload());
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			if (error.response.status === 404) {
				toast.error("File Tidak Ditemukan Di Server", toastOption);
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};


export const deleteExportConsolidation = (uuid) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer.deleteExportConsolidation(uuid)
		.then(response => {
			toast.success(response.data.message, toastOption)
			dispatch(actions.exportListReload());
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			if (error.response.status === 404) {
				toast.error("File Yang Akan Di Delete Tidak Ditemukan Di Server", toastOption);
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const deleteExportDonation = (uuid) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer.deleteExportDonation(uuid)
		.then(response => {
			toast.success(response.data.message, toastOption)
			dispatch(actions.exportListReload());
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			if (error.response.status === 404) {
				toast.error("File Yang Akan Di Delete Tidak Ditemukan Di Server", toastOption);
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const deleteExportDonor = (uuid) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer.deleteExportDonor(uuid)
		.then(response => {
			toast.success(response.data.message, toastOption)
			dispatch(actions.exportListReload());
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			if (error.response.status === 404) {
				toast.error("File Yang Akan Di Delete Tidak Ditemukan Di Server", toastOption);
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

export const deleteExportWakaf = (uuid) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer.deleteExportWakaf(uuid)
		.then(response => {
			toast.success(response.data.message, toastOption)
			dispatch(actions.exportListReload());
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			if (error.response.status === 404) {
				toast.error("File Yang Akan Di Delete Tidak Ditemukan Di Server", toastOption);
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};


