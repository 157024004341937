import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useDonorImportUIContext } from "./DonorImportUIContext";

import { DonorImportFilter } from "./donor-import-filter/DonorImportFilter";
import DonorImportTable from "./donor-import-table/DonorImportTable";
import { RoleChecker } from "../../RoleChecker";
import { ActionButton } from "../../../_metronic/_partials/controls/ActionButton";

export function DonorImportCard() {
  const donorImportUIContext = useDonorImportUIContext();
  const donorImportUIProps = useMemo(() => {
    return {
      newDonorImportButtonClick: donorImportUIContext.newDonorImportButtonClick,
      openEditCategoryPage: donorImportUIContext.openEditCategoryPage,
    };
  }, [donorImportUIContext]);

  return (
    <Card>
      <CardHeader title="Daftar Import Donatur">
        {RoleChecker("Create", "donor_import") && (
          <CardHeaderToolbar>
            <ActionButton
              type="button"
              variant="primary"
              onClick={donorImportUIProps.newDonorImportButtonClick}
              text="Import Donatur Baru"
            />
          </CardHeaderToolbar>
        )}
      </CardHeader>
      <CardBody>
        <DonorImportFilter />
        <DonorImportTable />
      </CardBody>
    </Card>
  );
}
