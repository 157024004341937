import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/thkChannelReportAction";
import * as uiHelpers from "../ThkChannelReportUIHelpers";

import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useThkChannelReportUIContext } from "../ThkChannelReportUIContext";
import { RoleChecker } from "../../../../../RoleChecker";
import moment from "moment";
// import { ToastContainer } from 'react-toastify';

const ThkChannelReportTable = (props) => {
  // ThkChannelReport UI Context
  const thkChannelReportUIContext = useThkChannelReportUIContext();
  const thkChannelReportUIProps = useMemo(() => {
    return {
      queryParams: thkChannelReportUIContext.queryParams,
      setQueryParams: thkChannelReportUIContext.setQueryParams,
      openEditThkChannelReportPage:
        thkChannelReportUIContext.openEditThkChannelReportPage,
      openManageThkChannelReportPage:
        thkChannelReportUIContext.openManageThkChannelReportPage,
      openEditThkChannelReportList:
        thkChannelReportUIContext.openEditThkChannelReportList,
    };
  }, [thkChannelReportUIContext]);

  // Getting curret state of thkChannelReport list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.thkChannelReport }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    //Requset ke Branch Action
    dispatch(
      actions.fetchThkChannelReports(thkChannelReportUIProps.queryParams)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thkChannelReportUIProps.queryParams, dispatch]);

  const allowEdit = RoleChecker("Edit", "thk_channel_report");
  const allowRead = RoleChecker("Read", "thk_channel_report");

  // Table columns
  const columns = [
    {
      dataField: "thk_period.name",
      text: "Periode",
    },
    {
      dataField: "channel.name",
      text: "Channel",
    },
    {
      dataField: "report_template_type",
      text: "Tipe",
    },
    {
      dataField: "updatedAt",
      text: "Update Terakhir",
      formatter: (cell) => {
        if (cell) {
          return moment(cell)
            .locale("id")
            .format("DD MMMM YYYY");
        }
      },
    },
    // {
    //   dataField: "report_file",
    //   text: "Template Laporan",
    //   formatter: (cell) => {
    //     if (cell) {
    //       const url = `${cell.adapter}${cell.path}`;
    //       return (
    //         <a
    //           className="mx-3"
    //           style={{
    //             textDecoration: "underline",
    //             textDecorationColor: "blue",
    //           }}
    //           onClick={() => window.open(url)}
    //         >
    //           {cell.filename}
    //         </a>
    //       );
    //     }
    //   },
    // },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditThkChannelReportPage:
          thkChannelReportUIProps.openEditThkChannelReportPage,
        openManageThkChannelReportPage:
          thkChannelReportUIProps.openManageThkChannelReportPage,
        openEditThkChannelReportList:
          thkChannelReportUIProps.openEditThkChannelReportList,
        allowEdit,
        allowRead,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: thkChannelReportUIProps.queryParams.pageSize,
    page: thkChannelReportUIProps.queryParams.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                // defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  thkChannelReportUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: productsUIProps.ids,
                //   setIds: productsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default ThkChannelReportTable;
