import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/periodTemplateAction";
import * as uiHelpers from "../PeriodTemplateUIHelpers";

import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../../../../_metronic/_helpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { usePeriodTemplateUIContext } from "../PeriodTemplateUIContext";
import { RoleChecker } from "../../../../../RoleChecker";
import moment from "moment";
// import { ToastContainer } from 'react-toastify';

const PeriodTemplateTable = (props) => {
  // PeriodTemplate UI Context
  const periodTemplateUIContext = usePeriodTemplateUIContext();
  const periodTemplateUIProps = useMemo(() => {
    return {
      queryParams: periodTemplateUIContext.queryParams,
      setQueryParams: periodTemplateUIContext.setQueryParams,
      openEditPeriodTemplatePage:
        periodTemplateUIContext.openEditPeriodTemplatePage,
      openManagePeriodTemplatePage:
        periodTemplateUIContext.openManagePeriodTemplatePage,
    };
  }, [periodTemplateUIContext]);

  // Getting curret state of periodTemplate list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.thkPeriodTemplate }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    //Requset ke Branch Action
    dispatch(actions.fetchPeriodTemplates(periodTemplateUIProps.queryParams));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodTemplateUIProps.queryParams, dispatch]);

  const allowEdit = RoleChecker("Edit", "thk_period_template");

  // Table columns
  const columns = [
    {
      dataField: "name",
      text: "Periode",
    },
    {
      dataField: "updated_at",
      text: "Update Terakhir",
      formatter: (cell) => {
        if (cell) {
          return moment(cell)
            .locale("id")
            .format("DD MMMM YYYY");
        }
      },
    },
    {
      dataField: "user_name",
      text: "Update By",
    },
    {
      dataField: "file_report_bg",
      text: "Template Laporan",
      formatter: (cell) => {
        if (cell) {
          const url = `${cell.adapter}${cell.path}`;
          return (
            <a
              className="mx-3"
              style={{
                textDecoration: "underline",
                textDecorationColor: "blue",
              }}
              onClick={() => window.open(url)}
            >
              {cell.filename}
            </a>
          );
        }
      },
    },
    {
      dataField: "file_certificate_bg",
      text: "Template Sertifikat",
      formatter: (cell) => {
        if (cell) {
          const url = `${cell.adapter}${cell.path}`;
          return (
            <a
              className="mx-3"
              style={{
                textDecoration: "underline",
                textDecorationColor: "blue",
              }}
              onClick={() => window.open(url)}
            >
              {cell.filename}
            </a>
          );
        }
      },
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditPeriodTemplatePage:
          periodTemplateUIProps.openEditPeriodTemplatePage,
        openManagePeriodTemplatePage:
          periodTemplateUIProps.openManagePeriodTemplatePage,
        allowEdit,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: periodTemplateUIProps.queryParams.pageSize,
    page: periodTemplateUIProps.queryParams.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="uuid"
                data={entities === null ? [] : entities}
                columns={columns}
                // defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  periodTemplateUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: productsUIProps.ids,
                //   setIds: productsUIProps.setIds,
                // })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};

export default PeriodTemplateTable;
