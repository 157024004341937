import moment from 'moment'

export const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '50', value: 30 },
];

export const initialFilter = {
    filter: {
        donation_item_project: '',
        donation_item_type: '',
        donor_name: '',
        channel_uuid: '',
        start_date: '',
        end_date: '',
        user_uuid: '',
        status: '',
        total_amountlt: '',
        total_amountgt: '',
        donation_hid: ''
        // total_amount: ''
    },
    pageNumber: 1,
    pageSize: 10,
};

export const badge = (status) => {
    let data = {};
    switch (status) {
        case 'VOID':
            data = { type: 'danger' };
            break;
        case 'REQUEST_VOID':
            data = { type: 'warning' };
            break;
        case 'SETTLE':
            data = { type: 'success' };
            break;
        default:
            data = { type: 'info' };

    }

    return data;
};

export const statusDonasi = [
    { value: 'SETTLE', name: 'SETTLE' },
    { value: 'COLLECT', name: 'COLLECT' },
    { value: 'DRAFT', name: 'DRAFT' },
    { value: 'RECOG', name: 'RECOG' },
    { value: 'VOID', name: 'VOID' },
    { value: 'REQUEST_VOID', name: 'REQUEST VOID' },
    { value: 'NOT_VOID', name: 'TIDAK VOID' },
]

