import React from "react";
import { Modal } from "react-bootstrap";

export default function DonationExportModal({ show, onHide, onClick }) {
  return (
    <>
      <Modal
        size="md"
        show={show}
        centered
        onHide={onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Export Data Donasi
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: "center" }}>
            <i
              className={`fas fa-question`}
              style={{ fontSize: "64px", color: `#FFA800` }}
            ></i>
          </div>
          <div className="mt-8 text-center">
            <strong>Apakah anda yakin ingin export data donasi</strong>
          </div>
          <div className="text-center" style={{ color: "#FFA800" }}>
            <strong>Data akan meyesuaikan filter yang telah di terapkan</strong>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate"
            >
              Cancel
            </button>
            <> </>
            <button
              type="button"
              onClick={onClick}
              className="btn btn-primary btn-elevate"
            >
              Export
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
