import React from 'react';
import { badge } from "../../ExportListUIHelpers";


export function StatusColumnFormatter(cellContent, row) {

    let value = badge(cellContent)
    return (
        <span className={`label label-lg label-light-${value.type} label-inline`}>
            {value.text}
        </span>
    );
}
