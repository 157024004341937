import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from '../../../../modules/MasterData/pages/user/UserUIHelpers';
import { useCollectUIContext } from '../CollectUIContext';

const CollectHeaderUIContext = createContext();

export function useCheckerHeaderUIContext() {
    return useContext(CollectHeaderUIContext);
}

export const CheckerHeaderUIConsumer = CollectHeaderUIContext.Consumer;

export function CheckerHeaderUIProvider({ children }) {

    const collectUIContext = useCollectUIContext();
    const collectUIProps = useMemo(() => {
        return {
            showChecker: collectUIContext.showChecker,
            selectedChecker: collectUIContext.selectedChecker
        };
    }, [collectUIContext]);

    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const value = {
        queryParams,
        setQueryParamsBase,
        setQueryParams,
        selectedChecker: collectUIProps.selectedChecker,
    };

    return (
        <CollectHeaderUIContext.Provider value={value}>
            {children}
        </CollectHeaderUIContext.Provider>
    );
}
