import React from 'react';
import { CheckUIProvider } from './CheckUIContext';
import { CheckList } from './list/CheckList';

export function CheckPage2(props) {
    return (
        <CheckUIProvider>
            <CheckList/>
        </CheckUIProvider>
    );
}