export const sizePerPageList = [
	{ text: "10", value: 10 },
	{ text: "20", value: 20 },
	{ text: "50", value: 50 }
];
// default value untuk payment disabled sampai info berikutnya
export const initialFilter = {
	filter: {
		name: "",
		status: "COLLECT",
		// start_date:'',
        // end_date:'',
		// paymentType: 'TUNAI'
	},
	pageNumber: 1,
	pageSize: 10
};

export const statusList = [
	{ value: 'COLLECT', name: 'REQUEST' },
	{ value: 'SETTLE', name: 'APPROVED' },
];

export const badge = (status) => {
    let data = {};
    switch (status) {
        case 'VOID':
            data = {type: 'danger'};
            break;
        case 'REQUEST_VOID':
            data = {type: 'warning'};
            break;
        case 'SETTLE':
            data = {type: 'success'};
            break;
        default:
            data = {type: 'info'};

    }

    return data;
};

// default value untuk payment disabled sampai info berikutnya
// export const paymentTypeList = [
//     {value: 'TUNAI'},
//     {value: 'EDC'},
//     {value: 'EDC + TUNAI'},
// ]

