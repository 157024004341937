import React, {useMemo} from 'react';
import {Card, CardBody, CardHeader, CardHeaderToolbar} from '../../../../../../_metronic/_partials/controls';

import {useKurbanListUIContext} from './KurbanUIContext';
import {KurbanListTable} from './kurban-table/KurbanTable';
import {KurbanListFilter} from "./kurban-filter/KurbanFilter";

export const KurbanListCard = () => {

    const kurbanListUIContext = useKurbanListUIContext();
    const kurbanListUIProps = useMemo(() => {
        return {
            newKurbanListClick: kurbanListUIContext.newKurbanListClick,
            openDetailKurbanListPage: kurbanListUIContext.openDetailKurbanListPage,
        };
    }, [kurbanListUIContext]);

    return (
        <Card>
            <CardHeader title="Daftar Pekurban">
                <CardHeaderToolbar>
                    {/* <button
                        type="button"
                        className="btn btn-primary"
                        onClick={kurbanListUIProps.newKurbanListClick}
                    >Konfirmasi Baru
                    </button> */}
                </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
                <KurbanListFilter/>
                <KurbanListTable/>

            </CardBody>
        </Card>
    );
};

