import { createSlice } from '@reduxjs/toolkit';

const initialCollectorState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    collectDetail: null,
    lastError: null,
    totalCount: null,
    edcItems: null,
    tunaiItems: null,
    tunaiEdcItems: null,
    goodsItems: null,
    totalDonations: null,
    totalEdcDonations: null,
    totalTunaiDonations: null,
    totalTunaiEdcDonations: null,
    totalGoodsDonations: null,
    partnerItems: null,
    totalPartnerDonations: null,
};

export const callTypes = {
    list: 'list',
    action: 'action',
};

export const collectorSlice = createSlice({
    name: 'collector',
    initialState: initialCollectorState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // getCollectById
        collectFetched: (state, action) => {
            const { count, rows } = action.payload.collectDetail;
            state.actionsLoading = false;
            state.error = null;
            state.collectDetail = rows;
            state.totalCount = count;
        },
        // findCollect Request
        collectsFetched: (state, action) => {
            const { total_items, items, tunai_items, edc_items, tunai_edc_items, total_tunai_edc_donations, total_donations, total_edc_donations, total_tunai_donations, partner_items, total_partner_donations, goods_items, total_goods_donations } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = items;
            state.totalCount = total_items;
            state.edcItems = edc_items
            state.tunaiItems = tunai_items
            state.tunaiEdcItems = tunai_edc_items
            state.totalDonations = total_donations
            state.totalEdcDonations = total_edc_donations
            state.totalTunaiDonations = total_tunai_donations
            state.totalTunaiEdcDonations = total_tunai_edc_donations
            state.partnerItems = partner_items
            state.totalPartnerDonations = total_partner_donations
            state.goodsItems = goods_items
            state.totalGoodsDonations = total_goods_donations
        },
        // Set to default state actionLoading & error
        collectsReload: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
        },

    },
});

