import { toast } from "react-toastify";
import { toastOption } from "../_metronic/_helpers";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        store.dispatch({ type: "[Logout] Action" });
        
        if (!error.response.data) {
          error.message = "Sesi telah berakhir.";
        }

        if (error.response.data.message.startsWith("Token")) {
          error.response.data.message = "Sesi telah berakhir."
        }
      }

      return Promise.reject(error);
    }
  );
}
