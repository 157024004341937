/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export const ActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex,
    { selectedChecker },
) => (
    <>
        <OverlayTrigger
            overlay={<Tooltip id="products-edit-tooltip">Pilih donatur</Tooltip>}
        >
            <a
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={() => {
                    // if (window.confirm(`Memilih ${row.name} sebagai Checker?`)) {
                        selectedChecker(row);
                    // }
                }}
            >{row.id}
                <span className="svg-icon svg-icon-md svg-icon-success">
          <SVG
              src={toAbsoluteUrl('/media/svg/icons/Code/Done-circle.svg')}
          />
        </span>
            </a>
        </OverlayTrigger>
    </>
);
