import * as requestFromServer from './checkerCrud';
import { checkerSlice, callTypes } from './checkerSlice';
import { actions as auth } from '../../../modules/Auth/_redux/authRedux'
import { toastOption } from '../../../../_metronic/_helpers/ToastHelpers'
import { toast } from 'react-toastify'

const { actions } = checkerSlice;

export const fetchCheckers = (queryParams) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.findChecker(queryParams)
		.then(response => {
			const { data: { total_items, items, tunai_items, edc_items, total_tunai_donations, total_edc_donations, total_donations, total_partner_donations, total_goods_donations } } = response.data;
			dispatch(actions.checkersFetched({ total_items, items, tunai_items, edc_items, total_tunai_donations, total_edc_donations, total_donations, total_partner_donations, total_goods_donations }));
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};

export const fetchCheckerDetail = (id, status) => dispatch => {
	if (!id) {
		const setNull = {
			rows: undefined,
			count: 0
		}
		return dispatch(actions.checkerFetched(setNull));
	}

	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.getCheckerById(id, status)
		.then(response => {
			const { data: checker } = response.data;
			dispatch(actions.checkerFetched({ rows: checker, count: checker.length }));
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

// Update status Checkeror (Approved, Rejected)
export const updateStatusChecker = (data) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.updateCheckerStatus(data)
		.then(response => {
			dispatch(actions.checkersReload);
			return response.data;
		})
		.catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.action }));
		});
};

