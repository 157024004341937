import React from 'react';
import { ExportListUIProvider } from './ExportListUIContext';
import { ExportListCard } from './ExportListCard'

export function ExportListPage({ history }) {

	const exportListUIEvents = {
		// newExportListClick: () => {
		// 	history.push('/donasi/exportList/new');
		// },
		// openChooseExportListPage: (id) => {
		// 	history.push(`/donasi/exportList/${id}/choose`);
		// },
		// openEditExportListPage: (id) => {
		// 	history.push(`/donasi/exportList/${id}/edit`);
		// }
		reloadData: () => {
			history.push(`/master-data/notifikasi/notifikasi-konsolidasi`);
		}
	}

	return (
		<ExportListUIProvider exportListUIEvents={exportListUIEvents} history={history} >
			<ExportListCard />
		</ExportListUIProvider>
	);
}

