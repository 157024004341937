import axios from "axios";
import { DonationURL } from "../../../../config/UrlEndPoint";

export const ThkPartnershipUrl = `${DonationURL}thk_partnership`;

export const ThkPartnershipBatchUrl = `${DonationURL}thk_partnership_batch`;

//Get By ID
export function getPartnershipById(partnershipId) {
  return axios.get(`${ThkPartnershipUrl}/${partnershipId}`);
}

// items => filtered/sorted result for table data
export function findPartnership(queryParams) {
  return axios.post(`${ThkPartnershipUrl}/find`, queryParams);
}

// POST => POST: Create the partnership on the server
export function createPartnership(partnership) {
  return axios.post(ThkPartnershipUrl, partnership);
}

// UPDATE => PUT: update the partnership on the server
export function updatePartnership(partnership) {
  return axios.put(`${ThkPartnershipUrl}/${partnership.uuid}`, partnership);
}

// items => filtered/sorted result for table data
export function findPartnershipBatch(queryParams) {
  return axios.post(`${ThkPartnershipBatchUrl}/find`, queryParams);
}

export function validationImportData(params) {
  return axios.post(`${ThkPartnershipBatchUrl}/validation`, params);
}

export function createPartnershipBatch(params) {
  return axios.post(`${ThkPartnershipBatchUrl}/create_batch`, params);
}

export function createDonationPartnership(params) {
  return axios.post(`${ThkPartnershipBatchUrl}/create_donation`, params);
}

export function getThkPartnershipBatchById(id) {
  return axios.get(`${ThkPartnershipBatchUrl}/${id}`);
}

export function updateReportTemplate(periodTemplate, uuid) {
  return axios.put(`${ThkPartnershipUrl}/upload/${uuid}`, periodTemplate);
}

export function findPartnershipDonation(queryParams) {
  return axios.post(`${ThkPartnershipUrl}/find_partnership_donation`, queryParams);
}

export function getDonationCountPartnership(uuid) {
  return axios.get(`${ThkPartnershipUrl}/donation_count_partnership/${uuid}`);
}

export function getPartnershipBatchReport(payload) {
  return axios.post(`${ThkPartnershipUrl}/partnership_batch_report`, payload);
}

export function deletePartnershipBatchReport(payload) {
  const { thk_partnership_uuid, file_uuid } = payload;
  return axios.delete(
    `${ThkPartnershipUrl}/delete_partnership_batch_report/${thk_partnership_uuid}/${file_uuid}`
  );
}

