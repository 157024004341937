import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Input } from "../../../../../../../_metronic/_partials/controls";
import { sizePerPageList } from "../../PartnershipUIHelpers";
import { Field, Formik } from "formik";
import CurrencyInput from "react-currency-input-field";
import AsyncSelect from "react-select/async";
import { genOptionLoader } from "../../../../../../../_metronic/_utils/option-loaders";
import { findHewanKurban } from "../../../../../Donation/_redux/hewan-kurban/hewanKurbanCrud";
import { promiseTuplify } from "../../../../../../../_metronic/_utils/promise-utils";
import { getSelectorStyles } from "../../../../../../../_metronic/_helpers/style-helpers";

export default function ThkCampaignForm({
  show,
  onHide,
  onSave,
  thkPartnership = {},
  initialValues,
}) {
  const [options, setOptions] = useState({
    livestock: [],
  });

  useEffect(() => {
    if (show) {
      fetchLivestock();
    }
  }, [show]);

  async function fetchLivestock() {
    const [res, error] = await promiseTuplify(
      findHewanKurban({
        pageNumber: 1,
        pageSize: 20,
        filter: {
          periode: thkPartnership?.thk_period?.name,
        },
      })
    );

    if (error) {
      return;
    }

    if (res) {
      const { data: { data: { items = [] } } = {} } = res;

      const options = items.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        livestock: options,
      }));
    }
  }

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values) => onSave(values)}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => (
        <>
          <Modal size="lg" show={show} onHide={onHide}>
            <Modal.Header>
              <h4>Pilih Hewan Campaign</h4>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group row">
                <div className="col-lg-6">
                  <Field
                    name="name"
                    label="Nama Campaign"
                    placeholder="Nama Campaign"
                    component={Input}
                    withFeedbackLabel={false}
                  />
                </div>
                <div className="col-lg-6">
                  <label>Hewan</label>
                  <AsyncSelect
                    name="thk_livestock"
                    defaultOptions={options.livestock}
                    placeholder={"Pilih Hewan"}
                    loadOptions={genOptionLoader(
                      (inputValue) =>
                        findHewanKurban({
                          filter: {
                            name: inputValue,
                            periode: thkPartnership?.thk_period?.name,
                          },
                          pageNumber: 1,
                          pageSize: 20,
                        }),
                      "items",
                      ({ name, uuid }) => {
                        return {
                          label: name,
                          value: uuid,
                        };
                      }
                    )}
                    value={
                      values.thk_livestock && values.thk_livestock.uuid
                        ? {
                            label: values.thk_livestock.name,
                            value: values.thk_livestock.uuid,
                          }
                        : null
                    }
                    onChange={({ label: name, value: uuid }) => {
                      setFieldValue("thk_livestock", { uuid, name });
                    }}
                    styles={getSelectorStyles("thk_livestock", errors, touched)}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-lg-6">
                  <label>Harga Hewan Campaign</label>
                  <CurrencyInput
                    name="price"
                    placeholder="Masukkan Jumlah"
                    intlConfig={{ locale: "id-ID", currency: "IDR" }}
                    className="form-control"
                    decimalsLimit={2}
                    value={values.price}
                    label="Jumlah"
                    onValueChange={(value, name) => {
                      if (value) {
                        setFieldValue(name, +value);
                      } else {
                        setFieldValue(name, "");
                      }
                    }}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-secondary mx-1" onClick={onHide}>
                Batal
              </button>
              <button className="btn btn-primary mx-1" onClick={handleSubmit}>
                Simpan
              </button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </Formik>
  );
}
