import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import ReactSelect from "react-select";
import AsyikSelect from "react-select/async";
import * as yup from "yup";
import { Input, Select } from "../../../../_metronic/_partials/controls";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as reqDistrict from "../../MasterData/_redux/province/provinceCrud";
import * as reqCategory from "../../MasterData/_redux/master-category/MasterCategoryCrud";
import {
  isAlive,
  isActive,
  type,
  greetingValue,
  letterChoice,
  jenisKelamin,
  getDonaturInitialValues,
  pendidikan,
  DONATUR_IS_PROSPECT,
} from "../DonaturUIHelpers";
import { useLoadOptions } from "../../../hooks/use-load-options";
import { findDonorSources } from "../../MasterData/_redux/donor-source/donor-source-api";

const DonaturEditSchema = yup.object().shape({
  name: yup
    .string()
    .min(2, "Minimun 2 Character")
    .required("Nama wajb diisi"),
});

const DonaturEditForm = ({ donatur, btnRef, saveDonatur, parent_uuid }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [district, setDistrict] = useState([]);
  const [postalCode, setPostalCode] = useState([]);
  const [showPIC, setShowPIC] = useState("INDIVIDU");
  const [kotaValue, setKotaValue] = useState("");
  const [userCategoryList, setUserCategoryList] = useState([]);

  const [defaultDonaturSrcOptions, loadDonaturSrcOptions] = useLoadOptions(
    (input) => findDonorSources({ name: input, pageNumber: 1, pageSize: 15 }),
    "items",
    ({ uuid: value, name: label }) => ({ label, value })
  );

  useEffect(() => {
    setIsMounted(true);

    return () => {
      setIsMounted(false);
    };
  }, []);

  useEffect(() => {
    let value = donatur.district;
    let query = {
      district: value,
      city: value,
    };

    if (donatur.district !== null) {
      if (donatur.district !== "") {
        reqDistrict.findDistrict(query).then((response) => {
          setPostalCode(response.data.data[0].postalcode);
        });
      }
    }

    reqCategory.getMasterCategoryAll().then((response) => {
      setUserCategoryList(response.data.data.rows);
    });

    if (donatur.name) {
      if (!donatur.district && !donatur.city && !donatur.province) {
        setKotaValue(``);
      } else {
        setKotaValue(`${donatur.district}, ${donatur.city}, ${donatur.province}`);
      }
    } else {
      setKotaValue(``);
    }

    donatur.type === "" ? setShowPIC("INDIVIDU") : setShowPIC(donatur.type);
    // eslint-disable-next-line
  }, [donatur]);

  const onInputChange = (e) => {
    const value = e.target.value;

    if (value.length >= 3) {
      let query = {
        district: value,
        city: value,
      };
      reqDistrict.findDistrict(query).then((response) => {
        setDistrict(response.data.data);
      });
    }
  };

  const onTypeChange = (value, setFieldValue) => {
    if (value === "LEMBAGA" || value === "KOMUNITAS") {
      setShowPIC(value);
    } else {
      setShowPIC("INDIVIDU");
    }
  };

  const onSelectTag = (value, setFieldValue) => {
    if (value) {
      const array = value.split(",");
      if (array.length === 3) {
        let index = district.findIndex(
          (i) =>
            i.district === array[0].trim() &&
            i.city === array[1].trim() &&
            i.province_name === array[2].trim()
        );

        setPostalCode(district[index].postalcode);

        setFieldValue("province", district[index].province_name);
        setFieldValue("city", district[index].city);
        setFieldValue("district", district[index].district);
        setKotaValue(
          `${district[index].district}, ${district[index].city}, ${district[index].province_name}`
        );
      }
    } else {
      setPostalCode([]);
      setFieldValue("province", "");
      setFieldValue("city", "");
      setFieldValue("district", "");
      setKotaValue("");
      setFieldValue("postal_code", "");
    }
  };

  function submitDonatur(values, cb) {
    const { notification_allowed_types, donor_source, branch, ...data } = values;
    const { json_profile } = data;

    if (json_profile && json_profile.jenis_kelamin) {
      json_profile.jenis_kelamin = json_profile.jenis_kelamin.value;
    }

    if (json_profile && json_profile.pendidikan) {
      json_profile.pendidikan = json_profile.pendidikan.value;
    }

    data.donor_source_uuid = donor_source.value || null;

    data.notification_allowed_types = Object.entries(notification_allowed_types)
      .filter(([, val]) => val)
      .map(([t]) => t);

    saveDonatur(data, cb);
  }

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={getDonaturInitialValues(donatur)}
        validationSchema={DonaturEditSchema}
        onSubmit={(values, { setSubmitting }) => {
          if (values.type === "") {
            values.type = "INDIVIDU";
          }

          submitDonatur(values, () => {
            if (isMounted) {
              setSubmitting(false);
            }
          });
        }}
      >
        {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
          <Form className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-3 mb-5 mb-lg-0">
                <Select
                  name="type"
                  label="Tipe Donatur"
                  withFeedbackLabel={false}
                  disabled={parent_uuid}
                  onChange={(e) => {
                    onTypeChange(e.target.value, setFieldValue);
                    setFieldValue("type", e.target.value);
                  }}
                >
                  {type.map((row) => (
                    <option key={row.value} value={row.value}>
                      {row.name}
                    </option>
                  ))}
                </Select>
                <div className="mt-2">
                  <ErrorMessage
                    name="type"
                    render={(msg) => <span className="text-danger">{msg}</span>}
                  />
                </div>
              </div>
              <div className="col-lg-3 mb-5 mb-lg-0">
                <Select
                  name="is_prospect"
                  label="Kategori Prospek"
                  withFeedbackLabel={false}
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setFieldValue(e.target.name, true);
                    } else {
                      setFieldValue(e.target.name, false);
                    }
                  }}
                >
                  {DONATUR_IS_PROSPECT.map((row) => (
                    <option key={row.value} value={row.value}>
                      {row.name}
                    </option>
                  ))}
                </Select>
                <div className="mt-2">
                  <ErrorMessage
                    name="type"
                    render={(msg) => <span className="text-danger">{msg}</span>}
                  />
                </div>
              </div>
              {(showPIC === "LEMBAGA" || showPIC === "KOMUNITAS") && (
                <>
                  <div className="col-lg-4 mb-5 mb-lg-0">
                    <Field
                      name="pic"
                      component={Input}
                      placeholder="Nama PIC"
                      label="Nama PIC"
                    />
                  </div>
                  <div className="col-lg-3 mb-5 mb-lg-0">
                    <Field
                      name="pic_email"
                      component={Input}
                      placeholder="Email PIC"
                      label="Email PIC"
                    />
                  </div>
                  <div className="col-lg-2 mb-5 mb-lg-0">
                    <Field
                      name="pic_hp"
                      component={Input}
                      placeholder="Nomor hp PIC"
                      label="Nomor PIC"
                    />
                  </div>
                </>
              )}
            </div>
            <div className="form-group row">
              <div className="col-lg-3 mb-5 mb-lg-0">
                <Select
                  name="category_uuid"
                  label="Kategori Donatur"
                  disabled={parent_uuid}
                  withFeedbackLabel={false}
                  onChange={(e) => {
                    setFieldValue("category_uuid", e.target.value);
                  }}
                >
                  <option value="">Tidak Berkategori</option>
                  {userCategoryList.map((row) => (
                    <option key={row.uuid} value={row.uuid}>
                      {row.name}
                    </option>
                  ))}
                </Select>
                <div className="mt-2">
                  <ErrorMessage
                    name="category_uuid"
                    render={(msg) => <span className="text-danger">{msg}</span>}
                  />
                </div>
              </div>

              <div className="col-lg-3 mb-5 mb-lg-0">
                <label htmlFor="donor_source">Sumber Data Donatur</label>
                <AsyikSelect
                  isClearable
                  name="donor_source"
                  placeholder="Sumber Data Donatur..."
                  defaultOptions={defaultDonaturSrcOptions}
                  loadOptions={loadDonaturSrcOptions}
                  value={
                    values.donor_source.value
                      ? { ...values.donor_source }
                      : null
                  }
                  onChange={(selected) => {
                    if (!selected) {
                      return setFieldValue("donor_source", {
                        label: "",
                        value: "",
                      });
                    }

                    setFieldValue("donor_source", { ...selected });
                  }}
                />
                <div className="mt-2">
                  <ErrorMessage
                    name="category_uuid"
                    render={(msg) => <span className="text-danger">{msg}</span>}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="form-group row">
              <div className="col-lg-4 mb-5 mb-lg-0">
                <label htmlFor="notification_allowed_types">
                  Kirim Notifikasi Berdasarkan
                </label>
                <div className="my-3 d-flex flex-row align-items-center">
                  <input
                    id="notification_allowed_types_sms"
                    type="checkbox"
                    checked={values.notification_allowed_types.SMS}
                    onChange={(e) =>
                      setFieldValue("notification_allowed_types", {
                        ...values.notification_allowed_types,
                        SMS: e.target.checked,
                      })
                    }
                  />
                  <label
                    htmlFor="notification_allowed_types_sms"
                    className="m-0 ml-2 mb-0"
                  >
                    SMS
                  </label>
                </div>
                <div className="mb-3 d-flex flex-row align-items-center">
                  <input
                    id="notification_allowed_types_sms"
                    type="checkbox"
                    checked={values.notification_allowed_types.WHATSAPP}
                    onChange={(e) =>
                      setFieldValue("notification_allowed_types", {
                        ...values.notification_allowed_types,
                        WHATSAPP: e.target.checked,
                      })
                    }
                  />
                  <label
                    htmlFor="notification_allowed_types_sms"
                    className="m-0 ml-2 mb-0"
                  >
                    WHATSAPP
                  </label>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <input
                    id="notification_allowed_types_sms"
                    type="checkbox"
                    checked={values.notification_allowed_types.EMAIL}
                    onChange={(e) =>
                      setFieldValue("notification_allowed_types", {
                        ...values.notification_allowed_types,
                        EMAIL: e.target.checked,
                      })
                    }
                  />
                  <label
                    htmlFor="notification_allowed_types_sms"
                    className="m-0 ml-2 mb-0"
                  >
                    EMAIL
                  </label>
                </div>
              </div>
            </div>

            <hr />
            <div className="form-group row">
              <div className="col-lg-2">
                <Select name="greeting" label="Sapaan">
                  <option value="">-PILIH-</option>
                  {greetingValue.map((row) => (
                    <option key={row.value} value={row.value}>
                      {row.value}
                    </option>
                  ))}
                </Select>
              </div>
              <div className="col-lg-4">
                <Field
                  name="name"
                  component={Input}
                  placeholder="Name"
                  label="Nama Lengkap"
                />
              </div>
              <div className="col-lg-3">
                <Field
                  name="hp"
                  component={Input}
                  placeholder="Untuk Indonesia 08xx, Untuk Luar +72xx"
                  label="No. Hp"
                />
              </div>
              <div className="col-lg-3">
                <Field
                  name="hp2"
                  component={Input}
                  placeholder="Untuk Indonesia 08xx, Untuk Luar +72xx"
                  label="No. Hp 2"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-lg-3">
                <Field
                  name="email"
                  component={Input}
                  placeholder="Email"
                  label="Email"
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-8">
                <Autocomplete
                  id="searchPostalCode"
                  // disableClearable
                  onChange={(e, value) => {
                    onSelectTag(value, setFieldValue);
                  }}
                  value={kotaValue}
                  options={district.map(
                    (option) =>
                      `${option.district}, ${option.city}, ${option.province_name}`
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cari Kota / Kecamatan"
                      margin="normal"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: "search" }}
                      onChange={(e) => {
                        onInputChange(e);
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-lg-4">
                <Select name="postal_code" label="Kodepos">
                  <option value="">-PILIH-</option>
                  {postalCode.map((row) => (
                    <option key={row} value={row}>
                      {row}
                    </option>
                  ))}
                </Select>
              </div>
            </div>

            <div className="form-group row" style={{ display: "none" }}>
              <div className="col-lg-3">
                <Field
                  name="province"
                  component={Input}
                  placeholder="Provinsi"
                  label="Provinsi"
                  disabled={true}
                />
              </div>
              <div className="col-lg-4">
                <Field
                  name="city"
                  component={Input}
                  placeholder="Kota"
                  label="Kota"
                  disabled={true}
                />
              </div>
              <div className="col-lg-3">
                <Field
                  name="district"
                  component={Input}
                  placeholder="Kecamatan"
                  label="Kecamatan"
                  disabled={true}
                />
              </div>
            </div>
            <div className="form-group">
              <Field
                name="address"
                component={Input}
                placeholder="Address"
                label="Address"
              />
            </div>

            <div className="form-group row">
              <div className="col-lg-4">
                <Field
                  name="nik"
                  component={Input}
                  placeholder="NIK / Nomor KTP / Nomor Perusahaan"
                  label="NIK / Nomor KTP / Nomor Perusahaan"
                />
              </div>
              <div className="col-lg-4">
                <Field
                  name="npwp"
                  component={Input}
                  placeholder="NPWP"
                  label="NPWP"
                />
              </div>
              <div className="col-lg-4">
                <Field
                  name="npwz"
                  component={Input}
                  placeholder="NPWZ"
                  label="NPWZ"
                />
              </div>
            </div>

            <div className="form-group row">
              {(showPIC === "INDIVIDU" || parent_uuid || showPIC === "") && (
                <>
                  <div className="col-lg-4">
                    <Select name="is_alive" label="Status Hidup">
                      {isAlive.map((row) => (
                        <option key={row.value} value={row.value}>
                          {row.name}
                        </option>
                      ))}
                    </Select>
                  </div>
                </>
              )}

              <div className="col-lg-4">
                <Select name="is_active" label="Keaktifan">
                  {isActive.map((row) => (
                    <option key={row.value} value={row.value}>
                      {row.name}
                    </option>
                  ))}
                </Select>
              </div>

              <div className="col-lg-4">
                <Field
                  name="kode_rekening"
                  component={Input}
                  placeholder="Kode Tag"
                  label="Kode Rekening"
                />
              </div>
            </div>

            <div className="form-group row">
              <div className="col-lg-4">
                <Select name="is_letters" label="Terima Hard Copy">
                  <option value="">-PILIH-</option>
                  {letterChoice.map((row) => (
                    <option key={row.name} value={row.value}>
                      {row.name}
                    </option>
                  ))}
                </Select>
              </div>
            </div>

            <hr />

            <div className="form-group row mb-5">
              <div className="col-lg-4 mb-5 mb-lg-0">
                <label htmlFor="tanggal_lahir">Tanggal Lahir</label>
                <Field
                  type="date"
                  component={Input}
                  name="json_profile.tanggal_lahir"
                  withFeedbackLabel={false}
                  value={values.json_profile.tanggal_lahir || ""}
                  onChange={(e) => {
                    setFieldValue("json_profile.tanggal_lahir", e.target.value);
                  }}
                />
              </div>
              <div className="col-lg-4 mb-5 mb-lg-0">
                <label htmlFor="jenis_kelamin">Jenis Kelamin</label>
                <ReactSelect
                  isClearable
                  name="jenis_kelamin"
                  placeholder="Jenis Kelamin..."
                  options={jenisKelamin.map(({ value, name: label }) => ({
                    label,
                    value,
                  }))}
                  value={
                    values.json_profile.jenis_kelamin.value
                      ? { ...values.json_profile.jenis_kelamin }
                      : null
                  }
                  onChange={(selected) => {
                    if (!selected) {
                      return setFieldValue("jenis_kelamin");
                    }

                    setFieldValue("json_profile.jenis_kelamin", {
                      ...selected,
                    });
                  }}
                />
              </div>
              <div className="col-lg-4 mb-5 mb-lg-0">
                <label htmlFor="pekerjaan">Pekerjaan</label>
                <Field
                  component={Input}
                  name="json_profile.pekerjaan"
                  withFeedbackLabel={false}
                />
              </div>
            </div>

            <div className="form-group row mb-5">
              <div className="col-lg-4 mb-5 mb-lg-0">
                <label htmlFor="pendidikan">Pendidikan</label>
                <ReactSelect
                  name="pendidikan"
                  placeholder="Pendidikan Terakhir..."
                  options={pendidikan.map(({ value, name: label }) => ({
                    label,
                    value,
                  }))}
                  value={
                    values.json_profile.pendidikan.value
                      ? { ...values.json_profile.pendidikan }
                      : null
                  }
                  onChange={(selected) => {
                    if (!selected) {
                      return setFieldValue("json_profile.pendidikan", {
                        label: "",
                        value: "",
                      });
                    }

                    setFieldValue("json_profile.pendidikan", { ...selected });
                  }}
                />
              </div>
            </div>

            <hr />

            {/* <div className="form-group row">
							<div className="col-lg-4">
								<FormControlLabel
									control={
										<Checkbox
											name="is_email"
										/>
									}
									label="Notifikasi Email"
								/>
							</div>
							<div className="col-lg-4">
								<FormControlLabel
									control={
										<Checkbox
											name="is_letters"
										/>
									}
									label="Notifikasi Pengiriman Surat"
								/>
							</div>
						</div> */}

            <button
              type="submit"
              style={{ display: "none" }}
              ref={btnRef}
              onSubmit={() => handleSubmit()}
              disabled={isSubmitting}
            ></button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DonaturEditForm;
