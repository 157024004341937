import React from 'react';
import {isActiveLabel} from '../../DonaturUIHelpers'

export function StatusColumnFormatter(cellContent, row) {
    let value = isActiveLabel(cellContent)
    return (
        <span className={`label label-lg label-light-${value.type} label-inline`}>
            {value.name}
        </span>
    );
}
