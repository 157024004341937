import * as requestFromServer from './bankPoolingCrud';
import { bankPoolingSlice, callTypes } from './bankPoolingSlice';
import { actions as auth } from '../../../Auth/_redux/authRedux'
import { toastOption } from '../../../../../_metronic/_helpers/ToastHelpers'
import { toast } from 'react-toastify'

const { actions } = bankPoolingSlice;

export const fetchBankPoolings = (queryParams) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.list }));
    return requestFromServer.findBankPooling(queryParams)
        .then(response => {
            const { data: { total_items, items } } = response.data;
            dispatch(actions.bankPoolingsFetched({ total_items, items }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const fetchBankPooling = (id) => dispatch => {
    if (!id) {
        return dispatch(actions.bankPoolingFetched({ bankPoolingForEdit: undefined }));
    }

    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.getBankPoolingById(id)
        .then(response => {
            const { data: bankPooling } = response.data; // alias data -> donatur
            dispatch(actions.bankPoolingFetched({ bankPoolingForEdit: bankPooling }));
        }).catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const createBankPooling = (bankPoolingForCreation) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.createBankPooling(bankPoolingForCreation)
        .then(response => {
            dispatch(actions.bankPoolingReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};

export const updateBankPooling = (bankPooling) => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));
    return requestFromServer.updateBankPooling(bankPooling)
        .then(response => {
            dispatch(actions.bankPoolingReload);
            return response.data;
        })
        .catch(error => {
            if (error.response.status === 401) {
                toast.error(error.response.data.message, toastOption);
                dispatch(auth.logout())
            }
            error = error.response.data.message;
            dispatch(actions.catchError({ error, callType: callTypes.list }));
        });
};
