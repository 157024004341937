import React, { useEffect, useMemo, useState } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { usePeriodTemplateUIContext } from "../../PeriodTemplateUIContext";
import { initialFilter } from "../../PeriodTemplateUIHelpers";
import AsyncSelect from "react-select/async";
import ReactSelect from "react-select";
import { promiseTuplify } from "../../../../../../../_metronic/_utils/promise-utils";
import { findAllThkPeriods } from "../../../../../Donation/thk-period/thk-period-api";
import { genOptionLoader } from "../../../../../../../_metronic/_utils/option-loaders";
import { findDonaturs } from "../../../../../Donatur/_redux/donatur/donaturCrud";

const prepareFilter = (queryParams, values) => {
  const { name, thk_period, donor } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  //add to Object filter
  filter.name = name ?? "";
  filter.thk_period_uuid = thk_period.uuid ?? "";
  filter.donor_uuid = donor.uuid ?? "";
  newQueryParams.filter = filter;
  return newQueryParams;
};

export const PeriodTemplateFilter = ({ listLoading }) => {
  const [options, setOptions] = useState({
    period: [],
    donor: [],
  });

  useEffect(() => {
    fetchThkPeriods();
    fetchDonor();
  }, []);

  // PeriodTemplate UI Context
  const periodTemplateUIContext = usePeriodTemplateUIContext();
  const periodTemplateUIProps = useMemo(() => {
    return {
      setQueryParams: periodTemplateUIContext.setQueryParams,
      queryParams: periodTemplateUIContext.queryParams,
      filterType: periodTemplateUIContext.filterType,
    };
  }, [periodTemplateUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      periodTemplateUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, periodTemplateUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      periodTemplateUIProps.setQueryParams(newQueryParams);
    }
  };

  async function fetchThkPeriods() {
    const [res, error] = await promiseTuplify(findAllThkPeriods());

    if (error) {
      return;
    }

    if (res) {
      const { data: { data = [] } = {} } = res;

      const options = data.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        period: options,
      }));
    }
  }

  async function fetchDonor() {
    const [res, error] = await promiseTuplify(
      findDonaturs({
        pageNumber: 1,
        pageSize: 20,
        filter: { is_not_individual: true },
      })
    );

    if (error) {
      return;
    }

    if (res) {
      const { data: { data: { items = [] } } = {} } = res;

      const options = items.map(({ uuid, name, donor_id }) => ({
        label: `${name} - ${donor_id}`,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        donor: options,
      }));
    }
  }

  return (
    <Formik
      initialValues={initialFilter.filter}
      onSubmit={(values) => {
        applyFilter(values);
      }}
      onReset={(values) => {
        applyFilter(values);
      }}
    >
      {({ values, handleSubmit, handleBlur, setFieldValue, handleReset }) => (
        <form onSubmit={handleSubmit} className="form form-label-right">
          <div className="form-group row">
            <div className="col-lg-4">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Search"
                onBlur={handleBlur}
                value={values.name}
                onChange={(e) => {
                  setFieldValue("name", e.target.value);
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Berdasarkan Name
              </small>
            </div>
            <div className="col-lg-4 mb-5">
              <ReactSelect
                name="thk_period"
                placeholder={"pilih periode"}
                options={options.period}
                value={
                  values.thk_period && values.thk_period.uuid
                    ? {
                        label: values.thk_period.name ?? "",
                        value: values.thk_period.uuid ?? "",
                      }
                    : null
                }
                onChange={({ label: name, value: uuid }) => {
                  setFieldValue("thk_period", {
                    uuid,
                    name,
                  });
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Berdasarkan Name
              </small>
            </div>
            <div className="col-lg-4 mb-5">
              <AsyncSelect
                name="donor"
                defaultOptions={options.donor}
                placeholder={"Pilih donatur"}
                loadOptions={genOptionLoader(
                  (inputValue) =>
                    findDonaturs({
                      filter: { name: inputValue, is_not_individual: true },
                      pageNumber: 1,
                      pageSize: 50,
                    }),
                  "items",
                  ({ name, uuid, donor_id }) => {
                    const label = `${name} - ${donor_id}`;

                    return {
                      label,
                      value: uuid,
                    };
                  }
                )}
                value={
                  values.donor && values.donor.uuid
                    ? {
                        label: values.donor.name,
                        value: values.donor.uuid,
                      }
                    : null
                }
                onChange={({ label: name, value: uuid }) => {
                  setFieldValue("donor", { uuid, name });
                }}
              />
              <small className="form-text text-muted">
                <b>Cari</b> Donatur
              </small>
            </div>
          </div>
          <div className="form-group float-right">
            <button
              className="btn btn-outline-danger mx-1"
              onClick={handleReset}
            >
              Reset Pencarian
            </button>
            <button className="btn btn-warning mx-1" onClick={handleSubmit}>
              Terapkan
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
};
