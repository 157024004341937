import * as requestFromServer from './kurbanCrud';
import { kurbanSlice, callTypes } from './kurbanSlice';
import { actions as auth } from '../../../Auth/_redux/authRedux'
import { toastOption } from '../../../../../_metronic/_helpers/ToastHelpers'
import { toast } from 'react-toastify'

const { actions } = kurbanSlice;

export const fetchKurbanTableList = (queryParams) => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }));
	return requestFromServer
		.getKurbanTableList(queryParams)
		.then(response => {
			// const { data: { total_items, items, donation_item_array } } = response.data;
			// dispatch(actions.donationsFetched({ total_items, items, donation_item_array }));
			const { data: { total_items, items } } = response.data;
			dispatch(actions.kurbanListsFetched({ total_items, items }));
		}).catch(error => {
			if (error.response.status === 401) {
				toast.error(error.response.data.message, toastOption);
				dispatch(auth.logout())
			}
			error = error.response.data.message;
			dispatch(actions.catchError({ error, callType: callTypes.list }));
		});
};


