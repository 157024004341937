/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect } from "react";
import objectPath from "object-path";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../../../_metronic/layout";
import { KTUtil } from "../../../../../_metronic/_assets/js/components/util";
import { color } from "./StylingHelper";
import { CircularProgress } from "@material-ui/core";
import { currencyFormatter } from "../../../../../_metronic/_helpers";
import { pembilang } from "../../../../../_metronic/_helpers/angkaTerbilangFormatter";


export function TilesDonationWidget({ className, widgetHeight, chartID, loading = false, total, persen = 0, baseColor = "primary", target = 0, pertumbuhan = 0 }) {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray100: objectPath.get(
        uiService.config,
        "js.colors.gray.gray100"
      ),
      colorsGrayGray700: objectPath.get(
        uiService.config,
        "js.colors.gray.gray700"
      ),
      colorsThemeBaseSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.base.success"
      ),
      colorsThemeLightSuccess: objectPath.get(
        uiService.config,
        "js.colors.theme.light.success"
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  useEffect(() => {
    const element = document.getElementById(chartID);
    if (!element) {
      return;
    }

    const height = parseInt(KTUtil.css(element, "height"));
    const options = getChartOptions(layoutProps, height, persen);

    const chart = new ApexCharts(element, options);
    chart.render();
    return function cleanUp() {
      chart.destroy();
    };
  }, [layoutProps, loading]);

  function singkatPembilang(labelValue) {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e+9

      ? Math.abs(Number(labelValue)) / 1.0e+9 + " Miliar"
      // Six Zeroes for Millions 
      : Math.abs(Number(labelValue)) >= 1.0e+6

        ? Math.abs(Number(labelValue)) / 1.0e+6 + " Juta"
        // Three Zeroes for Thousands
        : Math.abs(Number(labelValue)) >= 1.0e+3

          ? Math.abs(Number(labelValue)) / 1.0e+3 + " Ribu"

          : Math.abs(Number(labelValue));
  }

  return (
    <>
      <div
        className={`card card-custom bg-${baseColor} ${className}`}
        style={{ height: widgetHeight }}
      >
        <div className={`card-body d-flex ${loading && "justify-content-center"} no-wrap`}>
          {loading ?

            <div className="text-center align-self-center"><CircularProgress /></div> :
            <div>
              <div className="row">
                <i
                  className="fas fa-tachometer-alt col-4"
                  style={{ color: "#fff", fontSize: "36px" }}
                ></i>
                <div
                  className={`text-inverse-${baseColor} font-weight-bolder col-8`}
                >
                  Capaian Target
                  <br />
                  Tahun {new Date().getFullYear()}
                </div>
              </div>
              <div className="flex-grow-1">
                <div id={`${chartID}`} style={{ height: "160px" }}></div>
              </div>
              <div className="row">
                <div className="col-12">
                  <a
                    href="#"
                    className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1`}
                  >
                    Target {new Date().getFullYear()}
                    <br />
                    {/* {currencyFormatter(target)} */}
                    {"Rp. " + singkatPembilang(target)}
                  </a>
                </div>
                <div className="col-12 mt-1">
                  <a
                    href="#"
                    className={`text-inverse-${baseColor} font-weight-bold font-size-lg mt-1 `}
                  >
                    Growth
                    <br />
                    <p className="text-nowrap">{pertumbuhan}%</p>

                  </a>
                </div>
              </div>

            </div>

          }
        </div>
      </div>
    </>
    // <div
    //   className={`card card-custom  d-flex no-wrap ${className}`}
    //   style={{ height: widgetHeight }}
    // >
    //   {/* Header */}
    //   <div className="card-header border-0 pt-5 align-self-center justify-content-center ">
    //     <div className="">
    //       <h3 className="card-title font-weight-bolder ">
    //         Retensi Donatur
    //         <i
    //           className="fas fa-star ml-2"
    //           style={{ color: color.primaryPurple }}
    //         ></i>
    //       </h3>
    //       <div className="flex-grow-1">
    //         <div id={`${chartID}`} style={{ height: "150px" }}></div>
    //         <h5 className="font-weight-bolder text-center">1 Tahun Terakhir</h5>
    //       </div>
    //     </div>

    //   </div>
    // </div>
  );
}

function getChartOptions(layoutProps, height, persen) {
  const options = {
    series: [persen],
    chart: {
      height: height,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "70%",
        },
        dataLabels: {
          showOn: "always",
          name: {
            show: false,
            fontWeight: "500",
          },
          value: {
            color: "#FFF",
            fontSize: "28px",
            fontWeight: "500",
            offsetY: 12,
            show: true,
          },
        },
        track: {
          background: color.secondaryPurple,
          strokeWidth: "100%",
        },
      },
    },
    colors: [color.white],
    stroke: {
      lineCap: "round",
    },
    labels: ["Progress"],
  };
  return options;
}
