import axios from 'axios';
import { MasterDataURL } from '../../../../config/UrlEndPoint';

export const CHANNEL_URL = `${MasterDataURL}channel`;

// Get All Channel List on Page Branch
export function findBranchChannels(queryParams, branchsId) {
    return axios.post(`${CHANNEL_URL}/find/${branchsId}`, queryParams);
}

export function findChannels(queryParams) {
    return axios.post(`${CHANNEL_URL}/find/`, queryParams)
}

// Get Channel Data
export function getChannelById(channelId) {
    return axios.get(`${CHANNEL_URL}/${channelId}`);
}

export function getChanelByType(type) {
    return axios.get(`${CHANNEL_URL}?type=${type}`);
}
export function getChanelByTypeNotDeleted(type) {
    return axios.get(`${CHANNEL_URL}/status/not_deleted?type=${type}`);
}

// CREATE =>  POST: add a new channel to the server
export function createChannel(channel) {
    return axios.post(CHANNEL_URL, channel);
}

// UPDATE => PUT: update the remark
export function updateChannel(channel) {
    return axios.put(`${CHANNEL_URL}/${channel.uuid}`, channel);
}

export function updateApiSecretChannel(channel) {
    return axios.put(`${CHANNEL_URL}/api-secret/${channel.uuid}`, channel);
}

export function migrateChannel(values) {
    return axios.post(`${CHANNEL_URL}/channel_migration`, values)
}

