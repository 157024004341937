import axios from 'axios';
import { MasterDataURL } from '../../../../config/UrlEndPoint';

export const URL = `${MasterDataURL}user`;

//Get By ID
export function getUserById(userId) {
    return axios.get(`${URL}/${userId}`);
}

// items => filtered/sorted result for table data
export function findUser(queryParams) {
    return axios.post(`${URL}/find`, queryParams);
}

// items => filtered/sorted result for table data
export function searchUsers(search) {
    return axios.post(`${URL}/search`, search);
}

// POST => POST: Create the user on the server
export function createUser(user) {
    return axios.post(URL, user);
}

// UPDATE => PUT: update the user on the server
export function updateUser(user) {
    return axios.put(`${URL}/${user.uuid}`, user);
}

// UPDATE => PUT: update the user password only on the server
export function updateUserPassword(user) {
    return axios.put(`${URL}/change-password`, user);
}
