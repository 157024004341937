import * as XLSX from "xlsx";

export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];

export const initialFilter = {
  filter: {
    thk_period: {},
    channel: {},
    report_template_type: "",
  },
  pageNumber: 1,
  pageSize: 10,
};

export const DONATION_STATUS = [
  { value: "DRAFT", label: "DRAFT" },
  { value: "COLLECT", label: "COLLECT" },
  { value: "RECOG", label: "RECOG" },
  { value: "SETTLE", label: "SETTLE" },
  { value: "VOID", label: "VOID" },
  { value: "REQUEST_VOID", label: "REQUEST VOID" },
];

export const KURBAN_STATUS = [
  { value: "NOT_DISTRIBUTED", label: "Belum Di Distribusi" },
  { value: "WAITING_PARTNER", label: "Belum Di proses" },
  { value: "ACCEPTED_PARTNER", label: "Siap Potong" },
  { value: "EXECUTED", label: "Terpotong" },
  { value: "REPORTED", label: "Laporan Potong" },
  { value: "FINISH", label: "FINISH" },
];
