import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { usePartnershipUIContext } from "./PartnershipUIContext";
import PartnershipTable from "./partnership-table/PartnershipTable";
import { PartnershipFilter } from "./partnership-table/partnership-filter/PartnershipFilter";
import { RoleChecker } from "../../../../RoleChecker";

export const PartnershipCard = () => {
  const partnershipUIContext = usePartnershipUIContext();
  const partnershipUIProps = useMemo(() => {
    return {
      newPartnershipButtonClick: partnershipUIContext.newPartnershipButtonClick,
    };
  }, [partnershipUIContext]);

  return (
    <Card>
      <CardHeader title="Partnership List">
        <CardHeaderToolbar>
          {RoleChecker("Create", "thk_partnership") && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={partnershipUIProps.newPartnershipButtonClick}
            >
              Partnership Baru
            </button>
          )}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <PartnershipFilter />
        <PartnershipTable />
      </CardBody>
    </Card>
  );
};
