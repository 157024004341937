import { createSlice } from '@reduxjs/toolkit';

const initialBankState = {
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    entities: null,
    bankForEdit: undefined,
    lastError: null,
};

export const callTypes = {
    list: 'list',
    action: 'action',
};

export const bankSlice = createSlice({
    name: 'bank',
    initialState: initialBankState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = false;
            } else {
                state.actionsLoading = false;
            }
        },
        startCall: (state, action) => {
            state.error = null;
            if (action.payload.callType === callTypes.list) {
                state.listLoading = true;
            } else {
                state.actionsLoading = true;
            }
        },

        // getBankById
        bankFetched: (state, action) => {
            state.actionsLoading = false;
            state.error = null;
            state.bankForEdit = action.payload.bankForEdit;
        },
        // findBank
        banksFetched: (state, action) => {
            const { total_items, items } = action.payload;
            state.listLoading = false;
            state.error = null;
            state.entities = items;
            state.totalCount = total_items;
        },
        // Set to default state actionLoading & error
        bankReload: (state, action) => {
            state.actionsLoading = false;
            state.listLoading = false;
            state.error = null;
        },

    },
});

