/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import ProgressBar from 'react-bootstrap/ProgressBar'
export const ActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex,
    { downloadFile, loadingProgress, showDeleteModal, setSelected, allowDelete }
) => (
    <>
        {
            loadingProgress ? (
                <ProgressBar now={loadingProgress} label={`${loadingProgress}%`} />
            ) : <button
                type="button"
                className="btn btn-sm btn-success mb-2"
                onClick={() => downloadFile(row)}
                disabled={row.file_uuid ? false : true}
            > Download File
            </button>
        }
        {allowDelete && <button
            type="button"
            className="btn btn-sm btn-danger mb-2 ml-2"
            onClick={() => {
                showDeleteModal()
                setSelected(row)
            }}
            disabled={loadingProgress === true || (row.status === "GENERATE_PDF" || row.status === "COMBINE_PDF")}
        > Hapus
        </button>}
        {/* <OverlayTrigger
                    overlay={<Tooltip id="detail-trx-tooltip">Edit Konfirmasi</Tooltip>}
                >
                    <a
                        className="btn btn-icon btn-light btn-hover-info btn-sm mx-2"
                        onClick={() => openEditExportListPage(row.uuid)}
                    >
                        <span className="svg-icon svg-icon-md svg-icon-warning">
                            <SVG
                                src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}

                            />
                        </span>
                    </a>
                </OverlayTrigger> */}

    </>
);
