import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { HewanKurbanCard } from './HewanKurbanCard';
import { HewanKurbanEditDialog } from './hewan-kurban-edit-dialog/HewanKurbanEditDialog';
import { HewanKurbanUIProvider } from './HewanKurbanUIContext';
import { PrivateRoute } from "../../../../../PrivateRoute";
import { RoleChecker } from '../../../../../RoleChecker';


export function HewanKurbanPage({ history }) {

    const hewanKurbanUIEvents = {
        newHewanKurbanButtonClick: () => {
            history.push('/qurban/kurban/hewan-kurban/new');
        },
        openEditHewanKurbanPage: (id) => {
            history.push(`/qurban/kurban/hewan-kurban/${id}/edit`);
        },
    };

    return (
        <HewanKurbanUIProvider hewanKurbanUIEvents={hewanKurbanUIEvents}>

            <Route path="/qurban/kurban/hewan-kurban/new">
                {({ history, match }) => (
                    <HewanKurbanEditDialog
                        show={match != null}
                        onHide={() => {
                            history.push('/qurban/kurban/hewan-kurban/');
                        }}
                    />
                )}
            </Route>

            <Route path="/qurban/kurban/hewan-kurban/:id/edit">
                {({ history, match }) => (
                    <HewanKurbanEditDialog
                        show={match != null}
                        id={match && match.params.id}
                        onHide={() => {
                            history.push('/qurban/kurban/hewan-kurban/');
                        }}
                    />
                )}
            </Route>



            <HewanKurbanCard />
        </HewanKurbanUIProvider>
    );
}
