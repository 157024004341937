import React from "react";
import { Table } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { currencyFormatter } from "../../../../../../../_metronic/_helpers";
import moment from "moment";

export default function ImportPreviewList({ data = [], thkCampaign = {} }) {
  const columns = [
    {
      text: "No",
      style: { cursor: "pointer" },
      formatter: (_, __, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      dataField: "channel_trx_id",
      text: "ID Transaksi",
      style: { cursor: "pointer" },
    },
    {
      dataField: "trx_date",
      text: "Tanggal Transaksi",
      style: { cursor: "pointer" },
    },
    {
      dataField: "donor",
      text: "Nama Donatur",
      style: { cursor: "pointer" },
    },
    {
      dataField: "hp",
      text: "HP",
      style: { cursor: "pointer" },
    },
    {
      dataField: "email",
      text: "Email",
      style: { cursor: "pointer" },
    },
    {
      dataField: "country",
      text: "Negara",
      style: { cursor: "pointer" },
    },
    {
      dataField: "province",
      text: "Provinsi",
      style: { cursor: "pointer" },
    },
    {
      dataField: "city",
      text: "Kota",
      style: { cursor: "pointer" },
    },
    {
      dataField: "district",
      text: "Kecamatan",
      style: { cursor: "pointer" },
    },
    {
      dataField: "amount",
      text: "Jumlah",
      style: { cursor: "pointer" },
      classes: "text-nowrap",
      formatter: (cell) => {
        const equalAmount = +cell === +thkCampaign.price;
        return (
          <div className={`text-${equalAmount ? "success" : "danger"}`}>
            {currencyFormatter(cell)}
          </div>
        );
      },
    },
    {
      dataField: "name_1",
      text: "Atas Nama 1",
      style: { cursor: "pointer" },
    },
  ];

  const expandRow = {
    showExpandColumn: true,
    renderer: (row) => {
      const { name_1, name_2, name_3, name_4, name_5, name_6, name_7 } = row;
      return (
        <Table borderless hover>
          <thead>
            <tr>
              <th>Atas Nama 1</th>
              <th>Atas Nama 2</th>
              <th>Atas Nama 3</th>
              <th>Atas Nama 4</th>
              <th>Atas Nama 5</th>
              <th>Atas Nama 6</th>
              <th>Atas Nama 7</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{name_1}</td>
              <td>{name_2}</td>
              <td>{name_3}</td>
              <td>{name_4}</td>
              <td>{name_5}</td>
              <td>{name_6}</td>
              <td>{name_7}</td>
            </tr>
          </tbody>
        </Table>
      );
    },
  };

  // const rowStyle = (row, rowIndex) => {
  //   console.log(`row`, row);
  //   return { border: "2px solid red" };
  // };

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="uuid"
        data={data}
        columns={columns}
        expandRow={expandRow}
        // rowStyle={rowStyle}
      />
    </>
  );
}
