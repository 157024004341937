import React, { useMemo } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { useDonaturTypeDialogUIContext } from './DonaturTypeDialogUIContext';
import { initialFilterForParent } from '../../DonaturUIHelpers'

const prepareFilter = ( queryParams, values ) => {
	const { name } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};

	//add to Object filter
	filter.name = name;

	newQueryParams.filter = filter;
	return newQueryParams;
};

export const DonaturTypeFilter = () => {

	const donaturTypeDialogUIContext = useDonaturTypeDialogUIContext();
	const donaturTypeUIProps = useMemo( () => {
		return {
			setQueryParams: donaturTypeDialogUIContext.setQueryParams,
			queryParams: donaturTypeDialogUIContext.queryParams,
		};
	}, [ donaturTypeDialogUIContext ] );


	const applyFilter = ( values ) => {
		const newQueryParams = prepareFilter( donaturTypeUIProps.queryParams, values );
		if ( !isEqual( newQueryParams, donaturTypeUIProps.queryParams ) ) {
			newQueryParams.pageNumber = 1;
			donaturTypeUIProps.setQueryParams( newQueryParams );
		}
	};

	return (
		<Formik initialValues={ initialFilterForParent.filter } onSubmit={ ( values ) => {
			applyFilter( values );
		} }
		>{ ( { values, handleSubmit, handleBlur, setFieldValue } ) => (
			<form onSubmit={ handleSubmit } className="form form-label-right">
				<div className="form-group row">
					<div className="col-lg-4">
						<input
							type="text"
							className="form-control"
							name="name"
							placeholder="Search"
							onBlur={ handleBlur }
							value={ values.name }
							onChange={ ( e ) => {
								setFieldValue( 'name', e.target.value );
								handleSubmit();
							} }
						/>
						<small className="form-text text-muted">
							<b>Cari</b> Berdasarkan Name
						</small>
					</div>
				</div>
			</form>
		) }
		</Formik>
	)

}