/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useRef, useState } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { DonorImportEditForm } from "./DonorImportEditForm";
import * as actions from "../_redux/donor-import-action";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toastOption } from "../../../../_metronic/_helpers";
import { toast } from "react-toastify";
import { ActionButton } from "../../../../_metronic/_partials/controls/ActionButton";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import Loading from "../../../../_metronic/_assets/js/components/loading";
import ModalEditDonor from "./ModalEditDonor";
import { Formik } from "formik";
import { getDonorImportInitialValues } from "../DonorImportUIHelpers";
import DonorValidTable from "./DonorValidTable";
import DonorInvalidTable from "./DonorInvalidTable";
import BootstrapTable from "react-bootstrap-table-next";
import DonorDetailList from "./DonorDetailList";

export function DonorImportEdit({
  history,
  match: {
    params: { id },
  },
}) {
  const dispatch = useDispatch();
  const { donorImportForEdit, error, actionsLoading, auth } = useSelector(
    (state) => ({
      donorImportForEdit: state.donorImport.donorImportForEdit,
      error: state.donorImport.error,
      actionsLoading: state.donorImport.actionsLoading,
      auth: state.auth,
    }),
    shallowEqual
  );
  if (error) {
    toast.error(error, toastOption);
  }

  const [donorRows, setDonorRows] = useState([]);
  const [errorRows, setErrorRows] = useState([]);

  const [isValidation, setIsValidation] = useState(true);
  const [isNotification, setIsNotification] = useState("");

  const [loading, setLoading] = useState({
    show: false,
    percentage: 0,
  });

  const [openEditDonor, setOpenEditDonor] = useState({
    show: false,
    initValues: {},
  });

  useEffect(() => {
    dispatch(actions.fetchDonorImport(id));
  }, [id, dispatch]);

  // * Save Import Donatur
  async function createImport() {
    const { data } = await dispatch(actions.createImport()).then((response) => {
      return response;
    });
    return data;
  }

  async function saveDonorImport(values) {
    setLoading((prev) => ({
      ...prev,
      show: true,
      percentage: 0,
    }));

    const donor_import = await createImport();

    const tempData = [...values];
    const percentage = (tempData.length * 100) / tempData.length;

    let currentPercentage = 0;
    const successProcessData = [];

    for (const item of values) {
      item.donor_import_uuid = donor_import.uuid;

      // ? Send Notification
      item.is_send_notification = isNotification;
      dispatch(actions.createDonorImport(item)).then((response) => {
        // ? Loading State
        successProcessData.push(response);
        currentPercentage = Math.floor(
          (successProcessData.length * 100) / tempData.length
        );

        setLoading((prev) => ({
          ...prev,
          percentage: currentPercentage,
        }));
        // ? Hide Loading
        if (percentage === currentPercentage) {
          setLoading((prev) => ({
            ...prev,
            show: false,
            percentage: 0,
          }));
          toast.success("Created Successfully", toastOption);
          backToList();
        }
      });
    }
  }

  function backToList() {
    history.push(`/donatur-import`);
  }

  function promptTemplateDownload() {
    const link = document.createElement("a");
    link.style.display = "none";
    link.href =
      "https://storage.googleapis.com/ozip-sandra/static/template-import-donatur.xlsx";
    link.download = "template-import-donatur.xlsx";

    document.body.appendChild(link);
    link.click();

    setTimeout(() => {
      URL.revokeObjectURL(link.href);
      link.parentNode.removeChild(link);
    }, 0);
  }

  const hideEditDonor = () => {
    setOpenEditDonor((prev) => ({
      ...prev,
      show: false,
      initValues: {},
    }));
  };

  // * Handle update data error
  const handleSaveEditDonor = (values) => {
    const currentItem = errorRows.find(
      (errItem) => errItem.data.uuid === values.uuid
    );
    setDonorRows((prev) => {
      const temp = [...prev];
      temp.push(values);
      return temp;
    });
    setErrorRows((prev) => {
      const temp = [...prev];
      const newArray = temp.filter(
        (item) => item.data.uuid !== currentItem?.data.uuid
      );
      return newArray;
    });
    hideEditDonor();
  };

  // * Validation Data
  const validateData = async (importData, isNotification) => {
    if (!isNotification) {
      return toast.error("Harap Pilih Kirim Notifikasi", toastOption);
    }

    if (importData.length <= 0) {
      return toast.error("Harap Memasukkan Data Excel", toastOption);
    }

    setLoading((prev) => ({
      ...prev,
      show: true,
      percentage: 0,
    }));
    const tempData = [...importData];
    const percentage = (tempData.length * 100) / tempData.length;

    let currentPercentage = 0;
    const successProcessData = [];

    const hasErrorData = [];

    for (const item of importData) {
      if (item.hp && item.hp.substring(0, 1) == "8") {
        item.hp = `0${item.hp}`;
      }

      await dispatch(actions.validateDonatur(item)).then((response) => {
        if (response.error.length !== 0) {
          hasErrorData.push(response);
          setDonorRows((prev) => {
            const temp = [...prev];
            const filteredNotError = temp.filter(
              (oldData) => oldData.uuid !== response.data.uuid
            );
            return filteredNotError;
          });
          // ? Set Error / Invalid Data
          setErrorRows((prev) => {
            const temp = [...prev];
            temp.push(response);
            return temp;
          });
        }
        // ? Loading State
        successProcessData.push(response);
        currentPercentage = Math.floor(
          (successProcessData.length * 100) / tempData.length
        );
        setLoading((prev) => ({
          ...prev,
          percentage: currentPercentage,
        }));
      });
    }

    if (hasErrorData.length === 0) setIsValidation(false);

    // ? Hide Loading
    if (percentage === currentPercentage) {
      setLoading((prev) => ({
        ...prev,
        show: false,
        percentage: 0,
      }));
    }
  };

  // ? Generate Template
  const createTemplate = async () => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = "Sandra";
    workbook.created = new Date();
    workbook.addWorksheet("import_donatur");

    const worksheet = workbook.getWorksheet("import_donatur");

    // template old
    // worksheet.columns = [
    //   { header: "No", key: "no", width: 5 },
    //   {
    //     header: "Tipe Donatur *",
    //     width: 15,
    //     key: "type",
    //   },
    //   {
    //     header: "Nama *",
    //     width: 30,
    //     key: "name",
    //   },
    //   {
    //     header: "Sapaan",
    //     width: 30,
    //     key: "greeting",
    //   },
    //   { header: "HP", width: 30, key: "hp" },
    //   {
    //     header: "Email",
    //     width: 30,
    //     key: "email",
    //   },
    //   {
    //     header: "Alamat",
    //     width: 50,
    //     key: "address",
    //   },
    //   {
    //     header: "Provinsi",
    //     width: 30,
    //     key: "province",
    //   },
    //   {
    //     header: "Kota",
    //     width: 15,
    //     key: "city",
    //   },
    //   {
    //     header: "Kecamatan",
    //     width: 15,
    //     key: "district",
    //   },
    //   {
    //     header: "Kodepos",
    //     width: 10,
    //     key: "postal_code",
    //   },
    //   {
    //     header: "NIK / Nomor KTP / Nomor Perusahaan",
    //     width: 30,
    //     key: "nik",
    //   },
    //   {
    //     header: "NPWP",
    //     width: 30,
    //     key: "npwp",
    //   },
    //   {
    //     header: "NPWZ",
    //     width: 30,
    //     key: "npwz",
    //   },
    //   {
    //     header: "Status Hidup",
    //     width: 10,
    //     key: "is_alive",
    //   },
    //   {
    //     header: "Status Keaktifan",
    //     width: 10,
    //     key: "is_active",
    //   },
    //   {
    //     header: "Kode Rekening",
    //     width: 30,
    //     key: "kode_rekening",
    //   },
    //   {
    //     header: "Tanggal Lahir *",
    //     width: 30,
    //     key: "tanggal_lahir",
    //   },
    //   {
    //     header: "Jenis Kelamin *",
    //     width: 30,
    //     key: "jenis_kelamin",
    //   },
    //   {
    //     header: "Pekerjaan",
    //     width: 30,
    //     key: "pekerjaan",
    //   },
    //   {
    //     header: "Pendidikan Terakhir *",
    //     width: 30,
    //     key: "pendidikan_terakhir",
    //   },
    // ];

    // template new
    worksheet.columns = [
      {
        header: "Sapaan",
        width: 30,
        key: "greeting",
      },
      {
        header: "Nama Donatur *",
        width: 30,
        key: "name",
      },
      {
        header: "Email *",
        width: 30,
        key: "email",
      },
      { header: "No HP *", 
        width: 30, 
        key: "hp" 
      },
      {
        header: "Alamat",
        width: 50,
        key: "address",
      },
      {
        header: "Tanggal Lahir",
        width: 30,
        key: "tanggal_lahir",
      },
      {
        header: "Jenis Kelamin",
        width: 30,
        key: "jenis_kelamin",
      },
      {
        header: "NPWP",
        width: 30,
        key: "npwp",
      },
      {
        header: "Kategori Prospek",
        width: 30,
        key: "kat_prospek",
      },
      {
        header: "Tipe Donatur",
        width: 15,
        key: "type",
      },
      {
        header: "Kategori Donatur",
        width: 15,
        key: "kat_dono",
      },
      {
        header: "Sumber Data Donatur",
        width: 15,
        key: "sumber_data",
      },
    ];

    // * Sapaan
    worksheet.dataValidations.add("A2:A1001", {
      type: "list",
      allowBlank: false,
      operator: "equal",
      formulae: ['"Bapak,Ibu,Mr.,Mrs.,Haji,Hajjah"'],
      showErrorMessage: true,
      errorStyle: "error",
      error: "Harap Input Sesuai List Sapaan",
    });

    // * Nama Donatur
    worksheet.dataValidations.add("B2:B1001", {
      type: "textLength",
      allowBlank: false,
      operator: "greaterThan",
      formulae: [0],
      showErrorMessage: true,
      errorStyle: "error",
      error: "Harap Input Nama Donatur",
    });

    // * Email
    worksheet.dataValidations.add("C2:C1001", {
      type: "textLength",
      allowBlank: false,
      operator: "greaterThan",
      formulae: [0],
      showErrorMessage: true,
      errorStyle: "error",
      error: "Harap Input Email Donatur",
    });

    // * No HP
    worksheet.dataValidations.add("D2:D1001", {
      type: "textLength",
      allowBlank: false,
      operator: "greaterThan",
      formulae: [0],
      showErrorMessage: true,
      errorStyle: "error",
      error: "Harap Input No HP Donatur",
    });

    // * Tanggal Lahir
    // worksheet.dataValidations.add("F2:F1001", {
    //   type: "date",
    //   allowBlank: false,
    //   showErrorMessage: true,
    //   errorStyle: "error",
    //   error: "Harap Input Tanggal Lahir",
    // });

    // * Jenis Kelamin
    worksheet.dataValidations.add("G2:G1001", {
      type: "list",
      allowBlank: true,
      operator: "equal",
      formulae: ['"L,P"'],
      showErrorMessage: true,
      errorStyle: "error",
      error: "Harap Input Sesuai List Jenis Kelamin",
    });

    // * Kategori Prospek
    worksheet.dataValidations.add("I2:I1001", {
      type: "list",
      allowBlank: true,
      operator: "equal",
      formulae: ['"PROSPEK,NON PROSPEK"'],
      showErrorMessage: true,
      errorStyle: "error",
      error: "Harap Input Sesuai List Kategori Prospek",
    });

    // * Tipe Donatur
    worksheet.dataValidations.add("J2:J1001", {
      type: "list",
      allowBlank: true,
      operator: "equal",
      formulae: ['"INDIVIDU,LEMBAGA,KOMUNITAS"'],
      showErrorMessage: true,
      errorStyle: "error",
      error: "Harap Input Sesuai List Tipe Donatur",
    });

     // * Kategori Donatur
     worksheet.dataValidations.add("K2:K1001", {
      type: "list",
      allowBlank: true,
      operator: "equal",
      formulae: ['"DEFAULT CATEGORY"'],
    });


    // ? Default Data
    let defaultDataLength = 3;
    for (let i = 2; i <= defaultDataLength; i++) {
      // ? Default Sapaan
      const sapaanCell = worksheet.getCell(`A${i}`);
      if (sapaanCell.value === null) {
        sapaanCell.value = '-';
      }

      // ? Default Alamat
      const alamatCell = worksheet.getCell(`E${i}`);
      if (alamatCell.value === null) {
        alamatCell.value = 'kosong';
      }

      // ? Default Tgl Lahir
      const tglLahirCell = worksheet.getCell(`F${i}`);
      if (tglLahirCell.value === null) {
        tglLahirCell.value = 'kosong';
      }

      // ? Default Jenis Kelamin
      const jnsKelaminCell = worksheet.getCell(`G${i}`);
      if (jnsKelaminCell.value === null) {
        jnsKelaminCell.value = '-';
      }

      // ? Default NPWP
      const npwpCell = worksheet.getCell(`H${i}`);
      if (npwpCell.value === null) {
        npwpCell.value = 'kosong';
      }

      // ? Default Prospek
      const prospekCell = worksheet.getCell(`I${i}`);
      if (prospekCell.value === null) {
        prospekCell.value = 'PROSPEK';
      }

      // ? Default Kategori Donatur
      const katDonaturCell = worksheet.getCell(`K${i}`);
      if (katDonaturCell.value === null) {
        katDonaturCell.value = 'DEFAULT CATEGORY';
      }

      // ? Default Sumber Data
      const sumberData = worksheet.getCell(`L${i}`);
      if (sumberData.value === null) {
        sumberData.value = 'kosong';
      }
     
    }
    

    worksheet.columns.forEach((column, i) => {
      var maxLength = 0;
      column["eachCell"]({ includeEmpty: true }, (cell) => {
        var columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength;
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(
        new Blob([buffer], {
          type: "application/octet-stream",
        }),
        `Template Import Donatur.xlsx`
      );
    });
  };

  return (
    <>
      <Loading {...loading} />
      <ModalEditDonor
        {...openEditDonor}
        onHide={hideEditDonor}
        onSubmit={handleSaveEditDonor}
      />
      {!id ? (
        <Formik
          ableReinitialize={true}
          initialValues={getDonorImportInitialValues(donorImportForEdit)}
          // validationSchema={DonorImportSchema}
          onSubmit={() => saveDonorImport(donorRows)}
        >
          {({ handleSubmit, values }) => (
            <>
              <Card>
                <CardHeader title={"Import Donatur Baru"}>
                  <CardHeaderToolbar>
                    <ActionButton
                      type="button"
                      variant="success"
                      className={"mr-3"}
                      onClick={createTemplate}
                    >
                      <div className="d-flex align-items-center">
                        <span className="mr-2">Download Template</span>
                      </div>
                    </ActionButton>
                    <ActionButton
                      type="button"
                      variant="secondary"
                      className={"mr-3"}
                      onClick={backToList}
                    >
                      <div className="d-flex align-items-center">
                        <i className="fa fa-arrow-left mr-2"></i>
                        <span>Kembali</span>
                      </div>
                    </ActionButton>
                  </CardHeaderToolbar>
                </CardHeader>
                <DonorImportEditForm
                  donorRows={donorRows}
                  setIsNotification={setIsNotification}
                  setDonorRows={setDonorRows}
                  errorRows={errorRows}
                  setErrorRows={setErrorRows}
                  setLoading={setLoading}
                  setOpenEditDonor={setOpenEditDonor}
                />
              </Card>
              {errorRows.length !== 0 && (
                <Card>
                  <CardHeader title="Data Tidak Valid" />
                  <CardBody>
                    <DonorInvalidTable
                      errorRows={errorRows}
                      setOpenEditDonor={setOpenEditDonor}
                    />
                  </CardBody>
                </Card>
              )}
              <Card>
                <CardHeader title="Data Valid" />
                <CardBody>
                  <DonorValidTable donorRows={donorRows} />
                </CardBody>
              </Card>
              <CardFooter className="d-flex justify-content-end">
                {isValidation ? (
                  <>
                    {errorRows.length === 0 && (
                      <ActionButton
                        variant="warning"
                        className="mx-1"
                        onClick={() => validateData(donorRows, isNotification)}
                        // loading={actionsLoading}
                        text="Validasi"
                      />
                    )}
                  </>
                ) : (
                  <>
                    <ActionButton
                      className="mx-1"
                      onClick={() => handleSubmit()}
                      // loading={actionsLoading}
                      text="Simpan"
                    />
                  </>
                )}
              </CardFooter>
            </>
          )}
        </Formik>
      ) : (
        <>
          <Card>
            <CardHeader title="Detail List Import Donatur">
              <CardHeaderToolbar>
                <ActionButton
                  type="button"
                  variant="secondary"
                  className={"mr-3"}
                  onClick={backToList}
                >
                  <div className="d-flex align-items-center">
                    <i className="fa fa-arrow-left mr-2"></i>
                    <span>Kembali</span>
                  </div>
                </ActionButton>
              </CardHeaderToolbar>
            </CardHeader>
            <CardBody>
              <DonorDetailList data={donorImportForEdit} />
            </CardBody>
          </Card>
        </>
      )}
    </>
  );
}
