import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { usePartnershipUIContext } from "../PartnershipUIContext";
import { PartnershipEditForm } from "./PartnershipEditForm";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../_redux/partnershipAction";
import { toast } from "react-toastify";
import { toastOption } from "../../../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderTitle,
  CardHeaderToolbar,
} from "../../../../../../_metronic/_partials/controls";
import { promiseTuplify } from "../../../../../../_metronic/_utils/promise-utils";
import { findAllThkPeriods } from "../../../../Donation/thk-period/thk-period-api";
import Loading from "../../../../../../_metronic/_assets/js/components/loading";
import { findDonaturs } from "../../../../Donatur/_redux/donatur/donaturCrud";
import { findChannels } from "../../../../MasterData/_redux/channels/channelsCrud";

export const PartnershipEditDialog = ({
  match: {
    params: { id },
  },
  history,
}) => {
  const [options, setOptions] = useState({
    period: [],
    donor: [],
  });
  // Partnership Redux state
  const dispatch = useDispatch();
  const { actionsLoading, partnershipForEdit, error } = useSelector(
    (state) => ({
      actionsLoading: state.thkPartnership.actionsLoading,
      partnershipForEdit: state.thkPartnership.partnershipForEdit,
      error: state.thkPartnership.error,
    }),
    shallowEqual
  );

  async function fetchThkPeriods() {
    const [res, error] = await promiseTuplify(findAllThkPeriods());

    if (error) {
      return;
    }

    if (res) {
      const { data: { data = [] } = {} } = res;

      const options = data.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        period: options,
      }));
    }
  }

  async function fetchChannel() {
    const [res, error] = await promiseTuplify(
      findChannels({
        pageNumber: 1,
        pageSize: 10,
        filter: { type: "ECHANNEL" },
      })
    );

    if (error) {
      return;
    }

    if (res) {
      const { data: { data = [] } = {} } = res;

      const options = data.items.map(({ uuid, name }) => ({
        label: name,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        channel: options,
      }));
    }
  }

  async function fetchDonor() {
    const [res, error] = await promiseTuplify(
      findDonaturs({
        pageNumber: 1,
        pageSize: 20,
        filter: { is_not_individual: true },
      })
    );

    if (error) {
      return;
    }

    if (res) {
      const { data: { data: { items = [] } } = {} } = res;

      const options = items.map(({ uuid, name, donor_id }) => ({
        label: `${name} - ${donor_id}`,
        value: uuid,
      }));

      setOptions((prev) => ({
        ...prev,
        donor: options,
      }));
    }
  }

  useEffect(() => {
    // server call for getting Partnership by id
    dispatch(actions.fetchPartnership(id));

    fetchThkPeriods();
    fetchDonor();
    fetchChannel();
  }, [id, dispatch]);

  const savePartnership = (payload) => {
    const data = {
      ...payload,
      thk_period_uuid: payload.thk_period.uuid,
      donor_uuid: payload.donor.uuid,
      channel_uuid: payload.channel.uuid,
    };
    if (!id) {
      dispatch(actions.createPartnership(data)).then((response) => {
        toast.success(response.message, toastOption);
        handleBack();
      });
    } else {
      dispatch(actions.updatePartnership(data)).then((response) => {
        toast.success(response.message, toastOption);
        dispatch(actions.reloadPartnershipState());
      });
    }
  };

  function handleBack() {
    history.goBack();
  }

  function getInitialValues() {
    let initValue = {};
    if (id && partnershipForEdit) {
      initValue = {
        ...partnershipForEdit,
        donor: {
          name: `${partnershipForEdit.donor.name} - ${partnershipForEdit.donor.donor_id}`,
          uuid: partnershipForEdit.donor.uuid,
        },
        channel: {
          name: partnershipForEdit.channel.name,
          uuid: partnershipForEdit.channel.uuid,
        },
      };
    } else {
    }

    return initValue;
  }

  return (
    <>
      <Loading show={actionsLoading} />
      <Card>
        <CardHeader title={`${id ? "Edit" : "Tambah"} Partnership`}>
          <CardHeaderToolbar>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleBack}
            >
              Kembali
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <PartnershipEditForm
            savePartnership={savePartnership}
            actionsLoading={actionsLoading}
            partnership={getInitialValues()}
            options={options}
          />
        </CardBody>
      </Card>
    </>
  );
};
