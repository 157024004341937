import * as requestFromServer from "./periodTemplateCrud";
import { periodTemplateSlice, callTypes } from "./periodTemplateSlice";
import { actions as auth } from "../../../Auth/_redux/authRedux";
import { toastOption } from "../../../../../_metronic/_helpers/ToastHelpers";
import { toast } from "react-toastify";

const { actions } = periodTemplateSlice;

export const fetchPeriodTemplates = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findPeriodTemplate(queryParams)
    .then((response) => {
      const {
        data: { total_items, items },
      } = response.data;
      dispatch(actions.periodTemplatesFetched({ total_items, items }));
    })
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error(error.response.data.message, toastOption);
        dispatch(auth.logout());
      }
      error = error.response.data.message;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchPeriodTemplate = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.periodTemplateFetched({ periodTemplateForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPeriodTemplateById(id)
    .then((response) => {
      const { data: periodTemplate } = response.data; // alias data -> donatur
      dispatch(
        actions.periodTemplateFetched({ periodTemplateForEdit: periodTemplate })
      );
    })
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error(error.response.data.message, toastOption);
        dispatch(auth.logout());
      }
      error = error.response.data.message;
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updatePeriodTemplate = (periodTemplate, uuid) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updatePeriodTemplate(periodTemplate, uuid)
    .then((response) => {
      dispatch(actions.periodTemplateReload());
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        toast.error(error.response.data.message, toastOption);
        dispatch(auth.logout());
      }
      error = error.response.data.message;
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const reloadPeriodTemplateState = () => (dispatch) => {
  dispatch(actions.periodTemplateReload());
};
