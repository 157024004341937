import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import * as columnFormatters from '../checker-table/column-formatters';
import { currencyFormatter } from "../../../../_metronic/_helpers/UtilityHelpers"
import moment from 'moment';
import { CheckerRequestForm } from './CheckerRequestForm';


export function CheckerRequestDialogList({ checker, onHide, approve, reject, printDiv, status_checker, printEdc, printCash, printGoods, printPartner }) {

	const [totalEdc, setTotalEdc] = useState(0)
	const [totalCash, setTotalCash] = useState(0)
	const [totalPartner, setTotalPartner] = useState(0)
	const [totalGoods, setTotalGoods] = useState(0)
	const [grandTotal, setGrandTotal] = useState(0)


	useEffect(() => {
		if (checker.length !== 0) {
			checker.forEach(item => {
				setTotalEdc(oldTotalEdc => oldTotalEdc += Number(item.payment_edc))
				setTotalCash(oldTotalCash => oldTotalCash += Number(item.payment_cash))
				setTotalPartner(oldTotalPartner => oldTotalPartner += Number(item.payment_counter_partner))
				setTotalGoods(oldTotalGoods => oldTotalGoods += Number(item.payment_goods))
				setGrandTotal(oldGrandTotal => oldGrandTotal += Number(item.trx_total))
			})
			// console.log(checker);
		}
	}, [checker])
	// console.log(totalEdc, totalCash, totalPartner);

	// useEffect(() => {
	// }, [newChecker])

	// console.log(checker);



	const columns = [
		{
			dataField: 'trx_date',
			text: 'Tanggal Transaksi',
			formatter: (cell, row) => {
				return new Date(cell).toLocaleDateString('id-ID')
			},
			footer: ''
		},
		{
			dataField: 'donation_hid',
			text: 'Donation HID',
			footer: ''
		},
		{
			dataField: 'channel_name',
			text: 'Nama Counter',
			footer: ''
		},
		{
			dataField: 'fr_name',
			text: 'Nama FR',
			footer: ''
		},

		{
			dataField: 'donor_name',
			text: 'Donatur',
			footer: ''
		},
		{
			dataField: 'payment_cash',
			text: 'CASH',
			classes: 'text-right',
			headerClasses: 'text-right',
			footerClasses: 'text-right',
			footer: `${currencyFormatter(totalCash)}`,
			formatter: columnFormatters.PriceColumnFormatter,
		},
		{
			dataField: 'payment_edc',
			text: 'EDC',
			classes: 'text-right',
			headerClasses: 'text-right',
			footerClasses: 'text-right',
			footer: `${currencyFormatter(totalEdc)}`,
			formatter: columnFormatters.PriceColumnFormatter,
		},
		{
			dataField: 'payment_counter_partner',
			text: 'MITRA KONTER',
			classes: 'text-right',
			headerClasses: 'text-right',
			footerClasses: 'text-right',
			footer: `${currencyFormatter(totalPartner)}`,
			formatter: columnFormatters.PriceColumnFormatter,
		},
		{
			dataField: 'payment_goods',
			text: 'BARANG',
			classes: 'text-right',
			headerClasses: 'text-right',
			footerClasses: 'text-right',
			footer: `${currencyFormatter(totalGoods)}`,
			formatter: columnFormatters.PriceColumnFormatter,
		},
		{
			dataField: 'trx_total',
			text: 'Jumlah',
			classes: 'text-right',
			headerClasses: 'text-right',
			footerClasses: 'text-right',
			footer: `${currencyFormatter(grandTotal)}`,
			formatter: columnFormatters.PriceColumnFormatter,
		},
	];

	return (
		<Modal.Body className="overlay overlay-block cursor-default">
			<h6>Batch UUID : {checker && checker.length !== 0 ? checker[0].settle_batch_uuid : ""} </h6>
			<h6>Collector : {checker && checker.length !== 0 ? checker[0].collect_user_name : ""} </h6>
			<BootstrapTable
				wrapperClasses="table-responsive"
				classes="table table-head-custom table-vertical-center overflow-hidden"
				bootstrap4
				bordered={false}
				remote
				keyField="settle_batch_uuid"
				data={checker === null ? [] : checker}
				columns={columns}
			>
			</BootstrapTable>
			<CheckerRequestForm
				// saveChecker={ saveChecker }
				status_checker={status_checker}
				approve={approve}
				reject={reject}
				onHide={onHide}
				printDiv={printDiv}
				checker={checker}
				printEdc={printEdc}
				printCash={printCash}
				printPartner={printPartner}
				printGoods={printGoods}
				totalCash={totalCash}
				totalEdc={totalEdc}
				totalPartner={totalPartner}
				totalGoods={totalGoods}
			/>
		</Modal.Body>
	);
};
