import React from 'react';
import {Route, Redirect} from 'react-router-dom';

// End List Roles
export const PrivateRoute = ({
                                 role,
                                 component: Component,
                                 ...rest
                             }) => (
    <Route {...rest} component={(props) => (
        role ? (
            <Component {...props} />
        ) : (
            <Redirect to={{pathname: "/", state: {from: props.location}}}/>
        )
    )}/>
)
