/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import {toAbsoluteUrl, toastOption} from '../../../../../_metronic/_helpers';
import {addParent} from '../../_redux/donatur/donaturCrud'
import {toast} from 'react-toastify'

export const ActionsColumnFormatter = (
    cellContent,
    row,
    rowIndex,
    {onHide, parent, setGetChilds},
) => {

    // const dispatch = useDispatch()
    const saveDonaturMember = async (val) => {
        const value = {
            donor_uuid: val.uuid,
            parent_uuid: parent.uuid,
        }
        await addParent(value).then((response) => {
            toast.success(response.data.message, toastOption)
            setGetChilds( true )
            onHide()
        }).catch(error => {
            toast.error(error.response.data.message, toastOption)
        })
    }

    return (
        <>
            <OverlayTrigger
                overlay={<Tooltip id="products-edit-tooltip">Pilih donatur</Tooltip>}
            >
                <a
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={() => {
                        if (window.confirm(`Memilih ${row.name} sebagai donatur member?`)) {
                            saveDonaturMember(row);
                        }
                    }}
                >{row.id}
                    <span className="svg-icon svg-icon-md svg-icon-success">
          <SVG
              src={toAbsoluteUrl('/media/svg/icons/Code/Done-circle.svg')}
          />
        </span>
                </a>
            </OverlayTrigger>
        </>
    );
}
