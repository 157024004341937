import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { isEqual } from 'lodash';
import { useExportListUIContext } from '../ExportListUIContext';
import { initialFilter } from '../ExportListUIHelpers'
import { getMasterCategoryAll } from '../_redux/exportListCrud'
import { DateRangePicker } from 'react-dates';
import moment from 'moment'



const prepareFilter = (queryParams, values) => {
    const {
        name_file,
        start_date,
        end_date,
        type,
    } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};

    //add to Object filter
    filter.name_file = name_file ?? '';
    filter.type = type ?? '';
    filter.start_date = start_date ?? '';
    filter.end_date = end_date ?? '';
    filter.read_own = newQueryParams.filter.read_own

    newQueryParams.filter = filter;
    return newQueryParams;
};

export const ExportListFilter = () => {

    const exportListUIContext = useExportListUIContext();
    const exportListUIProps = useMemo(() => {
        return {
            setQueryParams: exportListUIContext.setQueryParams,
            queryParams: exportListUIContext.queryParams,
            filterType: exportListUIContext.filterType,
        };
    }, [exportListUIContext]);


    // USE STATE
    const [onFocused, setOnFocused] = useState(null)
    const [onFocusedTo, setOnFocusedTo] = useState(null)
    const [onFocusedEmail, setOnFocusedEmail] = useState(null)
    const [category, setCategory] = useState([]);
    const [tanggal, setTanggal] = useState(
        {
            start_date: null,
            end_date: null,
        }
    )
    const applyFilter = (values) => {
        const newQueryParams = prepareFilter(exportListUIProps.queryParams, values);
        if (!isEqual(newQueryParams, exportListUIProps.queryParams)) {
            newQueryParams.pageNumber = 1;
            exportListUIProps.setQueryParams(newQueryParams);
        }
    };

    // const fetchCategory = async () => {
    //     const result = await getMasterCategoryAll();
    //     setCategory(result.data.data.rows);
    // };

    // useEffect(() => {
    //     fetchCategory()
    //     // eslint-disable-next-line
    // }, []);

    return (
        <Formik initialValues={initialFilter.filter} onSubmit={(values) => {
            applyFilter(values);
        }}
        >
            {({ values, handleSubmit, handleBlur, setFieldValue }) => (
                <form onSubmit={handleSubmit} className="form form-label-right">

                    {/*ROW 1 BY DONATUR*/}
                    <div className="form-group row">

                        {/*ROW 2*/}

                        <div className="col-lg-4">
                            <input
                                type="text"
                                className="form-control"
                                name="name_file"
                                placeholder="Search"
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    setFieldValue('name_file', e.target.value);
                                    handleSubmit();
                                }}
                            />
                            <small className="form-text text-muted">
                                <b>Cari</b> Nama File
                            </small>
                        </div>

                        <div className="col-lg-4">
                            <select name="type"
                                className="form-control"
                                onChange={e => {
                                    handleSubmit(e)
                                    setFieldValue('type', e.target.value)
                                }}
                            >
                                <option value="">--ALL--</option>
                                <option value="SANDRA_DONATION">SANDRA_DONATION</option>
                                <option value="SANDRA_DONOR">SANDRA_DONOR</option>
                                <option value="SANDRA_CONSOLIDATION">SANDRA_CONSOLIDATION</option>
                                <option value="SANDRA_WAKAF">SANDRA_WAKAF</option>

                            </select>
                            <small className="form-text text-muted">
                                <b>Cari</b> Type
                            </small>
                        </div>
                        <div className="col-lg-4">
                            <DateRangePicker
                                regular={true}
                                startDate={tanggal.start_date}
                                endDate={tanggal.end_date}
                                onDatesChange={({ startDate, endDate }) => {
                                    setTanggal({
                                        start_date: startDate,
                                        end_date: endDate
                                    })
                                    setFieldValue('start_date', startDate)
                                    setFieldValue('end_date', endDate)
                                    handleSubmit()
                                }}
                                focusedInput={onFocused}
                                onFocusChange={focusedInput => setOnFocused(focusedInput)}
                                numberOfMonths={1}
                                isOutsideRange={() => false}
                                showClearDates={true}
                                startDateId="start_date"
                                endDateId="end_date"
                                hideKeyboardShortcutsPanel={true}
                            />
                            <small className="form-text text-muted">
                                <b>Cari</b> Berdasarkan Rentan Waktu <b>Export</b>
                            </small>
                        </div>
                    </div>

                </form>
            )}
        </Formik>

    );

};

