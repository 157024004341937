import * as XLSX from "xlsx";

export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
];

export const initialFilter = {
  filter: {
    name: "",
    thk_period: {},
    donor: {},
  },
  pageNumber: 1,
  pageSize: 10,
};

export const THK_IMPORT_FIELDS = {
  "ID Transaksi": "channel_trx_id",
  "Tanggal Transaksi": "trx_date",
  "Nama Donatur": "donor",
  Negara: "country",
  Provinsi: "province",
  Kota: "city",
  Kecamatan: "district",
  Alamat: "address",
  Jumlah: "amount",
  HP: "hp",
  Email: "email",
  "Atas Nama 1": "name_1",
  "Atas Nama 2": "name_2",
  "Atas Nama 3": "name_3",
  "Atas Nama 4": "name_4",
  "Atas Nama 5": "name_5",
  "Atas Nama 6": "name_6",
  "Atas Nama 7": "name_7",
};

export const THK_IMPORT_LABELS = ["Atas Nama", "HP", "Email"];

export async function readSpreadsheet(file) {
  if (!(file instanceof File)) {
    return;
  }

  const data = await file.arrayBuffer();
  const workbook = XLSX.read(data);
  const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  const sheet_json = XLSX.utils.sheet_to_json(worksheet);

  return sheet_json;
}

export const DONATION_STATUS = [
  { value: "DRAFT", label: "DRAFT" },
  { value: "COLLECT", label: "COLLECT" },
  { value: "RECOG", label: "RECOG" },
  { value: "SETTLE", label: "SETTLE" },
  { value: "VOID", label: "VOID" },
  { value: "REQUEST_VOID", label: "REQUEST VOID" },
];

export const KURBAN_STATUS = [
  { value: "NOT_DISTRIBUTED", label: "Belum Di Distribusi" },
  { value: "WAITING_PARTNER", label: "Belum Di proses" },
  { value: "ACCEPTED_PARTNER", label: "Siap Potong" },
  { value: "EXECUTED", label: "Terpotong" },
  { value: "REPORTED", label: "Laporan Potong" },
  { value: "FINISH", label: "FINISH" },
];
