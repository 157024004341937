import React, { useEffect, useMemo, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as actions from '../../_redux/donatur-double-name/doubleNameAction';
import * as uiHelpers from '../DoubleNameUIHelpers';

import {
    getHandlerTableChange,
    NoRecordsFoundMessage,
    PleaseWaitMessage,
} from '../../../../../_metronic/_helpers';
import * as columnFormatters from './column-formatters';
import { Pagination } from '../../../../../_metronic/_partials/controls';
import { useDoubleNameUIContext } from '../DoubleNameUIContext';
import { findDoubleNameDetail } from '../../_redux/donatur-double-name/doubleNameCrud';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
// import { ToastContainer } from 'react-toastify';

const DoubleNameTableDetail = (props) => {
    // DoubleName UI Context
    const doubleNameUIContext = useDoubleNameUIContext();
    const doubleNameUIProps = useMemo(() => {
        return {
            queryParamsDetail: doubleNameUIContext.queryParamsDetail,
            setQueryParamsDetail: doubleNameUIContext.setQueryParamsDetail,
            selectedDonor: doubleNameUIContext.selectedDonor
        };
    }, [doubleNameUIContext]);


    const [donorList, setDonorList] = useState([])
    const [donorData, setDonorData] = useState(null)
    const [loadingList, setLoadingList] = useState(false)

    const getDonaturs = () => {
        setLoadingList(true)
        findDoubleNameDetail(doubleNameUIProps.queryParamsDetail).then((response) => {
            setDonorList(response.data.data.items)
            setDonorData(response.data.data)
            setLoadingList(false)

        }).catch((error) => {
            setLoadingList(false)
            if (error.response) {
                toast.error(error.response.data.message)
            } else {
                toast.error("Gagal Mendapatkan Donatur")
            }
        })

    }
    useEffect(() => {
        getDonaturs()
    }, [doubleNameUIProps.queryParamsDetail]);
    // Getting curret state of doubleName list from store (Redux)

    // Table columns
    const columns = [
        {
            dataField: 'donor_id',
            text: 'ID Donatur',
        },
        {
            dataField: 'name',
            text: 'Nama',
        },
        {
            dataField: 'hp',
            text: 'HP',
        },
        {
            dataField: 'email',
            text: 'Email',
        },
        {
            dataField: 'qty_donation',
            text: 'Qty Donasi',
        },
        {
            dataField: 'uuid',
            text: "Detail Donatur",
            formatter: (cell, row) => {
                return <Link to={`/donatur/${cell}/edit`}>
                    <button className='btn btn-info'>Detail Donatur</button>
                </Link>
            }
        }
    ];

    // const selectRow = {
    //     mode: 'radio',
    //     clickToSelect: true,
    //     style: { backgroundColor: '#DFF6FF' }
    // };

    // const rowEvents = {
    //     onClick: (e, row, rowIndex) => {
    //         doubleNameUIProps.setSelectedDonor(row.donor_name)
    //     }
    // };

    // Table pagination properties
    const paginationOptions = {
        custom: true,
        totalSize: donorData ? donorData.total_items : 0,
        sizePerPageList: uiHelpers.sizePerPageList,
        sizePerPage: doubleNameUIProps.queryParamsDetail.pageSize,
        page: doubleNameUIProps.queryParamsDetail.pageNumber,
    };

    return (
        <>
            {loadingList ?
                <div className='d-flex justify-content-center align-items-center'>
                    <CircularProgress />
                </div>
                :
                <PaginationProvider pagination={paginationFactory(paginationOptions)}>
                    {({ paginationProps, paginationTableProps }) => {
                        return (
                            <Pagination
                                isLoading={loadingList}
                                paginationProps={paginationProps}
                            >
                                <BootstrapTable
                                    wrapperClasses="table-responsive"
                                    classes="table table-head-custom table-vertical-center overflow-hidden"
                                    bootstrap4
                                    bordered={false}
                                    remote
                                    keyField="uuid"
                                    data={donorData === null ? [] : donorList}
                                    columns={columns}
                                    // defaultSorted={uiHelpers.defaultSorted}
                                    onTableChange={getHandlerTableChange(
                                        doubleNameUIProps.setQueryParamsDetail,
                                    )}
                                    {...paginationTableProps}
                                >
                                    <PleaseWaitMessage entities={donorList} />
                                    <NoRecordsFoundMessage entities={donorList} />
                                </BootstrapTable>
                            </Pagination>
                        );
                    }}
                </PaginationProvider>}
        </>
    );
};

export default DoubleNameTableDetail;
