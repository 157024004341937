import React, { createContext, useCallback, useContext, useState } from "react";
import { isEqual, isFunction } from "lodash";

const AolConfigUIContext = createContext();

export function useAolConfigUIContext() {
  return useContext(AolConfigUIContext);
}

export const AolConfigUIConsumer = AolConfigUIContext.Consumer;

export function AolConfigUIProvider({ aolConfigUIEvents, children }) {
  const value = {};

  return (
    <AolConfigUIContext.Provider value={value}>
      {children}
    </AolConfigUIContext.Provider>
  );
}
