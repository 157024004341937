import React, { createContext, useCallback, useContext, useState } from 'react';
import { isEqual, isFunction } from 'lodash';
import { initialFilter } from './DonaturUIHelpers';

const DonaturUIContext = createContext();

export function useDonaturUIContext() {
    return useContext(DonaturUIContext);
}

export const DonaturUIConsumer = DonaturUIContext.Consumer;

export function DonaturUIProvider({ donaturUIEvents, children }) {

    const [queryParams, setQueryParamsBase] = useState(initialFilter);
    const [ids, setIds] = useState([]);
    const setQueryParams = useCallback((nextQueryParams) => {
        setQueryParamsBase((prevQueryParams) => {
            if (isFunction(nextQueryParams)) {
                nextQueryParams = nextQueryParams(prevQueryParams);
            }

            if (isEqual(prevQueryParams, nextQueryParams)) {
                return prevQueryParams;
            }

            return nextQueryParams;
        });
    }, []);

    const [showExportDonorDialog, setShowExportDonorDialog] = useState(false)
    const closeExportDonorDialog = () => {
        setShowExportDonorDialog(false);
    };
    const openExportDonorDialog = () => {
        setShowExportDonorDialog(true);
    };
    const value = {
        queryParams,
        setQueryParamsBase,
        ids,
        setIds,
        setQueryParams,
        newDonaturButtonClick: donaturUIEvents.newDonaturButtonClick,
        openEditDonaturPage: donaturUIEvents.openEditDonaturPage,
        openNewDonaturMember: donaturUIEvents.openNewDonaturMember,
        openDetailDonationPage: donaturUIEvents.openDetailDonationPage,
        showExportDonorDialog: showExportDonorDialog,
        closeExportDonorDialog,
        openExportDonorDialog
    };


    return (
        <DonaturUIContext.Provider value={value}>
            {children}
        </DonaturUIContext.Provider>
    );
}
